import { ApiConfig } from '../../../config/apiConfig';

const BASE_API_URL = ApiConfig();

export const GET_SUPPLIER_FILTERS = 'GET_SUPPLIER_FILTERS';
export const GET_SUPPLIER_FILTERS_URL = BASE_API_URL + 'Common/supplierFilters?clientID=2&langKey=enGB&role=APB_ADMIN'
export const UPDATE_SUPPLIER_FILTERS_ACTION = 'UPDATE_SUPPLIER_FILTERS_ACTION';

export const GET_SUPPLIER_FIELDS_ACTION = 'GET_SUPPLIER_FIELDS_ACTION';
export const GET_SUPPLIER_FIELDS_URL = BASE_API_URL + 'Common/supplierfields';
export const UPDATE_SUPPLIER_FIELDS_ACTION = 'UPDATE_SUPPLIER_FIELDS_ACTION';

export const UPDATE_SUPPLIER_COLUMNS = 'UPDATE_SUPPLIER_COLUMNS';

export const GET_SUPPLIERS_ACTION = 'GET_SUPPLIERS_ACTION';

export const GET_SUPPLIER_LIST_URL = BASE_API_URL + 'Common/suppliers';

export const UPDATE_SUPPLIER_LIST_ACTION = 'UPDATE_SUPPLIER_LIST_ACTION';

export const GET_SUPPLIER_DETAILS_BY_ID = 'GET_SUPPLIER_DETAILS_BY_ID';

export const GET_SUPPLIER_DETAILS_BY_ID_URL = BASE_API_URL + 'Common/filterById';

export const UPDTAE_SUPPLIER_LIST_BY_ID = 'UPDTAE_SUPPLIER_LIST_BY_ID';

export const GET_SUPPLIERS_BY_FILTER_SEARCH = 'GET_SUPPLIERS_BY_FILTER_SEARCH';

export const GET_SUPPLIERS_BY_FILTER_SEARCH_URL = BASE_API_URL + 'Common/suppliers';
export const UPDATE_SELECTED_SUPPLIERS_ACTION = 'UPDATE_SELECTED_SUPPLIERS_ACTION';

export const UPDATE_SHOW_SUPPLIER_MODAL = 'UPDATE_SHOW_SUPPLIER_MODAL';