import React from "react";
import Breadcrumb from "./Breadcrumb";

import { generateFilteredRoutes } from "../../routes/generateFilteredRoutes";
import { Route, Switch } from "react-router-dom";
import { commonAuthRoutes } from "../../routes/routes";
import { compose } from "redux";
import { connect } from "react-redux";
import { selectClientDetails } from "../../container/LoginPage/store/selectors";
import { createStructuredSelector } from "reselect";

const MainContentHead = (props) => {
  const sessionDetails = JSON.parse(sessionStorage.getItem("sessionDetails"));
  const isAuth = sessionDetails.isAuthenticated;

  const filteredRoutes = generateFilteredRoutes(
    sessionDetails.menus,
    props.clientDetails
  );
  const updatedRoutes = isAuth ? [...filteredRoutes, ...commonAuthRoutes] : [];
  return (
    <Switch>
      {updatedRoutes &&
        updatedRoutes.map(({ path, name, Component }, key) => (
          <Route
            exact
            path={path}
            key={key}
            render={(routeProps) => {
              const crumbs = updatedRoutes
                .filter(({ path }) => routeProps.match.path.includes(path))
                .map(({ ...rest }) => ({
                  path: Object.keys(routeProps.match.params).length
                    ? Object.keys(routeProps.match.params).reduce(
                        (param) =>
                          path.replace(
                            `:${param}`,
                            routeProps.match.params[param]
                          ),
                        path
                      )
                    : path,
                  ...rest,
                }));

              return <Breadcrumb crumbs={crumbs} />;
            }}
          />
        ))}
    </Switch>
  );
};

const mapStateToProps = createStructuredSelector({
  clientDetails: selectClientDetails(),
});
const withConnect = connect(mapStateToProps, null);

export default compose(withConnect)(MainContentHead);
