import styled, { keyframes } from "styled-components";
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
const Loader = styled.div`
  border: 2px solid #f3f3f3; /* Light grey */
  border-top: 2px solid #3498db; /* Blue */
  border-radius: 50%;
  width: ${(props) => (props.width ? props.width : "20px")};
  height: ${(props) => (props.height ? props.height : "20px")};
  animation: ${rotate} 2s linear infinite;
  self-align: center;
`;
export default Loader;
