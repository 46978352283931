import { fromJS } from "immutable";

import {
  RECONCILIATION_LIST_REQUEST,
  RECONCILIATION_LIST_FETCH_COMPLETE,
  SUPPLIER_LIST_REQUEST,
  SUPPLIER_LIST_FETCH_COMPLETE,
  SAVE_RECONCILIATION_REQUEST,
  SAVE_RECONCILIATION_COMPLETE,
  GET_RECONCILED_REPORTS_REQUEST,
  RECONCILED_REPORTS_FETCH_COMPLETE,
  GET_SUGGESTED_INVOICES_REQUEST,
  SUGGESTED_INVOICES_FETCH_COMPLETE,
  GET_RECONCILIATION_COMMENTS_REQUEST,
  RECONCILIATION_COMMENTS_FETCH_COMPLETE,
  SAVE_RECONCILIATION_COMMENTS_REQUEST,
  SAVE_RECONCILIATION_COMMENTS_COMPLETE,
  START_RECONCILIATION_REQUEST,
  START_RECONCILIATION_COMPLETE,
  UPDATE_RECONCIALITIN_LIST,
  RESET_SAVE_RECONCILIATION_STATUS,
} from "./actionTypes";

export const initialState = fromJS({
  isReconcileLoading: false,
  reconciliationList: [],
  reconcialiationData: [],
  isSupplierLoading: false,
  supplierList: [],
  totalSupplierCount: 0,
  userName: "",
  isUploading: false,
  uploadStatus: "",
  isReconciledReportsLoading: false,
  reconcileDetails: [],
  reconcileFieldHeaders: [],
  isSuggestedInvoicesLoading: false,
  suggestedInvoiceList: [],
  isReconciliationCommentLoading: false,
  reconciliationComments: [],
  isSavingComments: false,
  isStartReconciliation: false,
  reconciliationStatus: "",
  commentSaveStatus: "",
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RECONCILIATION_LIST_REQUEST:
      return state
        .set("isReconcileLoading", true)
        .set("reconciliationList", [])
        .set("reconciliationData", []);
    case RECONCILIATION_LIST_FETCH_COMPLETE:
      return state
        .set("reconciliationList", action.reconciliationList)
        .set("reconciliationData", action.reconciliationList)
        .set("isReconcileLoading", false);
    case UPDATE_RECONCIALITIN_LIST:
      return state.set("reconciliationData", action.data);
    case SUPPLIER_LIST_REQUEST:
      return state.set("isSupplierLoading", true).set("supplierList", []);

    case SUPPLIER_LIST_FETCH_COMPLETE:
      return state
        .set("supplierList", action.supplierList)
        .set("totalSupplierCount", action.totalSupplierCount)
        .set("isSupplierLoading", false);

    case SAVE_RECONCILIATION_REQUEST:
      return state.set("isUploading", true);
    case SAVE_RECONCILIATION_COMPLETE:
      return state.set("isUploading", false).set("uploadStatus", action.status);
    case RESET_SAVE_RECONCILIATION_STATUS:
      return state.set("uploadStatus", "");
    case GET_RECONCILED_REPORTS_REQUEST:
      return state.set("isReconciledReportsLoading", true);
    case RECONCILED_REPORTS_FETCH_COMPLETE:
      return state
        .set("isReconciledReportsLoading", false)
        .set("reconcileFieldHeaders", action.reconcileFieldHeaders)
        .set("reconcileDetails", action.reconcileDetails);
    case GET_SUGGESTED_INVOICES_REQUEST:
      return state.set("isSuggestedInvoicesLoading", true);
    case SUGGESTED_INVOICES_FETCH_COMPLETE:
      return state
        .set("isSuggestedInvoicesLoading", false)
        .set("suggestedInvoiceList", action.suggestedInvoiceList);
    case GET_RECONCILIATION_COMMENTS_REQUEST:
      return state.set("isReconciliationCommentLoading", true);
    case RECONCILIATION_COMMENTS_FETCH_COMPLETE:
      return state
        .set("isReconciliationCommentLoading", false)
        .set("reconciliationComments", action.reconciliationComments);
    case SAVE_RECONCILIATION_COMMENTS_REQUEST:
      return state.set("isSavingComments", true);
    case SAVE_RECONCILIATION_COMMENTS_COMPLETE:
      return state
        .set("isSavingComments", false)
        .set("commentSaveStatus", action.commentSaveStatus);
    case START_RECONCILIATION_REQUEST:
      return state.set("isStartReconciliation", true);
    case START_RECONCILIATION_COMPLETE:
      return state
        .set("isStartReconciliation", false)
        .set("reconciliationStatus", action.status);
    default:
      return state;
  }
};
export default reducer;
