import { put, takeEvery, call, all } from "redux-saga/effects";
import { buildNotification } from "../../../config/notification";
import { store } from "react-notifications-component";
import {
  fetchingFileType,
  fetchFileTpe,
  fileTypeFetchingComplete,
  fetchingRecentUploadedFiles,
  fetchedRecentUploadedFiles,
  fetchRecentUploadedFilesComplete,
  userUploadSaved,
  userUploadSaveFailed,
  fetchingUserUpload,
  fetchUserUpload,
  userUploadFetchingComplete,
  fetchingSupplierUpload,
  fetchSupplierUpload,
  supplierUploadFetchingComplete,
  downloadingRecentUploadFiles,
  downloadRecentUploadFilesComplete,
  downloadRecentUploadFilesFailed,
  initUserUploadData,
  getBulkUploadTypesCompleted,
  getBulkUploadTypesFailed,
  initSupplierUploadData,
  fetchingUpload,
  fetchUpload,
  UploadFetchingComplete,
} from "./actions";
import {
  INIT_FILE_TYPE,
  INIT_GET_RECENT_UPLOADED_FILES,
  INIT_USER_UPLOAD_SAVE,
  DOWNLOAD_BULK_SUPPLIERS,
  DOWNLOAD_MASS_SUPPLIERS,
  INIT_USER_UPLOAD_FETCH,
  INIT_SUPPLIER_UPLOAD_FETCH,
  DOWNLOAD_USER_UPLOAD,
  SEND_EMAIL,
  INIT_DOWNLOAD_RECENT_UPLOAD_FILE,
  INIT_GET_BULK_UPLOAD_TYPES,
  INIT_UPLOAD_FETCH,
} from "./actionTypes";
import axios from "../../../config/axios";
import {
  getRequestDetails,
  postRequestDetails,
} from "../../../config/requestHeaders";
export default function* watchBulkUpload() {
  yield all([
    takeEvery(INIT_FILE_TYPE, getFileTypeSaga),
    takeEvery(INIT_GET_RECENT_UPLOADED_FILES, initGetRecentUploadedFilesSaga),
    takeEvery(INIT_USER_UPLOAD_SAVE, initUserUploadSaveSaga),
    takeEvery(DOWNLOAD_BULK_SUPPLIERS, getBulkSuppliersSaga),
    takeEvery(DOWNLOAD_MASS_SUPPLIERS, getMassSuppliersSaga),
    takeEvery(INIT_USER_UPLOAD_FETCH, getUserUploadDataSaga),
    takeEvery(INIT_SUPPLIER_UPLOAD_FETCH, getSupplierUploadDataSaga),
    takeEvery(INIT_UPLOAD_FETCH, getUploadDataSaga),
    takeEvery(DOWNLOAD_USER_UPLOAD, getDownloadUserUploadSaga),
    takeEvery(SEND_EMAIL, sendEmailSaga),
    takeEvery(
      INIT_DOWNLOAD_RECENT_UPLOAD_FILE,
      initDownloadRecentUploadfileSaga
    ),
    takeEvery(INIT_GET_BULK_UPLOAD_TYPES, initGetBulkUploadTypesSaga),
  ]);
}

function* getFileTypeSaga() {
  let fileType = [];
  yield put(fetchingFileType());
  const url = `/Users/getFileType`;
  try {
    const response = yield call(axios, url, getRequestDetails);
    if (response && response.status === 200) {
      fileType = yield response.data.map((item) => {
        return item;
      });
    }

    yield put(fetchFileTpe(fileType));
    yield put(fileTypeFetchingComplete());
  } catch (error) {
    yield put(fileTypeFetchingComplete());
  }
}
function* initUserUploadSaveSaga(action) {
  let clientDetails = JSON.parse(sessionStorage.getItem("clientDetails"));
  let clientName = clientDetails.name;
  const url = "/Users/bulkuserCreation";
  yield (postRequestDetails.data = {
    ...action.payload,
    clientName: clientName,
  });
  try {
    const response = yield call(axios, url, postRequestDetails);
    if (response && response.status === 200) {
      yield put(userUploadSaved());
      if (action.saveSuccess) {
        action.saveSuccess(response.status);
      }
      yield put(initSupplierUploadData());
      const notification = buildNotification({
        message: "notification.manageUpload.recordSaved",
        type: "success",
      });
      store.addNotification({
        ...notification,
        dismiss: {
          duration: 1000,
        },
      });
      if (action.callback) {
        action.callback();
      }
      yield put(initUserUploadData());
    }
  } catch (error) {
    yield put(userUploadSaveFailed());
  }
}

function* getBulkSuppliersSaga(action) {
  const getRequest = {
    method: "GET",
    cache: "no-cache",
    responseType: "blob",
  };
  const url = `Users/downloadUpdateSuppliersFile`;
  try {
    const response = yield call(axios, url, getRequest);
    if (response && response.status !== 204) {
      const data = response.data;
      const url = window.URL.createObjectURL(
        new Blob([data], { type: "application/octet-stream" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "bulkSupplier.xlsx");
      document.body.appendChild(link);
      link.click();
    }
  } catch (error) {
    action.callback && action.callback();
  }
}
function* getMassSuppliersSaga(action) {
  const getRequest = {
    method: "GET",
    cache: "no-cache",
    responseType: "blob",
  };
  const url = `Users/downloadMassCreationFile`;
  try {
    const response = yield call(axios, url, getRequest);
    if (response && response.status !== 204) {
      const data = response.data;
      const url = window.URL.createObjectURL(
        new Blob([data], { type: "application/octet-stream" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "massSupplier.xlsx");
      document.body.appendChild(link);
      link.click();
    }
  } catch (error) {
    action.callback && action.callback();
  }
}
function* getUserUploadDataSaga() {
  yield put(fetchingUserUpload());
  const url = "Users/listUploadedFiles?fileType=1";
  try {
    const response = yield call(axios, url, { method: "GET" });
    yield put(fetchUserUpload(response.data));
    yield put(userUploadFetchingComplete());
  } catch (error) {
    yield put(userUploadFetchingComplete());
  }
}
function* getSupplierUploadDataSaga() {
  yield put(fetchingSupplierUpload());
  const url = "/Users/listUploadedFiles?fileType=2";
  try {
    const response = yield call(axios, url, { method: "GET" });
    yield put(fetchSupplierUpload(response.data));
    yield put(supplierUploadFetchingComplete());
  } catch (error) {
    yield put(supplierUploadFetchingComplete());
  }
}
function* getUploadDataSaga(action) {
  yield put(fetchingUpload());
  const url = `/Users/listUploadedFiles?fileType=${action.bulkUploadTypeID}`;
  try {
    const response = yield call(axios, url, { method: "GET" });
    yield put(fetchUpload(response.data));
    yield put(UploadFetchingComplete());
  } catch (error) {
    yield put(UploadFetchingComplete());
  }
}
function* getDownloadUserUploadSaga(action) {
  const getRequest = {
    method: "GET",
    cache: "no-cache",
    responseType: "blob",
  };
  const url =
    "/Users/downloadBulkUploadFile?attachmentId=" + action.attachmentId;
  try {
    const response = yield call(axios, url, getRequest);
    if (response && response.status !== 204) {
      const data = response.data;
      const url = window.URL.createObjectURL(
        new Blob([data], { type: "application/octet-stream" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.xlsx");
      document.body.appendChild(link);
      link.click();
      const notification = buildNotification({
        message: "notification.manageUpload.downloadSuccess",
        type: "success",
      });
      store.addNotification({
        ...notification,
        dismiss: {
          duration: 1000,
        },
      });
    }
  } catch (error) {
    action.callback && action.callback();
  }
}
function* sendEmailSaga(action) {
  const url = "Users/sendEmailToBulkUsers?fileDataID=" + action.fileDataID;
  try {
    const response = yield call(axios, url, { method: "POST" });
    if (response && response.status === 202) {
      action.callback && action.callback();
      const notification = buildNotification({
        message: "notification.manageUpload.emailSuccess",
        type: "success",
      });
      store.addNotification({
        ...notification,
        dismiss: {
          duration: 1000,
        },
      });
    }
  } catch (error) {
    action.callback && action.callback();
  }
}

function* initGetRecentUploadedFilesSaga() {
  yield put(fetchingRecentUploadedFiles());
  const url = `/Users/recentUploadedFiles`;
  try {
    const response = yield call(axios, url, getRequestDetails);
    if (response && response.status === 200) {
      yield put(fetchedRecentUploadedFiles(response.data));
    }
    yield put(fetchRecentUploadedFilesComplete());
  } catch (error) {
    yield put(fetchRecentUploadedFilesComplete());
  }
}

function* initDownloadRecentUploadfileSaga(action) {
  const getRequest = {
    method: "GET",
    cache: "no-cache",
    responseType: "blob",
  };
  yield put(downloadingRecentUploadFiles());
  const url = `Users/downloadBulkUploadFile?attachmentId=${action.data.fileId}`;
  try {
    const response = yield call(axios, url, getRequest);
    if (response && response.status === 200) {
      const data = response.data;
      const url = window.URL.createObjectURL(
        new Blob([data], { type: "application/octet-stream" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", action.data.fileName);
      document.body.appendChild(link);
      link.click();
      yield put(downloadRecentUploadFilesComplete());
      const notification = buildNotification({
        message: "notification.manageUpload.downloadSuccess",
        type: "success",
      });
      store.addNotification({
        ...notification,
        dismiss: {
          duration: 1000,
        },
      });
    } else {
      yield put(downloadRecentUploadFilesFailed());
    }
  } catch (error) {
    yield put(downloadRecentUploadFilesFailed());
    const notification = buildNotification({
      message: "notification.manageUpload.downloadFailed",
      type: "danger",
    });
    store.addNotification({
      ...notification,
      dismiss: {
        duration: 1000,
      },
    });
  }
}

function* initGetBulkUploadTypesSaga(action) {
  const url = `Users/bulkUploadTypes`;
  try {
    const response = yield call(axios, url, getRequestDetails);
    if (response && response.status === 200) {
      yield put(getBulkUploadTypesCompleted(response.data));
    } else {
      yield put(getBulkUploadTypesFailed());
    }
  } catch (error) {
    yield put(getBulkUploadTypesFailed());
    const notification = buildNotification({
      message: "notification.errorOccurred",
      type: "danger",
    });
    store.addNotification({
      ...notification,
      dismiss: {
        duration: 1000,
      },
    });
  }
}
