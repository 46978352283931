export const INIT_LANGUAGE_FETCH = "INIT_LANGUAGE_FETCH";
export const LANGUAGE_FETCHING = "LANGUAGE_FETCHING";
export const LANGUAGE_FETCH = "LANGUAGE_FETCH";
export const LANGUAGE_FETCH_COMPLETE = "LANGUAGE_FETCH_COMPLETE";

export const INIT_SUPPLIER_FIELD_FETCH = "INIT_SUPPLIER_FIELD_FETCH";
export const SUPPLIER_FIELD_FETCHING = "SUPPLIER_FIELD_FETCHING";
export const SUPPLIER_FIELD_FETCH = "SUPPLIER_FIELD_FETCH";
export const SUPPLIER_FIELD_FETCH_COMPLETE = "SUPPLIER_FIELD_FETCH_COMPLETE";

export const INIT_COUNTRY_FETCH = "INIT_COUNTRY_FETCH";
export const COUNTRY_FETCHING = "COUNTRY_FETCHING";
export const COUNTRY_FETCH = "COUNTRY_FETCH";
export const COUNTRY_FETCH_COMPLETE = "COUNTRY_FETCH_COMPLETE";

export const INIT_SUBMISSION_FIELDS_FETCH = "INIT_SUBMISSION_FIELDS_FETCH";
export const SUBMISSION_FIELDS_FETCHING = "SUBMISSION_FIELDS_FETCHING";
export const SUBMISSION_FIELDS_FETCH = "SUBMISSION_FIELDS_FETCH";
export const SUBMISSION_FIELDS_FETCH_COMPLETE =
  "SUBMISSION_FIELDS_FETCH_COMPLETE";

export const INIT_TIME_ZONES_FETCH = "INIT_TIME_ZONES_FETCH";
export const TIME_ZONES_FETCHING = "TIME_ZONES_FETCHING";
export const TIME_ZONES_FETCH = "TIME_ZONES_FETCH";
export const TIME_ZONES_FETCH_COMPLETE = "TIME_ZONES_FETCH_COMPLETE";
export const INIT_SUBMISSION_STAUS_FETCH = "INIT_SUBMISSION_STAUS_FETCH";
export const SUBMISSION_STATUS_FETCHING = "SUBMISSION_STATUS_FETCHING";
export const SUBMISSION_STATUS_FETCH = "SUBMISSION_STATUS_FETCH";
export const SUBMISSION_STATUS_FETCH_COMPLETE =
  "SUBMISSION_STATUS_FETCH_COMPLETE";

export const INIT_DOWNLOAD = "INIT_DOWNLOAD";
export const DOWNLOADING = "DOWNLOADING";
export const DOWNLOAD = "DOWNLOAD";
export const DOWNLOAD_COMPLETE = "DOWNLOAD_COMPLETE";
export const INIT_CLIENT_PLACEMENT_CONFIG_STATUS_FETCH =
  "INIT_CLIENT_PLACEMENT_CONFIG_STATUS_FETCH";
export const CLIENT_PLACEMENT_CONFIG_FETCHING =
  "CLIENT_PLACEMENT_CONFIG_FETCHING";
export const CLIENT_PLACEMENT_CONFIG_FETCH = "CLIENT_PLACEMENT_CONFIG_FETCH";
export const CLIENT_PLACEMENT_CONFIG_FETCH_COMPLETE =
  "CLIENT_PLACEMENT_CONFIG_FETCH_COMPLETE";

export const INIT_VALIDATION_TYPES_FETCH = "INIT_VALIDATION_TYPES_FETCH";
export const VALIDATION_TYPES_FETCHING = "VALIDATION_TYPES_FETCHING";
export const VALIDATION_TYPES_FETCH = "VALIDATION_TYPES_FETCH";
export const VALIDATION_TYPES_FETCHED = "VALIDATION_TYPES_FETCHED";

export const INIT_REGEX_FETCH = "INIT_REGEX_FETCH";
export const REGEX_FETCHING = "REGEX_FETCHING";
export const REGEX_FETCH = "REGEX_FETCH";
export const REGEX_FETCHED = "REGEX_FETCHED";

export const INIT_CURRENCY_FETCH = "INIT_CURRENCY_FETCH";
export const CURRENCY_FETCHING = "CURRENCY_FETCHING";
export const CURRENCY_FETCH = "CURRENCY_FETCH";
export const CURRENCY_FETCH_COMPLETE = "CURRENCY_FETCH_COMPLETE";

export const INIT_INVOICE_STATUS_FETCH = "INIT_INVOICE_STATUS_FETCH";
export const INVOICE_STATUS_FETCHING = "INVOICE_STATUS_FETCHING";
export const INVOICE_STATUS_FETCH = "INVOICE_STATUS_FETCH";
export const INVOICE_STATUS_FETCH_COMPLETE = "INVOICE_STATUS_FETCH_COMPLETE";
