import { call, put, all, takeEvery } from "redux-saga/effects";
import { store } from "react-notifications-component";

import axios from "../../../config/axios";
import { buildNotification } from "../../../config/notification";

import {
  USER_DETAILS_LIST,
  SUBMIT_USER_DATA,
  GET_SINGLE_USER_DATA_ACTION,
  USER_DATA_TO_DELETE,
  INIT_USER_ROLE_LIST,
  INIT_REGIONAL_SETTINGS,
  INIT_CHECK_FOR_EXISTING_USER,
  INIT_UPDATE_SECURITY_QUESTION,
} from "./actionTypes";

import {
  updateUserListAction,
  fetchUserRoleDropdownList,
  updateSingleUserDataAction,
  getUserDetailsAction,
  formSubmissionStatusAction,
  fetchRegionalSettings,
  userExist,
  submitUserDataStatus,
} from "./actions";

import {
  getRequestDetails,
  postRequestDetails,
} from "../../../config/requestHeaders";

export default function* rootUserManagementSaga() {
  yield all([
    takeEvery(USER_DETAILS_LIST, getUserList),
    takeEvery(INIT_USER_ROLE_LIST, getUserRoleSaga),
    takeEvery(SUBMIT_USER_DATA, submitUserDataSaga),
    takeEvery(GET_SINGLE_USER_DATA_ACTION, getSingleUserDataSaga),
    takeEvery(USER_DATA_TO_DELETE, deleteUserDataSaga),
    takeEvery(INIT_REGIONAL_SETTINGS, getRegionalSettingsSaga),
    takeEvery(INIT_CHECK_FOR_EXISTING_USER, initCheckForExistingUSerSaga),
    takeEvery(INIT_UPDATE_SECURITY_QUESTION, initUpdateSecurityQuestionSaga),
  ]);
}

function* getUserList(action) {
  let url = "/Users?approved=true";
  let keys = action.data && Object.keys(action.data);
  keys &&
    keys.forEach((data) => {
      if (action.data[data]) {
        url = url + "&" + data + "=" + action.data[data];
      }
    });
  try {
    const response = yield call(axios, url, getRequestDetails);
    if (response && response.status === 200) {
      yield put(updateUserListAction(response.data));
    }
    if (response.status === 204) {
      const notification = buildNotification({
        message: "notification.noDataDisplayLabel",
        type: "danger",
      });
      store.addNotification({
        ...notification,
      });
      yield put(updateUserListAction([]));
    }
  } catch (err) {
    action.callback && action.callback();
  }
}

function* getUserRoleSaga(action) {
  const url = "/Users/roles";
  try {
    const response = yield call(axios, url, getRequestDetails);
    if (response && response.status === 200) {
      yield put(fetchUserRoleDropdownList(response.data));
    }
  } catch (err) {
    action.callback && action.callback();
  }
}

function* submitUserDataSaga(action) {
  yield put(submitUserDataStatus(true));
  const url = "/Users/save";
  yield (postRequestDetails.data = {
    ...action.userCreationData,
  });
  try {
    const response = yield call(axios, url, postRequestDetails);
    if (response && response.status === 202) {
      yield put(formSubmissionStatusAction(response.statusText));
      const notification = buildNotification({
        message: "notification.userMgmt.saved",
        type: "success",
      });

      store.addNotification({
        ...notification,
        dismiss: {
          duration: 1000,
        },
      });
      yield put(submitUserDataStatus(false));
    }
  } catch (error) {
    action.callback && action.callback();
    yield put(submitUserDataStatus(false));
  }
}

function* getSingleUserDataSaga(action) {
  let url = "";
  if (action.myAccntFlag) url = "/Users/userProfileDetails";
  else url = "/Users/userById?userId=" + action.userId;
  try {
    const response = yield call(axios, url, getRequestDetails);
    if (response && response.status === 200) {
      yield put(updateSingleUserDataAction(response.data));
      if (action.callBackFunction) {
        action.callBackFunction(response.data);
      }
    }
  } catch (err) {
    action.callBackFunction && action.callBackFunction();
  }
}

function* deleteUserDataSaga(action) {
  const url = "/Users/Delete";
  postRequestDetails.data = {
    ...action.userDataToDelete,
  };
  try {
    const response = yield call(axios, url, postRequestDetails);
    if (response.status === 200) {
      const notification = buildNotification({
        message: "notification.userMgmt.deleted",
        type: "success",
      });

      store.addNotification({
        ...notification,
      });
      yield put(getUserDetailsAction());
    }
  } catch (error) {
    action.callback && action.callback();
  }
}

function* getRegionalSettingsSaga(action) {
  const url = "/Users/ListRegionalSettings";
  try {
    const response = yield call(axios, url, getRequestDetails);
    if (response && response.status === 200) {
      yield put(fetchRegionalSettings(response.data));
    }
  } catch (err) {
    action.callback && action.callback();
  }
}

function* initCheckForExistingUSerSaga(action) {
  const url = "/Users/UserAlreadyExists?userName=" + action.data;
  try {
    const response = yield call(axios, url, getRequestDetails);
    if (response && response.status === 200) {
      yield put(userExist(response.data));
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    action.callback && action.callback();
  }
}

function* initUpdateSecurityQuestionSaga(action) {
  let url = "/Users/UpdateSecurityQuestion";
  postRequestDetails.data = {
    ...action.payload,
  };
  try {
    const response = yield call(axios, url, postRequestDetails);
    if (response && response.status === 200) {
      const notification = buildNotification({
        message: "userManagement.securityQAUpdateSuccess",
        type: "success",
      });

      store.addNotification({
        ...notification,
      });
    }
  } catch (error) {
    action.callback && action.callback();
  }
}
