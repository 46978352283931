import React from "react";
import {
  HideDefaultCheckbox,
  CheckboxIcon,
  StyledCheckbox,
  checkboxLabelStyle,
} from "./layout";
import Div from "../Div";
import Label from "../Label";

import { useTranslation } from "react-i18next";

const Checkbox = (props) => {
  const { t } = useTranslation();

  return (
    <Div
      display="flex"
      border={props.border}
      padding={props.wrapperPadding}
      margin={props.wrapperMargin}
      position={props.wrapperPosition}
    >
      <Label
        width={props.labelWidth ? props.labelWidth : "auto"}
        height={props.labelHeight}
        padding={props.labelPadding}
        htmlFor={props.id}
      >
        <HideDefaultCheckbox
          id={props.id}
          name={props.name}
          checked={!!props.checked}
          defaultValue={props.value}
          onChange={props.handleToggleCheckbox}
          onKeyPress={(e) => {
            return e.key === "Enter" && document.activeElement.click();
          }}
          disabled={props.disabled}
        ></HideDefaultCheckbox>
        <StyledCheckbox
          checked={props.checked}
          disabled={props.disabled}
          {...props}
        >
          <CheckboxIcon viewBox="0 0 24 24">
            <polyline points="20 6 9 17 4 12"></polyline>
          </CheckboxIcon>
        </StyledCheckbox>
        <Label
          htmlFor={props.id}
          fontSize={props.fontSize}
          {...checkboxLabelStyle}
          position={props.textPosition}
        >
          {t(props.label)}
      </Label>
      </Label>
    </Div >
  );
};

export default Checkbox;
