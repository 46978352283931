import React, { useContext } from "react";

import Div from "../Div";
import IMG from "../IMG";
import menu from "../../assets/images/menu.png";
import close from "../../assets/images/close_lite.png";

import SideBarMenu from "../LandingPage/SideBarMenu";
import { flexCol, flexShrink } from "./style";
import { AppContext } from "../../context";
const QuickAccessBar = (props) => {
  const context = useContext(AppContext);
  const quickAccess = context.showQuickAccess;
  const updateQuickAccess = context.updateQuickAccessVisibility;

  return quickAccess === true ? (
    <Div
      id="quickAccess"
      {...flexShrink}
      {...flexCol}
      boxShadow="0px 2px 15px 0px rgba(0,0,0,0.1)"
      divWidth="210px"
      bgColor="#FFF"
      border="1px solid #e3eaed"
      overflow="hidden"
      position="relative"
    >
      <Div cursor="pointer" onClick={() => updateQuickAccess("collapse")}>
        <IMG
          imgWidth="25px"
          imgHeight="25px"
          float="right"
          src={close}
          alt="Close"
          padding="5px"
        />
      </Div>
      <SideBarMenu />
    </Div>
  ) : quickAccess === "collapse" ? (
    <Div
      cursor="pointer"
      border="1px solid #e3eaed"
      boxShadow="0px 2px 15px 0px rgba(0,0,0,0.1)"
      onClick={() => updateQuickAccess(true)}
    >
      <IMG src={menu} alt="Menu" />
    </Div>
  ) : null;
};

export default QuickAccessBar;
