import { put, takeEvery, call, all } from "redux-saga/effects";

import {
  languageFetching,
  fetchLanguages,
  languageFetchComplete,
  supplierFieldFetching,
  fetchSupplierFields,
  supplierFieldFetchComplete,
  countryFetching,
  fetchCountry,
  countryFetchComplete,
  submissionFieldsFetching,
  fetchSubmissionFields,
  submissionFieldsFetchComplete,
  fetchTimeZones,
  timeZonesFetchComplete,
  submissionStatusFetching,
  fetchSubmissionStatus,
  submissionStatusFetchComplete,
  downloading,
  download,
  downloadComplete,
  clientPlacementConfigFetching,
  clientPlacementConfigFetch,
  clientPlacementConfigFetchComplete,
  validationTypeFetching,
  validationTypeFetch,
  validationTypeFetched,
  regexFetching,
  regexFetch,
  regexFetched,
  currencyFetching,
  currencyFetch,
  currencyFetchCompleted,
  invoiceStatusFetching,
  invoiceStatusFetch,
  invoiceStatusFetchCompleted,
} from "../store/actions";

import {
  INIT_LANGUAGE_FETCH,
  INIT_SUPPLIER_FIELD_FETCH,
  INIT_COUNTRY_FETCH,
  INIT_SUBMISSION_FIELDS_FETCH,
  INIT_TIME_ZONES_FETCH,
  INIT_SUBMISSION_STAUS_FETCH,
  INIT_DOWNLOAD,
  INIT_CLIENT_PLACEMENT_CONFIG_STATUS_FETCH,
  INIT_VALIDATION_TYPES_FETCH,
  INIT_REGEX_FETCH,
  INIT_CURRENCY_FETCH,
  INIT_INVOICE_STATUS_FETCH,
} from "./actionTypes";
import axios from "../config/axios";

const getRequestDetails = {
  method: "GET",
  cache: "no-cache",
};
const postRequestDetails = {
  method: "POST",
  cache: "no-cache",
  headers: {
    "Content-Type": "application/json",
  },
};

export default function* watchCommonAPI() {
  yield all([
    takeEvery(INIT_LANGUAGE_FETCH, initLanguageFetchSaga),
    takeEvery(INIT_SUPPLIER_FIELD_FETCH, initSupplierFieldsFetchSaga),
    takeEvery(INIT_COUNTRY_FETCH, initCountryFetchSaga),
    takeEvery(INIT_SUBMISSION_FIELDS_FETCH, initSubmissionFieldsFetchSaga),
    takeEvery(INIT_TIME_ZONES_FETCH, initTimeZonesFetchSaga),
    takeEvery(INIT_SUBMISSION_STAUS_FETCH, initSubmissionStatusFetchSaga),
    takeEvery(INIT_DOWNLOAD, initDownloadSaga),
    takeEvery(
      INIT_CLIENT_PLACEMENT_CONFIG_STATUS_FETCH,
      initClientPlacementConfigSaga
    ),
    takeEvery(INIT_CURRENCY_FETCH, initCurrencySaga),
    takeEvery(INIT_VALIDATION_TYPES_FETCH, initValidationTypeFetchSaga),
    takeEvery(INIT_REGEX_FETCH, initRegexFetchSaga),
    takeEvery(INIT_INVOICE_STATUS_FETCH, initInvoiceStatusFetchSaga),
  ]);
}

function* initLanguageFetchSaga(action) {
  yield put(languageFetching());
  const url = "/Common/language";

  try {
    const response = yield call(axios, url, getRequestDetails);
    if (response) yield put(fetchLanguages(response.data));
    yield put(languageFetchComplete());
  } catch (error) {
    yield put(languageFetchComplete());
  }
}

function* initSupplierFieldsFetchSaga(action) {
  yield put(supplierFieldFetching());
  const url = "/Common/supplierfields";
  postRequestDetails.data = {
    clientId: 2,
    roleId: "APB_ADMIN",
    langKey: "enGB",
  };
  try {
    let data = [];
    const response = yield call(axios, url, postRequestDetails);
    if (response && response.status !== 204) {
      data = response.data;
    }
    yield put(fetchSupplierFields(data));
    yield put(supplierFieldFetchComplete());
  } catch (error) {
    yield put(supplierFieldFetchComplete());
  }
}
function* initCountryFetchSaga(action) {
  yield put(countryFetching());
  const url = "/Common/listkeys?typeName=__Countries";
  try {
    let data = [];
    const response = yield call(axios, url, getRequestDetails);
    if (response && response.status !== 204) {
      data = response.data;
    }
    yield put(fetchCountry(data));
    yield put(countryFetchComplete());
  } catch (error) {
    yield put(countryFetchComplete());
  }
}
function* initSubmissionFieldsFetchSaga(action) {
  yield put(submissionFieldsFetching());
  const url = "/Common/supplierfields";
  postRequestDetails.data = {
    clientId: 2,
    roleId: "APB_ADMIN",
    langKey: "enGB",
    fieldPlacementTypeId: 6,
  };
  try {
    let data = [];
    const response = yield call(axios, url, postRequestDetails);
    if (response && response.status !== 204) {
      data = response.data;
    }
    yield put(fetchSubmissionFields(data));
    yield put(submissionFieldsFetchComplete());
  } catch (error) {
    yield put(submissionFieldsFetchComplete());
  }
}

function* initTimeZonesFetchSaga() {
  yield put(countryFetching());
  const url = "/Common/listkeys?typeName=__Time_Zones";
  try {
    let data = [];
    const response = yield call(axios, url, getRequestDetails);
    if (response && response.status !== 204) {
      data = response.data;
    }
    yield put(fetchTimeZones(data));
    yield put(timeZonesFetchComplete());
  } catch (error) {
    yield put(timeZonesFetchComplete());
  }
}

function* initSubmissionStatusFetchSaga(action) {
  yield put(submissionStatusFetching());
  let sessionDetails =
    JSON.parse(sessionStorage.getItem("sessionDetails")) || {};
  let langKey =
    sessionDetails && sessionDetails.languageId
      ? sessionDetails.languageId
      : "";
  const url = "Common/listkeys?typeName=__SubmissionStatus&language=" + langKey;
  try {
    let data = [];
    const response = yield call(axios, url, getRequestDetails);
    if (response && response.status !== 204) {
      data = response.data;
    }
    yield put(fetchSubmissionStatus(data));
    yield put(submissionStatusFetchComplete());
  } catch (error) {
    yield put(submissionStatusFetchComplete());
  }
}

function* initDownloadSaga(action) {
  const { payload } = action;
  yield put(downloading());
  const url = `/Common/download?attachmentId=${payload.blobID}`;
  getRequestDetails.responseType = "blob";
  try {
    let data = [];
    const response = yield call(axios, url, getRequestDetails);
    if (response && response.status !== 204) {
      if (action.callback) action.callback();
      data = response.data;
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", payload.filename);
      document.body.appendChild(link);
      link.click();
    }
    yield put(download(data));
    yield put(downloadComplete());
  } catch (error) {
    yield put(downloadComplete());
  }
}

function* initClientPlacementConfigSaga(action) {
  yield put(clientPlacementConfigFetching());
  const url = "/Common/listSubmissionFieldPlacement";
  try {
    let data = [];
    const response = yield call(axios, url, getRequestDetails);
    if (response && response.status !== 204) {
      data = response.data;
    }
    yield put(clientPlacementConfigFetch(data));
    yield put(clientPlacementConfigFetchComplete());
  } catch (error) {
    yield put(clientPlacementConfigFetchComplete());
  }
}

function* initValidationTypeFetchSaga(action) {
  yield put(validationTypeFetching());
  const url = "/Common/ValidationType";
  try {
    let data = [];
    const response = yield call(axios, url, getRequestDetails);
    if (response && response.status !== 204) {
      data = response.data;
    }
    yield put(validationTypeFetch(data));
    yield put(validationTypeFetched());
  } catch (error) {
    yield put(validationTypeFetched());
  }
}

function* initRegexFetchSaga(action) {
  yield put(regexFetching());
  const url = "/Common/listRegEX";
  try {
    let data = [];
    const response = yield call(axios, url, getRequestDetails);
    if (response && response.status !== 204) {
      data = response.data;
    }
    yield put(regexFetch(data));
    yield put(regexFetched());
  } catch (error) {
    yield put(regexFetched());
  }
}

function* initCurrencySaga() {
  yield put(currencyFetching());
  const url = "/Common/listcurrency";
  try {
    const response = yield call(axios, url, getRequestDetails);
    yield put(currencyFetch(response.data));
    yield put(currencyFetchCompleted());
  } catch (error) {
    yield put(currencyFetchCompleted());
  }
}

function* initInvoiceStatusFetchSaga(action) {
  yield put(invoiceStatusFetching());
  let sessionDetails =
    JSON.parse(sessionStorage.getItem("sessionDetails")) || {};
  let langKey =
    sessionDetails && sessionDetails.languageId
      ? sessionDetails.languageId
      : "";
  const url = "Common/listkeys?typeName=__InvoiceStatus&language=" + langKey;
  try {
    let data = [];
    const response = yield call(axios, url, getRequestDetails);
    if (response && response.status !== 204) {
      data = response.data;
    }
    yield put(invoiceStatusFetch(data));
    yield put(invoiceStatusFetchCompleted());
  } catch (error) {
    yield put(invoiceStatusFetchCompleted());
  }
}
