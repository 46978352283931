import { fromJS } from "immutable";

import {
  LANGUAGE_FETCHING,
  LANGUAGE_FETCH,
  LANGUAGE_FETCH_COMPLETE,
  SUPPLIER_FIELD_FETCHING,
  SUPPLIER_FIELD_FETCH,
  SUPPLIER_FIELD_FETCH_COMPLETE,
  COUNTRY_FETCHING,
  COUNTRY_FETCH,
  COUNTRY_FETCH_COMPLETE,
  SUBMISSION_FIELDS_FETCHING,
  SUBMISSION_FIELDS_FETCH,
  SUBMISSION_FIELDS_FETCH_COMPLETE,
  TIME_ZONES_FETCHING,
  TIME_ZONES_FETCH,
  TIME_ZONES_FETCH_COMPLETE,
  SUBMISSION_STATUS_FETCHING,
  SUBMISSION_STATUS_FETCH,
  SUBMISSION_STATUS_FETCH_COMPLETE,
  CLIENT_PLACEMENT_CONFIG_FETCHING,
  CLIENT_PLACEMENT_CONFIG_FETCH,
  CLIENT_PLACEMENT_CONFIG_FETCH_COMPLETE,
  VALIDATION_TYPES_FETCHING,
  VALIDATION_TYPES_FETCH,
  VALIDATION_TYPES_FETCHED,
  REGEX_FETCHING,
  REGEX_FETCH,
  REGEX_FETCHED,
  CURRENCY_FETCH,
  CURRENCY_FETCHING,
  CURRENCY_FETCH_COMPLETE,
  INVOICE_STATUS_FETCH,
  INVOICE_STATUS_FETCHING,
  INVOICE_STATUS_FETCH_COMPLETE,
} from "./actionTypes";

export const initialState = fromJS({
  fetchingLanguage: true,
  languages: [],
  fecthingSupplierField: true,
  supplierFields: [],
  fetchingCountry: true,
  countries: [],
  fetchingSubmissionFields: true,
  submissionFields: [],
  fetchingTimeZones: true,
  timeZones: [],
  fetchingSubmissionStatus: true,
  submissionStatus: [],
  downloading: false,
  download: "",
  clientPlacementConfigStatus: false,
  clientPlacementConfig: [],
  fetchingValidationTypes: false,
  validationTypes: [],
  fetchingRegex: false,
  regexList: [],
  fetchingCurrency: false,
  currencyList: [],
  invoiceStatus: [],
  fetchingInvoiceStatus: false,
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LANGUAGE_FETCHING:
      return state.set("fetchingLanguage", true);
    case LANGUAGE_FETCH:
      return state.set("languages", action.data);
    case LANGUAGE_FETCH_COMPLETE: {
      return state.set("fetchingLanguage", false);
    }
    case SUPPLIER_FIELD_FETCHING:
      return state.set("fecthingSupplierField", true);
    case SUPPLIER_FIELD_FETCH:
      return state.set("supplierFields", action.data);
    case SUPPLIER_FIELD_FETCH_COMPLETE: {
      return state.set("fecthingSupplierField", false);
    }

    case COUNTRY_FETCHING:
      return state.set("fetchingCountry", true);
    case COUNTRY_FETCH:
      return state.set("countries", action.data);
    case COUNTRY_FETCH_COMPLETE: {
      return state.set("fetchingCountry", false);
    }

    case SUBMISSION_FIELDS_FETCHING:
      return state.set("fetchingSubmissionFields", true);
    case SUBMISSION_FIELDS_FETCH:
      return state.set("submissionFields", action.data);
    case SUBMISSION_FIELDS_FETCH_COMPLETE: {
      return state.set("fetchingSubmissionFields", false);
    }

    case TIME_ZONES_FETCHING:
      return state.set("fetchingTimeZones", true);
    case TIME_ZONES_FETCH:
      return state.set("timeZones", action.data);
    case TIME_ZONES_FETCH_COMPLETE: {
      return state.set("fetchingTimeZones", false);
    }

    case SUBMISSION_STATUS_FETCHING:
      return state.set("fetchingSubmissionStatus", true);
    case SUBMISSION_STATUS_FETCH:
      return state.set("submissionStatus", action.data);
    case SUBMISSION_STATUS_FETCH_COMPLETE: {
      return state.set("fetchingSubmissionFields", false);
    }

    case CLIENT_PLACEMENT_CONFIG_FETCHING:
      return state.set("clientPlacementConfigStatus", true);
    case CLIENT_PLACEMENT_CONFIG_FETCH:
      return state.set("clientPlacementConfig", action.data);
    case CLIENT_PLACEMENT_CONFIG_FETCH_COMPLETE: {
      return state.set("clientPlacementConfigStatus", false);
    }
    case VALIDATION_TYPES_FETCHING: {
      return state.set("fetchingValidationTypes", true);
    }
    case VALIDATION_TYPES_FETCH: {
      return state.set("validationTypes", action.data);
    }
    case VALIDATION_TYPES_FETCHED: {
      return state.set("fetchingValidationTypes", false);
    }

    case REGEX_FETCHING: {
      return state.set("fetchingRegex", true);
    }
    case REGEX_FETCH: {
      return state.set("regexList", action.data);
    }
    case REGEX_FETCHED: {
      return state.set("fetchingRegex", false);
    }

    case CURRENCY_FETCH:
      return state.set("currencyList", action.data);
    case CURRENCY_FETCHING:
      return state.set("fetchingCurrency", true);
    case CURRENCY_FETCH_COMPLETE: {
      return state.set("fetchingCurrency", false);
    }

    case INVOICE_STATUS_FETCH:
      return state.set("invoiceStatus", action.data);
    case INVOICE_STATUS_FETCHING:
      return state.set("fetchingInvoiceStatus", true);
    case INVOICE_STATUS_FETCH_COMPLETE: {
      return state.set("fetchingCurrency", false);
    }
    default:
      return state;
  }
};
export default reducer;
