import React from "react";
import Div from "../Div";
import P from "../P";
import Loader from "../Loader";

import {
  headerTextCommonStyle,
  clientSupplierDivWrapper,
  supplierHeaderTextStyle,
  selectSupplierLabelStyle,
} from "./Layout";
import { useTranslation } from "react-i18next";
const Supplier = (props) => {
  const { t } = useTranslation();

  return (
    <Div>
      <P style={headerTextCommonStyle}>
        {t("supplierLookup.selectSupplierLookupLabel")}
      </P>
      <Div style={clientSupplierDivWrapper} divHeight="180px !important">
        <P style={supplierHeaderTextStyle}>
          {t("supplierLookup.suppliersLabel")}
        </P>
        <Div
          divWidth="100%"
          divHeight="100px"
          overflow="auto"
          padding="0 0 0 4px"
        >
          {props.loading ? <Loader /> : null}
          {!props.loading &&
            props.setSelectSupplierNames &&
            props.setSelectSupplierNames.map((supplier, i) => {
              return (
                <Div key={`${supplier.supplierID}`}>
                  <P>{supplier.companyName}</P>
                </Div>
              );
            })}
          {!props.loading &&
          props.remainingSupplier &&
          props.remainingSupplier > 0 ? (
            <Div mt="4px">
              <P
                fontSize="18px"
                color="#000000"
                fontFamily="graphik-medium"
                fontWeight="500"
              >
                + {props.remainingSupplier}
              </P>
            </Div>
          ) : (
            ""
          )}
        </Div>
        <P style={selectSupplierLabelStyle} onClick={props.onClickHandler}>
          {t("supplierLookup.selectSupplierLabel")}
        </P>
      </Div>
    </Div>
  );
};

export default Supplier;
