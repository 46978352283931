import { put, takeEvery, call, all } from "redux-saga/effects";
import { buildNotification } from "../../../config/notification";
import { store } from "react-notifications-component";
import {
  reconciliationListFetchComplete,
  supplierListFetchComplete,
  saveReconciliationComplete,
  reconciledReportsFetchComplete,
  suggestedInvoicesFetchComplete,
  reconciliationCommentsFetchComplete,
  saveReconciliationCommentsComplete,
  startReconciliationComplete,
  reconciliationListRequest,
  getReconciledReportsRequest,
} from "../store/actions";

import {
  RECONCILIATION_LIST_REQUEST,
  SUPPLIER_LIST_REQUEST,
  // USER_DETAILS_REQUEST,
  DOWNLOAD_INPUT_FILE_ACTION,
  DOWNLOAD_DETAILS_FILE_ACTION,
  SAVE_RECONCILIATION_REQUEST,
  GET_RECONCILED_REPORTS_REQUEST,
  GET_SUGGESTED_INVOICES_REQUEST,
  GET_RECONCILIATION_COMMENTS_REQUEST,
  SAVE_RECONCILIATION_COMMENTS_REQUEST,
  START_RECONCILIATION_REQUEST,
  INIT_UPDATE_RECONCILED_DATA,
  INIT_DOWNLOAD_TEMPLATE,
} from "./actionTypes";
import axios from "../../../config/axios";
import moment from "moment";

export default function* watchReconciliation() {
  yield all([
    takeEvery(RECONCILIATION_LIST_REQUEST, getReconciliationList),
    takeEvery(SUPPLIER_LIST_REQUEST, getSupplierList),

    takeEvery(DOWNLOAD_INPUT_FILE_ACTION, downloadInputFile),
    takeEvery(DOWNLOAD_DETAILS_FILE_ACTION, downloadDetailsFile),
    takeEvery(SAVE_RECONCILIATION_REQUEST, saveReconciliation),
    takeEvery(GET_RECONCILED_REPORTS_REQUEST, getReconciledReports),
    takeEvery(GET_SUGGESTED_INVOICES_REQUEST, getSuggestedInvoices),
    takeEvery(GET_RECONCILIATION_COMMENTS_REQUEST, getReconciliationComments),
    takeEvery(SAVE_RECONCILIATION_COMMENTS_REQUEST, saveReconciliationComments),
    takeEvery(START_RECONCILIATION_REQUEST, startReconciliation),
    takeEvery(INIT_UPDATE_RECONCILED_DATA, updateReconciledDataSaga),
    takeEvery(INIT_DOWNLOAD_TEMPLATE, downloadTemplateSaga),
  ]);
}

const postRequestDetails = {
  method: "POST",
  cache: "no-cache",
  headers: {
    "Content-Type": "application/json",
  },
};

const getRequestDetails = {
  method: "GET",
  cache: "no-cache",
};

function* getReconciliationList(action) {
  let url = "/Reconciliation/ListUploadedData";
  let updatedData = [];
  postRequestDetails.data = action.data;
  try {
    const response = yield call(axios, url, postRequestDetails);
    updatedData = response.data || [];
    yield put(reconciliationListFetchComplete(updatedData));
  } catch (error) {
    yield put(reconciliationListFetchComplete());
  }
}

function* getSupplierList(action) {
  const { payload, callback } = action || {};
  let url = `/Reconciliation/GetSupplierData?`;
  payload &&
    Object.keys(payload).forEach((key) => (url += `${key}=${payload[key]}&`));
  try {
    const response = yield call(axios, url, getRequestDetails);
    if (response) {
      const { supplierData, totalCount: totalSupplierCount } =
        response.data || {
          supplierData: [],
          totalCount: 0,
        };
      callback && callback(response);
      yield put(supplierListFetchComplete(supplierData, totalSupplierCount));
    }
  } catch (error) {
    yield put(supplierListFetchComplete());
  }
}

function* downloadInputFile(action) {
  const url = `/Reconciliation/DownloadInputFile?reconcileId=${action.reconcileId}`;
  try {
    const response = yield call(axios, url, { responseType: "blob" });
    if (response && (response.status === 200 || response.status === 202)) {
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: response.headers["content-type"],
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", action.fileName);
      document.body.appendChild(link);
      link.click();
      const notification = buildNotification({
        message: "notification.reconciliation.templateDownloaded",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
    }
  } catch (error) {
    const notification = buildNotification({
      message: "notification.errorOccurred",
      type: "danger",
    });
    store.addNotification({
      ...notification,
    });
  }
}

function* saveReconciliation(action) {
  try {
    postRequestDetails.data = action.payload;
    const response = yield call(
      axios,
      "/Reconciliation/save",
      postRequestDetails
    );

    if (response && response.status === 200) {
      yield put(saveReconciliationComplete("success"));
      const notification = buildNotification({
        message: "notification.reconciliation.formSaved",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(saveReconciliationComplete("error"));
    const notification = buildNotification({
      message: "notification.errorOccurred",
      type: "danger",
    });
    store.addNotification({
      ...notification,
    });
    if (action.callback) {
      action.callback();
    }
  }
}

function* getReconciledReports(action) {
  let sessionDetails =
    JSON.parse(sessionStorage.getItem("sessionDetails")) || {};
  let languageID =
    sessionDetails &&
    sessionDetails.languageId &&
    sessionDetails.languageId !== ""
      ? sessionDetails.languageId
      : "enGB";
  const url = `Reconciliation/ViewReconciledReports?reconcileId=${action.reconcileId}&languageID=${languageID}`;
  let updatedData = {};
  try {
    const response = yield call(axios, url, getRequestDetails);
    updatedData = response.data || [];
    const { labels, listData } = updatedData || {};
    yield put(reconciledReportsFetchComplete(labels, listData));
  } catch (error) {
    yield put(reconciledReportsFetchComplete());
  }
}

function* getSuggestedInvoices(action) {
  const url = `Reconciliation/GetMatchingInvoices?reconcileId=${action.param.reconcileId}&ID=${action.param.id}`;
  try {
    const response = yield call(axios, url, getRequestDetails);
    yield put(suggestedInvoicesFetchComplete(response.data));
  } catch (error) {
    yield put(suggestedInvoicesFetchComplete());
  }
}

function* getReconciliationComments(action) {
  const url = `Reconciliation/GetReconciliationComments?reconcileId=${action.reconcileId}`;
  let updatedData = [];
  try {
    const response = yield call(axios, url, getRequestDetails);
    updatedData = yield response.data.map((item) => {
      return {
        ...item,
        time: moment(item.createdDate).format("hh:mm:ss a"),
      };
    });
    yield put(reconciliationCommentsFetchComplete(updatedData));
  } catch (error) {
    yield put(reconciliationCommentsFetchComplete());
  }
}

function* saveReconciliationComments(action) {
  try {
    postRequestDetails.data = action.payload;
    const response = yield call(
      axios,
      "Reconciliation/SaveReconciliationComments",
      postRequestDetails
    );

    if (response && response.status === 202) {
      yield put(saveReconciliationCommentsComplete("success"));
      const notification = buildNotification({
        message: "notification.reconciliation.commentSaved",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
    }
  } catch (error) {
    yield put(saveReconciliationCommentsComplete("error"));
    const notification = buildNotification({
      message: "notification.errorOccurred",
      type: "danger",
    });
    store.addNotification({
      ...notification,
    });
  }
}

function* startReconciliation(action) {
  try {
    const url = `/Reconciliation/StartReconciliation?reconcileId=${action.param}`;
    const response = yield call(axios, url, postRequestDetails);

    if (response && response.status === 200) {
      yield put(reconciliationListRequest(action.data));
      yield put(startReconciliationComplete("success"));
    }
  } catch (error) {
    yield put(startReconciliationComplete("error"));
  }
}

function* updateReconciledDataSaga(action) {
  const url = "/Reconciliation/UpdateReconciledData";
  postRequestDetails.data = action.data;
  try {
    const response = yield call(axios, url, postRequestDetails);
    if (response && response.status === 204) {
      // yield put(reconciliationListRequest());
      yield put(
        getReconciledReportsRequest(action.data.reconcileId, { languageID: "" })
      );
    }
  } catch (err) {
    action.callback && action.callback();
  }
}

function* downloadTemplateSaga(action) {
  const url = "/Reconciliation/DownloadTemplate";
  try {
    const response = yield call(axios, url, { responseType: "blob" });
    if (response && (response.status === 200 || response.status === 202)) {
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: response.headers["content-type"],
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Sample.xlsx");
      document.body.appendChild(link);
      link.click();
      const notification = buildNotification({
        message: "notification.reconciliation.templateDownloaded",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
    }
  } catch (err) {
    action.callback && action.callback();
  }
}

function* downloadDetailsFile(action) {
  const url = `/Reconciliation/DownloadReconciledFile?reconcileId=${action.ID}`;
  try {
    const response = yield call(axios, url, { responseType: "blob" });
    if (response && (response.status === 200 || response.status === 202)) {
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: response.headers["content-type"],
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "File.xlsx");
      document.body.appendChild(link);
      link.click();
      const notification = buildNotification({
        message: "notification.manageUpload.downloadSuccess",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
    }
  } catch (error) {
    const notification = buildNotification({
      message: "notification.errorOccurred",
      type: "danger",
    });
    store.addNotification({
      ...notification,
    });
  }
}
