import styled from "styled-components";

const Span = styled.span`
  font-family: ${(props) =>
    props.fontFamily ? props.fontFamily : "graphik-light"};
  font-style: ${(props) => (props.fontStyle ? props.fontStyle : "")};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "")};
  width: ${(props) => (props.width ? props.width : "")};
  padding: ${(props) => (props.padding ? props.padding : "")};
  padding-right: ${(props) => (props.pr ? props.pr : "")};
  padding-top: ${(props) => (props.pt ? props.pt : "")};
  overflow-wrap: ${(props) => props.overflowWrap || ""};
  float: ${(props) => (props.float ? props.float : "")};
  align-items: ${(props) => props.alignItems || ""};
  text-align: ${(props) => (props.textAlign ? props.textAlign : "")};
  align-self: ${(props) => props.selfAlign || ""};
  align-content: ${(props) => props.alignContent || ""};
  justify-content: ${(props) => props.justifyContent || ""};
  display: ${(props) => props.display || "inline"};
  flex-shrink: ${(props) => props.shrink || ""};
  flex-direction: ${(props) => props.direction || ""};
  position: ${(props) => (props.position ? props.position : "")};
  color: ${(props) => (props.color ? props.color : "")};
  height: ${(props) => (props.height ? props.height : "")};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : "")};
  letter-spacing: ${(props) => (props.lSpacing ? props.lSpacing : "")};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "")};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : "")};
  margin-right: ${(props) => (props.mr ? props.mr : "")};
  margin-top: ${(props) => (props.mt ? props.mt : "")};
  margin: ${(props) => (props.margin ? props.margin : "")};
  cursor: ${(props) => (props.cursor ? props.cursor : "")};
  border: ${(props) => props.border && props.border};
  border-radius: ${(props) => props.borderRadius || ""};
  background-color: ${(props) => props.bgColor || ""};
  &:hover {
    background-color: ${(props) => (props.hoverColor ? props.hoverColor : "")};
    border-radius: ${(props) => (props.hoverBR ? props.hoverBR : "")};
    color: ${(props) => (props.hoverTextColor ? props.hoverTextColor : "")};
  }
`;
export default Span;
