import React, { useEffect, useState } from "react";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import { useTranslation } from "react-i18next";

import "react-datepicker/dist/react-datepicker.css";

import { getLocale } from "../../helper";

import DatePickerCustom from "./DatepickerCustom";
import { getTranslationFile } from "../Datepicker/config";
import moment from "moment";
import { momentDateFormat } from "./config";

const Datepicker = (props) => {
  const { t } = useTranslation();

  const sessionDetails =
    JSON.parse(sessionStorage.getItem("sessionDetails")) || {};
  const { languageId } = sessionDetails || {};
  const locale = getLocale(languageId);
  const translationFile = getTranslationFile[locale];

  registerLocale(locale, {
    ...translationFile,
    options: { ...translationFile.options, weekStartsOn: 1 },
  });

  const ref = React.createRef();

  const [inputDate, setInputDate] = useState(null);

  useEffect(() => {
    props.selected && setInputDate(props.selected.format(momentDateFormat));
  }, [props]);
  const handleDateInputChange = (value) => {
    const date = moment(value, momentDateFormat);

    if (date.isValid() && value.length === 10) {
      props.setDate(moment(date));
    }
    setInputDate(value);
  };
  const handleDatePickerDateChange = (date) => {
    setInputDate(moment(date).format(momentDateFormat));
    props.setDate(moment(date));
  };

  return (
    <ReactDatePicker
      locale={locale}
      id={props.id}
      selected={props.selected.toDate()}
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      autoComplete="off"
      maxDate={props.maxDate}
      minDate={props.minDate}
      todayButton={t("common.todayLabel")}
      openToDate={props.selected.toDate()}
      selectsStart={props.selectsStart}
      selectsEnd={props.selectsEnd}
      startDate={props.startDate}
      endDate={props.endDate}
      popperPlacement="right"
      onChange={(date) => handleDatePickerDateChange(date)}
      customInput={
        <DatePickerCustom
          ref={ref}
          hint={props.placeholder}
          focus={props.focus}
          onInputChange={handleDateInputChange}
          inputValue={inputDate}
        />
      }
    />
  );
};

export default Datepicker;
