import React, { forwardRef } from "react";
import Div from "../Div";
import IMG from "../IMG";
import Input from "../Input";
import { flexGrow, flexRow, flexShrink } from "../Layout/style";
import calendarIcon from "../../assets/images/calendar.png";
const DatePickerCustom = forwardRef((props, _ref) => {
  const isValidDateFormat = (e) => {
    const charCode = e.which ? e.which : e.keyCode;
    if (charCode < 47 || charCode > 57) e.preventDefault();
  };
  return (
    <Div
      {...flexRow}
      {...flexGrow}
      id={props.id}
      margin={props.margin ? props.margin : "8px"}
      padding={props.padding ? props.padding : "8px"}
      border="none"
    >
      <Div {...flexRow} {...flexGrow}>
        <Input
          flex="1"
          border="none"
          autoFocus={props.focus}
          placeholder={props.hint}
          value={props.inputValue || ""}
          onKeyPress={isValidDateFormat}
          maxLength="10"
          onChange={(e) => {
            props.onInputChange(e.target.value);
          }}
        />
      </Div>
      <Div {...flexRow} {...flexShrink} onClick={props.onClick}>
        <IMG cursor="pointer" src={calendarIcon} alt="Datepicker Icon" />
      </Div>
    </Div>
  );
});

export default DatePickerCustom;
