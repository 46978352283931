import { createSelector } from "reselect";
import { initialState } from "./reducer";

const selectUMR = (state) => state.get("UMR", initialState);

export const selectUMRList = () =>
  createSelector(selectUMR, (state) => state.get("UMRList"));

export const selectUMRLoading = () =>
  createSelector(selectUMR, (state) => state.get("fetchingUMRList"));
