import React, { useRef } from "react";
import { store } from "react-notifications-component";
import i18next from "i18next";
import Div from "../Div";

import FileUploadPreview from "./FileUploadPreview";

import { buildNotification } from "../../config/notification";

import Span from "../Span";
import { useTranslation } from "react-i18next";
import { flexCol, flexGrow, flexRow, flexShrink } from "../Layout/style";

import { HideDefaultFileInput, StyledFileUploader } from "./styled";
import IMG from "../IMG";
import addAttchmentIcon from "../../assets/images/add.png";
const labelStyle = {
  color: "rgb(33, 43, 54)",
  fontSize: "14px",
  fontFamily: "graphik-medium",
  fontWeight: 500,
  lSpacing: "-0.25px",
  lineHeight: "16px",
  margin: "0 0 3px 0",
};
const despStyle = {
  ...labelStyle,
  margin: 0,
  color: "rgb(145, 158, 171)",
  fontSize: "10px",

  letterSpacing: "0px",
  lineHeight: "10px",
};
const FileUpload = (props) => {
  const { t } = useTranslation();
  const filePickerRef = useRef();
  const shouldDisable =
    !props.multiple && props.fileData && props.fileData.length > 0;

  const filePreviewer =
    props.fileData && props.fileData.length > 0 ? (
      <FileUploadPreview
        fileData={props.fileData}
        deleteSingleFile={props.deleteSingleFile}
      />
    ) : null;

  const handleUpload = (e) => {
    let maxSizeExceeded = false;
    let minSizeAllowed = false;
    const files = [];
    Array.from(e.target.files).every((item) => {
      const fileExt = item.name.split(".").pop().toLowerCase();

      let allowedFiles = null;
      if (props.allowedFiles) {
        allowedFiles = props.allowedFiles.toLowerCase();
      }
      if (allowedFiles && !allowedFiles.includes(fileExt)) {
        e.target.value = null;
        const notification = buildNotification({
          message: "common.invalidFileExtension", //previous message - "Invalid File Type"
          type: "danger",
        });

        store.addNotification({
          ...notification,
        });
        return false;
      }

      if (item && (!item.name || !/^[^.]+.[^.]+$/.test(item.name))) {
        e.target.value = null;
        const notification = buildNotification({
          message: `${i18next.t("common.invalidFile")} ${
            item.name
          }, ${i18next.t("common.invalidFileExtension")}`,
          type: "danger",
        });
        store.addNotification({
          ...notification,
        });
        return false;
      }

      if (item.size < props.maxFileSize && item.size > props.minFileSize) {
        files.push(item);
      } else {
        maxSizeExceeded = item.size > props.maxFileSize ? true : false;
        minSizeAllowed = item.size < props.minFileSize ? true : false;
      }

      return true;
    });
    if (files.length > 0) {
      props.handleFileUpload({
        target: { id: e.target.id, name: e.target.name, files },
      });
    }
    e.target.value = null;
    if (maxSizeExceeded) {
      const notification = buildNotification({
        message: "notification.fileUpload.maxSizeError",
        type: "warning",
      });

      store.addNotification({
        ...notification,
      });
      return false;
    }
    if (minSizeAllowed) {
      const notification = buildNotification({
        message: "notification.fileUpload.minSizeError",
        type: "warning",
      });

      store.addNotification({
        ...notification,
      });
      return false;
    }
  };
  const { allowedFiles } = props;

  return (
    <>
      <Div
        {...flexCol}
        {...flexGrow}
        divWidth={props.inputWidth || "450px"}
        cursor="not-allowed"
      >
        <HideDefaultFileInput
          disabled={shouldDisable}
          id={props.id}
          multiple={props.multiple}
          ref={filePickerRef}
          name={props.name}
          onChange={handleUpload}
          accept={props.allowedFiles}
        />
        <StyledFileUploader disabled={shouldDisable} htmlFor={props.id}>
          <Div {...flexRow} {...flexShrink}>
            <Span
              {...flexRow}
              {...flexShrink}
              selfAlign="center"
              margin="0 8px 0 0 "
            >
              <IMG
                imgHeight="28px"
                imgWidth="28px"
                src={addAttchmentIcon}
                alt="Add Attchment"
              />
            </Span>
          </Div>
          <Div {...flexCol} {...flexGrow} selfAlign="center">
            <Span {...flexRow} {...flexGrow} {...labelStyle}>
              {props.attachmentLabel
                ? t(props.attachmentLabel)
                : t("fileUpload.addAttachmentLabel")}
              &nbsp; {props.required ? <Span color="red">*</Span> : null}
            </Span>
            <Span {...flexRow} {...flexGrow} {...despStyle}>
              {props.addAttachmentSizeLabel
                ? t(props.addAttachmentSizeLabel)
                : t("fileUpload.sizeLabel")}
              &nbsp;,
              {t("fileUpload.allowedTypes", "...")}{" "}
              {allowedFiles || t("fileUpload.allFileTypes")}
            </Span>
          </Div>
        </StyledFileUploader>
      </Div>
      {filePreviewer}
    </>
  );
};
export default FileUpload;
