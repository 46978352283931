import styled from "styled-components";

const Li = styled.li`
  float: ${(props) =>
    props.float ? props.float.toString().toLowerCase() : ""};
  margin-right: ${(props) => (props.mr ? props.mr : "")};
  margin-left: ${(props) => (props.ml ? props.ml : "")};
  margin-top: ${(props) => (props.mt ? props.mt : "")};
  margin-bottom: ${(props) => (props.mb ? props.mb : "")};
  margin: ${(props) => (props.margin ? props.margin : "")};
  padding: ${(props) => (props.padding ? props.padding : "")};
  position: ${(props) => (props.postion ? props.postion : "")};
  width: ${(props) => (props.liWidth ? props.liWidth : "")};
  height: ${(props) => (props.liHeight ? props.liHeight : "")};
  background-color: ${(props) => (props.bgColor ? props.bgColor : "")};
  display: ${(props) => (props.display ? props.display : "")};
  color: ${(props) => (props.color ? props.color : "")};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "12px")};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "")};
  list-style-type: ${(props) =>
    props.listStyleType ? props.listStyleType : "none"};
  &::after {
    content: "${(props) => (props.content ? props.content : "")}";
  }
`;
export default Li;
