import styled from "styled-components";

const Label = styled.label`
  width: ${(props) => (props.width ? props.width : "")};
  height: ${(props) => (props.height ? props.height : "")};
  color: ${(props) => (props.fontColor ? props.fontColor : "#2d3a4b")};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "11px")};
  text-transform: ${(props) => props.textTransform || "capitalize"};
  font-family: ${(props) =>
    props.fontFamily ? props.fontFamily : "graphik-medium"};
  align-self: ${(props) => props.selfAlign || ""};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "500")};
  letter-spacing: ${(props) =>
    props.letterSpacing ? props.letterSpacing : "-0.35px"};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : "14px")};
  cursor: ${(props) => (props.cursor ? props.cursor : "pointer")};
  margin: ${(props) => (props.margin ? props.margin : "0px 0px")};
  display: ${(props) => (props.display ? props.display : "")};
  flex-shrink: ${(props) => (props.shrink ? props.shrink : "")};
  flex-direction: ${(props) => (props.direction ? props.direction : "")};
  flex-grow: ${(props) => (props.grow ? props.grow : "")};
  opacity: ${(props) => props.opacity || "1"};

  text-align: ${(props) => (props.textalign ? props.textalign : "")};
  float: ${(props) => (props.float ? props.float : "")};
  padding: ${(props) => (props.padding ? props.padding : "")};
  border-left: ${(props) => (props.borderLeft ? props.borderLeft : "")};
  font-style: ${(props) => (props.fontStyle ? props.fontStyle : "500")};
  background: ${(props) => (props.background ? props.background : "")};
  -webkit-background-clip: ${(props) =>
    props.backgroundClip ? props.backgroundClip : ""};
  -webkit-text-fill-color: ${(props) =>
    props.textFillColor ? props.textFillColor : ""};
  padding-top: ${(props) => (props.pt ? props.pt : "")};
  overflow: ${(props) => (props.overflow ? props.overflow : "")};
  text-overflow: ${(props) => (props.textOverflow ? props.textOverflow : "")};
  position: ${(props) => (props.position ? props.position : "relative")};

`;

export default Label;
