import {
  GET_NOTIFICATION_DATA,
  FETCH_NOTIFICATION_DATA,
} from "./actionTypes";

export const initGetNotification = () => {
  return {
    type: GET_NOTIFICATION_DATA,
  };
};
export const fetchNotificationData = (data) => {
  return {
    type: FETCH_NOTIFICATION_DATA,
    data: data,
  };
};