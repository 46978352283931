import React from "react";
import { useTranslation } from "react-i18next";

import Div from "../Div";

import successLite from "../../assets/images/success_lite.png";
import warningLite from "../../assets/images/warning_lite.png";
import dangerLite from "../../assets/images/error_lite.png";
import closeLite from "../../assets/images/close_lite.png";
import IMG from "../IMG";

const Notification = (props) => {
  const { t } = useTranslation();

  return (
    <Div
      display="flex"
      padding="9px 20px 9px 20px"
      borderRadius="10px 10px 10px 10px"
      bgColor="#FFFFFF"
      border="1px solid rgb(220, 226, 231)"
      boxShadow="0px 2px 15px 0px rgb(223, 227, 232)"
    >
      <Div margin="auto">
        {props.type === "success" ? (
          <IMG src={successLite} />
        ) : props.type === "warning" ? (
          <IMG src={warningLite} />
        ) : props.type === "danger" ? (
          <IMG src={dangerLite} />
        ) : null}
      </Div>
      <Div padding="0 15px 0 15px" grow="1">
        {t(props.message)}
      </Div>
      <Div cursor="pointer">
        <IMG src={closeLite} />
      </Div>
    </Div>
  );
};

export default Notification;
