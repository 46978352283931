import React, { Component } from "react";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import { store } from "react-notifications-component";
import { withTranslation } from "react-i18next";

import axiosHandler from "../../hoc/AxiosHandler";
import axios from "../../config/axios";
import { buildNotification } from "../../config/notification";

import Div from "../../components/Div";
import ChangePasswordModal from "../../components/Modal";
import { divWrapperStyle } from "./config/pageConfig";
import ChangePasswordForm from "./changePasswordForm";

import { initChangePassword } from "./store/action";
import { logout } from "../../container/LoginPage/store/actions";

import {
  selectChangingPassword,
  selectPasswordUpdated,
  selectResetForm,
} from "./store/selectors";
import { passwordRegEx } from "../SignUp/config";

const defaultForm = {
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
};
class ChangePassword extends Component {
  state = {
    newFormData: { ...defaultForm },
    enablePasswordFor: [],
    errors: {},
    touched: [],
  };
  componentDidUpdate(prepProps, prevState) {
    if (prepProps.passwordUpdated !== this.props.passwordUpdated) {
      this.props.logout();
    }
  }
  toggleShowPassword = (id) => {
    let cpyEnablePasswordFor = [...this.state.enablePasswordFor];
    const index = cpyEnablePasswordFor.indexOf(id);
    if (index > -1) {
      cpyEnablePasswordFor.splice(index, 1);
    } else {
      cpyEnablePasswordFor = [...cpyEnablePasswordFor, id];
    }
    this.setState(() => ({
      enablePasswordFor: cpyEnablePasswordFor,
    }));
  };
  resetFormData = () => {
    this.setState({
      newFormData: { ...defaultForm },
      errors: {},
      touched: [],
    });
  };
  validateInput = (e) => {
    const { id, value } = e.target;
    const isValid = this.validate(value);
    let errors = { ...this.state.errors };
    const touched = [...this.state.touched];
    const touchedIdx = touched.findIndex((i) => i === id);
    if (touched && touchedIdx === -1) touched.push(id);
    if (isValid.status) {
      if (id === "newPassword" || id === "confirmPassword") {
        delete errors.newPassword;
        delete errors.confirmPassword;
      }
      delete errors[id];
    } else {
      errors = { ...errors, [id]: isValid.message };
      const notification = buildNotification({
        message: isValid.message,
        type: "warning",
      });
      if (touched.indexOf(id) !== -1) {
        store.addNotification({
          ...notification,
        });
      }
    }
    this.setState({
      touched,
      errors,
    });
  };
  handleChange = (e) => {
    const { name, value } = e.target;

    this.setState((prevState) => ({
      newFormData: {
        ...prevState.newFormData,
        [name]: value,
      },
    }));
  };
  validate = (value) => {
    if (!value.match(passwordRegEx) && value.trim() !== "") {
      return {
        status: false,
        message: this.props.t("common.incorrectFormatError"),
      };
    }
    return { status: true };
  };
  isValidForm = () => {
    const { errors } = this.state;
    const noFieldErrors = Object.values(errors).every(
      (elem) => elem === null || elem === ""
    );
    if (noFieldErrors) {
      return true;
    }
  };
  onFormSubmit = (e) => {
    e.preventDefault();
    let { oldPassword, newPassword, confirmPassword } = this.state.newFormData;

    if (oldPassword === null || oldPassword === "") {
      const notification = buildNotification({
        message: this.props.t("changePassword.oldPasswordValidation"),
        type: "warning",
      });

      store.addNotification({
        ...notification,
      });
      return false;
    }
    if (newPassword === null || newPassword === "") {
      const notification = buildNotification({
        message: this.props.t("changePassword.newPasswordValidation"),
        type: "warning",
      });

      store.addNotification({
        ...notification,
      });
      return false;
    }
    if (confirmPassword === null || confirmPassword === "") {
      const notification = buildNotification({
        message: this.props.t("changePassword.confirmPasswordValidation"),
        type: "warning",
      });

      store.addNotification({
        ...notification,
      });
      return false;
    }
    if (newPassword !== confirmPassword) {
      const notification = buildNotification({
        message: this.props.t("changePassword.confirmPasswordMismatch"),
        type: "warning",
      });

      store.addNotification({
        ...notification,
      });
      return false;
    }

    const encryptOldPassword = btoa(oldPassword);
    const encryptNewPassword = btoa(newPassword);
    const encryptConfirmPassword = btoa(confirmPassword);

    const form = {
      oldPassword: encryptOldPassword,
      newPassword: encryptNewPassword,
      confirmPassword: encryptConfirmPassword,
    };
    this.props.changePassword(form, this.resetFormData);
  };
  render() {
    return (
      <React.Fragment>
        <ChangePasswordModal
          preventOutterClickClose
          show={this.props.show}
          onClose={this.props.onClose}
          title="changePassword.changePasswordLabel"
          width="500px"
          height="auto"
          borderRadius="16px"
          customStyle={{
            headerBgColor: "#ffffff",
            headerBorder: "none",
            headerHeight: "50px",
            headerTextalign: "center",
            headerPadding: "15px 0 0 20px",
          }}
        >
          <Div {...divWrapperStyle}>
            <ChangePasswordForm
              {...this.props}
              handleChange={this.handleChange}
              onFormSubmit={this.onFormSubmit}
              toggleShowPassword={this.toggleShowPassword}
              state={this.state}
              isResetPassword={this.props.changingPassword}
              onBlur={this.validateInput}
            />
          </Div>
        </ChangePasswordModal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  changingPassword: selectChangingPassword(),
  passwordUpdated: selectPasswordUpdated(),
  resetForm: selectResetForm(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    changePassword: (payload, callback) => {
      dispatch(initChangePassword(payload, callback));
    },
    logout: () => {
      dispatch(logout());
    },
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(
  axiosHandler(withTranslation()(ChangePassword), axios)
);
