import { put, takeEvery, takeLatest, call, all } from "redux-saga/effects";
import { buildNotification } from "../../../../config/notification";
import { store } from "react-notifications-component";
import axios from "../../../../config/axios";

import {
  fetchingLogStatus,
  fetchingLogComplete,
  reprocessedSavedORFailed,
  fetchingEmailLogDescription,
  fetchEmailLogDescription,
  emailLogDescriptionFetchComplete,
  clearingEmailLog,
  clearedEmailLog,
  clearEmailLogFailed,
  fetchLogsList,
  initLogsList,
  resetLogList,
} from "../store/actions";

import {
  INIT_FETCH_EMAIL_LOG_DESCRIPTION_BY_ID,
  INIT_CLEAR_EMAIL_LOG,
  INIT_LOGS_LIST,
  INIT_REPROCESS_SUBMISSION,
} from "./actionTypes";
import {
  postRequestDetails,
  getRequestDetails,
} from "../../../../config/requestHeaders";

export default function* watchUsersLog() {
  yield all([
    takeLatest(INIT_LOGS_LIST, initLogsListSaga),
    takeEvery(INIT_REPROCESS_SUBMISSION, initReprocessSubmissionSaga),
    takeEvery(
      INIT_FETCH_EMAIL_LOG_DESCRIPTION_BY_ID,
      getEmailLogDescriptionByIdSaga
    ),
    takeEvery(INIT_CLEAR_EMAIL_LOG, clearEmailLogSaga),
  ]);
}

function* initLogsListSaga(action) {
  let sourceType = action.param.sourceType;
  let dateFrom = action.param.dateFrom;
  let dateTo = action.param.dateTo;
  yield put(fetchingLogStatus());
  // let url = `/Log/LogsList?sourceType=${sourceType}`;
  // if (action.param.isFilter) {
    let url = `/Log/LogsList?sourceType=${sourceType}&dateFrom=${dateFrom}&dateTo=${dateTo}`;
  // }
  try {
    const response = yield call(axios, url, getRequestDetails);
    if (response && response.status === 200) {
      action.callback(response.data.logs);
      yield put(fetchLogsList(response.data));
      yield put(fetchingLogComplete());
    } else {
      action.callback([]);
      yield put(fetchingLogComplete());
    }
  } catch (error) {
    action.callback([]);
    yield put(fetchingLogComplete());
    const notification = buildNotification({
      message: "notification.errorOccurred",
      type: "danger",
    });
    store.addNotification({
      ...notification,
    });
  }
}
//Get Email Description by id
function* getEmailLogDescriptionByIdSaga(action) {
  yield put(fetchingEmailLogDescription());
  const url = `/Log/EmailLogsById?emailDataId=${action.id}`;
  try {
    const response = yield call(axios, url, getRequestDetails);
    if (response) {
      yield put(fetchEmailLogDescription(response.data));
    }
    yield put(emailLogDescriptionFetchComplete());
  } catch (error) {
    yield put(emailLogDescriptionFetchComplete());
    const notification = buildNotification({
      message: "notification.errorOccurred",
      type: "error",
    });

    store.addNotification({
      ...notification,
    });
  }
}

//clear/archive email logs
function* clearEmailLogSaga(action) {
  yield put(clearingEmailLog());

  const url = "/Log/ArchiveEmailLogs";
  try {
    postRequestDetails.data = { ...action.payload };

    const response = yield call(axios, url, postRequestDetails);
    if (response && response.status === 202) {
      yield put(clearedEmailLog());
      const notification = buildNotification({
        message: "notification.adminLogs.emailLogsCleared",
        type: "success",
      });

      store.addNotification({
        ...notification,
      });

      yield put(
        initLogsList({ sourceType: "email" }, () => {
          return;
        })
      );
    }
  } catch (error) {
    yield put(clearEmailLogFailed());
  }
}

function* initReprocessSubmissionSaga(action) {
  const url = `/Log/ReProcess?submissionId=${action.submissionId.submissionId}`;
  try {
    const response = yield call(axios, url, getRequestDetails);
    if (response && response.status === 200) {
      yield put(reprocessedSavedORFailed());
      yield put(resetLogList());
      const notification = buildNotification({
        message: "notification.adminLogs.reprocessSubmission",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
      action.callback(true);
    } else {
      action.callback(false);
      yield put(reprocessedSavedORFailed());
    }
  } catch (error) {
    const notification = buildNotification({
      message: "notification.errorOccurred",
      type: "error",
    });

    store.addNotification({
      ...notification,
    });
    yield put(reprocessedSavedORFailed());
    action.callback(false);
  }
}
