import {
  GET_EMAIL_DETAILS,
  UPDATE_EMAIL_DETAILS,
  UPDATE_FIELD_VALUE,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  FETCHING_EMAIL_TO_RESET_PASSWORD,
  FETCHING_EMAIL_TO_RESET_PASSWORD_SUCCESS,
  FETCHING_EMAIL_TO_RESET_PASSWORD_FAILED,
} from "./actionTypes";

export function getEmailDetailsAction(token, callBackFunction) {
  return {
    type: GET_EMAIL_DETAILS,
    token,
    callBackFunction,
  };
}

export function updateEmailDetails(userName) {
  return {
    type: UPDATE_EMAIL_DETAILS,
    userName,
  };
}

export function updateFieldValue(field) {
  return {
    type: UPDATE_FIELD_VALUE,
    field,
  };
}

export function updatePasswordRequest(body, callBackFunction) {
  return {
    type: UPDATE_PASSWORD_REQUEST,
    body,
    callBackFunction,
  };
}

export function updatePasswordSuccess() {
  return {
    type: UPDATE_PASSWORD_SUCCESS,
  };
}

export function fetchingEmailToResetPassword() {
  return {
    type: FETCHING_EMAIL_TO_RESET_PASSWORD,
  };
}

export function fetchingEmailToResetPasswordSuccess() {
  return {
    type: FETCHING_EMAIL_TO_RESET_PASSWORD_SUCCESS,
  };
}

export function fetchingEmailToResetPasswordFailed() {
  return {
    type: FETCHING_EMAIL_TO_RESET_PASSWORD_FAILED,
  };
}
