import {
  call,
  put,
  all,
  takeLatest,
  select,
  takeEvery,
} from "redux-saga/effects";
import { store } from "react-notifications-component";

import axios from "../../../config/axios";
import { buildNotification } from "../../../config/notification";

import {
  INIT_FETCH_UMR_LIST,
  INIT_UMR_DELETE,
  INIT_UMR_SAVE,
  INIT_UMR_UPDATE_STATUS,
} from "./actionTypes";

import {
  fetchingUMRList,
  fetchUMRList,
  fetchUMRListCompleted,
} from "./actions";

import {
  getRequestDetails,
  postRequestDetails,
} from "../../../config/requestHeaders";
import { selectUMRList } from "./selectors";

export default function* watchUMRSagas() {
  yield all([
    takeLatest(INIT_FETCH_UMR_LIST, getUMRListSaga),
    takeLatest(INIT_UMR_SAVE, initUMRSaveSaga),
    takeLatest(INIT_UMR_DELETE, initUMRDeleteSaga),
    takeEvery(INIT_UMR_UPDATE_STATUS, initUMRUpdateStatusSaga),
  ]);
}

function* getUMRListSaga(action) {
  try {
    const sessionDetails = yield sessionStorage.getItem("sessionDetails");
    let langKey = "enGB";
    if (sessionDetails) {
      let prsSD = yield JSON.parse(sessionDetails);
      langKey = yield prsSD.languageId;
    }
    const url = `/Report/listUserManagementReports?langKey=${langKey}`;
    yield put(fetchingUMRList());
    const response = yield call(axios, url, getRequestDetails);

    if (response && response.status === 200) {
      yield put(fetchUMRList(response.data));
    }
    yield put(fetchUMRListCompleted());
  } catch (error) {
    yield put(fetchUMRListCompleted());
  }
}

function* initUMRSaveSaga(action) {
  try {
    const url = yield "/Report/saveUserReportManagment";

    postRequestDetails.data = yield {
      ...action.payload,
    };

    yield put(fetchingUMRList());
    const response = yield call(axios, url, postRequestDetails);
    const { data } = yield response;
    const notification = buildNotification({
      message: "notification.saveSuccess",
      type: "success",
    });

    store.addNotification({
      ...notification,
    });
    const currentList = yield select(selectUMRList());
    let list = yield [{ ...data, reportType: "User Report" }];
    if (action.payload.umReportID) {
      const updatedItemIndex = yield currentList.findIndex(
        (item) => item.umReportID === action.payload.umReportID
      );
      const updatedItem = yield {
        ...data,
      };

      list = yield [...currentList];
      list[updatedItemIndex] = yield {
        ...updatedItem,
        reportType: "User Report",
      };
    } else {
      list = yield list.concat(...currentList);
    }

    yield put(fetchUMRList(list));
    yield put(fetchUMRListCompleted());
  } catch (error) {
    yield put(fetchUMRListCompleted());
  }
}

function* initUMRDeleteSaga(action) {
  try {
    const url = yield "/Report/DeleteUMReport";
    const deleteIDs = action.payload;
    postRequestDetails.data = yield {
      listOfIds: deleteIDs.toString(),
    };
    yield put(fetchingUMRList());
    yield call(axios, url, postRequestDetails);
    const notification = buildNotification({
      message: "client.deletedLabel",
      type: "success",
    });

    store.addNotification({
      ...notification,
    });
    const currentList = yield select(selectUMRList());
    const list = currentList.filter(
      (item) => !deleteIDs.includes(item.umReportID)
    );

    yield put(fetchUMRList(list));
    yield put(fetchUMRListCompleted());
  } catch (error) {
    yield put(fetchUMRListCompleted());
  }
}

function* initUMRUpdateStatusSaga(action) {
  try {
    const url = yield "/Report/changeUMReportStatus";

    postRequestDetails.data = yield {
      ...action.payload,
    };
    yield put(fetchingUMRList());
    const { data } = yield call(axios, url, postRequestDetails);
    if (data) {
      const currentList = yield select(selectUMRList());
      const updatedItemIndex = currentList.findIndex(
        (item) => item.umReportID === action.payload.umReportID
      );
      const updatedItem = {
        ...currentList[updatedItemIndex],
        enabled: action.payload.enabled,
      };

      const newList = [...currentList];
      newList[updatedItemIndex] = { ...updatedItem };

      yield put(fetchUMRList(newList));
    }
    yield put(fetchUMRListCompleted());
  } catch (error) {
    console.log(error);
    yield put(fetchUMRListCompleted());
  }
}
