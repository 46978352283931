import styled from "styled-components";
import A from "../../A";
import Span from "../../Span";

export const headerSection = {};

export const IconButton = styled.button.attrs((props) => ({
  disabled: props.disabled || false,
}))`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  height: 32px;

  background: #ffffff00;

  cursor: pointer;
  justify-content: center;
  align-items: center;

  border: none;
  border-radius: 0;
  outline: none;
`;

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  width: 40px;
  height: 40px;
  align-items: center;
  outline: none;
  &:hover {
    background-color: #005eabbf;
  }
  &:focus-within {
    background-color: #005eabbf;
  }
`;

export const MyAccount = styled(IconWrapper)`
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 32px;
`;

export const SiteActions = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: center;
  margin: 0 4px 0 0;

  @media screen and (max-width: 991px) {
    display: none;
  }
`;
export const VerticalLine = styled.div`
  display: flex;
  width: ${(props) => props.width || "1px"};
  height: ${(props) => props.height || "24px"};
  background: ${(props) => props.bg || "rgba(255, 255, 255, 0.35)"};
  border-radius: ${(props) => props.br || "0.5px"};
  align-self: center;
  margin: 0 8px;

  @media screen and (max-width: 991px) {
    display: ${(props) => (props.alwaysVisible ? "flex" : "none")};
  }
`;

export const LogoText = styled(Span)`
  color: rgb(255, 255, 255);
  font-size: 14px;
  font-family: graphik-medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
`;

export const HeaderWrapper = styled.header`
  position: relative;
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  box-sizing: border-box;
  height: 48px;
  background: #008eff;
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.15);
  border-radius: 0px;
`;

export const Logo = styled(A)`
  justify-self: flex-start;
  display: flex;
  align-items: center;
  outline: none;
  padding: 0 4px;
  &:hover {
    outline: 1px solid #fff;
  }
  &:focus {
    outline: 1px solid #fff;
  }
`;

export const UserMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: #fff;
  top: 48px;
  right: 28px;
  z-index: 1000;
  border: 1px solid #dfe3e8;
  border-radius: 8px;
  width: 230px;
`;

export const UserMenuOption = styled.span`
  height: 14px;
  color: #637381;
  font-size: 14px;
  font-family: graphik;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 14px;
  cursor: pointer;
  box-sizing: unset;
  padding: 12px 12px 14px 12px;
  &:hover {
    background-color: #f4f6f8;
  }
  &:focus {
    outline: none;
    border: 1px solid #008eff;
  }
`;
