import {
  SET_FROM_DATE,
  SET_TO_DATE,
  FETCH_CHART_DATA,
  NEW_USER_DATA_RECIEVED,
  FETCH_DRILLDOWN_DATA,
  EXISTING_USER_DATA_RECIEVED,
  INVOICE_STATUS_DATA_RECIEVED,
  INVOICE_COUNTRY_DATA_RECIEVED,
  INVOICE_REJECTION_DATA_RECIEVED,
  DOCUMENT_SUBMISSION_DATA_RECIEVED,
  SUPPLIER_COMPANY_DATA_RECIEVED,
  TICKET_COUNT_DATA_RECIEVED,
  DRILLDOWN_DATA_RECIEVED,
  SET_WIDGET_KEYS,
  FETCH_CHART_DATA_COMPLETE,
  FETCH_DRILLDOWN_DATA_COMPLETE,
  SET_CHART_DATA,
  CLEAR_ALL_CHARTS_DATA,
} from "./actionTypes";

export const updateFromDate = (value) => {
  return {
    type: SET_FROM_DATE,
    data: value,
  };
};

export const updateToDate = (value) => {
  return {
    type: SET_TO_DATE,
    data: value,
  };
};

export const fetchChartData = (fromDate, toDate, userId) => {
  return {
    type: FETCH_CHART_DATA,
    fromDate,
    toDate,
    userId,
  };
};

export const fetchChartDataComplete = () => {
  return {
    type: FETCH_CHART_DATA_COMPLETE,
  };
};

export const setChartData = (data) => {
  return {
    type: SET_CHART_DATA,
    data,
  };
};

export const setWidgetKeys = (data) => {
  return {
    type: SET_WIDGET_KEYS,
    data,
  };
};

export const getDrillDownChartData = (
  widgetKey,
  statusKey,
  fromDate,
  toDate,
  label,
  userId
) => {
  return {
    type: FETCH_DRILLDOWN_DATA,
    widgetKey,
    statusKey,
    fromDate,
    toDate,
    label,
    userId,
  };
};

export const drilldownDataRecieved = (data, key, label) => {
  return {
    type: DRILLDOWN_DATA_RECIEVED,
    data,
    key,
    label,
  };
};

export const fetchdrillDownDataComplete = () => {
  return {
    type: FETCH_DRILLDOWN_DATA_COMPLETE,
  };
};

export const newUserDataRecieved = (data) => {
  return {
    type: NEW_USER_DATA_RECIEVED,
    data,
  };
};

export const existingUserDataRecieved = (data) => {
  return {
    type: EXISTING_USER_DATA_RECIEVED,
    data,
  };
};

export const invoiceStatusDataRecieved = (data) => {
  return {
    type: INVOICE_STATUS_DATA_RECIEVED,
    data,
  };
};

export const invoiceCountryDataRecieved = (data) => {
  return {
    type: INVOICE_COUNTRY_DATA_RECIEVED,
    data,
  };
};

export const invoiceRejectionDataRecieved = (data) => {
  return {
    type: INVOICE_REJECTION_DATA_RECIEVED,
    data,
  };
};

export const documentSubmissionDataRecieved = (data) => {
  return {
    type: DOCUMENT_SUBMISSION_DATA_RECIEVED,
    data,
  };
};

export const supplierCompanyDataRecieved = (data) => {
  return {
    type: SUPPLIER_COMPANY_DATA_RECIEVED,
    data,
  };
};

export const ticketCountDataRecieved = (data) => {
  return {
    type: TICKET_COUNT_DATA_RECIEVED,
    data,
  };
};

export const clearAllChartsData = () => {
  return {
    type: CLEAR_ALL_CHARTS_DATA,
  };
};
