import moment from "moment";
import i18next from "i18next";
import { store } from "react-notifications-component";
import {
  authRoutes,
  unAuthRoutes,
  commonAuthRoutes,
  documentSubmissionClientConfigRoutes,
} from "../routes/routes";
import { momentDateFormat } from "../components/DateRangeFilter/config";
import { buildNotification } from "../config/notification";

export const exportData = (
  data,
  header,
  selector,
  fileName = "Export.csv",
  lineDelimiter = "\n"
) => {
  const dataArray = data.map((info) => {
    return Object.keys(info)
      .filter((info) => selector.indexOf(info) !== -1)
      .map((selector) => {
        return info[selector] && selector.toLowerCase().includes("date")
          ? new Date(info[selector]).toLocaleString()
          : info[selector];
      });
  });

  const csvArrayWithHeader = [header, ...dataArray];
  const csv =
    "data:text/csv;charset=utf-8," +
    csvArrayWithHeader.join(lineDelimiter).replace(/(^\[)|(\]$)/gm, "");

  const link = document.createElement("a");

  link.setAttribute("href", encodeURI(csv));
  link.setAttribute("download", fileName);
  link.click();
};

export const getRoutePathByKey = (key, params = null) => {
  const routes = [
    ...authRoutes,
    ...unAuthRoutes,
    ...commonAuthRoutes,
    ...documentSubmissionClientConfigRoutes,
  ];
  let foundRoute = routes.find((route) => route.key === key);
  if (params && foundRoute) {
    Object.keys(params).forEach((param) => {
      foundRoute.path = foundRoute.path.replace(
        `:${param}`,
        `${param}=${params[param]}`
      );
    });
  }

  return foundRoute ? foundRoute.path : "";
};

const base64ToUint8Array = (string) => {
  let raw = atob(string);
  let rawLength = raw.length;
  let array = new Uint8Array(new ArrayBuffer(rawLength));
  for (let i = 0; i < 4; i += 1) {
    array[i] = raw.charCodeAt(i);
  }
  return array;
};

const fileReader = (file) => {
  return new Promise((resolve, reject) => {
    const fr = new FileReader();
    fr.onload = () => {
      let arr = base64ToUint8Array(fr.result.split(",")[1]);
      let header = "";
      arr.forEach((item) => {
        header += item.toString(16);
      });
      if (header.includes("4d5a") || header.includes("5a4d")) {
        reject({
          message: `${i18next.t("common.invalidFile")} ${
            file.name
          }, ${i18next.t("common.exeFile")}`,
        });
      } else {
        resolve({
          filename: file.name,
          fileContent: fr.result.split(",")[1],
          filesize: file.size,
        });
      }
    };
    fr.readAsDataURL(file);
  });
};
export const getFileContent = async (file) => {
  try {
    return await fileReader(file);
  } catch (e) {
    throw e;
  }
};

export const handleNumberInput = (event, allowDecimal = true) => {
  if (event.key === "." && !allowDecimal) {
    event.preventDefault();
  }
  if (event.key === "." && event.target.value.split(".").length === 2) {
    event.preventDefault();
  }
  if (event.key === "e" || event.key === "+") {
    event.preventDefault();
  }
};

export const addDays = (days) => {
  const date = new Date();
  return date.setDate(date.getDate() + days);
};

export const truncate = (input, maxLength) =>
  input && input.length > maxLength
    ? `${input.substring(0, maxLength)}...`
    : input;

export const listSearch = (dataObj, lookFor, cols = []) => {
  let visibleCols = [];
  // if (process.env.NODE_ENV !== "development") {
  visibleCols = cols.filter((col) => !col.omit).map((col) => col.selector);
  // }
  const copyDataObj = visibleCols.length
    ? visibleCols.reduce((result, current) => {
        result = { ...result, [current]: dataObj[current] };
        return result;
      }, {})
    : { ...dataObj };

  const keys = Object.keys(copyDataObj);

  keys.forEach((key) => {
    if (isValidDate(copyDataObj[key]))
      copyDataObj[key] = moment(copyDataObj[key]).format(momentDateFormat);
  });

  return copyDataObj
    ? lookFor
      ? jsonStr(Object.values(copyDataObj))
          .toLowerCase()
          .includes(lookFor.toString().toLowerCase().trim())
      : true
    : false;
};

export const getNavigatorLanguage = () => {
  switch (navigator.language) {
    case "en-US":
      return "enGB";
    case "zh-cn":
      return "chCH";
    case "ar":
      return "arAR";
    case "cs":
      return "csCZ";
    case "de":
      return "deDE";
    case "es":
      return "esES";
    case "fr":
      return "frFR";
    case "hu":
      return "huHU";
    case "id":
      return "inIN";
    case "it":
      return "itIT";
    case "ja":
      return "jpJP";
    case "ko":
      return "krKR";
    case "nl":
      return "nlNL";
    case "pl":
      return "plPL";
    case "pt":
      return "ptPT";
    case "ro":
      return "roRO";
    case "ru":
      return "ruRU";
    case "sk":
      return "slSL";
    case "sv":
      return "svSE";
    case "th":
      return "thTH";
    case "tr":
      return "trTR";
    default:
      return "enGB";
  }
};

export const toHHMMSS = (timeInSec) => {
  const sec_num = timeInSec;
  let hours = Math.floor(sec_num / 3600);
  let minutes = Math.floor((sec_num - hours * 3600) / 60);
  let seconds = Math.floor(sec_num - hours * 3600 - minutes * 60);

  if (hours > 0 && hours < 10) {
    hours = "0" + hours + ":";
  } else {
    hours = "";
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return hours + minutes + ":" + seconds;
};

export const jsonStr = (json) => JSON.stringify(json);

export const jsonPrs = (jsonString) => JSON.parse(jsonString);

export const isValidDate = (value, format = process.env.REACT_DATE_FORMAT) => {
  return typeof value === "string" &&
    value.length > 9 &&
    (value.indexOf("/") > -1 || value.indexOf("-") > -1)
    ? moment(value, moment.ISO_8601, true).isValid()
    : false;
};

export const kickout = () => {
  const clientDetails =
    JSON.parse(sessionStorage.getItem("clientDetails")) || {};
  window.location.href = `/?${clientDetails.name || ""}`;
};

export const getLocale = (langKey) => {
  switch (langKey) {
    case "chCH":
      return "zh";
    case "csCZ":
      return "cs";
    case "deDE":
      return "de";
    case "enGB":
      return "en";
    case "esES":
      return "es";
    case "frFR":
      return "fr";
    case "huHU":
      return "hu";
    case "itIT":
      return "it";
    case "jpJP":
      return "ja";
    case "krKR":
      return "ko";
    case "nlNL":
      return "nl";
    case "plPL":
      return "pl";
    case "ptPT":
      return "pt";
    case "roRO":
      return "ro";
    case "ruRU":
      return "ru";
    case "slSL":
      return "sk";
    case "svSE":
      return "sv";
    case "thTH":
      return "th";
    case "trTR":
      return "tr";
    default:
      return "en";
  }
};

export const showNotPermittedNotification = (message = "common.actionNotPermittedSearch") => {
  const notification = buildNotification({
    message,
    type: "danger",
  });
  store.addNotification({
    ...notification,
  });
};
