import { fromJS } from "immutable";

import {

  INIT_FETCH_COMPLETE,

  FETCH_CASES_SUCCESS, FETCH_CASES_FAILURE,
  SUBMIT_REQUEST,
  SUBMIT_SUCCESS,
  SUBMIT_FAILURE,

  QUERY_TYPE_LIST, QUERY_FIELDS_LIST, DOWNLOADED_ATTACHMENT,
  FETCH_HELPDESK_TLOG_SUCCESS, FETCH_HELPDESK_TLOG_FAILURE,
  FETCHING_HELPDESK_DETAILS_BY_ID, FETCHING_HELPDESK_DETAILS_BY_ID_COMPLETED,
  RESET_FORM_SUBMISSION_STATUS,
  INIT_SAVE_COMMENT,
  COMMENT_SAVED,
  COMMENT_SAVE_FAILED,
  RESET_COMMENT_SAVE_STATUS,
  FETCH_COMMENT,
  FETCHING_QUERY_FIELDS, QUERY_FIELDS_FETCH_COMPLETED, INIT_ADD_ATTACHMENT, ATTACHMENT_ADDED,
  INIT_HELPDESK_OPEN_REQUEST_MARK_AS_COMPLETE, SAVED_HELPDESK_OPEN_REQUEST_MARK_AS_COMPLETE, FETCHING_GET_HELPDESK_QUERY_TYPE, FETCH_GET_HELPDESK_QUERY_TYPE, GET_HELPDESK_QUERY_TYPE_COMPLETED, UPDATE_HELPDESK_CASES, FETCHING_CASES, FETCHING_GET_SERVICE_QUERY_BY_ID, FETCH_GET_SERVICE_QUERY_BY_ID, GET_SERVICE_QUERY_BY_ID_COMPLETED, ATTACHMENT_ADDED_COMPLETED,
} from './constants'

export const initialState = fromJS({
  isPending: true,
  rowCount: [],
  initCases: [],
  openCases: [],
  errorMessage: '',
  closedCases: [],
  attachment: "",
  submissionStatus: false,
  queryTypeList: [],
  fetchingQueryFieldsList: false,
  queryFieldsList: [],
  TLogCase: [],
  additionalColumns: [],
  Cases: [],
  updatedCases: [],
  fetchingHelpdeskDetailsbyID: false,
  submisisonCommentSaving: false,
  submisisonCommentSaved: false,
  getCommentsData: [],
  attachmentSaved: false,
  isAttachmentSaving: false,
  isClientDetailsFetching: false,
  clientDetails: [],
  openRequestMarkAsCompleteStatus: false,
  isHelpdeskQueryFetching: false,
  helpdeskQuery: [],
  serviceNowQueryById:[],
  isServiceQueryByIDFething:false
});

const reducer = (state = initialState, action) => {
  // alert(action.type);
  switch (action.type) {


    case FETCHING_CASES:
      return state.set("isPending", true);

    case FETCH_CASES_SUCCESS:
      return state
        .set("Cases", action.data)
        .set("rowCount", action.rowCount)
    //.set("isPending", false);

    case UPDATE_HELPDESK_CASES:
      return state
        .set("updatedCases", action.data)
        .set("rowCount", action.rowCount)
    //.set("isPending", false);
    case INIT_FETCH_COMPLETE:
      return state
        .set("isPending", false);

    case FETCH_CASES_FAILURE:
      return state
        .set("isPending", false)
        .set("errorMessage", action.message);

    case DOWNLOADED_ATTACHMENT:
      return state
        .set("attachment", action.data);

    case SUBMIT_REQUEST:
      return state
        .set("submissionStatus", true);
    case SUBMIT_SUCCESS:
      return state
        .set("submissionStatus", false);

    case RESET_FORM_SUBMISSION_STATUS:
      return state
        .set("submissionStatus", false);

    case SUBMIT_FAILURE:
      return state
      .set("submissionStatus", false);


    case QUERY_TYPE_LIST:
      return state
        .set("queryTypeList", action.data);
    case FETCHING_QUERY_FIELDS:
      return state
        .set("fetchingQueryFieldsList", true);
    case QUERY_FIELDS_LIST:
      return state
        .set("queryFieldsList", action.data);
    case QUERY_FIELDS_FETCH_COMPLETED:
      return state
        .set("fetchingQueryFieldsList", false);

    case FETCHING_HELPDESK_DETAILS_BY_ID:
      return state
        .set("fetchingHelpdeskDetailsbyID", true);
    case FETCH_HELPDESK_TLOG_SUCCESS:
      return state
        .set("TLogCase", action.data);

    case FETCH_HELPDESK_TLOG_FAILURE:
      return state
        .set("errorMessage", action.message);
    case FETCHING_HELPDESK_DETAILS_BY_ID_COMPLETED:
      return state
        .set("fetchingHelpdeskDetailsbyID", false);
    case INIT_SAVE_COMMENT:
      return state.set("submisisonCommentSaving", true);
    case COMMENT_SAVED: {
      return state
        .set("submisisonCommentSaved", true)
        .set("submisisonCommentSaving", false);
    }
    case COMMENT_SAVE_FAILED: {
      return state
        .set("submisisonCommentSaving", false)
    }
    case RESET_COMMENT_SAVE_STATUS:
      return state.set("submisisonCommentSaved", false);
    case FETCH_COMMENT:
      return state
        .set("getCommentsData", action.data);
    case INIT_ADD_ATTACHMENT:
      return state.set("isAttachmentSaving", true);
    case ATTACHMENT_ADDED: {
      return state
        .set("attachmentSaved", true)
        .set("isAttachmentSaving", false);
    }
    case ATTACHMENT_ADDED_COMPLETED: {
      return state
        .set("attachmentSaved", false)
        .set("isAttachmentSaving", false);
    }

    case INIT_HELPDESK_OPEN_REQUEST_MARK_AS_COMPLETE:
      return state
        .set("openRequestMarkAsCompleteStatus", true);
    case SAVED_HELPDESK_OPEN_REQUEST_MARK_AS_COMPLETE:
      return state
        .set("openRequestMarkAsCompleteStatus", false);

    case FETCHING_GET_HELPDESK_QUERY_TYPE:
      return state
        .set("isHelpdeskQueryFetching", true);

    case FETCH_GET_HELPDESK_QUERY_TYPE:
      return state
        .set("helpdeskQuery", action.data);
    case GET_HELPDESK_QUERY_TYPE_COMPLETED:
      return state
        .set("isHelpdeskQueryFetching", false);

        case FETCHING_GET_SERVICE_QUERY_BY_ID:
      return state
        .set("isServiceQueryByIDFething", true);

    case FETCH_GET_SERVICE_QUERY_BY_ID:
      return state
        .set("serviceNowQueryById", action.data);
    case GET_SERVICE_QUERY_BY_ID_COMPLETED:
      return state
        .set("isServiceQueryByIDFething", false);
    default:
      return state;
  }
};
export default reducer;
