import {
  GET_FIELD_DEFINITION_ACTION,
  GET_INVOICE_COLUMNS_ACTION,
  UPDATE_INVOICE_FIELDS_ACTION,
  INVOICE_DETAILS_REQUEST,
  INVOICE_DETAILS_SUCCESS,
  REMITTANCE_DETAILS_REQUEST,
  REMITTANCE_DETAILS_SUCCESS,
  UPDATE_REMITTANCE_COLUMNS_ACTION,
  UPDATE_INVOICE_DATA_ACTION,
  UPDATE_SHOW_FILTER_ACTION,
  GET_CURRENCY_LIST_ACTION,
  UPDATE_CURRENCY_LIST,
  INIT_FETCH_INVOICE_DETAILS,
  FETHCING_INVOICE_DETAILS,
  FETCH_INVOICE_DETAILS,
  INVOICE_DETAILS_FETCH_COMPLETE,
  INIT_SUPPLIER_LIST,
  FETCH_SUPPLIER_LIST,
  FETCH_SUPPLIER_LIST_COMPLETED,
  FETCHING_SUPPLIER_LIST,
  INIT_DOWNLOAD_INVOICE_DFM,
  SET_INVOICE_DFM,
  INIT_GET_SELECTED_SUPPLIER_LIST,
} from "./actionTypes";

export function getFieldDefinitionAction(invoiceParam, callBackFunction) {
  return {
    type: GET_FIELD_DEFINITION_ACTION,
    invoiceParam,
    callBackFunction,
  };
}

export function getInvoiceColumnsAction(columnsList) {
  return {
    type: GET_INVOICE_COLUMNS_ACTION,
    columnsList,
  };
}

export function updateInvoiceFieldsAction(invoiceFields) {
  return {
    type: UPDATE_INVOICE_FIELDS_ACTION,
    invoiceFields,
  };
}

export function getInvoiceDetailsAction(param, callBackFunction) {
  return {
    type: INVOICE_DETAILS_REQUEST,
    isInvoiceLoading: true,
    body: param,
    callBackFunction,
  };
}

export function updateInvoiceDetailsAction(invoiceAllDetails, rowCount) {
  return {
    type: INVOICE_DETAILS_SUCCESS,
    invoiceAllDetails,
    rowCount,
  };
}

export function getRemittanceDetailsAction(param) {
  return {
    type: REMITTANCE_DETAILS_REQUEST,
    isRemittanceLoading: true,
    param,
  };
}

export function updateRemittanceDetailsAction(remittanceList) {
  return {
    type: REMITTANCE_DETAILS_SUCCESS,
    remittanceList,
    isRemittanceLoading: false,
  };
}

export function updateRemittanceColumnsAction(columnsList) {
  return {
    type: UPDATE_REMITTANCE_COLUMNS_ACTION,
    columnsList,
  };
}

export function updateInvoiceDataAction(invoiceData, rowCount) {
  return {
    type: UPDATE_INVOICE_DATA_ACTION,
    invoiceData,
    rowCount,
  };
}

export function updateShowFilterAction(showFilter) {
  return {
    type: UPDATE_SHOW_FILTER_ACTION,
    showFilter,
  };
}

export function getCurrencyList() {
  return {
    type: GET_CURRENCY_LIST_ACTION,
  };
}

export function updateCurrencyListAction(invoiceCurrency) {
  return {
    type: UPDATE_CURRENCY_LIST,
    invoiceCurrency,
  };
}

export const initGetInvoiceDetails = (payload, globalSearch, callback) => {
  return {
    type: INIT_FETCH_INVOICE_DETAILS,
    payload,
    globalSearch,
    callback,
  };
};

export const fetchingInvoiceDetails = () => {
  return {
    type: FETHCING_INVOICE_DETAILS,
  };
};

export const fetchInvoiceDetails = (data) => {
  return {
    type: FETCH_INVOICE_DETAILS,
    data,
  };
};

export const invoiceDetailsFetchComplete = () => {
  return {
    type: INVOICE_DETAILS_FETCH_COMPLETE,
  };
};

export const initSupplierList = (data, callback) => {
  return {
    type: INIT_SUPPLIER_LIST,
    data,
    callback,
  };
};

export const fetchSupplierList = (data, totalSupplierCount) => {
  return {
    type: FETCH_SUPPLIER_LIST,
    data,
    totalSupplierCount,
  };
};

export const fetchSupplierListComplete = () => {
  return {
    type: FETCH_SUPPLIER_LIST_COMPLETED,
  };
};

export const fetchingSupplierList = () => {
  return {
    type: FETCHING_SUPPLIER_LIST,
  };
};

export const initDownloadInvoiceDFM = (urn, clientName) => {
  return {
    type: INIT_DOWNLOAD_INVOICE_DFM,
    urn,
    clientName,
  };
};

export const setDownloadInvoice = (data) => {
  return {
    type: SET_INVOICE_DFM,
    data,
  };
};

export const initGetSelectdSupplierList = (payload, callback) => {
  return {
    type: INIT_GET_SELECTED_SUPPLIER_LIST,
    payload,
    callback,
  };
};
