import styled from "styled-components";

export const FilePreviewWrapper = styled.div`
  height: 50px;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(220, 226, 231);
  border-radius: 4px;
  padding: 8px;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  margin: 0 8px 0 0;
`;
