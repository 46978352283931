import {
  FETCH_DOCUMENT_SUBMISSION_HISTORY,
  FETCHING_DOC_LIST,
  DOC_FETCH_COMPLETE,
  INIT_DOCUMENT_HISTORY_FETCH,
  INIT_COUNTRY_LIST_FETCH,
  FETCHING_COUNTRY_LIST,
  FETCH_COUNTRY_LIST,
  COUNTRY_LIST_FETCH_COMPLETE,
  INIT_PROFILE_LIST_FETCH,
  FETCHING_PROFILE_LIST,
  FETCH_PROFILE_LIST,
  PROFILE_LIST_FETCH_COMPLETE,
  INIT_SUPPLIER_LIST_FETCH,
  FETCHING_SUPPLIER_LIST,
  FETCH_SUPPLIER_LIST,
  SUPPLIER_LIST_FETCH_COMPLETE,
  INIT_PO_NUMBER_FETCH,
  FETCHING_PO_NUMBER,
  FETCH_PO_NUMBER,
  PO_NUMBER_FETCH_COMPLETE,
  INIT_FETCH_ADDITIONAL_FIELDS,
  FETCH_ADDITIONAL_FIELDS,
  CLEAR_ADDITIONAL_FIELDS,
  FETCHING_DOCUMENT_SUBMISSION_EXISTING_DATA,
  FETCH_DOCUMENT_SUBMISSION_EXISTING_DATA,
  FETCH_DOCUMENT_SUBMISSION_EXISTING_DATA_COMPLETE,
  SET_DOCUMENT_SUBMISSION_FOR_EDIT_COMPLETED,
  FECTHING_ADDITIONAL_FIELDS,
  ADDITIONAL_FIELDS_FETCH_COMPLETE,
  INIT_GET_DOCUMENT_SUBMISSION,
  INIT_DOCUMENT_SUBMISSION_SAVE,
  INIT_PO_DETAILS_FETCH,
  PO_DETAILS_FETCH_COMPLETE,
  INIT_OCR_DETAILS_FETCH,
  OCR_DETAILS_FETCH_FAILED,
  FETCHING_OCR_DETAILS,
  FETCH_OCR_DETAILS,
  OCR_DETAILS_FETCH_COMPLETE,
  FETCH_DOC_EXTRACTED_DATA,
  INIT_SAVE_EINVOICE_WITH_DETAILS,
  INIT_UPLOAD_SUPPORTING_ATTACHMENTS,
  SET_UPLOAD_PROGRESS,
  SUPPORTING_ATTCAHMENTS_UPLOADING,
  SUPPORTING_ATTCAHMENTS_UPLOADED,
  SUPPORTING_ATTCAHMENTS_UPLOAD_FAIL,
  SUPPORTING_ATTCAHMENTS_UPLOAD_RESET,
  INIT_PDF_GENERATION,
  INIT_SAVE_MAPPING,
  PDF_GENERATING,
  PDF_GENERATED,
  PDF_GENERATE_FAILED,
  INIT_DOWNLOAD_SAMPLE_DOC,
} from "./actionTypes";

export const initDocSubmissionHistoryList = (payload) => {
  return {
    type: INIT_DOCUMENT_HISTORY_FETCH,
    payload,
  };
};

export const docFetching = () => {
  return {
    type: FETCHING_DOC_LIST,
  };
};
export const fetchDocumentSubmissionHistory = (data) => {
  return {
    type: FETCH_DOCUMENT_SUBMISSION_HISTORY,
    data: data,
  };
};
export const docFetchComplete = () => {
  return {
    type: DOC_FETCH_COMPLETE,
  };
};

// Document submit actions
export const initCountryDropdownList = () => {
  return {
    type: INIT_COUNTRY_LIST_FETCH,
  };
};
export const fetchingCountry = () => {
  return {
    type: FETCHING_COUNTRY_LIST,
  };
};

export const fetchCountryDropdownList = (data) => {
  return {
    type: FETCH_COUNTRY_LIST,
    data: data,
  };
};

export const countryFetchingComplete = () => {
  return {
    type: COUNTRY_LIST_FETCH_COMPLETE,
  };
};

export const initProfileDropdownList = (countryID) => {
  return {
    type: INIT_PROFILE_LIST_FETCH,
    payload: { countryID },
  };
};
export const fetchingProfiles = () => {
  return {
    type: FETCHING_PROFILE_LIST,
  };
};
export const fetchProfileDropdownList = (data) => {
  return {
    type: FETCH_PROFILE_LIST,
    data: data,
  };
};
export const profileFetchingComplete = () => {
  return {
    type: PROFILE_LIST_FETCH_COMPLETE,
  };
};

export const initSupplierDropdownList = (payload) => {
  return {
    type: INIT_SUPPLIER_LIST_FETCH,
    payload,
  };
};

export const fetchingSuppliers = () => {
  return {
    type: FETCHING_SUPPLIER_LIST,
  };
};
export const fetchSupplierDropdownList = (data) => {
  return {
    type: FETCH_SUPPLIER_LIST,
    data: data,
  };
};
export const supplierFetchingComplete = () => {
  return {
    type: SUPPLIER_LIST_FETCH_COMPLETE,
  };
};

export const initFetchPONumberList = (payload) => {
  return {
    type: INIT_PO_NUMBER_FETCH,
    payload,
  };
};

export const fetchingPONumbers = () => {
  return {
    type: FETCHING_PO_NUMBER,
  };
};
export const fetchPONumbers = (data) => {
  return {
    type: FETCH_PO_NUMBER,
    data: data,
  };
};
export const poNumbersFetchComplete = () => {
  return {
    type: PO_NUMBER_FETCH_COMPLETE,
  };
};

export const initFetchAdditionalFields = (param) => {
  return {
    type: INIT_FETCH_ADDITIONAL_FIELDS,
    param,
  };
};

export const fetchAdditionalingFields = () => {
  return {
    type: FECTHING_ADDITIONAL_FIELDS,
  };
};

export const fetchAdditionalFields = (data) => {
  return {
    type: FETCH_ADDITIONAL_FIELDS,
    data: data,
  };
};
export const additionalingFieldsFetchComplete = () => {
  return {
    type: ADDITIONAL_FIELDS_FETCH_COMPLETE,
  };
};

export const initDocumentSubmissionSave = (payload, callback) => {
  return {
    type: INIT_DOCUMENT_SUBMISSION_SAVE,
    payload,
    callback,
  };
};

export const clearAdditionalFields = () => {
  return {
    type: CLEAR_ADDITIONAL_FIELDS,
  };
};

export const initDocumentSubmissionData = ({ id, refNum }, callback) => {
  return {
    type: INIT_GET_DOCUMENT_SUBMISSION,
    payload: { id, refNum },
    callback,
  };
};
export const fetchingDocSubmissionExistingData = () => {
  return {
    type: FETCHING_DOCUMENT_SUBMISSION_EXISTING_DATA,
  };
};
export const fetchDocumentSubmissionExistingData = (data) => {
  return {
    type: FETCH_DOCUMENT_SUBMISSION_EXISTING_DATA,
    data,
  };
};
export const fetchDocumentSubmissionExistingDataComplete = () => {
  return {
    type: FETCH_DOCUMENT_SUBMISSION_EXISTING_DATA_COMPLETE,
  };
};

export const setDocumentSubmissionDataForEditComplete = () => {
  return {
    type: SET_DOCUMENT_SUBMISSION_FOR_EDIT_COMPLETED,
  };
};

export const initGetPODetails = (poNumber) => {
  return {
    type: INIT_PO_DETAILS_FETCH,
    poNumber,
  };
};

export const poDetailsFetchComplete = (poDetails) => {
  return {
    type: PO_DETAILS_FETCH_COMPLETE,
    poDetails,
  };
};

export const initGetOCRDetails = (invoiceID) => {
  return {
    type: INIT_OCR_DETAILS_FETCH,
    invoiceID,
  };
};

export const ocrDetailsFetching = () => {
  return {
    type: FETCHING_OCR_DETAILS,
  };
};

export const fetchOcrDetails = (data) => {
  return {
    type: FETCH_OCR_DETAILS,
    data,
  };
};

export const ocrDetailsFetchComplete = () => {
  return {
    type: OCR_DETAILS_FETCH_COMPLETE,
  };
};

export const ocrDetailsFetchFailed = () => {
  return {
    type: OCR_DETAILS_FETCH_FAILED,
  };
};

export const fetchDocExtractedData = (data) => {
  return {
    type: FETCH_DOC_EXTRACTED_DATA,
    data,
  };
};

export const initSaveEInvoiceWithDetails = (payload, invoiceType, callback) => {
  return {
    type: INIT_SAVE_EINVOICE_WITH_DETAILS,
    invoiceType,
    payload,
    callback,
  };
};

export const initUploadSupportingAttachments = (payload, callback) => {
  return {
    type: INIT_UPLOAD_SUPPORTING_ATTACHMENTS,
    payload,
    callback,
  };
};

export const uploading = () => {
  return {
    type: SUPPORTING_ATTCAHMENTS_UPLOADING,
  };
};

export const setUploadProgress = (data) => {
  return {
    type: SET_UPLOAD_PROGRESS,
    data,
  };
};

export const uploaded = () => {
  return {
    type: SUPPORTING_ATTCAHMENTS_UPLOADED,
  };
};

export const uploadFailed = () => {
  return {
    type: SUPPORTING_ATTCAHMENTS_UPLOAD_FAIL,
  };
};
export const uploadReset = () => {
  return {
    type: SUPPORTING_ATTCAHMENTS_UPLOAD_RESET,
  };
};

export const initPDFGeneration = (payload, callback) => {
  return {
    type: INIT_PDF_GENERATION,
    payload,
    callback,
  };
};

export const pdfGenerating = () => {
  return {
    type: PDF_GENERATING,
  };
};
export const pdfGenerated = (data) => {
  return {
    type: PDF_GENERATED,
    data,
  };
};
export const pdfGeneratFailed = () => {
  return {
    type: PDF_GENERATE_FAILED,
  };
};

export const initSaveMapping = (payload, callback) => {
  return {
    type: INIT_SAVE_MAPPING,
    payload,
    callback,
  };
};

export const initDownloadSampleDoc = (payload) => {
  return {
    type: INIT_DOWNLOAD_SAMPLE_DOC,
    payload,
  };
};
