import { fromJS } from "immutable";

import {
  USER_DETAILS_LIST,
  USER_DETAILS_SUCCESS,
  FETCH_USER_ROLE_LIST,
  USER_DETAILS_UPDATE_DATA,
  UPDATE_SHOW_USER_FILTER_ACTION,
  UPDATE_SINGLE_USER_DATA_ACTION,
  RESET_FORM_SUBMISSION_STATUS,
  FORM_SUBMISSION_STATUS,
  GET_SINGLE_USER_DATA_ACTION,
  INIT_USER_ROLE_LIST,
  INIT_REGIONAL_SETTINGS,
  FETCH_REGIONAL_SETTINGS,
  USER_EXIST,
  SUBMIT_USER_DATA_STATUS,
} from "./actionTypes";

export const initialState = fromJS({
  userList: [],
  userData: [],
  userColumns: [],
  userRoleList: [],
  showFilter: false,
  isUserLoading: false,
  userStatus: [],
  singleUserData: [],
  formSubmissionStatus: "",
  baseRoleList: [],
  editing: false,
  editData: null,
  fetchForEditComplete: false,
  fetchingUserRoleList: true,
  fetchingBaseRoleList: true,
  regionalSettings: [],
  fetchRegionalSettings: true,
  supplierList: [],
  fetchSupplierList: true,
  isExistingUser: false,
  saveUserDataStatus: false,
});

function userManagementReducer(state = initialState, action) {
  switch (action.type) {
    case USER_DETAILS_LIST:
      return state.set("isUserLoading", true);

    case USER_DETAILS_SUCCESS: {
      return state
        .set("userList", action.userList)
        .set("userData", action.userList)
        .set("isUserLoading", false);
    }
    case USER_DETAILS_UPDATE_DATA: {
      return state.set("userData", action.userData);
    }

    case INIT_USER_ROLE_LIST: {
      return state.set("fetchingUserRoleList", true);
    }
    case FETCH_USER_ROLE_LIST: {
      return state
        .set("userRoleList", action.userRoleList)
        .set("fetchingUserRoleList", false);
    }

    case UPDATE_SHOW_USER_FILTER_ACTION: {
      return state.set("showFilter", action.showFilter);
    }

    case FORM_SUBMISSION_STATUS: {
      return state.set("formSubmissionStatus", action.data);
    }
    case RESET_FORM_SUBMISSION_STATUS: {
      return state.set("formSubmissionStatus", false);
    }

    case GET_SINGLE_USER_DATA_ACTION: {
      return state.set("editing", true).set("fetchForEditComplete", true);
    }
    case UPDATE_SINGLE_USER_DATA_ACTION: {
      return state
        .set("editData", action.singleUserData)
        .set("fetchForEditComplete", false);
    }

    case INIT_REGIONAL_SETTINGS: {
      return state.set("fetchingRegionalSettings", true);
    }
    case FETCH_REGIONAL_SETTINGS: {
      return state
        .set("regionalSettings", action.data)
        .set("fetchingRegionalSettings", false);
    }

    case USER_EXIST: {
      return state.set("isExistingUser", action.data);
    }
    case SUBMIT_USER_DATA_STATUS: return state.set("saveUserDataStatus", action.boolean);
    default:
      return state;
  }
}

export default userManagementReducer;
