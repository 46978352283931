import React, { useState } from "react";
import Div from "../Div";

import Modal from "../Modal";

import { flexCol, flexGrow, flexRow, flexShrink } from "../Layout/style";
import Button from "../Button/Button";

import Datepicker from "./Datepicker";
import moment from "moment";
import { Tooltip } from "antd";

import { useTranslation } from "react-i18next";

const DateRange = (props) => {
  const { t } = useTranslation();
  const defaultStartDate = moment().subtract(30, "days");
  const defaultEndDate = moment();

  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);

  const minDate = moment().subtract(props.yearRange, "years");
  const maxDate = moment();

  const resetDates = () => {
    setStartDate(defaultStartDate);
    setEndDate(defaultEndDate);
  };
  const isValidDate = () => {
    if (startDate && endDate) {
      return (
        moment(startDate).isSameOrBefore(endDate, "day") &&
        moment(startDate).isSameOrAfter(minDate, "day") &&
        moment(endDate).isSameOrBefore(maxDate, "day")
      );
    }
  };

  const isValid = isValidDate();

  return (
    <>
      <Modal
        title={t("common.dateRangeSelection")}
        show={props.show}
        onClose={props.close}
        overflow="visible"
      >
        <Tooltip
          title={"Invalid Date Range"}
          visible={!isValid}
          placement="top"
        >
          <Div {...flexCol} {...flexGrow} padding="8px" minWidth="400px">
            <Datepicker
              id="startDate"
              startDate={startDate}
              selected={startDate}
              placeholder="Select Start Date"
              focus
              selectsStart
              setDate={(date) => setStartDate(date)}
              maxDate={endDate.toDate()}
              minDate={minDate.toDate()}
            />
            <Datepicker
              id="endDate"
              endDate={endDate}
              selected={endDate}
              placeholder="Select End Date"
              selectsEnd
              setDate={(date) => setEndDate(date)}
              minDate={startDate.toDate()}
              maxDate={maxDate.toDate()}
            />

            <Div {...flexRow} {...flexGrow}>
              <Div {...flexRow} {...flexGrow} margin="8px">
                <Div {...flexRow} {...flexGrow}>
                  <Button
                    disabled={!isValid}
                    onClick={() => {
                      props.close();

                      props.applyDateRangeFilter &&
                        props.applyDateRangeFilter({ startDate, endDate });
                    }}
                    bgColor="#018eff"
                    textColor="#FFF"
                    margin="2px"
                  >
                    {t("common.apply")}
                  </Button>
                </Div>
                <Div {...flexRow} {...flexShrink}>
                  <Button float="right" onClick={resetDates} margin="2px">
                    {t("common.reset")}
                  </Button>
                </Div>
              </Div>
            </Div>
          </Div>
        </Tooltip>
      </Modal>
    </>
  );
};

export default DateRange;
