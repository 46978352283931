import {
  INIT_WORKFLOW_FETCH,
  FETCHING_WORKFLOWS,
  FETCH_WORKFLOWS,
  WORKFLOWS_COMPLETE,
  INIT_WORKFLOW_SAVE,
  WORKFLOW_SAVED,
  WORKFLOW_SAVE_FAILED,
  RESET_SAVE_STATUS,
  INIT_WORKFLOWS_DELETE,
  DELETE_WORKFLOW_SUCCESS,
  DELETE_WORKFLOW_FAILED,
  INIT_WORKFLOW_EDIT,
  SET_WORKFLOW_EDIT_DATA,
  EDIT_WORKFLOW_DATA_FETCHED,
  INIT_CONDITION_LIST_FETCH,
  FETCHING_CONDITIONS,
  FETCH_CONDITIONS,
  CONDITIONS_FETCH_COMPLETE,
} from "./actionTypes";

export const initWorkflowFetch = (payload) => {
  return {
    type: INIT_WORKFLOW_FETCH,
    payload,
  };
};

export const workflowFetching = () => {
  return {
    type: FETCHING_WORKFLOWS,
  };
};
export const fetchWorkflow = (data) => {
  return {
    type: FETCH_WORKFLOWS,
    data: data,
  };
};
export const workflowFetchComplete = () => {
  return {
    type: WORKFLOWS_COMPLETE,
  };
};

export const initWorkflowSave = (payload) => {
  return {
    type: INIT_WORKFLOW_SAVE,
    payload,
  };
};

export const workflowSaved = () => {
  return {
    type: WORKFLOW_SAVED,
  };
};

export const workflowSaveFailed = () => {
  return {
    type: WORKFLOW_SAVE_FAILED,
  };
};

export const resetFormSave = () => {
  return {
    type: RESET_SAVE_STATUS,
  };
};

export const initSubmissionProfilesDelete = (id) => {
  return {
    type: INIT_WORKFLOWS_DELETE,
    id,
  };
};
export const deleteSuccess = (id) => {
  return {
    type: DELETE_WORKFLOW_SUCCESS,
    id,
  };
};
export const deletFailed = () => {
  return {
    type: DELETE_WORKFLOW_FAILED,
  };
};
export const getDataForEdit = (id, callback) => {
  return {
    type: INIT_WORKFLOW_EDIT,
    id,
    callback,
  };
};
export const setDataForEdit = (data) => {
  return {
    type: SET_WORKFLOW_EDIT_DATA,
    data,
  };
};
export const editDataFetched = () => {
  return {
    type: EDIT_WORKFLOW_DATA_FETCHED,
  };
};

export const initConditionListFetch = (payload) => {
  return {
    type: INIT_CONDITION_LIST_FETCH,
    payload,
  };
};

export const conditionListFetching = () => {
  return {
    type: FETCHING_CONDITIONS,
  };
};
export const fetchConditionList = (data) => {
  return {
    type: FETCH_CONDITIONS,
    data: data,
  };
};
export const conditionListFetchComplete = () => {
  return {
    type: CONDITIONS_FETCH_COMPLETE,
  };
};
