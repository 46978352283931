import styled from "styled-components";

export const ModalDiv = styled.div`
  position: fixed;
  z-index: ${(props) => (props.modalZIndex ? props.modalZIndex : "10000")};
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  font-family: "graphik-medium";
  transition: opacity 0.15s linear;
  padding: 5px;
`;

export const ModalContent = styled.div`
  position: relative;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  outline: 0;
  margin: ${(props) =>
    props.ModalContentMargin ? props.ModalContentMargin : "4% auto"};
  border: 1px solid #888;
  width: ${(props) => (props.width ? props.width : "50%")};
  height: ${(props) => (props.height ? props.height : "")};
  overflow: ${(props) => (props.overflow ? props.overflow : "")};

  box-sizing: border-box;
`;

export const ModalHeader = styled.div`
  padding: ${(props) => (props.padding ? props.padding : "15px")};
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "#008eff"};
  color: ${(props) => (props.headerColor ? props.headerColor : "#ffffff")};
  font-size: ${(props) => (props.headerFontSize ? props.headerFontSize : "")};
  font-weight: ${(props) =>
    props.headerFontWeight ? props.headerFontWeight : ""};
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : "")};
  height: ${(props) => (props.headerHeight ? props.headerHeight : "auto")};
  position: ${(props) => (props.headerPosition ? props.headerPosition : "")};
`;
export const ModalError = styled.div`
  display: ${(props) => (props.errors ? "block" : "none")};
`;

export const ModalTitle = styled.h5`
  margin: 0;
  line-height: ${(props) =>
    props.titleLineHeight ? props.titleLineHeight : "1.42857143"};
  font-size: ${(props) => (props.titleSize ? props.titleSize : "14px")};
  font-weight: inherit;
  color: ${(props) => (props.titleColor ? props.titleColor : "")};
  height: ${(props) => (props.titleHeight ? props.titleHeight : "")};
  padding-top: ${(props) => (props.titlePT ? props.titlePT : "")};
  padding-left: ${(props) => (props.titlePL ? props.titlePL : "")};
  font-family: ${(props) =>
    props.titleFontFamily ? props.titleFontFamily : "graphik-medium"};
`;

export const ModalBody = styled.div`
  padding: ${(props) =>
    props.bodyPadding ? props.bodyPadding : "20px 20px 0px 20px"};
  height: ${(props) => (props.bodyHeight ? props.bodyHeight : "")};
  width: ${(props) => (props.bodyWidth ? props.bodyWidth : "")};
  overflow-y: ${(props) =>
    props.modalBodyOverflowY ? props.modalBodyOverflowY : ""};
`;

export const ModalFooter = styled.div`
  padding: ${(props) => (props.footerPadding ? props.footerPadding : "15px")};
  text-align: ${(props) => (props.footerAlign ? props.footerAlign : "right")};
  border: 0px;
`;

export const ModalClose = styled.button`
  float: ${(props) =>
    props.closeButtonFloat ? props.closeButtonFloat : "right"};
  font-size: ${(props) =>
    props.closeButtonSize ? props.closeButtonSize : "21px"};
  font-weight: ${(props) =>
    props.closeButtonWeight ? props.closeButtonWeight : "700"};
  line-height: 1;
  color: ${(props) =>
    props.closeButtonColor ? props.closeButtonColor : "#ffffff"};
  text-shadow: 0 1px 0 #fff;
  opacity: 0.9;
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;
  margin-top: ${(props) => (props.closeButtonMT ? props.closeButtonMT : "")};
  position: ${(props) =>
    props.closeButtonPosition ? props.closeButtonPosition : ""};
  top: ${(props) => (props.closeButtonTop ? props.closeButtonTop : "")};
  right: ${(props) => (props.closeButtonRight ? props.closeButtonRight : "")};
  &:focus {
    outline: 0;
  }
`;

export const ModalSubmit = styled.button.attrs((props) => ({
  disabled: props.disabled ? props.disabled : "",
}))`
  //background-image: ${(props) =>
    props.submitBGImg
      ? props.submitBGImg
      : "-webkit-linear-gradient(top, #008fff, #008fff)"};
  display: inline-block;
  padding: ${(props) =>
    props.submitPadding ? props.submitPadding : "6px 12px"};
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  opacity: ${(props) => (props.disabled ? "0.65" : "1")};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  user-select: none;
  border: 1px solid transparent;
  border-radius: ${(props) => (props.submitBR ? props.submitBR : "20px")};
  background-color: ${(props) =>
    props.submitButtonColor ? props.submitButtonColor : "#008eff"};
  border-color: ${(props) =>
    props.submitButtonBorderColor
      ? props.submitButtonBorderColor
      : "#008eff"};
  color: ${(props) => (props.submitTextColor ? props.submitTextColor : "#fff")};
  width: ${(props) => (props.buttonWidth ? props.buttonWidth : "")};
  float: ${(props) => (props.float ? props.float : "")}; ;
`;

export const ModalCancel = styled.button`
  background-color: ${(props) =>
    props.cancelButtonColor ? props.cancelButtonColor : "#008eff"};
  display: inline-block;
  border-radius: ${(props) =>
    props.cancelButtonBorderRadius ? props.cancelButtonBorderRadius : "20px"};
  border: ${(props) =>
    props.cancelButtonBorder ? props.cancelButtonBorder : "1px solid"};
  border-color: ${(props) =>
    props.cancelButtonBorderColor
      ? props.cancelButtonBorderColor
      : "#008eff"};
  height: 34px;
  width: ${(props) => (props.cancelButtonWidth ? props.cancelButtonWidth : "")};
  color: ${(props) =>
    props.cancelButtonTextColor ? props.cancelButtonTextColor : "#fff"};
  font-family: "graphik-medium";
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.53px;
  line-height: 20px;
  margin: 3px;
  margin-right: 15px;
  cursor: pointer;
  padding: ${(props) =>
    props.cancelButtonPadding ? props.cancelButtonPadding : "6px 15px"};
`;
