import { fromJS } from "immutable";

import {
  INIT_EMAIL_INVITE,
  EMAIL_INVITE_SENT,
  FETCHING_LIST,
  FETCH_LIST,
  FETCHING_EDIT_LIST,
  FETCH_EDIT_LIST,
  INIT_TAKE_OWNERSHIP,
  SET_OWNERSHIP_DATA,
  FETCH_OWNERSHIP_STATUS,
  FETCHING_CONTACT_DETAILS,
  FETCH_CONTACT_DETAILS,
  FETCHING_ATTRIBUTES_DETAILS,
  FETCH_ATTRIBUTES_DETAILS,
  FETCHING_LOCATION_DETAILS,
  FETCH_LOCATION_DETAILS,
  FETCHING_ATTACHMENT_DETAILS,
  FETCH_ATTACHMENT_DETAILS,
  FETCHING_ATTRIBUTES_DETAILS_SUCCESS,
  FETCHING_EDIT_LIST_SUCCESS,
  SET_WORKFLOW_STEP_DATA,
  GET_WORKFLOW_STEP_DATA,
  SET_DIFF_DATA,
  GET_DIFF_DATA,
  INIT_NEW_COMPANY_CREATION,
  FETCH_NEW_COMPANY_CREATION,
  INIT_TAKE_PROXY,
  TAKE_PROXY_STATUS,
  INIT_WOKFLOW_STATE,
  SET_WORKFLOW_STATE,
  GET_CONTACT_DIFF_DATA,
  SET_CONTACT_DIFF_DATA,
  GET_LOCATION_DIFF_DATA,
  SET_LOCATION_DIFF_DATA,
  INIT_INVITE_REFRESH,
  INVITE_REFRESH_COMPLETE,
  SAVE_LOGO_COMPLETE,
  INIT_SAVE_LOGO,
  INIT_AUDIT_TRAIL_LIST,
  SET_AUDIT_TRAIL_LIST,
  INIT_AUDIT_TRAIL_DATA,
  SET_AUDIT_TRAIL_DATA,
  INIT_GET_LOGO,
  SET_LOGO,
  INIT_CLEAR_AUDIT_TRAIL,
  CLEAR_AUDIT_TRAIL_SUCCESS,
  UPDATE_LIST_DATA,
  UPDATE_AUDIT_TRAIL_LIST,
  INIT_FETCH_FLEX_FIELDS,
  SET_FLEX_FIELDS,
  INIT_CLEAR_STORE,
  INIT_REJECT,
  INIT_REJECT_REMOVE,
  REJECT_REMOVE_SUCCESS,
  REJECT_SUCCESS,
} from "./actionTypes";

export const initialState = fromJS({
  emailInviteSent: false,
  fetchingList: true,
  listData: [],
  statusCount: [],
  totalCount: 0,
  fetchingEditList: true,
  editListData: [],
  isOwnership: false,
  ownedCompanies: [],
  ownershipData: [],
  fetchOwnership: true,
  fetchingContactDetails: true,
  contactData: [],
  fetchingAttributesDetails: true,
  attributesData: [],
  fetchingLocationDetails: true,
  locationData: [],
  fetchingAttachmentDetails: true,
  attachmentData: [],
  fetchingWorkflowStepData: true,
  workflowStepData: {},
  fetchingDiffData: true,
  diffData: [],
  fetchingNewCmpnyStatus: true,
  fetchingTakeProxyStatus: true,
  fetchingWorkflowState: true,
  workflowState: [],
  fetchingContactDiffData: true,
  contactDiffData: [],
  fetchingLocationDiffData: true,
  locationDiffData: [],
  inviteRefreshDone: false,
  fetchSaveLogo: true,
  fetchingAuditTrailList: true,
  auditTrailData: [],
  auditTrailList: [],
  fetchingAuditTrailDetails: true,
  auditTrailDetails: [],
  fetchLogoStatus: true,
  logoData: {},
  fetchClearAuditStatus: true,
  list: [],
  count: [],
  total: 0,
  fetchFlexFieldStatus: true,
  flexFields: [],
  rejectCompanyStatus: true,
  rejectRemoveCompanyStatus: true,
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_EMAIL_INVITE: {
      return state.set("emailInviteSent", false);
    }
    case EMAIL_INVITE_SENT: {
      return state.set("emailInviteSent", true);
    }
    case FETCHING_LIST: {
      return state.set("fetchingList", true);
    }
    case FETCH_LIST: {
      return state
        .set("listData", action.data.companies)
        .set("list", action.data.companies)
        .set("statusCount", action.data.statusCounts)
        .set("count", action.data.statusCounts)
        .set("totalCount", action.data.totalCount)
        .set("total", action.data.totalCount)
        .set("fetchingList", false);
    }
    case UPDATE_LIST_DATA: {
      return state
        .set("listData", action.data)
        .set("statusCount", action.statusCounts)
        .set("totalCount", action.totalCount)
        .set("fetchingList", false);
    }
    case FETCHING_EDIT_LIST: {
      return state.set("fetchingEditList", true);
    }
    case FETCH_EDIT_LIST: {
      return state
        .set("editListData", action.data)
        .set("fetchingEditList", false);
    }
    case FETCHING_EDIT_LIST_SUCCESS: {
      return state.set("fetchingEditList", false);
    }
    case INIT_TAKE_OWNERSHIP: {
      return state.set("fetchOwnership", true);
    }
    case SET_OWNERSHIP_DATA: {
      return state.set("ownershipData", action.data);
    }
    case FETCH_OWNERSHIP_STATUS: {
      return state.set("fetchOwnership", false);
    }
    case FETCHING_ATTRIBUTES_DETAILS: {
      return state.set("fetchingAttributesDetails", true);
    }
    case FETCH_ATTRIBUTES_DETAILS: {
      return state
        .set("attributesData", action.data)
        .set("fetchingAttributesDetails", false);
    }
    case FETCHING_ATTRIBUTES_DETAILS_SUCCESS: {
      return state.set("fetchingAttributesDetails", false);
    }
    case FETCHING_CONTACT_DETAILS: {
      return state.set("fetchingContactDetails", true);
    }
    case FETCH_CONTACT_DETAILS: {
      return state
        .set("contactData", action.data)
        .set("fetchingContactDetails", false);
    }
    case FETCHING_LOCATION_DETAILS: {
      return state.set("fetchingLocationDetails", true);
    }
    case FETCH_LOCATION_DETAILS: {
      return state
        .set("locationData", action.data)
        .set("fetchingLocationDetails", false);
    }
    case FETCHING_ATTACHMENT_DETAILS: {
      return state.set("fetchingAttachmentDetails", true);
    }
    case FETCH_ATTACHMENT_DETAILS: {
      return state
        .set("attachmentData", action.data)
        .set("fetchingAttachmentDetails", false);
    }
    case GET_WORKFLOW_STEP_DATA: {
      return state.set("fetchingWorkflowStepData", true);
    }
    case SET_WORKFLOW_STEP_DATA: {
      return state
        .set("workflowData", action.data)
        .set("fetchingWorkflowStepData", false);
    }
    case GET_DIFF_DATA: {
      return state.set("fetchingDiffData", true);
    }
    case SET_DIFF_DATA: {
      return state.set("diffData", action.data).set("fetchingDiffData", false);
    }
    case INIT_NEW_COMPANY_CREATION: {
      return state.set("fetchingNewCmpnyStatus", true);
    }
    case FETCH_NEW_COMPANY_CREATION: {
      return state.set("fetchingNewCmpnyStatus", false);
    }
    case INIT_TAKE_PROXY: {
      return state.set("fetchingTakeProxyStatus", true);
    }
    case TAKE_PROXY_STATUS: {
      return state.set("fetchingTakeProxyStatus", false);
    }
    case INIT_WOKFLOW_STATE: {
      return state.set("fetchingWorkflowState", true);
    }
    case SET_WORKFLOW_STATE: {
      return state
        .set("workflowState", action.data)
        .set("fetchingWorkflowState", false);
    }
    case GET_CONTACT_DIFF_DATA: {
      return state.set("fetchingContactDiffData", true);
    }
    case SET_CONTACT_DIFF_DATA: {
      return state
        .set("contactDiffData", action.data)
        .set("fetchingContactDiffData", false);
    }
    case GET_LOCATION_DIFF_DATA: {
      return state.set("fetchingLocationDiffData", true);
    }
    case SET_LOCATION_DIFF_DATA: {
      return state
        .set("locationDiffData", action.data)
        .set("fetchingLocationDiffData", false);
    }
    case INIT_INVITE_REFRESH: {
      return state.set("inviteRefreshDone", false);
    }
    case INVITE_REFRESH_COMPLETE: {
      return state.set("inviteRefreshDone", true);
    }
    case INIT_SAVE_LOGO: {
      return state.set("fetchSaveLogo", true);
    }
    case SAVE_LOGO_COMPLETE: {
      return state.set("fetchSaveLogo", false);
    }
    case INIT_AUDIT_TRAIL_LIST: {
      return state.set("fetchingAuditTrailList", true);
    }
    case SET_AUDIT_TRAIL_LIST: {
      return state
        .set("auditTrailData", action.data)
        .set("auditTrailList", action.data)
        .set("fetchingAuditTrailList", false);
    }
    case UPDATE_AUDIT_TRAIL_LIST: {
      return state
        .set("auditTrailData", action.data)
        .set("fetchingAuditTrailList", false);
    }
    case INIT_AUDIT_TRAIL_DATA: {
      return state.set("fetchingAuditTrailDetails", true);
    }
    case SET_AUDIT_TRAIL_DATA: {
      return state
        .set("auditTrailDetails", action.data)
        .set("fetchingAuditTrailDetails", false);
    }
    case INIT_CLEAR_AUDIT_TRAIL: {
      return state.set("fetchClearAuditStatus", true);
    }
    case CLEAR_AUDIT_TRAIL_SUCCESS: {
      return state.set("fetchClearAuditStatus", false);
    }
    case INIT_GET_LOGO: {
      return state.set("fetchLogoStatus", true);
    }
    case SET_LOGO: {
      return state.set("logoData", action.data).set("fetchLogoStatus", false);
    }
    case INIT_FETCH_FLEX_FIELDS: {
      return state.set("fetchFlexFieldStatus", true);
    }
    case SET_FLEX_FIELDS: {
      return state
        .set("flexFields", action.data)
        .set("fetchFlexFieldStatus", false);
    }
    case INIT_CLEAR_STORE: {
      return state
        .set("editListData", [])
        .set("fetchingEditList", true)
        .set("workflowData", [])
        .set("fetchingWorkflowStepData", true);
    }
    case INIT_REJECT: {
      return state.set("rejectCompanyStatus", true);
    }
    case REJECT_SUCCESS: {
      return state.set("rejectCompanyStatus", false);
    }
    case INIT_REJECT_REMOVE: {
      return state.set("rejectRemoveCompanyStatus", true);
    }
    case REJECT_REMOVE_SUCCESS: {
      return state.set("rejectRemoveCompanyStatus", false);
    }
    default:
      return state;
  }
};
export default reducer;
