//Get categories from custom id

export const INIT_FETCH_CATEGORY = "INIT_FETCH_CATEGORY";
export const FETCHING_CATEGORY = "FETCHING_CATEGORY";
export const FETCH_CATEGORY = "FETCH_CATEGORY";

//---------------------------------------------------------------------------------------------------------------//
//FAQ
//List FAQ
export const INIT_FETCH_FAQ_MATERIALS = "INIT_FETCH_FAQ_MATERIALS";
export const FETCHING_FAQ_MATERIALS = "FETCHING_FAQ_MATERIALS";
export const FETCH_FAQ_MATERIALS = "FETCH_FAQ_MATERIALS";
export const FAQ_MATERIALS_FETCH_COMPLETE = "FAQ_MATERIALS_FETCH_COMPLETE";

//FAQ Questions by id
export const INIT_FETCH_FAQ_MATERIAL_BY_ID = "INIT_FETCH_FAQ_MATERIAL_BY_ID";
export const FETCHING_FAQ_MATERIAL_BY_ID = "FETCHING_FAQ_MATERIAL_BY_ID";
export const FETCH_FAQ_MATERIAL_BY_ID = "FETCH_FAQ_MATERIAL_BY_ID";
export const FAQ_MATERIAL_BY_ID_FETCH_COMPLETE =
  "FAQ_MATERIAL_BY_ID_FETCH_COMPLETE";

//Save FAQ Question
export const INIT_SAVE_FAQ_MATERIAL = "INIT_SAVE_FAQ_MATERIAL";
export const FAQ_MATERIAL_SAVED = "FAQ_MATERIAL_SAVED";
export const FAQ_MATERIAL_SAVE_FAILED = "FAQ_MATERIAL_SAVE_FAILED";

//Remove FAQ Question
export const INIT_DELETE_FAQ_MATERIAL = "INIT_DELETE_FAQ_MATERIAL";
export const DELETE_FAQ_MATERIAL_SUCCESS = "DELETE_FAQ_MATERIAL_SUCCESS";
export const DELETE_FAQ_MATERIAL_FAILED = "DELETE_FAQ_MATERIAL_FAILED";

//-------------------------------------------------------------------------------------------------------//
//TrainingMAterials
//List Training Material
export const INIT_FETCH_TRAINING_MATERIALS = "INIT_FETCH_TRAINING_MATERIALS";
export const FETCHING_TRAINING_MATERIALS = "FETCHING_TRAINING_MATERIALS";
export const FETCH_TRAINING_MATERIALS = "FETCH_TRAINING_MATERIALS";
export const TRAINING_MATERIALS_FETCH_COMPLETE =
  "TRAINING_MATERIALS_FETCH_COMPLETE";

//Training Material by id
export const INIT_FETCH_TRAINING_MATERIAL_BY_ID =
  "INIT_FETCH_TRAINING_MATERIAL_BY_ID";
export const FETCHING_TRAINING_MATERIAL_BY_ID =
  "FETCHING_TRAINING_MATERIAL_BY_ID";
export const FETCH_TRAINING_MATERIAL_BY_ID = "FETCH_TRAINING_MATERIAL_BY_ID";
export const TRAINING_MATERIAL_BY_ID_FETCH_COMPLETE =
  "TRAINING_MATERIAL_BY_ID_FETCH_COMPLETE";

//Save Traning Material
export const INIT_SAVE_TRAINING_MATERIAL = "INIT_SAVE_TRAINING_MATERIAL";
export const TRAINING_MATERIAL_SAVED = "TRAINING_MATERIAL_SAVED";
export const TRAINING_MATERIAL_SAVE_FAILED = "TRAINING_MATERIAL_SAVE_FAILED";

//Remove Training Material
export const INIT_DELETE_TRAINING_MATERIAL = "INIT_DELETE_TRAINING_MATERIAL";
export const DELETE_TRAINING_MATERIAL_SUCCESS =
  "DELETE_TRAINING_MATERIAL_SUCCESS";
export const DELETE_TRAINING_MATERIAL_FAILED =
  "DELETE_TRAINING_MATERIAL_FAILED";

//-------------------------------------------------------------------------------------------------------//
//Training Videos
//List Training Video
export const INIT_FETCH_TRAINING_VIDEOS = "INIT_FETCH_TRAINING_VIDEOS";
export const FETCHING_TRAINING_VIDEOS = "FETCHING_TRAINING_VIDEOS";
export const FETCH_TRAINING_VIDEOS = "FETCH_TRAINING_VIDEOS";
export const TRAINING_VIDEOS_FETCH_COMPLETE = "TRAINING_VIDEOS_FETCH_COMPLETE";

//Training Video by id
export const INIT_FETCH_TRAINING_VIDEO_BY_ID =
  "INIT_FETCH_TRAINING_VIDEO_BY_ID";
export const FETCHING_TRAINING_VIDEO_BY_ID = "FETCHING_TRAINING_VIDEO_BY_ID";
export const FETCH_TRAINING_VIDEO_BY_ID = "FETCH_TRAINING_VIDEO_BY_ID";
export const TRAINING_VIDEO_BY_ID_FETCH_COMPLETE =
  "TRAINING_VIDEO_BY_ID_FETCH_COMPLETE";

//Save Traning Video
export const INIT_SAVE_TRAINING_VIDEO = "INIT_SAVE_TRAINING_VIDEO";
export const TRAINING_VIDEO_SAVED = "TRAINING_VIDEO_SAVED";
export const TRAINING_VIDEO_SAVE_FAILED = "TRAINING_VIDEO_SAVE_FAILED";

//Remove Training Video
export const INIT_DELETE_TRAINING_VIDEO = "INIT_DELETE_TRAINING_VIDEO";
export const DELETE_TRAINING_VIDEO_SUCCESS = "DELETE_TRAINING_VIDEO_SUCCESS";
export const DELETE_TRAINING_VIDEO_FAILED = "DELETE_TRAINING_VIDEO_FAILED";
