import styled from "styled-components";
export const HideDefaultCheckbox = styled.input.attrs((props) => ({
  type: "checkbox",
  readOnly: props.readOnly ? props.readOnly : false,
}))`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
`;
export const CheckboxIcon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;
export const StyledCheckbox = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  background: ${(props) =>
    props.checked ? "#008eff" : "rgba(255, 255, 255, 0.38)"};
  border: ${(props) =>
    props.checked ? "1px solid #008eff" : "1px solid #c4cdd5"};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "none"};
  transition: all 150ms;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
  ${HideDefaultCheckbox}:focus + & {
    border: 1px solid #008eff;
  }
  ${CheckboxIcon} {
    visibility: ${(props) => (props.checked ? "visible" : "hidden")};
  }
`;

export const checkboxLabelStyle = {
  margin: "0 0 0 12px",
  fontColor: "rgb(51, 52, 54)",
  fontSize: "14px",
  fontFamily: "graphik-medium",
  fontWeight: "500",
  lSpacing: "-0.25px",
  lineHeight: "14px",
};
