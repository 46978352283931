import React from "react";
import Div from "../../Div/index";
import { FilePreviewWrapper } from "./styled";
import crossButton from "../../../assets/images/cross-button.png";
import attchmentIcon from "../../../assets/images/attachment.png";
import IMG from "../../IMG/";
import { flexCol, flexGrow, flexRow, flexShrink } from "../../Layout/style";
import Span from "../../Span";
const labelStyle = {
  color: "rgb(33, 43, 54)",
  fontSize: "14px",
  fontFamily: "graphik-medium",
  fontWeight: 500,
  letterSpacing: "-0.25px",
  lineHeight: "16px",
  margin: "0 0 3px 0",
};
const despStyle = {
  ...labelStyle,
  margin: 0,
  color: "rgb(145, 158, 171)",
  fontSize: "10px",

  letterSpacing: "0px",
  lineHeight: "10px",
};
const fileSize = (bytes) => {
  const sizes = ["Bytes", "KB", "MB"];
  if (bytes === 0 || bytes === "") return null;
  let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  if (i === 0) return bytes + " " + sizes[i];
  return (bytes / Math.pow(1024, i)).toFixed(1) + " " + sizes[i];
};

const FileUploadPreview = (props) => {
  const removeItem = (item, i) => {
    props.deleteSingleFile(item, i);
  };
  let showFileUploadWrapper;
  if (props.fileData) {
    showFileUploadWrapper = (
      <Div {...flexCol} {...flexShrink} padding="16px 8px 8px 0">
        <Div {...flexRow} {...flexGrow} flexWrap="wrap" overflow="auto">
          {props.fileData.map((singleFile, i) => (
            <FilePreviewWrapper key={i}>
              <Div {...flexRow} {...flexShrink}>
                <Span
                  {...flexRow}
                  {...flexShrink}
                  selfAlign="center"
                  margin="0 8px 0 0 "
                >
                  <IMG
                    imgHeight="21px"
                    imgWidth="19px"
                    src={attchmentIcon}
                    alt="Attchment"
                  />
                </Span>
              </Div>
              <Div {...flexCol} {...flexGrow} selfAlign="center">
                <Span {...flexRow} {...flexGrow} {...labelStyle}>
                  {singleFile.filename}
                  <Span
                    margin="0 8px"
                    cursor="pointer"
                    onClick={() => removeItem(singleFile, i)}
                  >
                    <IMG src={crossButton} imgWidth="10px" imgHeight="10px" />
                  </Span>
                </Span>
                <Span {...flexRow} {...flexGrow} {...despStyle}>
                  {fileSize(singleFile.filesize)
                    ? fileSize(singleFile.filesize)
                    : ""}
                </Span>
              </Div>
            </FilePreviewWrapper>
          ))}
        </Div>
      </Div>
    );
  }
  return showFileUploadWrapper;
};
export default FileUploadPreview;
