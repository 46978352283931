import React from "react";
import PropTypes from "prop-types";
import Notification from "../components/Notification";

export const buildNotification = ({ type, message }) => {
  return {
    content: <Notification type={type} message={message} />,
    insert: "top",
    container: "top-right",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: {
      duration: 4000,
      pauseOnHover: true,
    },
  };
};

buildNotification.propTypes = {
  mode: PropTypes.oneOf(["color", "lite"]),
  type: PropTypes.oneOf(["danger", "warning", "success"]),
};

export default {
  insert: "top",
  container: "top-right",
  animationIn: ["animated", "fadeIn"],
  animationOut: ["animated", "fadeOut"],
  dismiss: {
    duration: 4000,
    showIcon: true,
    onScreen: true,
    pauseOnHover: true,
  },
};
