import styled from "styled-components";

import searchLogo from "../../assets/images/search-icon.png";

const SearchInputBox = styled.input`
  float: left;
  width: ${(props) => (props.mainWidth ? props.mainWidth : "50%")};
  box-sizing: border-box;
  border: 1px solid #00000038;
  border-radius: 4px;
  font-size: 14px;
  height: 36px;
  background-color: white;
  background-image: url("${searchLogo}");
  background-size: 30px 30px;
  background-position: 0px 3px;
  background-repeat: no-repeat;
  padding: 12px 20px 12px 40px;
  -webkit-transition: width 0.4s ease-in-out;
  transition: width 0.4s ease-in-out;
  margin-top: ${(props) => (props.mt ? props.mt : "")};
  &:focus {
    width: ${(props) => (props.foucsWidth ? props.foucsWidth : "80%")};
    border: ${(props) =>
      props.borderOnFocus ? props.borderOnFocus : "1.5px solid #008eff"};
    box-shadow: ${(props) =>
      props.boxShadowOnFocus
        ? props.boxShadowOnFocus
        : "0px 2px 8px 0px rgba(0, 0, 0, 0.15)"};
    outline: none;
  }
`;

export default SearchInputBox;
