export const headerTextCommonStyle = {
  color: "rgb(45, 58, 75)",
  fontFamily: " graphik-medium",
  fontSize: "14px",
  fontWeight: "500",
  height: "24px",
  letterSpacing: "-0.32px",
  lineHeight: "24px",
  margin: "0",
  flex: "1",
};

export const clientSupplierDivWrapper = {
  position: "relative",
  background: "rgb(255, 255, 255)",
  borderRadius: "5px",
  border: " 1px solid rgb(220, 226, 231)",
  height: "120px",
  margin: "14px 0 0 0",
};

export const supplierHeaderTextStyle = {
  color: " rgb(145, 167, 177)",
  fontSize: "10px",
  fontFamily: "graphik-medium",
  fontWeight: "500",
  textTransform: "capitalize",
  letterSpacing: "1px",
  lineHeight: "10px",
  margin: "0",
  padding: " 10px 0 0 8px",
};

export const selectSupplierLabelStyle = {
  height: "20px",
  color: "#008eff",
  fontSize: "14px",
  fontFamily: "graphik-medium",
  fontWeight: "500",
  textalign: "center",
  letterSpacing: "-0.53px",
  cursor: "pointer",
  lineHeight: "20px",
  position: "absolute",
  bottom: "0",
};
