import React from "react";

import Div from "../../../Div";

import Label from "../../../Label";
import { flexCol, flexGrow, flexRow, flexShrink } from "../../../Layout/style";
import FilterControls from "./FilterControls";

import { useTranslation } from "react-i18next";
import FormInput from "../../../Input/FormInput";
import Button from "../../../Button/Button";
import IMG from "../../../IMG";
import closeIcon from "../../../../assets/images/close_lite.png";
const AdvanceSearch = (props) => {
  const { t } = useTranslation();

  const sections = props.sections;

  return (
    <Div
      {...flexCol}
      {...flexGrow}
      overflow="auto"
      padding="8px 16px 16px 16px"
      borderTop="1px solid #008eff"
    >
      {sections &&
        sections.map((controls, key) => {
          return (
            <>
              {props.isLogical && key === 1 ? (
                <Div {...flexCol} {...flexShrink}>
                  <Div {...flexRow} {...flexShrink}>
                    <Label
                      fontFamily="graphik-semibold"
                      fontSize="11px"
                      fontWeight="600"
                      lineHeight="11px"
                      margin="0 0 8px 0"
                    >
                      Select Condition
                    </Label>
                  </Div>
                  <Div {...flexRow} {...flexGrow}>
                    <FormInput
                      elementType="select"
                      value={props.conditionValue}
                      elementProps={{
                        placeholder: "Please Select an option",
                        options: props.logicalOptions,
                        id: "logicalFilterOption",
                        padding: "5px 5px 5px 15px",
                        inputHeight: "36px",
                        inputWidth: "100%",
                        border: "1px solid #e3eaed",
                        borderRadius: "5px 0px 0px 5px",
                        margin: "5px",
                      }}
                      onChangeHandler={props.onConditionChange}
                    />
                    <Div {...flexRow} {...flexShrink} divHeight="36px">
                      <Button
                        disabled={props.conditionValue === ""}
                        onClick={props.addCondition}
                      >
                        Add condition
                      </Button>
                    </Div>
                  </Div>

                  {props.logicalInputs.length > 0 ? (
                    <Div {...flexRow} {...flexGrow}>
                      <Div {...flexCol} {...flexGrow}>
                        {props.logicalInputs.map((inputs, index) => {
                          const joinValue =
                            props.logicalValues[index].condition || "AND";

                          const {
                            paramTo,
                            paramFrom,
                            paramAdditional,
                          } = props.logicalValues[index];
                          const values = {
                            paramTo,
                            paramFrom,
                            paramAdditional,
                          };
                          return (
                            <>
                              <Div {...flexRow} {...flexGrow}>
                                <Label
                                  fontFamily="graphik-semibold"
                                  fontSize="11px"
                                  fontWeight="600"
                                  lineHeight="11px"
                                  margin="0 0 8px 0"
                                >
                                  {t(inputs.title)}
                                </Label>
                              </Div>
                              <Div {...flexRow} {...flexGrow}>
                                <FilterControls
                                  logical={true}
                                  values={values}
                                  controls={inputs.inputs}
                                  onFilterChange={(e) =>
                                    props.onLogicalControlChange(e, {
                                      controlID: inputs.controlID,
                                      order: index,
                                    })
                                  }
                                />
                                {index !== props.logicalInputs.length - 1 ? (
                                  <Div {...flexRow} {...flexShrink}>
                                    <FormInput
                                      elementType="select"
                                      value={joinValue}
                                      elementProps={{
                                        placeholder: "Join With...",
                                        options: ["AND", "OR"],
                                        id: "condition",
                                        padding: "5px 5px 5px 15px",
                                        inputHeight: "36px",
                                        inputWidth: "100%",
                                        border: "1px solid #e3eaed",
                                        borderRadius: "5px 0px 0px 5px",
                                        margin: "5px",
                                      }}
                                      onChangeHandler={(e) =>
                                        props.onLogicalControlChange(e, {
                                          controlID: inputs.controlID,
                                          order: index,
                                        })
                                      }
                                    />
                                  </Div>
                                ) : null}

                                <Div
                                  {...flexRow}
                                  {...flexShrink}
                                  onClick={() => props.removeCondition(index)}
                                >
                                  <IMG src={closeIcon} alt="Close Icon" />
                                </Div>
                              </Div>
                            </>
                          );
                        })}
                      </Div>
                    </Div>
                  ) : null}
                </Div>
              ) : null}
              <Div {...flexCol} key={key} margin="8px 0">
                {controls.title ? (
                  <Label
                    fontFamily="graphik-semibold"
                    fontSize="11px"
                    fontWeight="600"
                    lineHeight="11px"
                    margin="0 0 8px 0"
                  >
                    {t(controls.title)}
                  </Label>
                ) : (
                  ""
                )}

                <Div {...flexCol} {...flexGrow}>
                  {
                    <Div
                      display="flex"
                      direction={props.isLogical ? "row" : "column"}
                      {...flexShrink}
                    >
                      {controls.component ? (
                        controls.component
                      ) : (
                        <FilterControls
                          values={props.values}
                          controls={controls.inputs}
                          onFilterChange={props.onControlChange}
                          customEvent={props.customEvent}
                        />
                      )}
                    </Div>
                  }
                </Div>
              </Div>
            </>
          );
        })}
    </Div>
  );
};

export default AdvanceSearch;
