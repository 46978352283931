import {
  FETCHING_UMR_LIST,
  FETCH_UMR_LIST,
  FETCH_UMR_LIST_COMPLETED,
  INIT_FETCH_UMR_LIST,
  INIT_UMR_DELETE,
  INIT_UMR_SAVE,
  INIT_UMR_UPDATE_STATUS,
} from "./actionTypes";

export function initFetchUMRList() {
  return {
    type: INIT_FETCH_UMR_LIST,
  };
}

export function fetchingUMRList() {
  return {
    type: FETCHING_UMR_LIST,
  };
}

export function fetchUMRList(payload) {
  return {
    type: FETCH_UMR_LIST,
    payload,
  };
}

export function fetchUMRListCompleted(payload) {
  return {
    type: FETCH_UMR_LIST_COMPLETED,
  };
}

export function initUMRSave(payload) {
  return {
    type: INIT_UMR_SAVE,
    payload,
  };
}

export function initUMRDelete(payload) {
  return {
    type: INIT_UMR_DELETE,
    payload,
  };
}

export function initUMRUpdateStatus(payload) {
  return {
    type: INIT_UMR_UPDATE_STATUS,
    payload,
  };
}
