import {
  INIT_FETCH_SIGNUP_FORM_FIELDS,
  FETCHING_SIGNUP_FORM_FIELDS,
  FETCH_SIGNUP_FORM_FIELDS,
  INIT_FETCH_SIGNUP_FLEX_FIELDS,
  FETCHING_SIGNUP_FLEX_FIELDS,
  FETCH_SIGNUP_FLEX_FIELDS,
  INIT_SAVE_SIGNUP_FORM,
  SIGNUP_FORM_SAVED,
  SIGNUP_FORM_SAVE_FAILED,
  RESET_SAVE_STATUS,
  INIT_CHECK_USER_EXISTS,
  USER_EXIST,
  INIT_FETCH_SECURITY_QUESTION,
  FETCHING_SECURITY_QUESTION,
  FETCH_SECURITY_QUESTION,
} from "./actionTypes";

export const initFetchSignUpFormFields = (langId) => {
  return {
    type: INIT_FETCH_SIGNUP_FORM_FIELDS,
    langId,
  };
};

export const fetchingSignUpFormFields = () => {
  return {
    type: FETCHING_SIGNUP_FORM_FIELDS,
  };
};

export const fetchSignUpFormFields = (data) => {
  return {
    type: FETCH_SIGNUP_FORM_FIELDS,
    data: data,
  };
};

export const initFetchSignUpFlexFields = (langId) => {
  return {
    type: INIT_FETCH_SIGNUP_FLEX_FIELDS,
    langId,
  };
};

export const fetchingSignUpFlexFields = () => {
  return {
    type: FETCHING_SIGNUP_FLEX_FIELDS,
  };
};

export const fetchSignUpFlexFields = (data) => {
  return {
    type: FETCH_SIGNUP_FLEX_FIELDS,
    data: data,
  };
};

export const initSaveSignUpForm = (payload) => {
  return {
    type: INIT_SAVE_SIGNUP_FORM,
    payload,
  };
};

export const signUpFormSaved = () => {
  return {
    type: SIGNUP_FORM_SAVED,
  };
};

export const signUpFormSaveFailed = () => {
  return {
    type: SIGNUP_FORM_SAVE_FAILED,
  };
};

export const resetSaveStatus = () => {
  return {
    type: RESET_SAVE_STATUS,
  };
};

export const initCheckUserExists = (payload) => {
  return {
    type: INIT_CHECK_USER_EXISTS,
    payload, //this is when user gives email and calls api
  };
};

export const userExist = (data) => {
  return {
    type: USER_EXIST,
    data, //this is response of api
  };
};

export const initFetchSecurityQuestion = (langId) => {
  return {
    type: INIT_FETCH_SECURITY_QUESTION,
    langId,
  };
};

export const fetchingSecurityQuestion = () => {
  return {
    type: FETCHING_SECURITY_QUESTION,
  };
};

export const fetchSecurityQuestion = (data) => {
  return {
    type: FETCH_SECURITY_QUESTION,
    data: data,
  };
};
