import { fromJS } from "immutable";
import {
  INIT_CLIENT_SSO_LOGIN,
  CLIENT_SSO_LOGIN_COMPLETE,
  FETCHING_CLIENT_SSO_TOKEN,
  FETCH_CLIENT_SSO_TOKEN,
} from "./actionTypes";

export const initialState = fromJS({
  clientSsoLoginStatus: false,
  fetchingClientSsoToken: false,
  clientSsoToken: [],
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_CLIENT_SSO_LOGIN: {
      return state.set("clientSsoLoginStatus", true);
    }
    case FETCHING_CLIENT_SSO_TOKEN: {
      return state.set("fetchingClientSsoToken", true);
    }
    case FETCH_CLIENT_SSO_TOKEN: {
      return state.set("clientSsoToken", action.data);
    }
    case CLIENT_SSO_LOGIN_COMPLETE: {
      return state
        .set("clientSsoLoginStatus", false)
        .set("fetchingClientSsoToken", false);
    }
    default:
      return state;
  }
};
export default reducer;
