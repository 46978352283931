import styled from "styled-components";

const H = styled.h2`
  width: ${(props) => (props.width ? props.width : "100%")};
  border-bottom: ${(props) =>
    props.borderBottom ? props.borderBottom : "1px solid #2d3a4b"};
  line-height: ${(props) => (props.lh ? props.lh : "0.1em")};
  margin: ${(props) => (props.margin ? props.margin : "10px 0 20px")};
  color: #2d3a4b;
  font-size: 20px;
  font-family: graphik-medium;
  font-weight: 500;
  padding: ${(props) => (props.padding ? props.padding : "0 0 0 0")};
`;

export default H;
