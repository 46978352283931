import { put, takeEvery, call, all } from "redux-saga/effects";
import { buildNotification } from "../../../../config/notification";
import { store } from "react-notifications-component";
import {
  updateAnnouncementDetails,
  setDataForEdit,
  deleteSuccess,
  deletFailed,
  getAnnouncementDetails,
  formSubmissionStatusAction,
  fetchingCategoryList,
  fetchCategoryList,
  fetchingImportanceList,
  fetchImportanceList,
  getDataForEditFetching,
  editDataFetched,
  saveItemDataCompleted,
} from "./actions";

import {
  GET_ANNOUNCEMENT_DETAILS,
  INIT_NEWSANDEVENT_DELETE,
  SAVE_ANNOUNCEMENT_DATA,
  INIT_CATEGORY_LIST_FETCH,
  INIT_IMPORTANCE_LIST_FETCH,
  INIT_NEWS_AND_EVENTS_EDIT,
} from "./actionTypes";
import axios from "../../../../config/axios";
import {
  getRequestDetails,
  postRequestDetails,
} from "../../../../config/requestHeaders";
let languageId = "";
let sessionDetails = JSON.parse(sessionStorage.getItem("sessionDetails"));
if (sessionDetails) {
  languageId = sessionDetails.languageId;
}

export default function* watchNewsAndEvents() {
  yield all([
    takeEvery(GET_ANNOUNCEMENT_DETAILS, getAnnouncementListSaga),
    takeEvery(INIT_NEWS_AND_EVENTS_EDIT, initEditSaga),
    takeEvery(INIT_NEWSANDEVENT_DELETE, initNewsandEventDelete),
    takeEvery(SAVE_ANNOUNCEMENT_DATA, saveSaga),
    takeEvery(INIT_CATEGORY_LIST_FETCH, getCategoryListSaga),
    takeEvery(INIT_IMPORTANCE_LIST_FETCH, getImportanceListSaga),
  ]);
}

function* getAnnouncementListSaga(action) {
  const url =
    "/Announcement?LangKey=" + languageId + "&Filter=" + action.param.filter;

  try {
    const response = yield call(axios, url, getRequestDetails);

    yield put(updateAnnouncementDetails(response.data));
  } catch (error) {
    yield put(updateAnnouncementDetails());
  }
}

function* initEditSaga(action) {
  yield put(getDataForEditFetching());
  const url = `/Announcement/detailsById?AnnouncementID=${action.id}`;
  try {
    const response = yield call(axios, url, getRequestDetails);
    if (response && response.status === 200) {
      if (action && action.fetchDataSuccess) {
        action.fetchDataSuccess(response.data);
      }
      yield put(setDataForEdit(response.data));
    }
    yield put(editDataFetched());
  } catch (error) {
    yield put(editDataFetched());
  }
}

function* initNewsandEventDelete(action) {
  const url = `Announcement/delete?AnnouncementIdList=${action.id}&UserId=${action.userId}`;
  try {
    const response = yield call(axios, url, postRequestDetails);
    if ((response && response.status === 200) || response.status === 201) {
      const id = action.id.split(",").map((id) => parseInt(id));
      yield put(deleteSuccess(id));
      if (action.deleteSuccess) {
        action.deleteSuccess(response.status);
      }
      const notification = buildNotification({
        message: "notification.newsEvents.announcementDeleted",
        type: "success",
      });
      store.addNotification({
        ...notification,
        dismiss: {
          duration: 5000,
        },
      });

      yield put(
        getAnnouncementDetails({
          langKey: "",
          filter: "",
        })
      );
    }
  } catch (error) {
    yield put(deletFailed());
  }
}

function* saveSaga(action) {
  let clientDetails = JSON.parse(sessionStorage.getItem("clientDetails"));
  let clientName = clientDetails.name;
  const url = "/Announcement/Save";
  let saveData = {
    announcementID: action.data.announcementID,
    importanceID: action.data.importanceID,
    categoryID: action.data.categoryID,
    validFrom: action.data.validFrom,
    validTo: action.data.validTo,
    sendNotification: action.data.sendNotification,
    active: action.data.active,
    groupDetails: action.data.groupDetails,
    announcementDetail: [],
    userId: action.data.userId,
  };
  for (let i = 0; i < action.data.language.length; i++) {
    saveData.announcementDetail.push({
      langKey: action.data.language[i].langKey,
      message: action.data.language[i].message,
      title: action.data.language[i].title,
    });
  }
  yield (postRequestDetails.data = {
    ...saveData,
    clientName: clientName,
  });
  try {
    const response = yield call(axios, url, postRequestDetails);
    if (response && response.status === 202) {
      if (action.saveSuccess) {
        action.saveSuccess(true);
      }
      yield put(formSubmissionStatusAction(response.statusText));
      const notification = buildNotification({
        message: "notification.newsEvents.annoucementSaved",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
      yield put(
        getAnnouncementDetails({
          langKey: "",
          filter: "",
        })
      );
      yield put(saveItemDataCompleted());
    }
  } catch (error) {
    yield put(saveItemDataCompleted());
  }
}

function* getCategoryListSaga(action) {
  yield put(fetchingCategoryList());
  const url = "/CustomListItem/listkeys?typeName=__News_Events_Categories";
  try {
    const response = yield call(axios, url, {
      method: "GET",
    });
    if (response) {
      yield put(fetchCategoryList(response.data));
    }
  } catch (error) {
    action.callback && action.callback();
  }
}

function* getImportanceListSaga(action) {
  yield put(fetchingImportanceList());
  const url = "/CustomListItem/listkeys?typeName=__News_Events_Importance";
  try {
    const response = yield call(axios, url, {
      method: "GET",
    });
    if (response) {
      yield put(fetchImportanceList(response.data));
    }
  } catch (error) {
    action.callback && action.callback();
  }
}
