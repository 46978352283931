import { fromJS } from "immutable";
import {
  GET_PAYMENT_COLUMNS_ACTION,
  PAYMENTS_DETAILS_REQUEST,
  UPDATE_PAYMENTS_DETAILS,
  GET_INVOICE_COLUMNS_ACTION,
  PAY_INVOICE_DETAILS_REQUEST,
  UPDATE_INVOICE_DETAILS_ACTION,
  UPDATE_PAYMENT_DATA_ACTION,
  UPDATE_PAYMENT_FILTER_ACTION,
  INIT_GET_REMITTANCE_BY_DOC_NO,
  REMITTANCE_DATA_BY_DOC_NO,
  INIT_GET_REMITTANCE_BY_ID,
  REMITTANCE_DATA_BY_ID,
} from "./constants";

export const initialState = fromJS({
  paymentColumns: [],
  paymentList: [],
  paymentData: [],
  isPaymentLoading: false,
  payInvoiceColumns: [],
  payInvoiceList: [],
  isPayInvoiceLoading: false,
  showPaymentFilter: false,
  remittanceByDocLoading: true,
  remittanceDataByDocNo: [],
  remittanceByIdLoading: true,
  remittanceDataById: [],
});

function paymentsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PAYMENT_COLUMNS_ACTION:
      return state.set("paymentColumns", action.paymentColumns);
    case PAYMENTS_DETAILS_REQUEST:
      return state
        .set("isPaymentLoading", action.isPaymentLoading)
        .set("paymentList", [])
        .set("paymentData", []);
    case UPDATE_PAYMENTS_DETAILS:
      return state
        .set("paymentList", action.paymentsList)
        .set("paymentData", action.paymentsList)
        .set("isPaymentLoading", false);
    case UPDATE_PAYMENT_DATA_ACTION:
      return state.set("paymentList", action.paymentData);
    case GET_INVOICE_COLUMNS_ACTION:
      return state.set("payInvoiceColumns", action.invoiceColumns);
    case PAY_INVOICE_DETAILS_REQUEST:
      return state.set("isPayInvoiceLoading", action.isInvoiceLoading);
    case UPDATE_INVOICE_DETAILS_ACTION:
      return state
        .set("payInvoiceList", action.invoiceList)
        .set("isPayInvoiceLoading", false);
    case UPDATE_PAYMENT_FILTER_ACTION:
      return state.set("showPaymentFilter", action.showPaymentFilter);
    case INIT_GET_REMITTANCE_BY_DOC_NO:
      return state.set("remittanceByDocLoading", true);
    case REMITTANCE_DATA_BY_DOC_NO:
      return state
        .set("remittanceByDocLoading", false)
        .set("remittanceDataByDocNo", action.data);
    case INIT_GET_REMITTANCE_BY_ID:
      return state.set("remittanceByIdLoading", true);
    case REMITTANCE_DATA_BY_ID:
      return state
        .set("remittanceByIdLoading", false)
        .set("remittanceDataById", action.data);
    default:
      return state;
  }
}

export default paymentsReducer;
