import { put, takeEvery, call, all } from "redux-saga/effects";
import { buildNotification } from "../../../config/notification";
import { store } from "react-notifications-component";

import {
  fetchingCustomList,
  fetchCustomList,
  customListFetchingComplete,
  customListItemSaved,
  fetchingListItemData,
  fetchingListItems,
  fetchListItems,
  fetchingItemData,
  fetchItemData,
  initListItemData,
  initCustomListData,
  fetchingParentList,
  fetchparentList,
  parentListFetchingComplete,
  fetchingChildList,
  fetchChildList,
} from "../store/actions";

import {
  INIT_CUSTOM_LIST_FETCH,
  INIT_DELETE_CUSTOM_LIST_ITEM,
  SAVE_CUSTOM_LIST_ITEM,
  INIT_FETCH_CUSTOM_LIST_ITEMS_BY_ID,
  INIT_LIST_ITEMS_FETCH,
  INIT_ITEMS_FETCH,
  DELETE_ITEM,
  SAVE_ITEM_DATA,
  DELETE_ITEM_LANGUAGE,
  INIT_PARENT_LIST_FETCH,
  INIT_CHILD_LIST_FETCH,
} from "./actionTypes";
import axios from "../../../config/axios";

export default function* watchCustomListFetch() {
  yield all([
    takeEvery(INIT_CUSTOM_LIST_FETCH, getCustomListDataSaga),
    takeEvery(INIT_DELETE_CUSTOM_LIST_ITEM, deleteCustomListDataSaga),
    takeEvery(SAVE_CUSTOM_LIST_ITEM, saveCustomListDataSaga),
    takeEvery(INIT_FETCH_CUSTOM_LIST_ITEMS_BY_ID, getCustomListDataByIdSaga),
    takeEvery(INIT_LIST_ITEMS_FETCH, getListItemsDataSaga),
    takeEvery(INIT_ITEMS_FETCH, getItemDataSaga),
    takeEvery(DELETE_ITEM, deleteItemSaga),

    takeEvery(SAVE_ITEM_DATA, saveSaga),
    takeEvery(DELETE_ITEM_LANGUAGE, deleteItemLanguageSaga),
    takeEvery(INIT_PARENT_LIST_FETCH, getParentListSaga),
    takeEvery(INIT_CHILD_LIST_FETCH, getChildListSaga),
  ]);
}

function* getCustomListDataSaga() {
  yield put(fetchingCustomList());
  const url = "/CustomList/list";
  try {
    const response = yield call(axios, url, { method: "GET" });
    yield put(fetchCustomList(response.data));
    yield put(customListFetchingComplete());
  } catch (error) {
    yield put(customListFetchingComplete());
  }
}
function* deleteCustomListDataSaga(action) {
  const url = "/CustomList/delete";
  const requestBody = {
    listOfIds: action.payload.id.toString(),
    userId: action.payload.userId,
  };

  try {
    const response = yield call(axios, url, {
      method: "PUT",
      data: requestBody,
    });
    if (response && response.status === 201) {
      const notification = buildNotification({
        message: "notification.customList.itemDeleted",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
      yield put(initCustomListData());
    }
  } catch (error) {
    action.callback && action.callback();
  }
}
function* saveCustomListDataSaga(action) {
  try {
    const response = yield call(axios, "/CustomList/save", {
      method: "POST",
      data: action.data,
    });
    if (response && response.status === 202) {
      yield put(customListItemSaved());
      if (action.saveSuccess) {
        action.saveSuccess(response.status);
      }
      const notification = buildNotification({
        message: "notification.customList.itemCreated",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
      yield put(initCustomListData());
    }
  } catch (error) {
    action.saveSuccess && action.saveSuccess();
  }
}
function* getCustomListDataByIdSaga(action) {
  yield put(fetchingListItemData());
  const url = `/CustomList/item?customListID=${action.id}`;
  try {
    const response = yield call(axios, url, { method: "GET" });
    if (response) {
      if (action.callback) {
        action.callback(response.data);
      }
    }
  } catch (error) {
    action.callback && action.callback();
  }
}
function* getListItemsDataSaga(action) {
  yield put(fetchingListItems());
  const url = "/CustomListItem/listkeys?customListId=" + action.custId;
  try {
    const response = yield call(axios, url, { method: "GET" });
    if (response) {
      yield put(fetchListItems(response.data));
    }
  } catch (error) {
    action.callback && action.callback();
  }
}

function* getItemDataSaga(action) {
  yield put(fetchingItemData());
  const url = `/CustomListItem/listkey?id=${action.id}`;
  try {
    const response = yield call(axios, url, { method: "GET" });
    if (response) {
      yield put(fetchItemData(response.data));
      if (action.callback) {
        action.callback(response.data);
      }
    }
  } catch (error) {
    action.callback && action.callback();
  }
}

function* deleteItemSaga(action) {
  const requestBody = {
    listOfIds: action.data.id.toString(),
    userId: action.data.userId,
  };
  const url = "/CustomListItem/deletekey";
  try {
    const response = yield call(axios, url, {
      method: "PUT",
      data: requestBody,
    });
    if (response && response.status === 201) {
      const notification = buildNotification({
        message: "notification.customList.itemDeleted",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
    }
    yield put(initListItemData(action.data.custId));
  } catch (error) {
    action.callback && action.callback();
  }
}

function* saveSaga(action) {
  let sessionDetails = JSON.parse(sessionStorage.getItem("sessionDetails"));
  let userId = sessionDetails.userId;
  const url = "/CustomListItem/savelistkey";
  let saveData = {
    id: action.data.id,
    key: action.data.key,
    customListID: action.data.custId,
    rank: parseInt(action.data.sortOrder),
    customListItems: [],
    customListItemParentID: action.data.customListParentID
      ? action.data.customListParentID
      : 0,
    userId: userId,
  };
  for (let i = 0; i < action.data.language.length; i++) {
    saveData.customListItems.push({
      languageID: action.data.language[i].langKey,
      label: action.data.language[i].label,
      description: action.data.language[i].description,
    });
  }

  const response = yield call(axios, url, { method: "POST", data: saveData });
  try {
    if (response && response.status === 202) {
      const notification = buildNotification({
        message: "notification.customList.itemCreated",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
      yield put(initListItemData(action.data.custId));
      if (action.callback) {
        action.callback(response.status);
      }
    }
  } catch (error) {
    action.callback && action.callback();
  }
}

function* deleteItemLanguageSaga(action) {
  let sessionDetails = JSON.parse(sessionStorage.getItem("sessionDetails"));
  let userId = sessionDetails.userId;
  const requestBody = {
    listOfIds: action.langKey,
    id: action.itemId,
    userId: userId,
  };
  const url = "/CustomListItem/deleteitemLabel";
  try {
    const response = yield call(axios, url, {
      method: "PUT",
      data: requestBody,
    });
    if (response.status === 201) {
      const notification = buildNotification({
        message: "notification.customList.langDeleted",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
      if (action.callback) {
        return action.callback();
      }
    }
  } catch (error) {
    action.callback && action.callback();
  }
}
function* getParentListSaga(action) {
  yield put(fetchingParentList());
  const url = "/CustomList/ParentCustomList";
  try {
    const response = yield call(axios, url, { method: "GET" });
    if (response.status === 200 || 204) {
      yield put(fetchparentList(response.data));
      yield put(parentListFetchingComplete());
    }
  } catch (error) {
    action.callback && action.callback();
  }
}
function* getChildListSaga(action) {
  yield put(fetchingChildList());
  const url = "/CustomListItem/listkeys?customListId=" + action.custId;
  try {
    const response = yield call(axios, url, { method: "GET" });
    if (response) {
      yield put(fetchChildList(response.data));
    }
  } catch (error) {
    action.callback && action.callback();
  }
}
