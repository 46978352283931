import {
  INIT_FETCH_USER_GROUP_LIST,
  FETCHING_USER_GROUP_LIST,
  USER_GROUP_LIST,
  USER_GROUP_LIST_FETCHING_COMPLETED,
  INIT_GET_BASE_ROLE,
  FETCHING_GET_BASE_ROLE,
  FETCH_BASE_ROLE,
  GET_BASE_ROLE_COMPLETED,
  INIT_USER_GROUP_SAVE,
  SAVE_USER_GROUP,
  FORM_SUBMISSION_STATUS,
  RESET_FORM_SUBMISSION_STATUS,
  INIT_USER_GROUP_DELETE,
  DELETE_SUCCESS,
  DELETE_FAILED,
  FETCHING_SELECT_USERS_LIST,
  SELECT_USERS_LIST,
  SELECT_USERS_LIST_FETCHING_COMPLETED,
  INIT_SELECT_USERS_LIST,
  INIT_USER_GROUP_EDIT,
  SET_USER_GROUP_EDIT_DATA,
  SAVE_UPDATED_GROUP_STATUS,
  INIT_UPDATE_GROUP_STATUS,
  SAVE_USER_GROUP_FAILED,
  SET_USER_GROUP_EDIT_DATA_FAILED,
} from "./actionTypes";

export const initUserGroupList = () => {
  return {
    type: INIT_FETCH_USER_GROUP_LIST,
  };
};
export const fetchingUserGroup = () => {
  return {
    type: FETCHING_USER_GROUP_LIST,
  };
};

export const userGroupList = (data) => {
  return {
    type: USER_GROUP_LIST,
    data: data,
  };
};

export const userGroupFetchingComplete = () => {
  return {
    type: USER_GROUP_LIST_FETCHING_COMPLETED,
  };
};

export const initBaseRoleList = () => {
  return {
    type: INIT_GET_BASE_ROLE,
  };
};
export const fetchingBaseRoleList = () => {
  return {
    type: FETCHING_GET_BASE_ROLE,
  };
};

export const fetchBaseRoleList = (data) => {
  return {
    type: FETCH_BASE_ROLE,
    data: data,
  };
};

export const baseRoleFetchingComplete = () => {
  return {
    type: GET_BASE_ROLE_COMPLETED,
  };
};

export const initUserGroupSave = (
  formdata,
  userId,
  isEditMode,
  saveSuccess
) => {
  return {
    type: INIT_USER_GROUP_SAVE,
    formdata,
    userId,
    isEditMode,
    saveSuccess,
  };
};

export const userGroupSave = () => {
  return {
    type: SAVE_USER_GROUP,
  };
};
export const userGroupSaveFailed = () => {
  return {
    type: SAVE_USER_GROUP_FAILED,
  };
};
export const formSubmissionStatusAction = (data) => {
  return {
    type: FORM_SUBMISSION_STATUS,
    data: data,
  };
};
export const resetFormSubmissionStatus = () => {
  return {
    type: RESET_FORM_SUBMISSION_STATUS,
  };
};

export const initUserGroupDelete = (id, userId, deleteSuccess) => {
  return {
    type: INIT_USER_GROUP_DELETE,
    id,
    userId,
    callback: deleteSuccess,
  };
};
export const deleteSuccess = (id) => {
  return {
    type: DELETE_SUCCESS,
    id,
  };
};
export const deleteFailed = () => {
  return {
    type: DELETE_FAILED,
  };
};

export const initSelectUsersList = (roleID) => {
  return {
    type: INIT_SELECT_USERS_LIST,
    roleID,
  };
};
export const fetchingSelectUsersList = () => {
  return {
    type: FETCHING_SELECT_USERS_LIST,
  };
};

export const fetchSelectUsersList = (data) => {
  return {
    type: SELECT_USERS_LIST,
    data: data,
  };
};

export const selectUsersFetchingComplete = () => {
  return {
    type: SELECT_USERS_LIST_FETCHING_COMPLETED,
  };
};

export const getUserGroupDataForEdit = (id, fetchSuccess) => {
  return {
    type: INIT_USER_GROUP_EDIT,
    id,
    callback: fetchSuccess,
  };
};
export const setUserGroupDataForEdit = (data) => {
  return {
    type: SET_USER_GROUP_EDIT_DATA,
    data,
  };
};
export const setUserGroupDataForEditFailed = (data) => {
  return {
    type: SET_USER_GROUP_EDIT_DATA_FAILED,
  };
};

export const initUpdateUserGroupStatus = (formData, updateSuccess) => {
  return {
    type: INIT_UPDATE_GROUP_STATUS,
    formData,
    updateSuccess,
  };
};

export const saveUpdatedUserGroupStatus = () => {
  return {
    type: SAVE_UPDATED_GROUP_STATUS,
  };
};
