import { call, put, all, takeEvery } from "redux-saga/effects";

import { buildNotification } from "../../../config/notification";
import { store } from "react-notifications-component";
import {
  GET_FIELDS_URL,
  GET_FIELD_DEFINITIONS_ACTION,
  GET_PAYMENTS_URL,
  INIT_GET_REMITTANCE_BY_DOC_NO,
  INIT_GET_REMITTANCE_BY_ID,
  PAYMENTS_DETAILS_REQUEST,
  PAY_INVOICE_DETAILS_REQUEST,
} from "./constants";

import {
  updatePaymentsDetailsAction,
  updateInvoiceDetailsAction,
  setRemittanceDataByDocNo,
  setRemittanceDataById,
} from "./actions";

import axios from "../../../config/axios";
import { GET_REMITTANCE_URL } from "../../InvoiceAndCredits/store/actionTypes";

const getRequestDetails = {
  method: "GET",
  cache: "no-cache",
};
const postRequestDetails = {
  method: "POST",
  cache: "no-cache",
  headers: {
    "Content-Type": "application/json",
  },
};

export default function* rootPaymentsSaga() {
  yield all([
    takeEvery(GET_FIELD_DEFINITIONS_ACTION, getFieldDefinitions),
    takeEvery(PAYMENTS_DETAILS_REQUEST, getPaymentsDetails),
    takeEvery(PAY_INVOICE_DETAILS_REQUEST, getInvoiceDetails),
    takeEvery(INIT_GET_REMITTANCE_BY_DOC_NO, getRemittanceByDocNoSaga),
    takeEvery(INIT_GET_REMITTANCE_BY_ID, getRemittanceByIdSaga),
  ]);
}

function* getFieldDefinitions(action) {
  let sessionDetails =
    JSON.parse(sessionStorage.getItem("sessionDetails")) || {};
  let languageID =
    sessionDetails &&
    sessionDetails.languageId &&
    sessionDetails.languageId !== ""
      ? sessionDetails.languageId
      : "enGB";
  try {
    const param = action && action.paymentParam;
    const URL =
      GET_FIELDS_URL +
      "?findInInvoice=" +
      param.findInInvoices +
      "&lanKey=" +
      languageID;
    const response = yield call(axios, URL, getRequestDetails);

    if (response === [] || response == null || response === undefined) {
      if (action.callBackFunction) {
        action.callBackFunction();
      }
    } else {
      if (action.callBackFunction) {
        action.callBackFunction(response.data);
      }
    }
  } catch (err) {
    action.callBackFunction && action.callBackFunction();
  }
}

function* getPaymentsDetails(action) {
  var paymentsDetails = [];
  try {
    postRequestDetails.data = action.body;
    const response = yield call(axios, GET_PAYMENTS_URL, postRequestDetails);

    paymentsDetails = response.data || [];
    yield put(updatePaymentsDetailsAction(paymentsDetails));
  } catch (err) {
    yield put(updatePaymentsDetailsAction([]));
  }
}

function* getInvoiceDetails(action) {
  let sessionDetails =
    JSON.parse(sessionStorage.getItem("sessionDetails")) || {};
  let languageID =
    sessionDetails &&
    sessionDetails.languageId &&
    sessionDetails.languageId !== ""
      ? sessionDetails.languageId
      : "enGB";
  try {
    const URL =
      GET_REMITTANCE_URL +
      "?invoiceID=" +
      action.param +
      "&LanguageID=" +
      languageID;
    const response = yield call(axios, URL, getRequestDetails);
    if (response && response.status === 200) {
      yield put(updateInvoiceDetailsAction(response.data));
      if (action.callback) {
        action.callback();
      }
    }
    if (response && response.status === 204) {
      const notification = buildNotification({
        message: "notification.noDataDisplayLabel",
        type: "danger",
      });
      store.addNotification({
        ...notification,
      });
    }
  } catch (err) {
    yield put(updateInvoiceDetailsAction([]));
  }
}

function* getRemittanceByDocNoSaga(action) {
  let sessionDetails =
    JSON.parse(sessionStorage.getItem("sessionDetails")) || {};
  let languageID =
    sessionDetails &&
    sessionDetails.languageId &&
    sessionDetails.languageId !== ""
      ? sessionDetails.languageId
      : "enGB";
  let URL =
    GET_REMITTANCE_URL +
    "?RemittanceDocNum=" +
    action.docNo +
    "&LanguageID=" +
    languageID;
  try {
    const response = yield call(axios, URL, getRequestDetails);
    if (response && response.status === 200) {
      yield put(setRemittanceDataByDocNo(response.data));
      if (action.callback) {
        action.callback();
      }
    }
    if (response && response.status === 204) {
      const notification = buildNotification({
        message: "notification.noDataDisplayLabel",
        type: "danger",
      });
      store.addNotification({
        ...notification,
      });
    }
  } catch (err) {
    action.callback && action.callback();
  }
}

function* getRemittanceByIdSaga(action) {
  let sessionDetails =
    JSON.parse(sessionStorage.getItem("sessionDetails")) || {};
  let languageID =
    sessionDetails &&
    sessionDetails.languageId &&
    sessionDetails.languageId !== ""
      ? sessionDetails.languageId
      : "enGB";
  let URL =
    GET_REMITTANCE_URL +
    "?invoiceID=" +
    action.id +
    "&LanguageID=" +
    languageID;
  try {
    const response = yield call(axios, URL, getRequestDetails);
    if (response && response.status === 200) {
      yield put(setRemittanceDataById(response.data));
      if (action.callback) {
        action.callback();
      }
    }
    if (response && response.status === 204) {
      const notification = buildNotification({
        message: "notification.noDataDisplayLabel",
        type: "danger",
      });
      store.addNotification({
        ...notification,
      });
    }
  } catch (err) {
    action.callback && action.callback();
  }
}
