
export const INIT_HELPDESK_OPENCASES_HISTORY_FETCH =
  "INIT_HELPDESK_OPENCASES_HISTORY_FETCH";
  export const FETCHING_CASES = "FETCHING_CASES";

  export const FETCH_CASES_SUCCESS = "FETCH_CASES_SUCCESS";
export const FETCH_CASES_FAILURE = "FETCH_CASES_FAILURE";
export const UPDATE_HELPDESK_CASES = "UPDATE_HELPDESK_CASES";
export const INIT_FETCH_COMPLETE = "INIT_FETCH_COMPLETE";


export const FETCH_ADDITIONAL_FIELDS = "FETCH_ADDITIONAL_FIELDS";


export const FETCH_ATTACHMENT = "FETCH_ATTACHMENT";
export const DOWNLOADED_ATTACHMENT = "DOWNLOADED_ATTACHMENT";

export const SUBMIT_REQUEST = "SUBMIT_REQUEST";
export const SUBMIT_SUCCESS = "SUBMIT_SUCCESS";
export const SUBMIT_FAILURE = "SUBMIT_FAILURE";

export const QUERY_TYPE_LIST = "QUERY_TYPE_LIST";
export const FETCH_QUERY_TYPE_LIST = "FETCH_QUERY_TYPE_LIST";

export const INIT_FETCH_QUERY_FIELDS = "INIT_FETCH_QUERY_FIELDS";
export const FETCHING_QUERY_FIELDS = "FETCHING_QUERY_FIELDS";
export const QUERY_FIELDS_LIST = "QUERY_FIELDS_LIST";
export const QUERY_FIELDS_FETCH_COMPLETED = "QUERY_FIELDS_FETCH_COMPLETED";

export const FETCHING_HELPDESK_DETAILS_BY_ID =
  "FETCHING_HELPDESK_DETAILS_BY_ID";
export const FETCH_HELPDESK_TLOG = "FETCH_HELPDESK_TLOG";
export const FETCH_HELPDESK_TLOG_SUCCESS = "FETCH_HELPDESK_TLOG_SUCCESS";
export const FETCH_HELPDESK_TLOG_FAILURE = "FETCH_HELPDESK_TLOG_FAILURE";
export const FETCH_INDIVIDUAL_CASE = "FETCH_INDIVIDUAL_CASE";
export const FETCHING_HELPDESK_DETAILS_BY_ID_COMPLETED =
  "FETCHING_HELPDESK_DETAILS_BY_ID_COMPLETED";
export const RESET_FORM_SUBMISSION_STATUS = "RESET_FORM_SUBMISSION_STATUS";

export const INIT_SAVE_COMMENT = "INIT_SAVE_COMMENT";
export const COMMENT_SAVED = "COMMENT_SAVED";
export const COMMENT_SAVE_FAILED = "COMMENT_SAVE_FAILED";
export const RESET_COMMENT_SAVE_STATUS = "RESET_COMMENT_SAVE_STATUS";
export const INIT_SERVICE_NOW_SAVE_COMMENT = "INIT_SERVICE_NOW_SAVE_COMMENT";
export const SERVICE_NOW_COMMENT_SAVE_FAILED = "SERVICE_NOW_COMMENT_SAVE_FAILED";
export const SERVICE_NOW_COMMENT_SAVED = "SERVICE_NOW_COMMENT_SAVED";


export const GET_COMMENTS = "INIT_GET_COMMENTS";
export const FETCH_COMMENT = "FETCH_COMMENT";
export const FETCHING_COMMENTS_END = "FETCHING_COMMENTS_END";

export const INIT_ADD_ATTACHMENT = "INIT_ADD_ATTACHMENT";
export const ADDING_ATTACHMENT = "ADDING_ATTACHMENT";
export const ATTACHMENT_ADDED = "ATTACHMENT_ADDED";
export const ATTACHMENT_ADDED_COMPLETED = "ATTACHMENT_ADDED_COMPLETED";



export const INIT_HELPDESK_OPEN_REQUEST_MARK_AS_COMPLETE =
  "INIT_HELPDESK_OPEN_REQUEST_MARK_AS_COMPLETE";
export const SAVED_HELPDESK_OPEN_REQUEST_MARK_AS_COMPLETE =
  "SAVED_HELPDESK_OPEN_REQUEST_MARK_AS_COMPLETE";

export const INIT_GET_HELPDESK_QUERY_TYPE = "INIT_GET_HELPDESK_QUERY_TYPE";
export const FETCHING_GET_HELPDESK_QUERY_TYPE =
  "FETCHING_GET_HELPDESK_QUERY_TYPE";
export const FETCH_GET_HELPDESK_QUERY_TYPE = "FETCH_GET_HELPDESK_QUERY_TYPE";
export const GET_HELPDESK_QUERY_TYPE_COMPLETED =
  "GET_HELPDESK_QUERY_TYPE_COMPLETED";


  export const INIT_GET_SERVICE_QUERY_BY_ID = "INIT_GET_SERVICE_QUERY_BY_ID";
export const FETCHING_GET_SERVICE_QUERY_BY_ID =
  "FETCHING_GET_SERVICE_QUERY_BY_ID";
export const FETCH_GET_SERVICE_QUERY_BY_ID = "FETCH_GET_SERVICE_QUERY_BY_ID";
export const GET_SERVICE_QUERY_BY_ID_COMPLETED =
  "GET_SERVICE_QUERY_BY_ID_COMPLETED";
