import {
  SAVE_REPORT_DETAILS,
  FORM_SUBMISSION_STATUS,
  RESET_FORM_SUBMISSION_STATUS,
  INIT_REPORT_DETAILS_LIST,
  FETCH_REPORT_DETAILS_LIST,
  FETCH_REPORT_DETAILS_LIST_COMPLETED,
  FETCHING_REPORT_DETAILS_LIST,
  INIT_FREQUENCY_LIST_FETCH,
  FETCHING_FREQUENCY_LIST,
  FETCH_FREQUENCY_LIST,
  FREQUENCY_LIST_FETCH_COMPLETE,
  INIT_CURRENCY_LIST_FETCH,
  FETCHING_CURRENCY_LIST,
  FETCH_CURRENCY_LIST,
  CURRENCY_LIST_FETCH_COMPLETE,
  INIT_REPORT_DETAILS_BY_ID,
  FETCHING_REPORT_DETAILS_BY_ID,
  FETCH_REPORT_DETAILS_BY_ID,
  FETCH_REPORT_DETAILS_BY_ID_COMPLETED,
  INIT_REPORT_DETAILS_SAVE,
  INIT_DELETE_REPORT_CONFIG,
  DELETE_SUCCESS,
  DELETE_FAILED,
  INIT_UPDATE_REPORT_STATUS,
  SAVE_UPDATED_REPORT_STATUS,
  UPDATE_REPORT_DETAILS_LIST,
  FETCH_USER_LIST_COMPLETED,
  FETCHING_USER_LIST,
  FETCH_USER_LIST,
  INIT_USER_LIST,
  ADMIN_ROLE_DETAILS,
} from "./actionTypes";

export const initFrqeuencyDropdownList = () => {
  return {
    type: INIT_FREQUENCY_LIST_FETCH,
  };
};
export const fetchingFrqeuencyDropdownList = () => {
  return {
    type: FETCHING_FREQUENCY_LIST,
  };
};

export const fetchFrqeuencyDropdownList = (data) => {
  return {
    type: FETCH_FREQUENCY_LIST,
    data: data,
  };
};

export const frequencyFetchingComplete = () => {
  return {
    type: FREQUENCY_LIST_FETCH_COMPLETE,
  };
};

export const initCurrencyDropdownList = () => {
  return {
    type: INIT_CURRENCY_LIST_FETCH,
  };
};
export const fetchingCurrencyDropdownList = () => {
  return {
    type: FETCHING_CURRENCY_LIST,
  };
};

export const fetchCurrencyDropdownList = (data) => {
  return {
    type: FETCH_CURRENCY_LIST,
    data: data,
  };
};

export const currencyFetchingComplete = () => {
  return {
    type: CURRENCY_LIST_FETCH_COMPLETE,
  };
};

export const initSaveReportDetailsData = (
  formData,
  isEditMode,
  saveSuccess
) => {
  return {
    type: INIT_REPORT_DETAILS_SAVE,
    data: formData,
    isEditMode,
    saveSuccess,
  };
};
export const saveReportDetailsData = () => {
  return {
    type: SAVE_REPORT_DETAILS,
  };
};
export const initUpdateReportStatus = (formData, updateSuccess) => {
  return {
    type: INIT_UPDATE_REPORT_STATUS,
    formData,
    updateSuccess,
  };
};
export const saveUpdatedReportStatus = () => {
  return {
    type: SAVE_UPDATED_REPORT_STATUS,
  };
};
export const formSubmissionStatusAction = (data) => {
  return {
    type: FORM_SUBMISSION_STATUS,
    data: data,
  };
};
export const resetFormSubmissionStatus = () => {
  return {
    type: RESET_FORM_SUBMISSION_STATUS,
  };
};

// Report details list
export const initReportDetailsList = (baseRoleId, data) => {
  return {
    type: INIT_REPORT_DETAILS_LIST,
    baseRoleId,
    data,
  };
};
export const fetchingReportDetailsList = () => {
  return {
    type: FETCHING_REPORT_DETAILS_LIST,
  };
};
export const fetchReportDetailsList = (data) => {
  return {
    type: FETCH_REPORT_DETAILS_LIST,
    data: data,
  };
};
export const updateReportDetailsList = (data) => {
  return {
    type: UPDATE_REPORT_DETAILS_LIST,
    data: data,
  };
};
export const fetchReportDetailsListCompleted = () => {
  return {
    type: FETCH_REPORT_DETAILS_LIST_COMPLETED,
  };
};

export const initReportDetailsbyID = (
  invoiceReportID,
  reportUserID,
  baseRoleId,
  fetchSuccess
) => {
  return {
    type: INIT_REPORT_DETAILS_BY_ID,
    invoiceReportID,
    reportUserID,
    baseRoleId,
    callback: fetchSuccess,
  };
};
export const fetchingReportDetailsbyID = () => {
  return {
    type: FETCHING_REPORT_DETAILS_BY_ID,
  };
};
export const fetchReportDetailsbyID = (data) => {
  return {
    type: FETCH_REPORT_DETAILS_BY_ID,
    data: data,
  };
};
export const fetchReportDetailsbyIDCompleted = () => {
  return {
    type: FETCH_REPORT_DETAILS_BY_ID_COMPLETED,
  };
};

export const initDeleteReportConfig = (id, userId, deleteSuccess) => {
  return {
    type: INIT_DELETE_REPORT_CONFIG,
    id,
    userId,
    deleteSuccess,
  };
};
export const deleteSuccess = (id) => {
  return {
    type: DELETE_SUCCESS,
    id,
  };
};
export const deleteFailed = () => {
  return {
    type: DELETE_FAILED,
  };
};

export const initUserList = (data) => {
  return {
    type: INIT_USER_LIST,
    data,
  };
};

export const fetchUserList = (data) => {
  return {
    type: FETCH_USER_LIST,
    data,
  };
};

export const fetchingUserList = () => {
  return {
    type: FETCHING_USER_LIST,
  };
};

export const fetchUserListComplete = () => {
  return {
    type: FETCH_USER_LIST_COMPLETED,
  };
};
export const adminRoleDetails = (selectedUserId, isRoleAdmin) => {
  return {
    type: ADMIN_ROLE_DETAILS,
    selectedUserId,
    isRoleAdmin,
  };
};
