import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { getNavigatorLanguage, getRoutePathByKey } from "../../../helper";

import {
  IconButton,
  IconWrapper,
  LogoText,
  MyAccount,
  SiteActions,
  VerticalLine,
} from "./style";

import { headerIcons } from "./config";
import { HeaderWrapper, Logo, UserMenuOption, UserMenuWrapper } from "./style";

import logo from "../../../assets/images/Header/Logo.png";
import userAccountIcon from "../../../assets/images/Header/UserIcon.png";

import { logout } from "../../../container/LoginPage/store/actions";
import { compose } from "redux";
import { flexGrow, flexRow } from "../style";
import Div from "../../Div";
import IMG from "../../IMG";
import { withRouter } from "react-router-dom";
import ChatBot from "../../../container/ChatBot";
import { myAccountURL } from "./config";
import { PublicClientApplication } from "@azure/msal-browser";

const Header = (props) => {
  const getStoredMsalConfig =
    JSON.parse(sessionStorage.getItem("configMsal")) || null;
  const isSsoLogin = JSON.parse(sessionStorage.getItem("isSsoLogin")) || null;
  const sessionDetails =
    JSON.parse(sessionStorage.getItem("sessionDetails")) || null;
  const configMsal =
    getStoredMsalConfig !== null &&
    new PublicClientApplication(getStoredMsalConfig);
  const viewHelp = getRoutePathByKey("viewHelp");
  const { t, i18n } = useTranslation();
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [showChatBot, setShowChatbot] = useState(false);
  const onLogout = () => {
    if (isSsoLogin !== null) {
      configMsal.logoutRedirect();
      sessionStorage.removeItem("configMsal");
      sessionStorage.removeItem("isSsoLogin");
    }
    props.logout(() => {
      const lang = getNavigatorLanguage();
      i18n.changeLanguage(lang);
    });
  };

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowUserMenu(false);
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };
  const handleOptionClick = (event) => {
    setShowUserMenu(false);
    event();
  };

  const handleToolEvents = (event) => {
    if (event === "toggleChatbot") {
      setShowChatbot(!showChatBot);
    } else if (event === "gotoViewHelp") {
      return props.history.push({
        pathname: viewHelp,
      });
    }
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <HeaderWrapper id="header">
      <Div {...flexRow} {...flexGrow} margin="4px 0 4px 34px">
        <Div {...flexRow} {...flexGrow}>
          <Logo to="/">
            <IMG src={logo} alt="My Supplier Portal Home" />

            <LogoText margin="0 0 0 18px">My Supplier Portal</LogoText>
          </Logo>
        </Div>

        <SiteActions>
          {headerIcons
            ? headerIcons.map((header, key) => (
                <IconWrapper
                  tabIndex="-1"
                  key={key}
                  onClick={() => handleToolEvents(header.action)}
                >
                  <IconButton tabIndex="0">
                    <IMG src={header.icon} alt={header.alt} />
                  </IconButton>
                </IconWrapper>
              ))
            : null}
          {/* <ChatBot show={showChatBot} onClose={() => setShowChatbot(false)} /> */}
        </SiteActions>

        <VerticalLine />
        <MyAccount tabIndex="-1">
          <IconButton
            tabIndex="0"
            onClick={() => setShowUserMenu(!showUserMenu)}
          >
            <IMG src={userAccountIcon} alt="User Profile" />
          </IconButton>
        </MyAccount>
        {showUserMenu ? (
          <UserMenuWrapper ref={wrapperRef}>
            <UserMenuOption
              onClick={() => handleOptionClick(props.handleModal)}
            >
              {t("changePassword.changePasswordLabel")}
            </UserMenuOption>
            <UserMenuOption
              onClick={() =>
                handleOptionClick(() => {
                  props.history.push({
                    pathname: myAccountURL,
                    state: {
                      fromMyAccnt: true,
                      userId: sessionDetails && sessionDetails.userId,
                    },
                  });
                })
              }
            >
              {t("common.myAccount")}
            </UserMenuOption>
            <UserMenuOption onClick={onLogout}>
              {t("common.logout", " ... ")}
            </UserMenuOption>
          </UserMenuWrapper>
        ) : null}
      </Div>
    </HeaderWrapper>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: (callback) => {
      dispatch(logout(callback));
    },
  };
};

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, withRouter)(Header);
