import React from "react";

import PrivacyStatementModal from "../../Popup";
import { privacyStatementContent } from "./PrivacyStatementContent";
import { useTranslation } from "react-i18next";

const PrivacyStatement = (props) => {
  const { t } = useTranslation();
  let content = t(privacyStatementContent.body);
  return (
    <PrivacyStatementModal
      modalWidth="80%"
      containerHeight="auto"
      header={t("common.privacyStatementTitle")}
      body={content}
      pPadding="0 0 20px 0"
      show={props.show}
      headerColor="#ffffff"
      close={props.close}
    />
  );
};

export default PrivacyStatement;
