import {
  USER_DETAILS_SUCCESS,
  USER_DETAILS_LIST,
  FETCH_USER_ROLE_LIST,
  SUBMIT_USER_DATA,
  USER_DETAILS_UPDATE_DATA,
  UPDATE_SHOW_USER_FILTER_ACTION,
  GET_USER_STATUS_ACTION,
  FETCH_USER_STATUS,
  GET_SINGLE_USER_DATA_ACTION,
  UPDATE_SINGLE_USER_DATA_ACTION,
  USER_DATA_TO_DELETE,
  RESET_FORM_SUBMISSION_STATUS,
  FORM_SUBMISSION_STATUS,
  SET_SINGLE_USER_DATA_ACTION,
  INIT_USER_ROLE_LIST,
  INIT_REGIONAL_SETTINGS,
  FETCH_REGIONAL_SETTINGS,
  INIT_CHECK_FOR_EXISTING_USER,
  USER_EXIST,
  SUBMIT_USER_DATA_STATUS,
  INIT_UPDATE_SECURITY_QUESTION,
} from "./actionTypes";

export function updateUserListAction(userList) {
  return {
    type: USER_DETAILS_SUCCESS,
    userList,
  };
}

export const resetFormSubmissionStatus = () => {
  return {
    type: RESET_FORM_SUBMISSION_STATUS,
  };
};

export const formSubmissionStatusAction = (data) => {
  return {
    type: FORM_SUBMISSION_STATUS,
    data: data,
  };
};

export function getUserDetailsAction(data, pageConfig) {
  return {
    type: USER_DETAILS_LIST,
    data,
    pageConfig,
  };
}

export function updateUserDataAction(userData) {
  return {
    type: USER_DETAILS_UPDATE_DATA,
    userData,
  };
}

export function submitUserDataAction(userCreationData) {
  return {
    type: SUBMIT_USER_DATA,
    userCreationData,
  };
}

export const submitUserDataStatus = (boolean) => {
  return {
    type: SUBMIT_USER_DATA_STATUS,
    boolean,
  };
};

export function updateShowFilterAction(showFilter) {
  return {
    type: UPDATE_SHOW_USER_FILTER_ACTION,
    showFilter,
  };
}

export function getUserStatusAction() {
  return {
    type: GET_USER_STATUS_ACTION,
  };
}

export function fetchUserStatus(userStatus) {
  return {
    type: FETCH_USER_STATUS,
    userStatus,
  };
}

export function getSingleUserDataAction(userId, callBackFunction, myAccntFlag) {
  return {
    type: GET_SINGLE_USER_DATA_ACTION,
    userId,
    callBackFunction,
    myAccntFlag,
  };
}

export function setSingleUserDataAction(editData) {
  return {
    type: SET_SINGLE_USER_DATA_ACTION,
    editData,
  };
}

export function updateSingleUserDataAction(singleUserData) {
  return {
    type: UPDATE_SINGLE_USER_DATA_ACTION,
    singleUserData,
  };
}

export function DeleteUserDataAction(userDataToDelete, callBackFunction) {
  return {
    type: USER_DATA_TO_DELETE,
    userDataToDelete,
    callBackFunction,
  };
}

export const initUserRole = () => {
  return {
    type: INIT_USER_ROLE_LIST,
  };
};

export function fetchUserRoleDropdownList(userRoleList) {
  return {
    type: FETCH_USER_ROLE_LIST,
    userRoleList,
  };
}

export const initRegionalSettings = () => {
  return {
    type: INIT_REGIONAL_SETTINGS,
  };
};

export const fetchRegionalSettings = (data) => {
  return {
    type: FETCH_REGIONAL_SETTINGS,
    data,
  };
};

export const initCheckExistingUser = (data, callback) => {
  return {
    type: INIT_CHECK_FOR_EXISTING_USER,
    data,
    callback,
  };
};

export const userExist = (data) => {
  return {
    type: USER_EXIST,
    data,
  };
};

export const updateSecurityQuestion = (payload) => {
  return {
    type: INIT_UPDATE_SECURITY_QUESTION,
    payload,
  };
};
