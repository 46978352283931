import {
  INIT_FILE_TYPE,
  FETCHING_FILE_TYPE,
  FETCH_FILE_TYPE,
  FILE_TYPE_FETCH_COMPLETE,
  INIT_GET_RECENT_UPLOADED_FILES,
  FETCHING_RECENT_UPLOADED_FILES,
  FETCH_RECENT_UPLOADED_FILES_COMPLETE,
  FETCHED_RECENT_UPLOADED_FILES,
  INIT_USER_UPLOAD_SAVE,
  USER_UPLOAD_SAVED,
  USER_UPLOAD_SAVE_FAILED,
  DOWNLOAD_MASS_SUPPLIERS,
  DOWNLOAD_BULK_SUPPLIERS,
  INIT_USER_UPLOAD_FETCH,
  FETCHING_USER_UPLOAD,
  FETCH_USER_UPLOAD,
  USER_UPLOAD_FETCH_COMPLETE,
  INIT_SUPPLIER_UPLOAD_FETCH,
  FETCHING_SUPPLIER_UPLOAD,
  FETCH_SUPPLIER_UPLOAD,
  SUPPLIER_UPLOAD_FETCH_COMPLETE,
  DOWNLOAD_USER_UPLOAD,
  SEND_EMAIL,
  INIT_DOWNLOAD_RECENT_UPLOAD_FILE,
  DOWNLOADING_RECENT_UPLOAD_FILE,
  DOWNLOADED_RECENT_UPLOAD_FILE_COMPLETE,
  DOWNLOAD_RECENT_UPLOAD_FILE_FAILED,
  RESET_FORM_SAVED_STATE,
  INIT_GET_BULK_UPLOAD_TYPES,
  GET_BULK_UPLOAD_TYPES_COMPLETED,
  GET_BULK_UPLOAD_TYPES_FAILED,
  INIT_UPLOAD_FETCH,
  FETCHING_UPLOAD,
  FETCH_UPLOAD,
  UPLOAD_FETCH_COMPLETE,
} from "./actionTypes";
export const getFileType = () => {
  return {
    type: INIT_FILE_TYPE,
  };
};
export const fetchingFileType = () => {
  return {
    type: FETCHING_FILE_TYPE,
  };
};

export const fetchFileTpe = (data) => {
  return {
    type: FETCH_FILE_TYPE,
    data: data,
  };
};

export const fileTypeFetchingComplete = () => {
  return {
    type: FILE_TYPE_FETCH_COMPLETE,
  };
};
export const initUserUploadSave = (payload, callback) => {
  return {
    type: INIT_USER_UPLOAD_SAVE,
    payload,
    callback,
  };
};

export const userUploadSaved = () => {
  return {
    type: USER_UPLOAD_SAVED,
  };
};
export const userUploadSaveFailed = () => {
  return {
    type: USER_UPLOAD_SAVE_FAILED,
  };
};
export const downloadBulkSuppliers = (data) => {
  return {
    type: DOWNLOAD_BULK_SUPPLIERS,
    data,
  };
};
export const downloadMassSuppliers = (data) => {
  return {
    type: DOWNLOAD_MASS_SUPPLIERS,
    data,
  };
};
export const initUserUploadData = () => {
  return {
    type: INIT_USER_UPLOAD_FETCH,
  };
};
export const fetchingUserUpload = () => {
  return {
    type: FETCHING_USER_UPLOAD,
  };
};
export const fetchUserUpload = (data) => {
  return {
    type: FETCH_USER_UPLOAD,
    data: data,
  };
};
export const userUploadFetchingComplete = () => {
  return {
    type: USER_UPLOAD_FETCH_COMPLETE,
  };
};
export const downloadUserUpload = (attachmentId) => {
  return {
    type: DOWNLOAD_USER_UPLOAD,
    attachmentId,
  };
};
export const sendEmail = (fileDataID, callback) => {
  return {
    type: SEND_EMAIL,
    fileDataID,
    callback,
  };
};
export const initGetRecentUploadedFiles = () => {
  return {
    type: INIT_GET_RECENT_UPLOADED_FILES,
  };
};
export const fetchingRecentUploadedFiles = () => {
  return {
    type: FETCHING_RECENT_UPLOADED_FILES,
  };
};
export const fetchRecentUploadedFilesComplete = () => {
  return {
    type: FETCH_RECENT_UPLOADED_FILES_COMPLETE,
  };
};
export const fetchedRecentUploadedFiles = (data) => {
  return {
    type: FETCHED_RECENT_UPLOADED_FILES,
    data,
  };
};
export const initDownloadRecentUploadfile = (data) => {
  return {
    type: INIT_DOWNLOAD_RECENT_UPLOAD_FILE,
    data,
  };
};
export const downloadingRecentUploadFiles = () => {
  return {
    type: DOWNLOADING_RECENT_UPLOAD_FILE,
  };
};
export const downloadRecentUploadFilesComplete = () => {
  return {
    type: DOWNLOADED_RECENT_UPLOAD_FILE_COMPLETE,
  };
};
export const downloadRecentUploadFilesFailed = () => {
  return {
    type: DOWNLOAD_RECENT_UPLOAD_FILE_FAILED,
  };
};
export const resetFormSavedState = () => {
  return {
    type: RESET_FORM_SAVED_STATE,
  };
};
export const initGetBulkUploadTypes = () => {
  return {
    type: INIT_GET_BULK_UPLOAD_TYPES,
  };
};
export const getBulkUploadTypesCompleted = (data) => {
  return {
    type: GET_BULK_UPLOAD_TYPES_COMPLETED,
    data,
  };
};
export const getBulkUploadTypesFailed = () => {
  return {
    type: GET_BULK_UPLOAD_TYPES_FAILED,
  };
};
export const initSupplierUploadData = () => {
  return {
    type: INIT_SUPPLIER_UPLOAD_FETCH,
  };
};
export const fetchingSupplierUpload = () => {
  return {
    type: FETCHING_SUPPLIER_UPLOAD,
  };
};
export const fetchSupplierUpload = (data) => {
  return {
    type: FETCH_SUPPLIER_UPLOAD,
    data: data,
  };
};
export const supplierUploadFetchingComplete = () => {
  return {
    type: SUPPLIER_UPLOAD_FETCH_COMPLETE,
  };
};
export const initUploadData = (bulkUploadTypeID) => {
  return {
    type: INIT_UPLOAD_FETCH,
    bulkUploadTypeID,
  };
};
export const fetchingUpload = () => {
  return {
    type: FETCHING_UPLOAD,
  };
};
export const fetchUpload = (data) => {
  return {
    type: FETCH_UPLOAD,
    data: data,
  };
};
export const UploadFetchingComplete = () => {
  return {
    type: UPLOAD_FETCH_COMPLETE,
  };
};
