import {
  USER_CREATION_REQUEST_FETCH,
  USER_CREATION_REQUEST_COMPLETE,
  APPROVE_USER_REQUEST,
  REJECT_USER_REQUEST,
  UPDATE_SUBMIT_STATUS,
  CONFIRM_ACCOUNT,
  UPDATE_CONFIRM_STATUS,
  UPDATE_REQUEST_STATUS,
  GET_USER_BY_ID,
  FETCH_GET_USER_BY_ID,
  SAVE_USER_CREATION,
  INIT_DOWNLOAD_REQ_ATTACHMENT,
  UPDATE_LIST,
} from "./actionTypes";

export function getUserCreationRequestList(data) {
  return {
    type: USER_CREATION_REQUEST_FETCH,
    data,
  };
}

export function updateUserCreationRequest(
  requestList,
  statusCounts,
  totalCount
) {
  return {
    type: USER_CREATION_REQUEST_COMPLETE,
    requestList,
    statusCounts,
    totalCount,
  };
}

export function approveUserRequest(payload, approveSuccess) {
  return {
    type: APPROVE_USER_REQUEST,
    payload,
    callback: approveSuccess,
  };
}

export function rejectUserRequest(payload, rejectSuccess) {
  return {
    type: REJECT_USER_REQUEST,
    payload,
    callback: rejectSuccess,
  };
}

export function updateSubmitStatus(status) {
  return {
    type: UPDATE_SUBMIT_STATUS,
    status,
  };
}

export function confirmAccount(payload, callBackFunction) {
  return {
    type: CONFIRM_ACCOUNT,
    payload,
    callBackFunction,
  };
}

export function updateConfirmStatus(status) {
  return {
    type: UPDATE_CONFIRM_STATUS,
    status,
  };
}

export function updateRequestStatus(requestStatus) {
  return {
    type: UPDATE_REQUEST_STATUS,
    requestStatus,
  };
}

export function getUserById(companyDetailID, callBackFunction) {
  return {
    type: GET_USER_BY_ID,
    companyDetailID,
    callBackFunction,
  };
}

export const fetchUserByIDData = (data) => {
  return {
    type: FETCH_GET_USER_BY_ID,
    data,
  };
};

export const saveUserCreation = (data, callback) => {
  return {
    type: SAVE_USER_CREATION,
    data,
    callback,
  };
};

export const initDownloadReqAttachment = (id, fileName, callback) => {
  return {
    type: INIT_DOWNLOAD_REQ_ATTACHMENT,
    id,
    fileName,
    callback,
  };
};

export const updateList = (requestList, statusCounts, totalCount) => {
  return {
    type: UPDATE_LIST,
    requestList,
    statusCounts,
    totalCount,
  };
};
