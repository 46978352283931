import React, { useState } from "react";
import Div from "../Div";
import SearchList from "./SearchList";
import Select from "../Select";
const CheckList = props => {
  const style = {
    inputWidth: "100%",
    inputHeight: "200px",
    textColor: "rgba(0, 0, 0, 0.87)",
    textSize: "15px",
    family: "roboto-medium",
    ls: "0.14px",
    lineHeight: "27px"
  };
  const [list, setList] = useState(props.options);

  const filterList = (event, options) => {
    const { value } = event.target;
    const newList = options.map((elem, key) => {
      return {
        ...elem,
        exclude: elem.text.toLowerCase().indexOf(value.toLowerCase()) === -1
      };
    });

    setList(newList);
  };
  const onSelectOptions = e => {
    const optionValues = [...e.target.options]
      .filter(option => option.selected)
      .map(option => option.value);
    props.onSelect(optionValues);
  };

  return (
    <Div padding="5px">
      <SearchList onType={event => filterList(event, props.options)} />
      <Select
        {...style}
        placeholder="    "
        multiple
        onChange={onSelectOptions}
        options={list}
      />
    </Div>
  );
};

export default CheckList;
