import { put, takeEvery, call, all } from "redux-saga/effects";
import { buildNotification } from "../../../../config/notification";
import { store } from "react-notifications-component";
import {
  workflowFetching,
  fetchWorkflow,
  workflowFetchComplete,
  workflowSaved,
  workflowSaveFailed,
  setDataForEdit,
  deleteSuccess,
  deletFailed,
  conditionListFetching,
  fetchConditionList,
  conditionListFetchComplete,
} from "../store/actions";

import {
  INIT_WORKFLOW_FETCH,
  INIT_WORKFLOW_SAVE,
  INIT_WORKFLOW_EDIT,
  INIT_WORKFLOWS_DELETE,
  INIT_CONDITION_LIST_FETCH,
} from "./actionTypes";
import axios from "../../../../config/axios";

import {
  getRequestDetails,
  postRequestDetails,
  putRequestDetails,
} from "../../../../config/requestHeaders";

let sessionDetails = JSON.parse(sessionStorage.getItem("sessionDetails"));
let userId = sessionDetails ? sessionDetails.userId : 0;

export default function* watchWorkflow() {
  yield all([
    takeEvery(INIT_WORKFLOW_FETCH, initWorkflowFetchSaga),
    takeEvery(INIT_WORKFLOW_SAVE, initWorkflowSaveSaga),
    takeEvery(INIT_WORKFLOW_EDIT, initEditSaga),
    takeEvery(INIT_WORKFLOWS_DELETE, initDeleteWorkflowSaga),

    takeEvery(INIT_CONDITION_LIST_FETCH, initConditionListFetchSaga),
  ]);
}

function* initWorkflowFetchSaga(action) {
  yield put(workflowFetching());
  const url = `/Workflow/List`;
  try {
    const response = yield call(axios, url, getRequestDetails);

    if (response && response.status === 200) {
      yield put(fetchWorkflow(response.data));
    }
    yield put(workflowFetchComplete());
  } catch (error) {
    yield put(workflowFetchComplete());
  }
}

function* initWorkflowSaveSaga(action) {
  const url = "/Workflow/save";
  try {
    putRequestDetails.data = { ...action.payload, userId };
    const response = yield call(axios, url, putRequestDetails);

    if (response && response.status === 202) {
      yield put(workflowSaved());
      const notification = buildNotification({
        message: "notification.workflow.saveSuccess",
        type: "success",
      });
      store.addNotification({
        ...notification,
        dismiss: {
          duration: 5000,
        },
      });
    } else {
      yield put(workflowSaveFailed());
    }
  } catch (error) {
    yield put(workflowSaveFailed());
  }
}

function* initEditSaga(action) {
  const url = `/Workflow/workflowById?approvalWorkflowId=${action.id}`;
  try {
    const response = yield call(axios, url, getRequestDetails);
    if (response && response.status === 200) {
      yield put(setDataForEdit(response.data));
      if (action.callback) {
        action.callback(response.data);
      }
    }
  } catch (error) {
    action.callback && action.callback({});
  }
}

function* initDeleteWorkflowSaga(action) {
  const url = "/Workflow/Delete";
  postRequestDetails.data = {
    listOfIds: action.id,
    userId: userId,
  };
  try {
    const response = yield call(axios, url, postRequestDetails);

    if (response && response.status === 200) {
      const id = action.id.split(",").map((id) => parseInt(id));
      const notification = buildNotification({
        message: "notification.workflow.deleted",
        type: "success",
      });

      yield put(deleteSuccess(id));
      store.addNotification({
        ...notification,
        dismiss: {
          duration: 5000,
        },
      });
    } else {
      yield put(deletFailed());
    }
  } catch (error) {
    yield put(deletFailed());
  }
}

function* initConditionListFetchSaga(action) {
  yield put(conditionListFetching());
  const url = `/Workflow/ListConditions`;
  try {
    const response = yield call(axios, url, getRequestDetails);

    if (response && response.status === 200) {
      yield put(fetchConditionList(response.data));
    }
    yield put(conditionListFetchComplete());
  } catch (error) {
    yield put(conditionListFetchComplete());
  }
}
