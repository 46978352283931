import { call, put, all, takeEvery } from "redux-saga/effects";

import {
  GET_SUPPLIER_FILTERS,
  GET_SUPPLIER_FILTERS_URL,
  GET_SUPPLIER_FIELDS_ACTION,
  GET_SUPPLIER_FIELDS_URL,
  GET_SUPPLIERS_ACTION,
  GET_SUPPLIER_LIST_URL,
  GET_SUPPLIER_DETAILS_BY_ID,
  GET_SUPPLIER_DETAILS_BY_ID_URL,
  GET_SUPPLIERS_BY_FILTER_SEARCH,
  GET_SUPPLIERS_BY_FILTER_SEARCH_URL,
} from "./constants";

import {
  updateSupplierFiltersAction,
  updateSupplierFieldsAction,
  updateSupplierListAction,
  updateSupplierListByIdAction,
} from "./actions";

import axios from "../../../config/axios";

export default function* rootInvoiceAndCreditsSaga() {
  yield all([
    takeEvery(GET_SUPPLIER_FILTERS, getSupplierFilters),
    takeEvery(GET_SUPPLIER_FIELDS_ACTION, getSupplierFields),
    takeEvery(GET_SUPPLIERS_ACTION, getSuppliers),
    takeEvery(GET_SUPPLIER_DETAILS_BY_ID, getSupplierDetailsById),
    takeEvery(GET_SUPPLIERS_BY_FILTER_SEARCH, getSuppliersByFilterSearch),
  ]);
}

const getRequestDetails = {
  method: "GET",
  //'mode': "no-cors", // no-cors, cors, *same-origin
  cache: "no-cache",
  //'X-Requested-With' : 'https://cors-anywhere.herokuapp.com/'
};

const postRequestDetails = {
  method: "POST",
  //'mode': "no-cors", // no-cors, cors, *same-origin
  cache: "no-cache",
  //'X-Requested-With' : 'https://cors-anywhere.herokuapp.com/'
  headers: {
    "Content-Type": "application/json",
  },
};

function* getSupplierFilters() {
  var supplierFilters = [];
  try {
    const response = yield call(
      axios,
      GET_SUPPLIER_FILTERS_URL,
      getRequestDetails
    );
    supplierFilters = response.data.map((res) => {
      return {
        id: res.CND_ID,
        value: res.CNM_Name,
      };
    });
    yield put(updateSupplierFiltersAction(supplierFilters));
  } catch (err) {
    yield put(updateSupplierFiltersAction([]));
  }
}

function* getSupplierFields(action) {
  var supplierFields = [];
  try {
    postRequestDetails.data = {
      isSubmissionGrid: null,
      supplierProfileFieldId: null,
      uniqueName: null,
      clientId: 2,
      group1Id: null,
      group2Id: null,
      groupRegisterId: null,
      roleId: "APB_ADMIN",
      langKey: "enGB",
      isSearch: true,
      isRequest: false,
      fieldPlacementTypeId: action.fieldPlacementTypeId || 1,
      mandatory: null,
      submissionSupplierProfileFieldId: null,
    };
    const response = yield call(
      axios,
      GET_SUPPLIER_FIELDS_URL,
      postRequestDetails
    );
    supplierFields = response.data;
    yield put(
      updateSupplierFieldsAction(supplierFields, action.fieldPlacementTypeId)
    );
  } catch (err) {
    yield put(updateSupplierFieldsAction([]));
  }
}

function* getSuppliers() {
  var supplierList = [];
  try {
    postRequestDetails.data = {
      supplierIds: "0",
      clientId: "2", //TO-DO Sathesh - Replace from User Login
      roleId: "APB_ADMIN",
      placementId: 1,
      temp: 0,
      userId: "admin.cagcbbihid@test.cz",
      whereCondition: "(mstr.__Active=1)",
      params: null,
      joinSyntax: null,
      columns: "workflow.SWS_UserOnly,",
      orderSyntax: null,
      pageIndex: 0,
      pageSize: null,
      filters: [],
    };
    const response = yield call(
      axios,
      GET_SUPPLIER_LIST_URL,
      postRequestDetails
    );
    supplierList = response.data;
    yield put(updateSupplierListAction(supplierList));
  } catch (err) {
    yield put(updateSupplierListAction([]));
  }
}

function* getSupplierDetailsById(action) {
  try {
    postRequestDetails.data = {
      supplierIds: "0",
      clientId: "2", //TO-DO Sathesh - Replace from User Login
      roleId: "APB_ADMIN",
      placementId: 1,
      temp: 0,
      userId: "admin.cagcbbihid@test.cz",
      whereCondition: "(mstr.__Active=1)",
      params: null,
      joinSyntax: null,
      columns: "workflow.SWS_UserOnly,",
      orderSyntax: null,
      pageIndex: 0,
      pageSize: null,
      filterBy: action.filterBy || "P",
    };
    const response = yield call(
      axios,
      GET_SUPPLIER_DETAILS_BY_ID_URL,
      postRequestDetails
    );
    yield put(updateSupplierListByIdAction(response.data));
  } catch (err) {
    yield put(updateSupplierListByIdAction([]));
  }
}

function* getSuppliersByFilterSearch(action) {
  var supplierList = [];
  try {
    postRequestDetails.data = {
      supplierIds: "0",
      clientId: "2", //TO-DO Sathesh - Replace from User Login
      roleId: "APB_ADMIN",
      placementId: 1,
      temp: 0,
      userId: "admin.cagcbbihid@test.cz",
      whereCondition: "(mstr.__Active=1)",
      params: null,
      joinSyntax: null,
      columns: "workflow.SWS_UserOnly,",
      orderSyntax: null,
      pageIndex: 0,
      pageSize: null,
      filters: action.filterBy,
    };
    const response = yield call(
      axios,
      GET_SUPPLIERS_BY_FILTER_SEARCH_URL,
      postRequestDetails
    );
    supplierList = response.data;
    yield put(updateSupplierListAction(supplierList));
  } catch (err) {
    yield put(updateSupplierListAction([]));
  }
}
