import { call, put, all, takeEvery } from "redux-saga/effects";
import {
  fetchSignUpFormFields,
  fetchSignUpFlexFields,
  signUpFormSaved,
  signUpFormSaveFailed,
  userExist,
  fetchSecurityQuestion,
} from "./actions";
import {
  INIT_FETCH_SIGNUP_FORM_FIELDS,
  INIT_FETCH_SIGNUP_FLEX_FIELDS,
  INIT_SAVE_SIGNUP_FORM,
  INIT_CHECK_USER_EXISTS,
  INIT_FETCH_SECURITY_QUESTION,
} from "./actionTypes";
import { buildNotification } from "../../../config/notification";
import { store } from "react-notifications-component";
import axios from "../../../config/axios";
import {
  getRequestDetails,
  postRequestDetails,
} from "../../../config/requestHeaders";

export default function* watchSignUpSaga() {
  yield all([
    takeEvery(INIT_FETCH_SIGNUP_FORM_FIELDS, getSignUpFormFieldsSaga),
    takeEvery(INIT_FETCH_SIGNUP_FLEX_FIELDS, getSignUpFlexFieldsSaga),
    takeEvery(INIT_SAVE_SIGNUP_FORM, initSaveSignUpFormSaga),
    takeEvery(INIT_CHECK_USER_EXISTS, initCheckForExistingUSerSaga),
    takeEvery(INIT_FETCH_SECURITY_QUESTION, getSecurityQuestionSaga),
  ]);
}

function* getSignUpFormFieldsSaga(action) {
  const url = `/Login/signupfields?languageID=${action.langId}`;
  try {
    const response = yield call(axios, url, getRequestDetails);

    if (response && response.status === 200) {
      yield put(fetchSignUpFormFields(response.data));
    }
  } catch (error) {
    action.callback && action.callback();
  }
}

function* getSignUpFlexFieldsSaga(action) {
  const url = `/Login/flexFields?languageID=${action.langId}`;
  try {
    const response = yield call(axios, url, getRequestDetails);

    if (response && response.status === 200) {
      yield put(fetchSignUpFlexFields(response.data));
    }
  } catch (error) {
    action.callback && action.callback();
  }
}

function* initSaveSignUpFormSaga(action) {
  const url = "/Login/createaccount";
  postRequestDetails.data = action.payload;
  const response = yield call(axios, url, postRequestDetails);
  try {
    if (response && response.status) {
      yield put(signUpFormSaved());
      const notification = buildNotification({
        message: "notification.signUp.saveSuccess",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
    }
  } catch (error) {
    yield put(signUpFormSaveFailed());
    const notification = buildNotification({
      message: "notification.signUp.saveFailure",
      type: "danger",
    });
    store.addNotification({
      ...notification,
    });
  }
}

function* initCheckForExistingUSerSaga(action) {
  const url = `/Users/UserAlreadyExists?userName=${action.payload}`;
  try {
    const response = yield call(axios, url, getRequestDetails);
    if (response && response.status === 200) {
      yield put(userExist(response.data));

      if (response.data && response.data.userExists) {
        const notification = buildNotification({
          message: "signup.userExistsValidation",
          type: "warning",
        });
        store.addNotification({
          ...notification,
        });
      }
    }
  } catch (error) {
    action.callback && action.callback();
  }
}

function* getSecurityQuestionSaga(action) {
  const url = `/Login/ListSecurityQuestion?langID=${action.langId}`;
  try {
    const response = yield call(axios, url, getRequestDetails);
    if (response && response.status === 200) {
      yield put(fetchSecurityQuestion(response.data));
    }
  } catch (error) {
    action.callback && action.callback();
  }
}
