import styled from "styled-components";

export const HideDefaultFileInput = styled.input.attrs((props) => ({
  type: "file",
}))`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

export const StyledFileUploader = styled.label`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  background-color: #fff;
  border: 1px solid rgb(220, 226, 231);
  min-height: 65px;
  margin: ${(props) => props.margin || ""};
  padding: ${(props) => (props.padding ? props.padding : "8px")};
  width: ${(props) => props.divWidth || ""};
  color: rgb(51, 52, 54);
  border-radius: 5px;

  ${HideDefaultFileInput} + & {
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  }
  ${HideDefaultFileInput} + & * {
    pointer-events: none;
  }
  ${HideDefaultFileInput}: focus + & {
    border: 1px solid #008eff;
    background-color: #fafbfc;
  }
  ${HideDefaultFileInput} + &:hover {
    border: 1px solid #008eff;
    background-color: #fafbfc;
  }
`;
