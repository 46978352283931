import { fromJS } from "immutable";

import {
  FETCH_NOTIFICATION_DATA,
} from "./actionTypes";

export const initialState = fromJS({
  notificationData: [],
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_NOTIFICATION_DATA: {
      return state.set("notificationData", action.data);
    }
    default:
      return state;
  }
};
export default reducer;
