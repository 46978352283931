export const GET_EMAIL_DETAILS = "GET_EMAIL_DETAILS";
export const UPDATE_EMAIL_DETAILS = "UPDATE_EMAIL_DETAILS";
export const UPDATE_FIELD_VALUE = "UPDATE_FIELD_VALUE";
export const UPDATE_PASSWORD_REQUEST = "UPDATE_PASSWORD_REQUEST";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const FETCHING_EMAIL_TO_RESET_PASSWORD =
  "FETCHING_EMAIL_TO_RESET_PASSWORD";
export const FETCHING_EMAIL_TO_RESET_PASSWORD_SUCCESS =
  "FETCHING_EMAIL_TO_RESET_PASSWORD_SUCCESS";
export const FETCHING_EMAIL_TO_RESET_PASSWORD_FAILED =
  "FETCHING_EMAIL_TO_RESET_PASSWORD_FAILED";
