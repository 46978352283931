import {
  INIT_CLIENTS_LIST,
  FETCHING_CLIENTS,
  FETCH_CLIENTS_LIST,
  CLIENTS_FETCHING_COMPLETE,
  INIT_CONTACT_LIST,
  FETCHING_CONTACT,
  FETCH_CONTACT_LIST,
  CONTACT_FETCHING_COMPLETE,
  INIT_CONTACT_BY_ID,
  SUBMIT_CONTACT_DATA,
  CONTACT_DATA_SAVED,
  RESET_CONTACT_SAVED_STATUS,
  INIT_DELETE_CONTACT,
  CONTACT_DELETE_SUCCESS,
  UPDATE_SEARCHED_CONTACT_DATA,
  INIT_GET_CLIENT_BY_ID,
  SET_EDIT_CLIENT_DATA,
  EDIT_DATA_FETCHED,
  RESET_IS_EDIT,
  INIT_SAVE_CLIENT_DATA,
  CLIENT_SAVE_FAILED,
  CLIENT_SAVED,
  INIT_SAVE_CLIENT_HELPDESK_DATA,
  CLIENT_HELPDESK_SAVED,
  CLIENT_HELPDESK_SAVE_FAILED,
  UPDATE_SEARCHED_CLIENT_DATA,
  INIT_COUNTRY_CONTACT_DATA,
  FETCHING_COUNTRY_CONTACT,
  FETCH_COUNTRY_CONTACT_LIST,
  COUNTRY_CONTACT_FETCHING_COMPLETE,
  CONTACT_DELETE_FAILED,
  UPDATE_SEARCHED_COUNTRY_CONTACT_DATA,
} from "./actionTypes";

export function initClientsList() {
  return {
    type: INIT_CLIENTS_LIST,
  };
}

export function fetchingClients() {
  return {
    type: FETCHING_CLIENTS,
  };
}

export function fetchClientsList(data) {
  return {
    type: FETCH_CLIENTS_LIST,
    data,
  };
}

export function ClientsFetchingComplete() {
  return {
    type: CLIENTS_FETCHING_COMPLETE,
  };
}

export function initContactList() {
  return {
    type: INIT_CONTACT_LIST,
  };
}

export function fetchingContact() {
  return {
    type: FETCHING_CONTACT,
  };
}

export function fetchContactList(data) {
  return {
    type: FETCH_CONTACT_LIST,
    data,
  };
}

export function ContactFetchingComplete() {
  return {
    type: CONTACT_FETCHING_COMPLETE,
  };
}

export function initContactByIdSaga(contactId, fetchConatcatSuccess) {
  return {
    type: INIT_CONTACT_BY_ID,
    contactId,
    callback: fetchConatcatSuccess,
  };
}

export function submitContactDataAction(data) {
  return {
    type: SUBMIT_CONTACT_DATA,
    data,
  };
}

export function ContactDataSaved() {
  return {
    type: CONTACT_DATA_SAVED,
  };
}

export function resetContactSavedState() {
  return {
    type: RESET_CONTACT_SAVED_STATUS,
  };
}

export function initDeleteContactData(dataToDelete, getConatctList) {
  return {
    type: INIT_DELETE_CONTACT,
    dataToDelete,
    callback: getConatctList,
  };
}

export function contactDeleteSuccess() {
  return {
    type: CONTACT_DELETE_SUCCESS,
  };
}

export function contactDeleteFailed() {
  return {
    type: CONTACT_DELETE_FAILED,
  };
}

export const updateSearchedContactData = (data) => {
  return {
    type: UPDATE_SEARCHED_CONTACT_DATA,
    data: data,
  };
};

export const updateSearchedClientData = (data) => {
  return {
    type: UPDATE_SEARCHED_CLIENT_DATA,
    data: data,
  };
};

export const getClientByIdData = (clientId) => {
  return {
    type: INIT_GET_CLIENT_BY_ID,
    clientId,
  };
};

export const setDataForEditClient = (data) => {
  return {
    type: SET_EDIT_CLIENT_DATA,
    data,
  };
};

export const editDataFetched = () => {
  return {
    type: EDIT_DATA_FETCHED,
  };
};

export const resetIsEditing = () => {
  return {
    type: RESET_IS_EDIT,
  };
};

export const saveClientData = (formData) => {
  return {
    type: INIT_SAVE_CLIENT_DATA,
    formData,
  };
};

export const clientSaveFailed = () => {
  return {
    type: CLIENT_SAVE_FAILED,
  };
};

export const clientSaved = () => {
  return {
    type: CLIENT_SAVED,
  };
};

export const saveHelpdeskContact = (data, getCountryContactList) => {
  return {
    type: INIT_SAVE_CLIENT_HELPDESK_DATA,
    data,
    callback: getCountryContactList,
  };
};

export const clientHelpdeskSaved = () => {
  return {
    type: CLIENT_HELPDESK_SAVED,
  };
};

export const clientHelpdeskSaveFailed = () => {
  return {
    type: CLIENT_HELPDESK_SAVE_FAILED,
  };
};

export function initCountryContactList() {
  return {
    type: INIT_COUNTRY_CONTACT_DATA,
  };
}

export function fetchingCountryContact() {
  return {
    type: FETCHING_COUNTRY_CONTACT,
  };
}

export function fetchCountryContactList(data) {
  return {
    type: FETCH_COUNTRY_CONTACT_LIST,
    data,
  };
}

export function countryContactFetchingComplete() {
  return {
    type: COUNTRY_CONTACT_FETCHING_COMPLETE,
  };
}

export function updateSearchedCountryContactData(data) {
  return {
    type: UPDATE_SEARCHED_COUNTRY_CONTACT_DATA,
    data,
  };
}
