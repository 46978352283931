import { fromJS } from "immutable";

import {
  FETCH_REPORT_STATUS,
  INIT_FETCH_REPORT_STATUS,
  INIT_NOTIFICATION_LIST,
  FETCH_NOTIFICATION_LIST,
} from "./actionTypes";

export const initialState = fromJS({
  pending: true,
  reportStatus: [],
  notificationList: [],
  fetchingnotificationList: true,
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_FETCH_REPORT_STATUS:
      return state.set("pending", true);

    case FETCH_REPORT_STATUS: {
      return state
        .set("reportStatus", action.data)
        .set("pending", false)
    }
    case INIT_NOTIFICATION_LIST: {
      return state.set("fetchingnotificationList", true);
    }
    case FETCH_NOTIFICATION_LIST: {
      return state
        .set("notificationList", action.data)
        .set("fetchingnotificationList", false);
    }
    default:
      return state;
  }
};
export default reducer;
