import React from "react";
import { customStyles } from "./style";
import Select from "react-select";
import Div from "../Div";
import { flexCol, flexGrow } from "../Layout/style";
import { useTranslation } from "react-i18next";

const SelectLookup = (props) => {
  const { t } = useTranslation();

  const lookupOptions = props.options
    ? props.options.map((option) => ({
        label: option.value,
        value: option.id,
      }))
    : [];
  lookupOptions.unshift({ value: "", label: t("attribute.select", "...") });
  const stringVal = props.value ? props.value.toString() : "";
  const value = lookupOptions.find(
    (option) => option.value.toString() === stringVal
  );

  const CustomMenu = (componentProps) => {
    return (
      <Div {...componentProps.innerProps} {...flexCol} {...flexGrow}>
        {props.components}
      </Div>
    );
  };

  return (
    <Select
      isMulti={props.multiselect}
      onInputChange={(value) =>
        props.onSelectInputType &&
        props.onSelectInputType({
          target: {
            value,
            name: props.name,
            id: props.id,
          },
        })
      }
      isDisabled={props.isDisabled || false}
      isLoading={props.isLoading}
      value={value || ""}
      placeholder={t(props.placeholder)}
      onChange={(selectedOption) =>
        props.onChangeHandler &&
        props.onChangeHandler({
          target: {
            value: selectedOption.value,
            id: props.id,
            name: props.name,
            text: selectedOption.label,
            options: lookupOptions,
            selectedIndex: lookupOptions.findIndex(
              (option) => option.value === selectedOption.value
            ),
          },
        })
      }
      onBlur={
        props.onBlurHandler
          ? (selectedOption) =>
              props.onBlurHandler({
                target: {
                  value: selectedOption.value,
                  id: props.id,
                  name: props.name,
                  text: selectedOption.label,
                  options: lookupOptions,
                  selectedIndex: lookupOptions.findIndex(
                    (option) => option.value === selectedOption.value
                  ),
                },
              })
          : false
      }
      options={lookupOptions}
      styles={customStyles}
      components={props.components ? { MenuList: CustomMenu } : null}
      menuPlacement="auto"
      menuPortalTarget={props.inTable ? document.body : false}
    />
  );
};

export default SelectLookup;
