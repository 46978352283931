export const GET_FIELD_DEFINITIONS_ACTION = "GET_FIELD_DEFINITIONS_ACTION";
export const GET_PAYMENT_COLUMNS_ACTION = "GET_PAYMENT_COLUMNS_ACTION";
export const PAYMENTS_DETAILS_REQUEST = "PAYMENTS_DETAILS_REQUEST";
export const UPDATE_PAYMENTS_DETAILS = "UPDATE_PAYMENTS_DETAILS";

export const GET_INVOICE_COLUMNS_ACTION = "GET_INVOICE_COLUMNS_ACTION";
export const PAY_INVOICE_DETAILS_REQUEST = "PAY_INVOICE_DETAILS_REQUEST";

export const UPDATE_INVOICE_DETAILS_ACTION = "UPDATE_INVOICE_DETAILS_ACTION";
export const UPDATE_PAYMENT_DATA_ACTION = "UPDATE_PAYMENT_DATA_ACTION";
export const UPDATE_PAYMENT_FILTER_ACTION = "UPDATE_PAYMENT_FILTER_ACTION";

export const GET_CURRENCY_LIST_ACTION = "GET_CURRENCY_LIST_ACTION";
export const UPDATE_CURRENCY_LIST = "UPDATE_CURRENCY_LIST";

export const GET_FIELDS_URL = "Search/listFieldLabels";
export const GET_PAYMENTS_URL = "Search/ListRemittance";
export const GET_INVOICE_URL = "Search/listInvoiceByID";

export const INIT_GET_REMITTANCE_BY_DOC_NO = "INIT_GET_REMITTANCE_BY_DOC_NO";
export const REMITTANCE_DATA_BY_DOC_NO = "REMITTANCE_DATA_BY_DOC_NO";
export const INIT_GET_REMITTANCE_BY_ID = "INIT_GET_REMITTANCE_BY_ID";
export const REMITTANCE_DATA_BY_ID = "REMITTANCE_DATA_BY_ID";
export const INIT_GET_REMITTANCE_FIELDS = "INIT_GET_REMITTANCE_FIELDS";
