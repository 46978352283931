import {
  INIT_CLIENT_SSO_LOGIN,
  CLIENT_SSO_LOGIN_COMPLETE,
  FETCHING_CLIENT_SSO_TOKEN,
  FETCH_CLIENT_SSO_TOKEN,
} from "./actionTypes";

export const initClientSsoLogin = (payload, callback) => {
  return {
    type: INIT_CLIENT_SSO_LOGIN,
    payload,
    callback,
  };
};
export const fetchingClientSsoToken = () => {
  return {
    type: FETCHING_CLIENT_SSO_TOKEN,
  };
};
export const fetchClientSsoToken = (data) => {
  return {
    type: FETCH_CLIENT_SSO_TOKEN,
    data,
  };
};
export const clientSsoLoginComplete = () => {
  return {
    type: CLIENT_SSO_LOGIN_COMPLETE,
  };
};
