import {
  INIT_FETCH_CATEGORY,
  FETCHING_CATEGORY,
  FETCH_CATEGORY,
  INIT_FETCH_FAQ_MATERIALS,
  FETCHING_FAQ_MATERIALS,
  FETCH_FAQ_MATERIALS,
  FAQ_MATERIALS_FETCH_COMPLETE,
  INIT_FETCH_FAQ_MATERIAL_BY_ID,
  FETCHING_FAQ_MATERIAL_BY_ID,
  FETCH_FAQ_MATERIAL_BY_ID,
  FAQ_MATERIAL_BY_ID_FETCH_COMPLETE,
  INIT_SAVE_FAQ_MATERIAL,
  FAQ_MATERIAL_SAVED,
  FAQ_MATERIAL_SAVE_FAILED,
  INIT_DELETE_FAQ_MATERIAL,
  DELETE_FAQ_MATERIAL_SUCCESS,
  DELETE_FAQ_MATERIAL_FAILED,
  INIT_FETCH_TRAINING_MATERIALS,
  FETCHING_TRAINING_MATERIALS,
  FETCH_TRAINING_MATERIALS,
  TRAINING_MATERIALS_FETCH_COMPLETE,
  INIT_FETCH_TRAINING_MATERIAL_BY_ID,
  FETCHING_TRAINING_MATERIAL_BY_ID,
  FETCH_TRAINING_MATERIAL_BY_ID,
  TRAINING_MATERIAL_BY_ID_FETCH_COMPLETE,
  INIT_SAVE_TRAINING_MATERIAL,
  TRAINING_MATERIAL_SAVED,
  TRAINING_MATERIAL_SAVE_FAILED,
  INIT_DELETE_TRAINING_MATERIAL,
  DELETE_TRAINING_MATERIAL_SUCCESS,
  DELETE_TRAINING_MATERIAL_FAILED,
  INIT_FETCH_TRAINING_VIDEOS,
  FETCHING_TRAINING_VIDEOS,
  FETCH_TRAINING_VIDEOS,
  TRAINING_VIDEOS_FETCH_COMPLETE,
  INIT_FETCH_TRAINING_VIDEO_BY_ID,
  FETCHING_TRAINING_VIDEO_BY_ID,
  FETCH_TRAINING_VIDEO_BY_ID,
  TRAINING_VIDEO_BY_ID_FETCH_COMPLETE,
  INIT_SAVE_TRAINING_VIDEO,
  TRAINING_VIDEO_SAVED,
  TRAINING_VIDEO_SAVE_FAILED,
  INIT_DELETE_TRAINING_VIDEO,
  DELETE_TRAINING_VIDEO_SUCCESS,
  DELETE_TRAINING_VIDEO_FAILED,
} from "./actionTypes";

//get category id
export const initFetchCategory = () => {
  return {
    type: INIT_FETCH_CATEGORY,
  };
};
export const fetchingCategory = () => {
  return {
    type: FETCHING_CATEGORY,
  };
};
export const fetchCategory = (data) => {
  return {
    type: FETCH_CATEGORY,
    data,
  };
};

//FAQ Questions
//Faq listing
export const initFetchFaqMaterial = (data) => {
  return {
    type: INIT_FETCH_FAQ_MATERIALS,
    data,
  };
};
export const fetchingFaqMaterials = () => {
  return {
    type: FETCHING_FAQ_MATERIALS,
  };
};
export const fetchFaqMaterials = (data) => {
  return {
    type: FETCH_FAQ_MATERIALS,
    data: data,
  };
};
export const faqMaterialsFetchingComplete = () => {
  return {
    type: FAQ_MATERIALS_FETCH_COMPLETE,
  };
};

//faq by id
export const initFetchFaqMaterialById = (id) => {
  return {
    type: INIT_FETCH_FAQ_MATERIAL_BY_ID,
    id,
  };
};
export const fetchingFaqMaterialById = () => {
  return {
    type: FETCHING_FAQ_MATERIAL_BY_ID,
  };
};
export const fetchFaqMaterialById = (data) => {
  return {
    type: FETCH_FAQ_MATERIAL_BY_ID,
    data,
  };
};
export const faqMaterialByIdFetchComplete = () => {
  return {
    type: FAQ_MATERIAL_BY_ID_FETCH_COMPLETE,
  };
};

//save faq
export const initSaveFaqMaterial = (payload, saveSuccess) => {
  return {
    type: INIT_SAVE_FAQ_MATERIAL,
    payload,
    saveSuccess,
  };
};
export const faqMaterialSaved = () => {
  return {
    type: FAQ_MATERIAL_SAVED,
  };
};
export const faqMaterialSaveFailed = () => {
  return {
    type: FAQ_MATERIAL_SAVE_FAILED,
  };
};

//delete faq
export const initDeleteFaqMaterial = (id) => {
  return {
    type: INIT_DELETE_FAQ_MATERIAL,
    id,
  };
};
export const deleteFaqMaterialSuccess = (id) => {
  return {
    type: DELETE_FAQ_MATERIAL_SUCCESS,
    id,
  };
};
export const deleteFaqMaterialFailed = () => {
  return {
    type: DELETE_FAQ_MATERIAL_FAILED,
  };
};

//Traning Materials
//Training listing
export const initFetchTrainingMaterial = (data) => {
  return {
    type: INIT_FETCH_TRAINING_MATERIALS,
    data,
  };
};
export const fetchingTrainingMaterials = () => {
  return {
    type: FETCHING_TRAINING_MATERIALS,
  };
};
export const fetchTrainingMaterials = (data) => {
  return {
    type: FETCH_TRAINING_MATERIALS,
    data: data,
  };
};
export const trainingMaterialsFetchingComplete = () => {
  return {
    type: TRAINING_MATERIALS_FETCH_COMPLETE,
  };
};

//training by id
export const initFetchTrainingMaterialById = (id) => {
  return {
    type: INIT_FETCH_TRAINING_MATERIAL_BY_ID,
    id,
  };
};
export const fetchingTrainingMaterialById = () => {
  return {
    type: FETCHING_TRAINING_MATERIAL_BY_ID,
  };
};
export const fetchTrainingMaterialById = (data) => {
  return {
    type: FETCH_TRAINING_MATERIAL_BY_ID,
    data,
  };
};
export const trainingMaterialByIdFetchComplete = () => {
  return {
    type: TRAINING_MATERIAL_BY_ID_FETCH_COMPLETE,
  };
};

//save training
export const initSaveTrainingMaterial = (payload, saveSuccess) => {
  return {
    type: INIT_SAVE_TRAINING_MATERIAL,
    payload,
    saveSuccess,
  };
};
export const trainingMaterialSaved = () => {
  return {
    type: TRAINING_MATERIAL_SAVED,
  };
};
export const trainingMaterialSaveFailed = () => {
  return {
    type: TRAINING_MATERIAL_SAVE_FAILED,
  };
};

//delete training material
export const initDeleteTrainingMaterial = (id) => {
  return {
    type: INIT_DELETE_TRAINING_MATERIAL,
    id,
  };
};
export const deleteTrainingMaterialSuccess = (id) => {
  return {
    type: DELETE_TRAINING_MATERIAL_SUCCESS,
    id,
  };
};
export const deleteTrainingMaterialFailed = () => {
  return {
    type: DELETE_TRAINING_MATERIAL_FAILED,
  };
};

//Traning Videos
//Training listing
export const initFetchTrainingVideo = (data) => {
  return {
    type: INIT_FETCH_TRAINING_VIDEOS,
    data,
  };
};
export const fetchingTrainingVideos = () => {
  return {
    type: FETCHING_TRAINING_VIDEOS,
  };
};
export const fetchTrainingVideos = (data) => {
  return {
    type: FETCH_TRAINING_VIDEOS,
    data: data,
  };
};
export const trainingVideosFetchingComplete = () => {
  return {
    type: TRAINING_VIDEOS_FETCH_COMPLETE,
  };
};

//training by id
export const initFetchTrainingVideoById = (id) => {
  return {
    type: INIT_FETCH_TRAINING_VIDEO_BY_ID,
    id,
  };
};
export const fetchingTrainingVideoById = () => {
  return {
    type: FETCHING_TRAINING_VIDEO_BY_ID,
  };
};
export const fetchTrainingVideoById = (data) => {
  return {
    type: FETCH_TRAINING_VIDEO_BY_ID,
    data,
  };
};
export const trainingVideoByIdFetchComplete = () => {
  return {
    type: TRAINING_VIDEO_BY_ID_FETCH_COMPLETE,
  };
};

//save training
export const initSaveTrainingVideo = (payload, saveSuccess) => {
  return {
    type: INIT_SAVE_TRAINING_VIDEO,
    payload,
    saveSuccess,
  };
};
export const trainingVideoSaved = () => {
  return {
    type: TRAINING_VIDEO_SAVED,
  };
};
export const trainingVideoSaveFailed = () => {
  return {
    type: TRAINING_VIDEO_SAVE_FAILED,
  };
};

//delete training material
export const initDeleteTrainingVideo = (id) => {
  return {
    type: INIT_DELETE_TRAINING_VIDEO,
    id,
  };
};
export const deleteTrainingVideoSuccess = (id) => {
  return {
    type: DELETE_TRAINING_VIDEO_SUCCESS,
    id,
  };
};
export const deleteTrainingVideoFailed = () => {
  return {
    type: DELETE_TRAINING_VIDEO_FAILED,
  };
};
