import { fromJS } from "immutable";

import {
  FETCH_DOCUMENT_SUBMISSION_HISTORY,
  FETCHING_DOC_LIST,
  DOC_FETCH_COMPLETE,
  FETCHING_COUNTRY_LIST,
  FETCH_COUNTRY_LIST,
  COUNTRY_LIST_FETCH_COMPLETE,
  FETCHING_PROFILE_LIST,
  FETCH_PROFILE_LIST,
  PROFILE_LIST_FETCH_COMPLETE,
  FETCHING_SUPPLIER_LIST,
  FETCH_SUPPLIER_LIST,
  SUPPLIER_LIST_FETCH_COMPLETE,
  FETCHING_PO_NUMBER,
  FETCH_PO_NUMBER,
  PO_NUMBER_FETCH_COMPLETE,
  FECTHING_ADDITIONAL_FIELDS,
  FETCH_ADDITIONAL_FIELDS,
  ADDITIONAL_FIELDS_FETCH_COMPLETE,
  CLEAR_ADDITIONAL_FIELDS,
  FETCH_OCR_DETAILS,
  FETCHING_DOCUMENT_SUBMISSION_EXISTING_DATA,
  FETCH_DOCUMENT_SUBMISSION_EXISTING_DATA,
  FETCH_DOCUMENT_SUBMISSION_EXISTING_DATA_COMPLETE,
  SET_DOCUMENT_SUBMISSION_FOR_EDIT_COMPLETED,
  INIT_PO_DETAILS_FETCH,
  PO_DETAILS_FETCH_COMPLETE,
  INIT_OCR_DETAILS_FETCH,
  INIT_GET_DOCUMENT_SUBMISSION,
  INIT_FETCH_ADDITIONAL_FIELDS,
  SET_UPLOAD_PROGRESS,
  SUPPORTING_ATTCAHMENTS_UPLOADING,
  SUPPORTING_ATTCAHMENTS_UPLOADED,
  SUPPORTING_ATTCAHMENTS_UPLOAD_FAIL,
  SUPPORTING_ATTCAHMENTS_UPLOAD_RESET,
  FETCHING_OCR_DETAILS,
  OCR_DETAILS_FETCH_COMPLETE,
  OCR_DETAILS_FETCH_FAILED,
  PDF_GENERATING,
  PDF_GENERATED,
  PDF_GENERATE_FAILED,
  FETCH_DOC_EXTRACTED_DATA,
} from "./actionTypes";

export const initialState = fromJS({
  pending: true,
  formSubmissionStatus: false,
  resetDocumentSubmission: false,
  filterConditions: {
    submissionId: "",
    username: "",
    billingCountry: "",
    DocumentType: "",
    from: "",
    to: "",
    status: "",
  },
  documentList: "",
  rowCount: "",
  additionalColumns: "",
  fetchingCountry: false,
  countryDropdownList: "",
  fetchingProfile: false,
  profileDropdownList: "",
  fetchingSupplier: false,
  supplierDropdownList: "",
  fetchingAdditionalFields: false,
  fetchingPONumber: false,
  poNumberList: "",
  additionalFields: "",
  fetchingSubmissionProfiles: true,
  submissionProfiles: "",
  submisisonProfileSaving: false,
  submisisonProfileSaved: false,
  submisisonProfileSaveFail: false,
  deleting: false,
  deleted: "",
  editing: false,
  submissionProfile: null,
  fetchForEditComplete: false,
  documentSubmissionExistingDataFetching: false,
  documentSubmission: "",
  documentSubmissionEditDataSetComplete: false,
  fetchingPODetails: false,
  poDetails: "",
  fetchingOCR: false,
  ocrDetails: "",
  uploading: false,
  uploadProgress: 0,
  uploaded: false,
  generatingPDF: false,
  pdfContent: "",
  docExtractedData: "",
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_DOC_LIST:
      return state
        .set("pending", true)
        .set("documentList", "")
        .set("additionalColumns", "");

    case FETCH_DOCUMENT_SUBMISSION_HISTORY:
      return state
        .set("documentList", action.data.documentList)
        .set("additionalColumns", action.data.addtionalColumns)
        .set("rowCount", action.data.rowCount);

    case DOC_FETCH_COMPLETE: {
      return state.set("pending", false);
    }
    case FETCHING_COUNTRY_LIST: {
      return state.set("fetchingCountry", true);
    }
    case FETCH_COUNTRY_LIST: {
      return state.set("countryDropdownList", action.data);
    }
    case COUNTRY_LIST_FETCH_COMPLETE: {
      return state.set("fetchingCountry", false);
    }
    case FETCHING_PROFILE_LIST: {
      return state.set("fetchingProfile", true);
    }
    case FETCH_PROFILE_LIST: {
      return state.set("profileDropdownList", action.data);
    }
    case PROFILE_LIST_FETCH_COMPLETE: {
      return state.set("fetchingProfile", false);
    }
    case FETCHING_SUPPLIER_LIST: {
      return state.set("fetchingSupplier", true);
    }
    case FETCH_SUPPLIER_LIST: {
      return state.set("supplierDropdownList", action.data);
    }
    case SUPPLIER_LIST_FETCH_COMPLETE: {
      return state.set("fetchingSupplier", false);
    }
    case FETCHING_PO_NUMBER: {
      return state.set("fetchingPONumber", true);
    }
    case FETCH_PO_NUMBER: {
      return state.set("poNumberList", action.data);
    }
    case PO_NUMBER_FETCH_COMPLETE: {
      return state.set("fetchingPONumber", false);
    }
    case INIT_FETCH_ADDITIONAL_FIELDS: {
      return state.set("additionalFields", "");
    }
    case FECTHING_ADDITIONAL_FIELDS: {
      return state.set("fetchingAdditionalFields", true);
    }
    case FETCH_ADDITIONAL_FIELDS: {
      return state.set("additionalFields", action.data);
    }
    case ADDITIONAL_FIELDS_FETCH_COMPLETE: {
      return state.set("fetchingAdditionalFields", false);
    }
    case CLEAR_ADDITIONAL_FIELDS: {
      return state
        .set("additionalFields", "")
        .set("documentSubmission", "")
        .set("ocrDetails", "")
        .set("pdfContent", "")
        .set("poDetails", "")
        .set("docExtractedData", "");
    }
    case INIT_GET_DOCUMENT_SUBMISSION: {
      return state.set("documentSubmission", "");
    }
    case FETCHING_DOCUMENT_SUBMISSION_EXISTING_DATA: {
      return state
        .set("documentSubmissionExistingDataFetching", true)
        .set("documentSubmissionEditDataSetComplete", false);
    }
    case FETCH_DOCUMENT_SUBMISSION_EXISTING_DATA: {
      return state.set("documentSubmission", action.data);
    }
    case FETCH_DOCUMENT_SUBMISSION_EXISTING_DATA_COMPLETE: {
      return state.set("documentSubmissionExistingDataFetching", false);
    }
    case SET_DOCUMENT_SUBMISSION_FOR_EDIT_COMPLETED: {
      return state.set("documentSubmissionEditDataSetComplete", true);
    }
    case INIT_PO_DETAILS_FETCH: {
      return state.set("fetchingPODetails", true).set("poDetails", []);
    }
    case PO_DETAILS_FETCH_COMPLETE: {
      return state
        .set("fetchingPODetails", false)
        .set("poDetails", action.poDetails);
    }
    case INIT_OCR_DETAILS_FETCH: {
      return state.set("ocrDetails", "");
    }
    case FETCHING_OCR_DETAILS: {
      return state.set("fetchingOCR", true);
    }
    case FETCH_OCR_DETAILS: {
      return state.set("ocrDetails", action.data);
    }
    case OCR_DETAILS_FETCH_COMPLETE: {
      return state.set("fetchingOCR", false);
    }

    case OCR_DETAILS_FETCH_FAILED: {
      return state.set("fetchingOCR", false).set("ocrDetails", "");
    }
    case FETCH_DOC_EXTRACTED_DATA: {
      return state.set("docExtractedData", action.data);
    }
    case SUPPORTING_ATTCAHMENTS_UPLOADING: {
      return state.set("uploading", true);
    }
    case SET_UPLOAD_PROGRESS: {
      return state.set("uploadProgress", action.data);
    }
    case SUPPORTING_ATTCAHMENTS_UPLOADED: {
      return state
        .set("uploading", false)
        .set("uploadProgress", 0)
        .set("uploaded", true);
    }
    case SUPPORTING_ATTCAHMENTS_UPLOAD_FAIL:
    case SUPPORTING_ATTCAHMENTS_UPLOAD_RESET: {
      return state
        .set("uploading", false)
        .set("uploadProgress", 0)
        .set("uploaded", false);
    }
    case PDF_GENERATING: {
      return state.set("generatingPDF", true);
    }
    case PDF_GENERATED: {
      return state.set("generatingPDF", false).set("pdfContent", action.data);
    }
    case PDF_GENERATE_FAILED: {
      return state.set("generatingPDF", false);
    }
    default:
      return state;
  }
};
export default reducer;
