import React, { useLayoutEffect, useState } from "react";
import Div from "../../Div";

import { flexCol, flexGrow, flexRow } from "../../Layout/style";
import { Overlay, FilterPanelSection } from "../style";
import Bottombar from "./subComponents/Bottombar";
import AdvanceSearch from "./subComponents/AdvanceSearch";
import Topbar from "./subComponents/Topbar";

const FilterPanel = (props) => {
  const content = document.getElementById("content");
  const useWindowSize = () => {
    const [size, setSize] = useState([0, 0]);

    useLayoutEffect(() => {
      function updateSize() {
        if (content) {
          setSize([content.offsetWidth, content.offsetHeight]);
        }
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  };
  const height = useWindowSize()[1];

  const [logicalFilter, setLogicalFilterMode] = useState(false);

  const values = props.values;

  const sections = logicalFilter
    ? props.sections.filter((section) => !section.includeInLogical)
    : props.sections;

  const nonEmptyVal = Object.values(values).filter((v) => v !== "");
  const hasDefaultValue = nonEmptyVal.length > 0;

  const hasLogicalValues = props.logicalValues.length > 0;
  const disable = logicalFilter
    ? !hasLogicalValues && !hasDefaultValue
    : !hasDefaultValue;
  return (
    <Overlay
      show={props.show}
      role="dialog"
      id="overlay"
      tabIndex="-1"
      height={`${height}px`}
    >
      <Div role="dialog" {...flexCol} {...flexGrow} overflow="hidden">
        <Div
          {...flexRow}
          {...flexGrow}
          direction="row-reverse"
          overflow="hidden"
        >
          <FilterPanelSection vertical={!logicalFilter}>
            <Topbar clearFilter={props.clearFilter} onClose={props.onClose} />

            <AdvanceSearch
              sections={sections}
              values={values}
              onControlChange={props.handleControlChange}
              handleClickEvents={props.handleClickEvents}
              isLogical={logicalFilter}
              logicalOptions={props.logicalOptions}
              conditionValue={props.conditionValue}
              onConditionChange={props.onConditionChange}
              addCondition={props.addCondition}
              logicalInputs={props.logicalInputs}
              logicalValues={props.logicalValues}
              removeCondition={props.removeCondition}
              onLogicalControlChange={props.onLogicalControlChange}
              customEvent={props.customEvent}
            />

            <Bottombar
              disable={false}
              applyFilter={() => props.onApplyFilter(logicalFilter)}
              toggleSearchMode={() => setLogicalFilterMode(!logicalFilter)}
              isLogical={logicalFilter}
              hasLogical={props.hasLogical}
            />
          </FilterPanelSection>
        </Div>
      </Div>
    </Overlay>
  );
};

export default FilterPanel;
