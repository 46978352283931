import React from "react";

import { authRoutes, documentSubmissionClientConfigRoutes } from "./routes";

const HomePage = React.lazy(() => import("../container/HomePage"));
const DocumentList = React.lazy(() =>
  import("../container/DocumentSubmission")
);
const Uploads = React.lazy(() => import("../container/ManageUpload/Uploads"));
const SuppliersUpload = React.lazy(() =>
  import("../container/ManageUpload/SuppliersUpload")
);
const UsersUpload = React.lazy(() =>
  import("../container/ManageUpload/UsersUpload")
);

const InvoiceDetails = React.lazy(() =>
  import("../container/DocumentSubmission/InvoiceDetails")
);

export const generateFilteredRoutes = (menus, client) => {
  const filteredRoute = authRoutes
    .filter((item) => {
      return (
        menus &&
        menus.findIndex(
          (x) =>
            x.pageElementFileName &&
            x.pageElementFileName.trim() === item.key &&
            item.key.trim() &&
            x.accessAllowed
        ) > -1
      );
    })
    .map((menuItem) => {
      const item = menus.find(
        (x) =>
          x.pageElementFileName &&
          x.pageElementFileName.trim() === menuItem.key &&
          menuItem.key.trim()
      );
      return {
        ...menuItem,
        accessLevelName: item.accessLevelName,
        isMenu: item.isMenu,
        pageElementId: item.pageElementId,
        parentPageElementId: item.parentPageElementId,
        order: item.pageElementSortOrder,
        moduleName: item.moduleName,
        name: item.pageElementDisplayName,
        title: item.pageElementDisplayName,
      };
    });

  const hasDocumentSubmissionPermission =
    filteredRoute && filteredRoute.find((route) => route.key === "documents");
  const { invoiceConfig } = client || {};

  const submissionConfig =
    invoiceConfig &&
    invoiceConfig
      .filter((cf) => cf.active)
      .map((cf) => cf.invoiceSubmissionConfigID);
  const id = {};

  const submissionConfigsRoutes =
    submissionConfig && hasDocumentSubmissionPermission
      ? documentSubmissionClientConfigRoutes
          .filter((route) => {
            const hasPermisison = menus.find(
              (menu) => menu.pageElementFileName === route.key
            );
            return hasPermisison
              ? submissionConfig.indexOf(route.configID) !== -1
              : false;
          })
          .map((route) => {
            if (!route.parent) id[route.key] = route.configID; // push id or parent only

            return {
              ...route,
              isMenu: true,
              pageElementId: route.parent
                ? `dc${route.configID}`
                : `d${route.configID}`,
              parentPageElementId: route.parent
                ? `d${id[route.parent]}`
                : hasDocumentSubmissionPermission.pageElementId,
              moduleName: "Document Submission",
              configID: route.configID,
              state: {
                config: client,
                flowType: route.configID,
                type: route.type,
              },
            };
          })
      : [];

  const documentSubmissionRoutes = hasDocumentSubmissionPermission
    ? [
        ...submissionConfigsRoutes,
        {
          path: "/document-submission/submission-history",
          name: "documentSubmission.submissionHistoryTitle", //"Document Submission List",
          Component: DocumentList,
          title: "documentSubmission.submissionHistoryTitle",
          key: "documentList",
          isMenu: true,
          pageElementId: `DSH`,
          parentPageElementId: hasDocumentSubmissionPermission.pageElementId,
          moduleName: "Document Submission",
        },

        {
          path: "/document-submission/invoice-details",
          name: "documentSubmission.InvoiceDetailsTitle",
          Component: InvoiceDetails,
          title: "documentSubmission.InvoiceDetailsTitle",
          key: "invoiceDetails",
          isMenu: false,
          pageElementId: `DSI`,
          parentPageElementId: hasDocumentSubmissionPermission.pageElementId,
          moduleName: "Document Submission",
        },
        {
          path: "/document-submission/submission-history/invoice-details",
          name: "Invoice Details",
          Component: InvoiceDetails,
          title: "Invoice Details",
          key: "invoiceDetailsFromHistory",
          isMenu: false,
          pageElementId: `DSI-H`,
          parentPageElementId: hasDocumentSubmissionPermission.pageElementId,
          moduleName: "Document Submission",
        },
      ]
    : [];

  filteredRoute.unshift({
    path: "/",
    name: "homepage.homePath",
    Component: HomePage,
    title: "homepage.homePath",
  });

  filteredRoute.push(
    {
      path: "/administration/manage-upload/supplier-upload",
      name: "manageUpload.suppliersUpload",
      Component: SuppliersUpload,
      title: "manageUpload.suppliersUpload",
      key: "suppliersUpload",
    },
    {
      path: "/administration/manage-upload/user-upload",
      name: "manageUpload.usersUpload",
      Component: UsersUpload,
      title: "manageUpload.usersUpload",
      key: "usersUpload",
    },
    {
      path: "/administration/manage-upload/reconciliation-upload",
      name: "manageUpload.reconciliationUpload",
      Component: Uploads,
      title: "manageUpload.reconciliationUpload",
      key: "reconciliationUpload",
    },
    {
      path: "/administration/manage-upload/submission-upload",
      name: "manageUpload.submissionUpload",
      Component: Uploads,
      title: "manageUpload.submissionUpload",
      key: "submissionUpload",
    },
    {
      path: "/administration/manage-upload/submission-upload-with-metadata",
      name: "manageUpload.submissionUploadWithMetadata",
      Component: Uploads,
      title: "manageUpload.submissionUploadWithMetadata",
      key: "submissionUploadWithMetadata",
    }
  );

  filteredRoute.push(...documentSubmissionRoutes);

  return filteredRoute;
};
