import {
  GET_SUPPLIER_FILTERS,
  UPDATE_SUPPLIER_FILTERS_ACTION,
  GET_SUPPLIER_FIELDS_ACTION,
  UPDATE_SUPPLIER_FIELDS_ACTION,
  UPDATE_SUPPLIER_COLUMNS,
  GET_SUPPLIERS_ACTION,
  UPDATE_SUPPLIER_LIST_ACTION,
  GET_SUPPLIER_DETAILS_BY_ID,
  UPDTAE_SUPPLIER_LIST_BY_ID,
  GET_SUPPLIERS_BY_FILTER_SEARCH,
  UPDATE_SELECTED_SUPPLIERS_ACTION,
  UPDATE_SHOW_SUPPLIER_MODAL,
} from './constants';

export function getSupplierFilters() {
  return {
    type: GET_SUPPLIER_FILTERS,
  }
}

export function updateSupplierFiltersAction(supplierFilters) {
  return {
    type: UPDATE_SUPPLIER_FILTERS_ACTION,
    supplierFilters,
  }
}

export function getSupplierFields(fieldPlacementTypeId) {
  return {
    type: GET_SUPPLIER_FIELDS_ACTION,
    fieldPlacementTypeId
  }
}

export function updateSupplierFieldsAction(supplierFields, fieldPlacementTypeId) {
  return {
    type: UPDATE_SUPPLIER_FIELDS_ACTION,
    supplierFields,
    fieldPlacementTypeId,
  }
}

export function updateSupplierColumns(supplierColumns) {
  return {
    type: UPDATE_SUPPLIER_COLUMNS,
    supplierColumns
  }
}

export function getSuppliers() {
  return {
    type: GET_SUPPLIERS_ACTION
  }
}

export function updateSupplierListAction(supplierList) {
  return {
    type: UPDATE_SUPPLIER_LIST_ACTION,
    supplierList
  }
}

export function getSupplierDetailsById(filterBy) {
  return {
    type: GET_SUPPLIER_DETAILS_BY_ID,
    filterBy
  }
}

export function updateSupplierListByIdAction(payload) {
  return {
    type: UPDTAE_SUPPLIER_LIST_BY_ID,
    payload,
  }
}

export function getSuppliersByFilterSearch(filterBy) {
  return {
    type: GET_SUPPLIERS_BY_FILTER_SEARCH,
    filterBy
  }
}

export function updateSelectedSuppliersAction(selectedSuppliers) {
  return {
    type: UPDATE_SELECTED_SUPPLIERS_ACTION,
    selectedSuppliers,
  }
}

export function updateShowSupplierModal(showSupplier) {
  return {
    type: UPDATE_SHOW_SUPPLIER_MODAL,
    showSupplier
  }
}