import styled from "styled-components";

export const Overlay = styled.div`
  position: fixed;
  z-index: ${(props) => (props.overlayZIndex ? props.overlayZIndex : "1000")};
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  background-color: ${(props) =>
    props.bgColor ? props.bgColor : "rgba(255, 255, 255, 0.4)"};

  transition: opacity 0.15s linear;
`;

export const ModalContainer = styled.div`
  position: relative;
  height: ${(props) => (props.height ? props.height : "")};
  width: ${(props) => (props.width ? props.width : "")};
  background-color: #fff;
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "3px"};
  border-bottom: ${(props) =>
    props.border ? props.border : "1px solid #e3eaed"};
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);
  overflow: ${(props) => props.overflow || "auto"};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding: ${(props) => (props.padding ? props.padding : "")};
`;

export const ModalTitle = styled.span`
  color: ${(props) => (props.color ? props.color : "rgb(45, 58, 75)")};
  font-size: ${(props) =>
    props.headerTextSize ? props.headerTextSize : "20px"};
  font-family: ${(props) =>
    props.headerFontFamily ? props.headerFontFamily : "graphik-medium"};
  font-weight: 500;
  letter-spacing: -0.35px;
  line-height: 24px;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-self: center;
`;
