import { put, takeEvery, call, all } from "redux-saga/effects";
import { buildNotification } from "../../../config/notification";
import { store } from "react-notifications-component";

import { updateUserCreationRequest, fetchUserByIDData } from "./actions";

import {
  USER_CREATION_REQUEST_FETCH,
  APPROVE_USER_REQUEST,
  REJECT_USER_REQUEST,
  CONFIRM_ACCOUNT,
  GET_USER_BY_ID,
  SAVE_USER_CREATION,
  INIT_DOWNLOAD_REQ_ATTACHMENT,
} from "./actionTypes";
import axios from "../../../config/axios";
import {
  getRequestDetails,
  postRequestDetails,
} from "../../../config/requestHeaders";

export default function* watchRequestsManagement() {
  yield all([
    takeEvery(USER_CREATION_REQUEST_FETCH, getUserCreationRequestListSaga),
    takeEvery(APPROVE_USER_REQUEST, approveUserRequest),
    takeEvery(REJECT_USER_REQUEST, rejectUserRequest),
    takeEvery(CONFIRM_ACCOUNT, confirmAccount),
    takeEvery(GET_USER_BY_ID, getUserByIdSaga),
    takeEvery(SAVE_USER_CREATION, saveUserCreationSaga),
    takeEvery(INIT_DOWNLOAD_REQ_ATTACHMENT, downloadAttachmentSaga),
  ]);
}

function* getUserCreationRequestListSaga(action) {
  let url = "/Users/ListUserCreationRequest?";
  let keys = action.data && Object.keys(action.data);
  keys &&
    keys.forEach((data) => {
      if (action.data[data]) {
        url = url + data + "=" + action.data[data] + "&";
      }
    });
  try {
    const response = yield call(axios, url, getRequestDetails);
    if (response && response.status === 200) {
      yield put(
        updateUserCreationRequest(
          response.data.userRequest,
          response.data.statusCounts,
          response.data.totalCount
        )
      );
    }
  } catch (err) {
    yield put(updateUserCreationRequest([]));
  }
}

function* approveUserRequest(action) {
  try {
    const API_URL = "Users/AccountApproved";
    postRequestDetails.data = { ...action.payload };
    const response = yield call(axios, API_URL, postRequestDetails);
    if (response && response.status === 202) {
      const notification = buildNotification({
        message: "notification.requestMgmt.userApproved",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
      if (action.callback) {
        action.callback(response.status);
      }
    }
  } catch (error) {
    action.callback && action.callback();
  }
}

function* rejectUserRequest(action) {
  try {
    const API_URL = "Users/AccountRejected";
    postRequestDetails.data = action.payload;
    const response = yield call(axios, API_URL, postRequestDetails);
    if (response && response.status === 202) {
      const notification = buildNotification({
        message: "notification.requestMgmt.userRejected",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
      if (action.callback) {
        action.callback(response.status);
      }
    }
  } catch (error) {
    action.callback && action.callback();
  }
}

function* confirmAccount(action) {
  try {
    const API_URL = `Users/AccountConfirmed`;
    postRequestDetails.data = action.payload;
    const response = yield call(axios, API_URL, postRequestDetails);
    if (response && response.status === 200) {
      const notification = buildNotification({
        message: "notification.requestMgmt.accountConfirmed",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });

      action.callBackFunction(response);
    }
  } catch (error) {
    action.callBackFunction("error");
  }
}

function* getUserByIdSaga(action) {
  const URL =
    "/Users/GetUserCreationRequestByID?userCompanyDetailID=" +
    action.companyDetailID;
  try {
    const response = yield call(axios, URL, getRequestDetails);
    if (response && response.status === 200) {
      yield put(fetchUserByIDData(response.data));
      if (action.callBackFunction) {
        action.callBackFunction(response.data);
      }
    }
  } catch (err) {
    action.callBackFunction && action.callBackFunction();
  }
}

function* saveUserCreationSaga(action) {
  const URL = "/Users/saveUserCreationRequest";
  postRequestDetails.data = action.data;
  try {
    const response = yield call(axios, URL, postRequestDetails);
    if (response && response.status === 202) {
      const notification = buildNotification({
        message: "notification.requestMgmt.dataSaved",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
    }
    if (action.callback) action.callback(response);
  } catch (err) {
    action.callback && action.callback();
  }
}

function* downloadAttachmentSaga(action) {
  const url =
    "/Users/downloadUserRequestAttachment?userCompanyDetailID=" + action.id;
  try {
    const response = yield call(axios, url, { responseType: "blob" });
    if (response && response.status === 200) {
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: response.headers["content-type"],
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", action.fileName);
      document.body.appendChild(link);
      link.click();
      const notification = buildNotification({
        message: "notification.requestMgmt.attachmentDownloaded",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
    }
    action.callback && action.callback();
  } catch (err) {
    action.callback && action.callback();
  }
}
