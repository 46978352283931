import styled from "styled-components";

const CrumbText = styled.span`
  height: 12px;
  color: #91a7b1;
  font-size: 11px;
  font-family: graphik-medium;
  font-weight: 500;
  letter-spacing: -0.18px;
  line-height: 12px;
`;

export default CrumbText;
