export const termsOfUseContent = [
    {
        header: "common.acceptanceTermsHeader",
        content: {
            body: "common.acceptanceTermsContent"
        }
    },
    {
        header: "common.serviceProvidedHeader",
        content: {
            body: "common.serviceProvidedContent"
        }
    },
    {
        header: "common.userCodeHeader",
        content: {
            body: "common.userCodeContent"
        }
    },
    {
        header: "common.privacyHeader",
        content: {
            body: "common.privacyContent"
        }
    },
    {
        header: "common.reservationRightHeader",
        content: {
            body: "common.reservationRightContent"
        }
    },
    {
        header: "common.prohibitedCommunicationHeader",
        content: {
            body: "common.prohibitedCommunicationContent",
            childBody: [
                {
                    body: "any areas on the Site where users transmit or post communications or communicate solely with each other, and"
                },
                {
                    body: " the content of any such communications. MySupplier Portal, however, will have no liability related to the content of any such communications, whether or not arising under the laws of copyright, libel, privacy, obscenity or otherwise. You are prohibited from posting or transmitting to or from this Site any unlawful, threatening, libelous, defamatory, obscene, pornographic or other material that would violate any law."
                }
            ]
        }
    },
    {
        header: "common.warrantyLimitationHeader",
        content: {
            body: "common.warrantyLimitationContent"
        }
    },
    {
        header: "common.representationWarrantyHeader",
        content: {
            body: "common.representationWarrantyContent"
        }
    },
    {
        header: "common.confidentialityHeader",
        content: {
            body: "common.confidentialityContent"
        }
    },
    {
        header: "common.terminationHeader",
        content: {
            body: "common.terminationContent"
        }
    },
    {
        header: "common.assignmentHeader",
        content: {
            body: "common.assignmentContent"
        }
    },
    {
        header: "common.agreementSeverabilityHeader",
        content: {
            body: "common.agreementSeverabilityContent"
        }
    },
    {
        header: "common.waiverHeader",
        content: {
            body: "common.waiverContent"
        }
    },
    {
        header: "",
        content: {
            body: "common.waiverContentTwo",
            childBody: [
                {
                    body: "be used, and none of the content, nor any information acquired through the use of the MySupplier Portal, is or will be used for nuclear activities, chemical or biological weapons, or missile projects; and"
                },
                {
                    body: " be used, and none of the underlying information, software, or technology may be transferred or otherwise exported or re-exported to, countries against which the U.S. government currently imposes economic sanctions programs, such as Cuba, Iran, North Korea, Sudan and Syria (collectively, Sanctioned Countries), or to or by a national or resident thereof, or any person or entity on a restricted party list maintained by the U.S. government, including, but not limited to, the Specially Designated Nationals List administered by OFAC; the Denied Persons List, Unverified List or Entity List maintained by BIS; or the List of Statutorily Debarred Parties maintained by the U.S. State Department's Directorate of Defense Trade Controls (collectively, Restricted Parties ). The lists of Sanctioned Countries and Restricted Parties are subject to change without notice."
                }

            ]
        }
    }
]



export const policy = [
    {
        header: "common.termsChilderenPolicyHeader",
        body: "common.termsChilderenPolicyContent"
    },
    {
        header: "common.termsPolicyEnforcementHeader",
        body: "common.termsPolicyEnforcementContent"
    },
    {
        header: "common.termsChangePolicyHeader",
        body: "common.termsChangePolicyContent"
    }

]

export const additionalInformation = [
    {
        body: "common.termsAdditionalInfoOne"
    },
    {
        body: "common.termsAdditionalInforTwo"
    },
    {
        body: "common.termsAdditionalInfoThree"
    }
]