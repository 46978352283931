import { put, takeEvery, call, all } from "redux-saga/effects";
import { buildNotification } from "../../../config/notification";
import { store } from "react-notifications-component";

import {
  fetchingRejectedInvoices,
  initRejectedList,
  resolveInvoicesComplete,
  setRejectedList,
} from "./actions";
//import { getInvoiceDetailsAction } from "../../InvoiceAndCredits/store/actions";

import { INIT_REJECTED_LIST, INIT_RESOLVE_INVOICES } from "./actionTypes";
import axios from "../../../config/axios";

export default function* watchReconciliation() {
  yield all([
    takeEvery(INIT_RESOLVE_INVOICES, resolveInvoicesSaga),
    takeEvery(INIT_REJECTED_LIST, getRejectedListSaga),
  ]);
}

const postRequestDetails = {
  method: "POST",
  cache: "no-cache",
  headers: {
    "Content-Type": "application/json",
  },
};

function* resolveInvoicesSaga(action) {
  let url = "/Search/markAsResolved";
  postRequestDetails.data = {
    ...action.data,
  };
  try {
    const response = yield call(axios, url, postRequestDetails);
    if (response.status === 200) {
      const notification = buildNotification({
        message: "notification.resolveInvoices.resolved",
        type: "success",
      });

      store.addNotification({
        ...notification,
      });
      yield put(resolveInvoicesComplete());
      yield put(initRejectedList(action.param));
    }
  } catch (error) {
    action.callback && action.callback();
  }
}

function* getRejectedListSaga(action) {
  yield put(fetchingRejectedInvoices());
  let invoiceDetails = [];

  try {
    postRequestDetails.data = JSON.stringify(action.data);
    const response = yield call(
      axios,
      "Search/listRejectedInvoice",
      postRequestDetails
    );

    invoiceDetails = response.data || [];
    yield put(setRejectedList(invoiceDetails, invoiceDetails.rowCount));
    if (
      invoiceDetails === [] ||
      invoiceDetails === null ||
      invoiceDetails === undefined
    ) {
      if (action.callBackFunction) {
        action.callBackFunction();
      }
    } else {
      if (action.callBackFunction) {
        action.callBackFunction(invoiceDetails, invoiceDetails.rowCount);
      }
    }
  } catch (err) {
    action.callBackFunction && action.callBackFunction();
  }
}
