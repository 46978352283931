import {
  RECONCILIATION_LIST_REQUEST,
  RECONCILIATION_LIST_FETCH_COMPLETE,
  SUPPLIER_LIST_FETCH_COMPLETE,
  SUPPLIER_LIST_REQUEST,
  DOWNLOAD_INPUT_FILE_ACTION,
  DOWNLOAD_DETAILS_FILE_ACTION,
  SAVE_RECONCILIATION_REQUEST,
  SAVE_RECONCILIATION_COMPLETE,
  GET_RECONCILED_REPORTS_REQUEST,
  RECONCILED_REPORTS_FETCH_COMPLETE,
  GET_SUGGESTED_INVOICES_REQUEST,
  SUGGESTED_INVOICES_FETCH_COMPLETE,
  GET_RECONCILIATION_COMMENTS_REQUEST,
  RECONCILIATION_COMMENTS_FETCH_COMPLETE,
  SAVE_RECONCILIATION_COMMENTS_REQUEST,
  SAVE_RECONCILIATION_COMMENTS_COMPLETE,
  START_RECONCILIATION_REQUEST,
  START_RECONCILIATION_COMPLETE,
  UPDATE_RECONCIALITIN_LIST,
  INIT_UPDATE_RECONCILED_DATA,
  INIT_DOWNLOAD_TEMPLATE,
  RESET_SAVE_RECONCILIATION_STATUS,
} from "./actionTypes";

export function reconciliationListRequest(data) {
  return {
    type: RECONCILIATION_LIST_REQUEST,
    data,
  };
}

export function reconciliationListFetchComplete(reconciliationList) {
  return {
    type: RECONCILIATION_LIST_FETCH_COMPLETE,
    reconciliationList,
  };
}

export const updateReconciliationList = (data) => {
  return {
    type: UPDATE_RECONCIALITIN_LIST,
    data,
  };
};

export const initUpdateReconciledData = (data, listParam) => {
  return {
    type: INIT_UPDATE_RECONCILED_DATA,
    data,
    listParam,
  };
};

export function supplierListRequest(payload, callback) {
  return {
    type: SUPPLIER_LIST_REQUEST,
    payload,
    callback,
  };
}

export function supplierListFetchComplete(supplierList, totalSupplierCount) {
  return {
    type: SUPPLIER_LIST_FETCH_COMPLETE,
    supplierList,
    totalSupplierCount,
  };
}

export function downloadInputFileAction(reconcileId, fileName) {
  return {
    type: DOWNLOAD_INPUT_FILE_ACTION,
    reconcileId,
    fileName,
  };
}

export function downloadDetailsFileAction(ID) {
  return {
    type: DOWNLOAD_DETAILS_FILE_ACTION,
    ID,
  };
}

export function saveReconciliationRequest(payload, callback) {
  return {
    type: SAVE_RECONCILIATION_REQUEST,
    payload,
    callback,
  };
}

export function saveReconciliationComplete(status) {
  return {
    type: SAVE_RECONCILIATION_COMPLETE,
    status,
  };
}

export const resetSaveReconciliationStatus = () => {
  return {
    type: RESET_SAVE_RECONCILIATION_STATUS,
  };
};

export function getReconciledReportsRequest(reconcileId) {
  return {
    type: GET_RECONCILED_REPORTS_REQUEST,
    reconcileId,
  };
}

export function reconciledReportsFetchComplete(
  reconcileFieldHeaders,
  reconcileDetails
) {
  return {
    type: RECONCILED_REPORTS_FETCH_COMPLETE,
    reconcileFieldHeaders,
    reconcileDetails,
  };
}

export function getSuggestedInvoices(param) {
  return {
    type: GET_SUGGESTED_INVOICES_REQUEST,
    param,
  };
}

export function suggestedInvoicesFetchComplete(suggestedInvoiceList) {
  return {
    type: SUGGESTED_INVOICES_FETCH_COMPLETE,
    suggestedInvoiceList,
  };
}

export function getReconciliationComments(reconcileId) {
  return {
    type: GET_RECONCILIATION_COMMENTS_REQUEST,
    reconcileId,
  };
}

export function reconciliationCommentsFetchComplete(reconciliationComments) {
  return {
    type: RECONCILIATION_COMMENTS_FETCH_COMPLETE,
    reconciliationComments,
  };
}

export function saveReconciliationComments(payload) {
  return {
    type: SAVE_RECONCILIATION_COMMENTS_REQUEST,
    payload,
  };
}

export function saveReconciliationCommentsComplete(commentSaveStatus) {
  return {
    type: SAVE_RECONCILIATION_COMMENTS_COMPLETE,
    commentSaveStatus,
  };
}

export function startReconciliation(param, data) {
  return {
    type: START_RECONCILIATION_REQUEST,
    param,
    data,
  };
}

export function startReconciliationComplete(status) {
  return {
    type: START_RECONCILIATION_COMPLETE,
    status,
  };
}

export const initDownloadTemplate = () => {
  return {
    type: INIT_DOWNLOAD_TEMPLATE,
  };
};
