import styled from "styled-components";

export const OvalDivStyle = styled.div`
  position: ${(props) => (props.position ? props.position : "")};
  display: inline-block;
  border-radius: 20px;
  height: 30px;
  width: ${(props) => (props.divWidth ? props.divWidth : "")};
  border: 1px solid #dce2e7;
  text-align: ${(props) => (props.textalign ? props.textalign : "")};
  padding: ${(props) => (props.padding ? props.padding : "")};
  font-size: 14px;
  color: ${(props) => (props.textColor ? props.textColor : "")};
  font-family: graphik;
  font-weight: normal;
  line-spacing: -0.35px;
  margin: 5px 10px 10px 0px;
  background-color: ${(props) => (props.bgColor ? props.bgColor : "")};
`;

export const PageTitle = styled.div`
  color: rgb(45, 58, 75);
  font-size: 24px;
  font-family: graphik-bold;
  font-weight: bold;
  letter-spacing: -0.32px;
  line-height: 26px;
  margin-top: 2px;
`;

export const BoldDivInline = styled.div`
  height: 17px;
  font-size: 14px;
  font-family: graphik-medium;
  font-weight: 500;
  letter-spacing: -0.23px;
  margin: ${(props) => (props.margin ? props.margin : null)};
  padding: ${(props) => (props.padding ? props.padding : null)};
  display: inline-block;
`;

export const FilterContainer = styled.div`
  position: absolute;
  top: 100px;
  right: 50px;
  border-radius: 5px;
  border: 1px solid #dce2e7;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  background: #fff;
  flex-direction: column;
  flex-shrink: 0;
  display: flex;
  z-index: 999;
  max-height: 350px;
  max-width: 300px;
  width: ${(props) => props.filterWidth || ""};
`;

export const SubmitDocumentContentWrapper = styled.div`
  // width: 100%;
`;

export const SubmitDocumentFormWrapper = styled.div`
  width: 800px;
  padding-left: 25px;
`;

export const ButtonWrapper = styled.div`
  padding: ${(props) => (props.padding ? props.padding : "37px 0 0 0")};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : "")};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "")};
  float: ${(props) => (props.float ? props.float : "right")};
  position: ${(props) => (props.position ? props.position : "")};
  display: ${(props) => (props.display ? props.display : "")};
  margin-right: ${(props) => (props.marginRight ? props.marginRight : "")};
  width: ${(props) => (props.width ? props.width : "")};
  margin: ${(props) => (props.margin ? props.margin : "")};
  padding: ${(props) => (props.padding ? props.padding : "")};
`;

export const DivTable = styled.div`
  display: table;
  width: auto;
  border: 1px solid rgb(227, 234, 237);
  border-radius: 5px;
`;

export const DivRow = styled.div`
  display: table-row;
  width: auto;
  clear: both;
  background-color: ${(props) => (props.bgColor ? props.bgColor : "")};
  border-radius: ${(props) => (props.br ? props.br : "")};
`;

export const DivCell = styled.div`
  //float: left; /* fix for  buggy browsers */
  display: table-cell;
  // width: 180px;
  min-width: 100px;
  padding: 8px;
  margin: 8px;
  background-color: transparent;
  min-height: 30px;
  border: ${(props) => (props.border ? props.border : "")};
`;
