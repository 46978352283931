import { put, takeEvery, call, all } from "redux-saga/effects";
import { buildNotification } from "../../../config/notification";
import { store } from "react-notifications-component";
import {
  fetchingUserGroup,
  userGroupFetchingComplete,
  fetchingBaseRoleList,
  fetchBaseRoleList,
  baseRoleFetchingComplete,
  userGroupSave,
  deleteSuccess,
  deleteFailed,
  selectUsersFetchingComplete,
  fetchingSelectUsersList,
  fetchSelectUsersList,
  setUserGroupDataForEdit,
  saveUpdatedUserGroupStatus,
  userGroupList,
  userGroupSaveFailed,
  setUserGroupDataForEditFailed,
} from "../store/actions";
import {
  INIT_FETCH_USER_GROUP_LIST,
  INIT_GET_BASE_ROLE,
  INIT_USER_GROUP_SAVE,
  INIT_USER_GROUP_DELETE,
  INIT_SELECT_USERS_LIST,
  INIT_USER_GROUP_EDIT,
  INIT_UPDATE_GROUP_STATUS,
} from "../store/actionTypes";

import axios from "../../../config/axios";
import {
  getRequestDetails,
  postRequestDetails,
  putRequestDetails,
} from "../../../config/requestHeaders";

export default function* watchUserGroupSaga() {
  yield all([
    takeEvery(INIT_FETCH_USER_GROUP_LIST, getUserGroupListSaga),
    takeEvery(INIT_GET_BASE_ROLE, getBaseRoleListSaga),
    takeEvery(INIT_USER_GROUP_SAVE, saveUserGroupSaga),
    takeEvery(INIT_USER_GROUP_DELETE, initUserGroupDeleteSaga),
    takeEvery(INIT_SELECT_USERS_LIST, getSelectUsersListSaga),
    takeEvery(INIT_USER_GROUP_EDIT, getUserGroupDataForEdit),
    takeEvery(INIT_UPDATE_GROUP_STATUS, updateUserGroupStatusSaga),
  ]);
}

function* getUserGroupListSaga() {
  yield put(fetchingUserGroup());
  const url = `/Users/ListGroups`;
  try {
    const response = yield call(axios, url, getRequestDetails);
    if (response) {
      yield put(userGroupList(response.data));
    }
    yield put(userGroupFetchingComplete());
  } catch (error) {
    const notification = buildNotification({
      message: "notification.errorOccurred",
      type: "danger",
    });
    store.addNotification({
      ...notification,
      dismiss: {
        duration: 1000,
      },
    });
    yield put(userGroupFetchingComplete());
  }
}

function* getBaseRoleListSaga() {
  yield put(fetchingBaseRoleList());
  const url = "/Users/baserole";
  try {
    const response = yield call(axios, url, getRequestDetails);
    const data = yield response.data.map((item) => ({
      value: item.baseRoleName,
      id: item.baseRoleId,
    }));
    yield put(fetchBaseRoleList(data));
    yield put(baseRoleFetchingComplete());
  } catch (error) {
    yield put(baseRoleFetchingComplete());
  }
}

function* saveUserGroupSaga(action) {
  yield (putRequestDetails.data = {
    userId: action.userId,
    ...action.formdata,
  });
  const url = `/Users/SaveGroup`;
  try {
    const response = yield call(axios, url, putRequestDetails);
    if (response) {
      if (response.status) {
        if (action.saveSuccess) {
          action.saveSuccess(response.status);
          yield put(userGroupSave());
        }
        if (action.isEditMode) {
          const notification = buildNotification({
            message: "notification.userGrp.updated",
            type: "success",
          });
          store.addNotification({
            ...notification,
            dismiss: {
              duration: 1000,
            },
          });
        } else {
          const notification = buildNotification({
            message: "notification.userGrp.created",
            type: "success",
          });
          store.addNotification({
            ...notification,
            dismiss: {
              duration: 1000,
            },
          });
        }
      } else {
        yield put(userGroupSaveFailed());
        action.saveSuccess(false)
      }
    } else {
      const notification = buildNotification({
        message: "notification.userGrp.existingUG",
        type: "danger",
      });
      store.addNotification({
        ...notification,
        dismiss: {
          duration: 1000,
        },
      });
      yield put(userGroupSaveFailed());
    }
  } catch (error) {
    yield put(userGroupSaveFailed());
  }
}

function* initUserGroupDeleteSaga(action) {
  const url = `/Users/DeleteGroup`;
  postRequestDetails.data = {
    userId: action.userId,
    listOfIds: action.id,
  };
  try {
    const response = yield call(axios, url, postRequestDetails);
    if (response && response.status === 200) {
      const id = action.id.split(",").map((id) => parseInt(id));
      yield put(deleteSuccess(id));
      if (action.callback) {
        action.callback(response.status);
      }
      const notification = buildNotification({
        message: "notification.userGrp.deleted",
        type: "success",
      });
      store.addNotification({
        ...notification,
        dismiss: {
          duration: 1000,
        },
      });
    }
  } catch (error) {
    const notification = buildNotification({
      message: "notification.errorOccurred",
      type: "danger",
    });
    store.addNotification({
      ...notification,
      dismiss: {
        duration: 1000,
      },
    });
    yield put(deleteFailed());
  }
}

function* getSelectUsersListSaga(action) {
  yield put(fetchingSelectUsersList());
  const url = `/Users?baseRoleID=${action.roleID}`;
  try {
    const response = yield call(axios, url, getRequestDetails);
    yield put(fetchSelectUsersList(response.data));
    yield put(selectUsersFetchingComplete());
  } catch (error) {
    yield put(selectUsersFetchingComplete());
  }
}

function* getUserGroupDataForEdit(action) {
  const url = `/Users/GroupById?groupID=${action.id}`;
  try {
    const response = yield call(axios, url, getRequestDetails);
    if (response && response.status === 200) {
      yield put(setUserGroupDataForEdit(response.data));
      if (action.callback) {
        action.callback(response.status);
      }
    }
  } catch (error) {
    const notification = buildNotification({
      message: "notification.errorOccurred",
      type: "danger",
    });
    store.addNotification({
      ...notification,
      dismiss: {
        duration: 1000,
      },
    });
    yield put(setUserGroupDataForEditFailed());
  }
}

function* updateUserGroupStatusSaga(action) {
  yield (postRequestDetails.data = {
    ...action.formData,
  });
  try {
    const response = yield call(
      axios,
      "/Users/changeGroupStatus",
      postRequestDetails
    );
    if (response) {
      yield put(saveUpdatedUserGroupStatus());
      if (action.updateSuccess) {
        action.updateSuccess(response.status);
      }
      const notification = buildNotification({
        message: "notification.userGrp.statusUpdated",
        type: "success",
      });
      store.addNotification({
        ...notification,
        dismiss: {
          duration: 1000,
        },
      });
    }
  } catch (error) {
    action.updateSuccess && action.updateSuccess();
  }
}
