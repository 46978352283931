import React, { forwardRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Div from "../../components/Div";
import { getLocale } from "../../helper";

import DatePicker, { registerLocale } from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "./customDatePickerWidth.css";

import moment from "moment";
import { momentDateFormat } from "../DateRangeFilter/config";
import DatePickerCustom from "./DatepickerCustom";
import { getTranslationFile } from "./config.js";

export const Datepicker = (props, _ref) => {
  const { t } = useTranslation();
  const sessionDetails =
    JSON.parse(sessionStorage.getItem("sessionDetails")) || {};
  const { defaultMinDate: minDate, languageId } = sessionDetails || {};
  const locale = getLocale(languageId);
  const translationFile = getTranslationFile[locale];

  registerLocale(locale, {
    ...translationFile,
    options: { ...translationFile.options, weekStartsOn: 1 },
  });

  const ref = React.createRef();
  const [inputDate, setInputDate] = useState(null);

  const selected =
    props.dateValue && !moment.isMoment(props.dateValue)
      ? moment(props.dateValue)
      : props.dateValue || "";
  useEffect(() => {
    selected
      ? setInputDate(selected.format(momentDateFormat))
      : setInputDate("");
  }, [selected]);
  const handleDateInputChange = (value) => {
    const date = moment(value, momentDateFormat);

    if (date.isValid() && value.length === 10) {
      props.setDate({
        target: { id: props.id, name: props.name, value: moment(date) },
      });
    }
    setInputDate(value);
  };
  const handleDatePickerDateChange = (date) => {
    setInputDate(moment(date).format(momentDateFormat));

    props.setDate({
      target: { id: props.id, name: props.name, value: moment(date) },
    });
  };
  return (
    <Div
      tabindex="0"
      className="customDatePickerWidth"
      margin={props.margin ? props.margin : "0px"}
      position={props.position ? props.position : null}
      divWidth={props.containerWidth ? props.containerWidth : "auto"}
    >
      <DatePicker
        locale={locale}
        selected={selected && selected.toDate()}
        openToDate={selected && selected.toDate()}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        name={props.name ? props.name : ""}
        onChange={(date) => handleDatePickerDateChange(date)}
        popperPlacement="auto"
        popperModifiers={{
          offset: {
            enabled: true,
            offset: "5px, 10px",
          },
          preventOverflow: {
            enabled: true,
            escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
            boundariesElement: "scrollParent",
          },
        }}
        placement="auto"
        autoComplete="off"
        placeholderText={props.placeholder}
        customInput={
          props.content ? (
            props.content
          ) : (
            <DatePickerCustom
              hint={props.placeholder}
              onInputChange={handleDateInputChange}
              inputValue={inputDate}
              ref={ref}
              margin={props.datePickerCustomMargin}
              padding={props.datePickerCustomPadding}
            />
          )
        }
        maxDate={props.maxDate}
        minDate={props.minDate || minDate}
        todayButton={t("common.todayLabel")}
        selectsStart={props.selectsStart}
        selectsEnd={props.selectsEnd}
        startDate={props.startDate}
        endDate={props.endDate}
      />
    </Div>
  );
};

export default forwardRef(Datepicker);
