export const customStyles = {
  container: (provided) => ({
    ...provided,
    border: "none",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: "transparent",
  }),
  placeholder: (provided) => ({
    ...provided,
    fontFamily: "graphik",
    fontSize: "12px",
  }),
  control: (provided, state) => {
    return {
      ...provided,
      border: "none",
      boxShadow: "none",
    };
  },
  menuPortal: (base) => ({ ...base, zIndex: 1002 }),
};
