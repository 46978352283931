import { call, put, all, takeLatest, takeEvery } from "redux-saga/effects";

import axios from "../../../config/axios";
import { buildNotification } from "../../../config/notification";
import { store } from "react-notifications-component";
import {
  INIT_HELPDESK_OPENCASES_HISTORY_FETCH,
  SUBMIT_REQUEST,
  FETCH_QUERY_TYPE_LIST,
  FETCH_HELPDESK_TLOG,
  INIT_SAVE_COMMENT,
  GET_COMMENTS,
  INIT_FETCH_QUERY_FIELDS,
  INIT_ADD_ATTACHMENT,
  INIT_HELPDESK_OPEN_REQUEST_MARK_AS_COMPLETE,
  INIT_GET_HELPDESK_QUERY_TYPE,
  INIT_GET_SERVICE_QUERY_BY_ID,
  INIT_SERVICE_NOW_SAVE_COMMENT,
} from "./constants";
import {
  initFetchComplete,
  FetchCasesSuccess,
  FetchCasesFailure,
  queryTypeList,
  queryFieldsList,
  fetchTLogSuccess,
  fetchTLogFailure,
  submitSuccess,
  submitFailure,
  fetchingHelpdeskDetailsByID,
  fetchingHelpdeskDetailsByIDCompleted,
  commentSaved,
  commentSaveFailed,
  fetchingqueryFields,
  queryFieldsListFetchingCompleted,
  attachmentAdded,
  savedHelpdeskOpenRequestMarkAsComplete,
  fetchComments,
  fetchCommentsEnd,
  FetchingGetHelpdeskQuery,
  FetchGetHelpdeskQuery,
  helpdeskQueryFetchCompleted,
  updateHelpdeskCases,
  fetchingCases,
  FetchingGetServiceNowQueryByID,
  FetchGetServiceNowQueryByID,
  ServiceNowQueryByIDFetchCompleted,
  servicenowCommentSaved,
  serviceNowCommentSaveFailed,
  attachmentAddCompleted,
} from "./action";
import { putRequestDetails } from "../../../config/requestHeaders";
export default function* rootHelpDeskSaga() {
  yield all([
    takeLatest(INIT_HELPDESK_OPENCASES_HISTORY_FETCH, getinitOpenCases),
    takeEvery(FETCH_QUERY_TYPE_LIST, fetchqueryType),
    takeEvery(INIT_FETCH_QUERY_FIELDS, fetchqueryfield),
    takeEvery(SUBMIT_REQUEST, submitRequest),
    takeEvery(FETCH_HELPDESK_TLOG, fetchTLogCases),
    takeEvery(GET_COMMENTS, initGetCommentsSaga),
    takeEvery(INIT_SAVE_COMMENT, initCommentSaveSaga),
    takeEvery(INIT_SERVICE_NOW_SAVE_COMMENT, initServiceNowCommentSaveSaga),
    takeEvery(INIT_ADD_ATTACHMENT, initAddAttachmentSaga),
    takeEvery(
      INIT_HELPDESK_OPEN_REQUEST_MARK_AS_COMPLETE,
      helpdeskOpenRequestMarkasCompleteSaga
    ),
    takeEvery(INIT_GET_HELPDESK_QUERY_TYPE, initGetHelpdeskQueryAttributeSaga),
    takeEvery(INIT_GET_SERVICE_QUERY_BY_ID, initGetServicenowByIdSaga),
  ]);
}
let sessionDetails = JSON.parse(sessionStorage.getItem("sessionDetails")) || {};
let userId = sessionDetails && sessionDetails.userId;
let languageId =
  sessionDetails && sessionDetails.languageId
    ? sessionDetails.languageId
    : "enGB";

const postRequestDetails = {
  method: "POST",
  cache: "no-cache",
  headers: {
    "Content-Type": "application/json",
  },
};

const getRequestDetails = {
  method: "GET",
  cache: "no-cache",
};

function* getinitOpenCases(action) {
  yield put(fetchingCases());
  const zeroPad = (num, places) => {
    let zero = places - num.toString().length + 1;
    return Array(+(zero > 0 && zero)).join("0") + num;
  };
  let url;
  const pageSize =
    action.filterData.pageSize !== null &&
      action.filterData.pageSize !== undefined
      ? action.filterData.pageSize
      : 1000;
  const pageIndex =
    action.filterData.pageIndex !== null &&
      action.filterData.pageIndex !== undefined
      ? action.filterData.pageIndex
      : 1;
  const statusID =
    action.filterData.statusID !== null &&
      action.filterData.statusID !== undefined
      ? action.filterData.statusID
      : 0;
  if (action.isExtHelpDeskLinkUsed === false) {
    url =
      action.filterData.statusID !== null &&
        action.filterData.statusID !== undefined
        ? `/HelpDesk?pageSize=${pageSize}&pageIndex=${pageIndex}&statusID=${statusID}&Language=${languageId}`
        : `/HelpDesk?pageSize=${pageSize}&pageIndex=${pageIndex}&Language=${languageId}`;
    let keys = action.filterData && Object.keys(action.filterData);
    keys &&
      keys.forEach((data) => {
        if (data === "FromDate" || data === "ToDate" || data === "quertTypeID") {
          if (action.filterData[data]) {
            url = url + "&" + data + "=" + action.filterData[data];
          }
        }
      });
  } else {
    url = `/HelpDesk/GetServiceNowTickets`;
  }
  try {
    const response = yield call(axios, url, getRequestDetails);
    let initCasesList = [];
    let modifyInternalList = [];
    let getRowCount = [];
    if (response && response.data && response.data.listHelpdeskTickets) {
      initCasesList = response.data.listHelpdeskTickets;
      getRowCount =
        response.data.rowCount.length > 0 ? response.data.rowCount : [];
      modifyInternalList =
        initCasesList &&
        initCasesList.length > 0 &&
        initCasesList.map((item) => {
          return {
            ...item,
            searchId: `MSP${zeroPad(item.helpdeskId, 7)}`,
          };
        });
      yield put(FetchCasesSuccess(modifyInternalList, getRowCount));
      yield put(updateHelpdeskCases(modifyInternalList, getRowCount));
    } else {
      initCasesList = response.data;
      yield put(FetchCasesSuccess(initCasesList, []));
      yield put(updateHelpdeskCases(initCasesList, []));
    }

    yield put(initFetchComplete());
  } catch (error) {
    yield put(FetchCasesFailure("Failed to Fetch Data"));
  }
}

function* fetchqueryType(action) {
  let sessionDetails = JSON.parse(sessionStorage.getItem("sessionDetails")) || {};
  let languageId =
    sessionDetails && sessionDetails.languageId
      ? sessionDetails.languageId
      : "enGB";
  const url = `/Common/listHelpdeskQuery?languageID=${languageId}`;
  try {
    const response = yield call(axios, url, getRequestDetails);
    yield put(queryTypeList(response.data));
  } catch (error) {
    action.callback && action.callback();
  }
}

function* fetchqueryfield(action) {
  yield put(fetchingqueryFields());
  let queryFieldlist = [];
  const url = `/HelpDesk/attributes?querytypeId=${action.queryField}&languageID=${languageId}`;
  try {
    const response = yield call(axios, url, getRequestDetails);
    if (response && response.data) {
      queryFieldlist = response.data;
    }
    yield put(queryFieldsList(queryFieldlist));
    if (action.fetchDynamicFieldSuccess) {
      action.fetchDynamicFieldSuccess(response.status);
    }
    yield put(queryFieldsListFetchingCompleted());
  } catch (error) {
    yield put(queryFieldsListFetchingCompleted());
  }
}

function* fetchTLogCases(action) {
  yield put(fetchingHelpdeskDetailsByID());
  const url = `/HelpDesk/helpdeskDetailByID?helpdeskId=${action.reqID}`;
  try {
    const response = yield call(axios, url, getRequestDetails);

    if (response && response.status === 200) {
      yield put(fetchTLogSuccess(response.data));
      if (action.fetchHelpdeskByIdSuccess) {
        action.fetchHelpdeskByIdSuccess(response.status, response.data);
      }
    }
    if (response.status === 204) {
      const notification = buildNotification({
        message: "notification.noDataDisplayLabel",
        type: "danger",
      });
      store.addNotification({
        ...notification,
      });
    }
    yield put(fetchingHelpdeskDetailsByIDCompleted());
  } catch (error) {
    yield put(fetchTLogFailure("Failed to Fetch Data"));
  }
}

function* submitRequest(action) {
  let url;
  if (action.isExtHelpDeskLinkUsed === false) {
    url = `/HelpDesk`;
    yield (postRequestDetails.data = {
      ...action.formData,
      clientName: action.clientName
    });
  } else if (action.isExtHelpDeskLinkUsed === true) {
    url = `/HelpDesk/SaveServiceNowTicket`;
    yield (postRequestDetails.data = {
      ...action.formData,
      userName: "",
      email: action.primaryEmail
    });
  }
  try {
    const response = yield call(axios, url, postRequestDetails);
    if (response && response.status) {
      yield put(submitSuccess());
      const notification = buildNotification({
        message: "notification.helpdesk.querySubmitSuccess",
        type: "success",
      });
      store.addNotification({
        ...notification,
        dismiss: {
          duration: 1000,
        },
      });
      if (action.saveSuccess) {
        action.saveSuccess(response.status);
      }
    } else {
      yield put(submitFailure());
    }
  } catch (error) {
    yield put(submitFailure());
  }
}

// save comment
function* initCommentSaveSaga(action) {
  const url = `/HelpDesk/SaveComments?helpdeskId=${action.ID}&userType=supplier&Comments=${action.payload}&userId=${userId}`;
  try {
    const response = yield call(axios, url, postRequestDetails);
    if (response && response.status) {
      yield put(commentSaved());
      if (action.commentSaveSuccess) {
        action.commentSaveSuccess(response.status);
      }
    }
  } catch (error) {
    yield put(commentSaveFailed());
  }
}
function* initServiceNowCommentSaveSaga(action) {
  yield (putRequestDetails.data = {
    ...action.formdata,
  });
  const url = `/HelpDesk/UpdateServiceNowTicket`;

  try {
    const response = yield call(axios, url, putRequestDetails);
    if (response) {
      if (response.status) {
        yield put(servicenowCommentSaved());
        if (action.callback) {
          action.callback(true);
          const notification = buildNotification({
            message: "notification.reconciliation.commentSaved",
            type: "success",
          });
          store.addNotification({
            ...notification,
            dismiss: {
              duration: 1000,
            },
          });
        }
      }
      else {
        action.callback(false);
        const notification = buildNotification({
          message: "notification.errorOccurred",
          type: "danger",
        });
        store.addNotification({
          ...notification,
          dismiss: {
            duration: 1000,
          },
        });
      }
    }
  } catch (error) {
    yield put(serviceNowCommentSaveFailed());
  }
}

//get comment

function* initGetCommentsSaga(action) {
  const url = `/HelpDesk/GetComments?helpdeskId=${action.id}`;
  try {
    const response = yield call(axios, url, getRequestDetails);
    if (response && response.status === 200) {
      yield put(fetchComments(response.data));
    }
    yield put(fetchCommentsEnd());
  } catch (error) {
    yield put(fetchCommentsEnd());
  }
}

// Add Attachment
function* initAddAttachmentSaga(action) {
  const url = `/HelpDesk/AddAttachment`;
  try {
    postRequestDetails.data = {
      ...action.formData,
      userId: userId,
    };
    const response = yield call(axios, url, postRequestDetails);
    if (response && response.status === 200) {
      yield put(attachmentAdded());
      if (action.callback) {
        action.callback(response.status);
      }
      const notification = buildNotification({
        message: "notification.helpdesk.attachmentAdded",
        type: "success",
      });
      store.addNotification({
        ...notification,
        dismiss: {
          duration: 1000,
        },
      });
    }
    yield put(attachmentAddCompleted());
  } catch (error) {
    const notification = buildNotification({
      message: "notification.errorOccurred",
      type: "danger",
    });
    store.addNotification({
      ...notification,
      dismiss: {
        duration: 1000,
      },
    });
    yield put(attachmentAddCompleted());
  }
}

function* helpdeskOpenRequestMarkasCompleteSaga(action) {
  const url = `/HelpDesk/MarkComplete?helpdeskId=${action.helpdeskID}&userId=${userId}`;
  try {
    const response = yield call(axios, url, postRequestDetails);
    if (response) {
      yield put(savedHelpdeskOpenRequestMarkAsComplete());
      const notification = buildNotification({
        message: "notification.helpdesk.requestCompleted",
        type: "success",
      });
      store.addNotification({
        ...notification,
        dismiss: {
          duration: 1000,
        },
      });
      if (action.saveSuccess) {
        action.saveSuccess(response);
      }
    }
  } catch (error) {
    yield put(submitFailure("error"));
  }
}

function* initGetHelpdeskQueryAttributeSaga(action) {
  yield put(FetchingGetHelpdeskQuery());
  const url = `/Attribute`;
  try {
    const response = yield call(axios, url, getRequestDetails);
    if (response && response.status) {
      yield put(FetchGetHelpdeskQuery(response.data));
    }
    yield put(helpdeskQueryFetchCompleted());
  } catch (error) {
    yield put(helpdeskQueryFetchCompleted());
  }
}

function* initGetServicenowByIdSaga(action) {
  yield put(FetchingGetServiceNowQueryByID());

  const url = `/HelpDesk/GetServiceNowTicketDetails?ticketID=${action.reqID}`;
  try {
    const response = yield call(axios, url, getRequestDetails);

    if (response && response.status === 200) {
      yield put(FetchGetServiceNowQueryByID(response.data));
      if (action.fetchServicenowByIdSuccess) {
        action.fetchServicenowByIdSuccess(response.data);
      }
    }
    if (response.status === 204) {
      const notification = buildNotification({
        message: "notification.noDataDisplayLabel",
        type: "danger",
      });
      store.addNotification({
        ...notification,
      });
    }
    yield put(ServiceNowQueryByIDFetchCompleted());
  } catch (error) {
    yield put(ServiceNowQueryByIDFetchCompleted());
  }
}
