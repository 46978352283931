import { ApiConfig } from "./apiConfig";
import axios from "axios";

const sessionDetails =
  JSON.parse(sessionStorage.getItem("sessionDetails")) || "";
const clientToken = JSON.parse(sessionStorage.getItem("clientDetails")) || "";

const instance = axios.create({
  baseURL: ApiConfig(),
  headers: {
    Authorization: `Bearer ${sessionDetails.token}`,
    "X-App-Info": clientToken.token,
  },
});

instance.interceptors.request.use(
  (config) => {
    if (config.method.toLowerCase() === "post") {
      Object.assign(config.headers, { "Content-Type": "application/json" });
    } else if (config.method.toLowerCase() === "put") {
      Object.assign(config.headers, {
        "Content-Type": "application/json-patch+json",
      });
    } else {
      Object.assign(config.headers, { cache: "no-cache" });
    }
    return Promise.resolve(config);
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;
