export const INIT_EMAIL_INVITE = "INIT_EMAIL_INVITE";
export const EMAIL_INVITE_SENT = "EMAIL_INVITE_SENT";

export const INIT_LIST_FETCH = "INIT_LIST_FETCH";
export const FETCHING_LIST = "FETCHING_LIST";
export const FETCH_LIST = "FETCH_LIST";
export const UPDATE_LIST_DATA = "UPDATE_LIST_DATA";

export const INIT_EDIT_LIST_FETCH = "INIT_EDIT_LIST_FETCH";
export const FETCHING_EDIT_LIST = "FETCHING_EDIT_LIST";
export const FETCH_EDIT_LIST = "FETCH_EDIT_LIST";
export const FETCHING_EDIT_LIST_SUCCESS = "FETCHING_EDIT_LIST_SUCCESS";
export const FETCH_EDIT_DATA_BY_NAME = "FETCH_EDIT_DATA_BY_NAME";

export const INIT_TAKE_OWNERSHIP = "INIT_TAKE_OWNERSHIP";
export const FETCH_OWNERSHIP_STATUS = "FETCH_OWNERSHIP_STATUS";
export const SET_OWNERSHIP_DATA = "SET_OWNERSHIP_DATA";

export const INIT_ATTRIBUTES_DETAILS = "INIT_ATTRIBUTES_DETAILS";
export const FETCHING_ATTRIBUTES_DETAILS = "FETCHING_ATTRIBUTES_DETAILS";
export const FETCH_ATTRIBUTES_DETAILS = "FETCH_ATTRIBUTES_DETAILS";
export const FETCHING_ATTRIBUTES_DETAILS_SUCCESS =
  "FETCHING_ATTRIBUTES_DETAILS_SUCCESS";

export const INIT_CONTACT_DETAILS = "INIT_CONTACT_DETAILS";
export const FETCHING_CONTACT_DETAILS = "FETCHING_CONTACT_DETAILS";
export const FETCH_CONTACT_DETAILS = "FETCH_CONTACT_DETAILS";
export const SAVE_CONTACT = "SAVE_CONTACT";
export const REMOVE_CONTACT = "REMOVE_CONTACT";

export const INIT_ATTACHMENT_DETAILS = "INIT_ATTACHMENT_DETAILS";
export const FETCHING_ATTACHMENT_DETAILS = "FETCHING_ATTACHMENT_DETAILS";
export const FETCH_ATTACHMENT_DETAILS = "FETCH_ATTACHMENT_DETAILS";
export const SAVE_ATTACHMENT = "SAVE_ATTACHMENT";
export const REMOVE_ATTACHMENT = "REMOVE_ATTACHMENT";
export const DOWNLOAD_VMD_ATTACHMENT = "DOWNLOAD_VMD_ATTACHMENT";

export const INIT_LOCATION_DETAILS = "INIT_LOCATION_DETAILS";
export const FETCHING_LOCATION_DETAILS = "FETCHING_LOCATION_DETAILS";
export const FETCH_LOCATION_DETAILS = "FETCH_LOCATION_DETAILS";
export const SAVE_LOCATION = "SAVE_LOCATION";
export const REMOVE_LOCATION = "REMOVE_LOCATION";
export const SAVE_BULK_LOCATIONS = "SAVE_BULK_LOCATIONS";

export const SAVE_DYNAMIC_TABS_DATA = "SAVE_DYNAMIC_TABS_DATA";

export const GET_WORKFLOW_STEP_DATA = "GET_WORKFLOW_STEP_DATA";
export const SET_WORKFLOW_STEP_DATA = "SET_WORKFLOW_STEP_DATA";
export const SAVE_WORKFLOW_STEP_DATA = "SAVE_WORKFLOW_STEP_DATA";

export const GET_DIFF_DATA = "GET_DIFF_DATA";
export const SET_DIFF_DATA = "SET_DIFF_DATA";
export const GET_CONTACT_DIFF_DATA = "GET_CONTACT_DIFF_DATA";
export const SET_CONTACT_DIFF_DATA = "SET_CONTACT_DIFF_DATA";
export const GET_LOCATION_DIFF_DATA = "GET_LOCATION_DIFF_DATA";
export const SET_LOCATION_DIFF_DATA = "SET_LOCATION_DIFF_DATA";

export const INIT_NEW_COMPANY_CREATION = "INIT_NEW_COMPANY_CREATION";
export const FETCH_NEW_COMPANY_CREATION = "FETCH_NEW_COMPANY_CREATION";

export const INIT_FETCH_FLEX_FIELDS = "INIT_FETCH_FLEX_FIELDS";
export const SET_FLEX_FIELDS = "SET_FLEX_FIELDS"; 

export const INIT_TAKE_PROXY = "INIT_TAKE_PROXY";
export const TAKE_PROXY_STATUS = "TAKE_PROXY_STATUS";

export const INIT_REJECT = "INIT_REJECT";
export const REJECT_SUCCESS = "REJECT_SUCCESS";

export const INIT_REJECT_REMOVE = "INIT_REJECT_REMOVE";
export const REJECT_REMOVE_SUCCESS = "REJECT_REMOVE_SUCCESS";

export const INIT_WOKFLOW_STATE = "INIT_WOKFLOW_STATE";
export const SET_WORKFLOW_STATE = "SET_WORKFLOW_STATE";

export const INIT_INVITE_REFRESH = "INIT_INVITE_REFRESH";
export const INVITE_REFRESH_COMPLETE = "INVITE_REFRESH_COMPLETE"; 

export const INIT_SAVE_LOGO = "INIT_SAVE_LOGO";
export const SAVE_LOGO_COMPLETE = "SAVE_LOGO_COMPLETE"; 

export const INIT_AUDIT_TRAIL_LIST = "INIT_AUDIT_TRAIL_LIST";
export const SET_AUDIT_TRAIL_LIST = "SET_AUDIT_TRAIL_LIST";
export const UPDATE_AUDIT_TRAIL_LIST = "UPDATE_AUDIT_TRAIL_LIST";

export const INIT_AUDIT_TRAIL_DATA = "INIT_AUDIT_TRAIL_DATA";
export const SET_AUDIT_TRAIL_DATA = "SET_AUDIT_TRAIL_DATA";

export const INIT_CLEAR_AUDIT_TRAIL = "INIT_CLEAR_AUDIT_TRAIL";
export const CLEAR_AUDIT_TRAIL_SUCCESS = "CLEAR_AUDIT_TRAIL_SUCCESS";

export const INIT_GET_LOGO = "INIT_GET_LOGO";
export const SET_LOGO = "SET_LOGO";

export const INIT_COMBINED_SAGA_CALL = "INIT_COMBINED_SAGA_CALL";

export const INIT_CLEAR_STORE = "INIT_CLEAR_STORE";
