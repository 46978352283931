import React from "react";
import MultiSelect from "@khanacademy/react-multi-select";
import Div from "../Div";
import Label from "../Label";
import P from "../P";
import { useTranslation } from "react-i18next";
const Lookup = (props) => {
  const { t } = useTranslation();
  return (
    <Div {...props.containerStyle}>
      <Div {...props.inputHolderStyle}>
        <Label {...props.labelStyle}>{t(props.title)}</Label>
        <Div display="block" padding="5px">
          <MultiSelect
            valueRenderer={(selected, options) =>
              props.renderSelected(selected, options, props.title)
            }
            options={props.options || []}
            disabled={props.disabled}
            disableSearch={props.disableSearch}
            isLoading={props.isLoading}
            selected={props.selected || []}
            onSelectedChanged={(selected) =>
              props.handleDropdownChanges({ value: selected, id: props.id })
            }
            selectAllLabel={t("common.selectAllLabel")}
            overrideStrings={{
              search: t("common.searchBtn"),
            }}
          />
        </Div>
        <Div display="block">
          <Div
            border="1px solid rgb(220, 226, 231)"
            bgColor="#f1f1f1"
            divHeight="200px"
            overflowX="scroll"
          >
            {props.options &&
              props.options
                .filter((option) => props.selected.indexOf(option.value) !== -1)
                .map((option) => (
                  <P key={option.value} padding="5px 5px 5px 10px">
                    {option.label}
                  </P>
                ))}
          </Div>
        </Div>
      </Div>
    </Div>
  );
};

export default Lookup;
