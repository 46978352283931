import styled from "styled-components";

const UL = styled.ul`
  float: ${props =>
    props.float ? props.float.toString().toLowerCase() : "left"};
  margin-right: ${props => (props.mr ? props.mr : "")};
  margin-left: ${props => (props.ml ? props.ml : "")};
  margin-top: ${props => (props.mt ? props.mt : "")};
  margin-bottom: ${props => (props.mb ? props.mb : "")};
  margin: ${props => (props.margin ? props.margin : "")};
  padding: ${props => (props.padding ? props.padding : "")};
  position: ${props => (props.position ? props.position : "")};
  width: ${props => (props.ulWidth ? props.ulWidth : "")};
  height: ${props => (props.ulHeight ? props.ulHeight : "")};
  background-color: ${props => (props.bgColor ? props.bgColor : "")};
  display: ${props => (props.display ? props.display : "")};
  color: ${props => (props.color ? props.color : "")};
  font-size: ${props => (props.fontSize ? props.fontSize : "12px")};
  list-style-type: ${props =>
    props.listStyleType ? props.listStyleType : "none"};
  box-shadow: ${props => (props.boxShadow ? props.boxShadow : "")};
  -webkit-box-shadow: ${props => (props.boxShadow ? props.boxShadow : "")};
`;
export default UL;
