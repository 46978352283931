export const sessionDetails = {
  isAuthenticated: false,
  userName: "",
  roleId: "",
  baseRoleId: "",
  userId: "",
  loginId: "",
  menus: [],
  token: "",
  expirationDate: "",
  primaryEmail: "",
  lastPwdChangedOn: "",
  passwordExpiryDuration: 0,
  suppliers: [],
  languageId: "",
  defaultMinDate: "",
};

export const logoutWarningPopsupInSecs = //6;
  parseInt(process.env.REACT_APP_SHOW_LOGOUT_POPUP_IN_MINS || 5) * 60;

export const userLogoutTimeInSecs = //10;
  (parseInt(process.env.REACT_APP_LOGOUT_TIME_IN_MINS) || 20) * 60;

export const tokenExpirationTimeInSecs = //70;
  (parseInt(process.env.REACT_APP_EXPIRATION_TIME) || 59) * 60; // in seconds

export const dateFormat = process.env.REACT_APP_DATE_FORMAT || "MM/DD/YYYY";

export const maxPageSize = process.env.REACT_APP_DATA_TABLE_PER_PAGE || 10;
