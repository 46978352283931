import React from "react";
import A from "../A";

import IMG from "../IMG";
import icon from "../../assets/images/back-to-list.png";
import Div from "../Div";
const Back = (props) => {
  return (
    <A to={props.to}>
      <Div
        onClick={props.handleOnClick}
        border="1px solid rgb(196, 205, 213)"
        bgColor="#FFF"
        divHeight="30px"
        borderRadius="50%"
        divWidth="30px"
        display="inline-flex"
        alignItems="center"
        justifyContent="center"
        title={props.text || "Go Back"}
      >
        <IMG src={icon} alt="Go Back" imgHeight="12px" imgWidth="10px" />
      </Div>
    </A>
  );
};

export default Back;
