import styled from "styled-components";

const Div = styled.div`
  float: ${(props) =>
    props.float ? props.float.toString().toLowerCase() : ""};
  clear: ${(props) => props.clear || ""};
  white-space: ${(props) => props.whiteSpace || ""};
  margin-right: ${(props) => (props.mr ? props.mr : "")};
  margin-left: ${(props) => (props.ml ? props.ml : "")};
  margin-top: ${(props) => (props.mt ? props.mt : "")};
  margin-bottom: ${(props) => (props.mb ? props.mb : "")};
  margin: ${(props) => (props.margin ? props.margin : "")};
  padding: ${(props) => (props.padding ? props.padding : "")};
  position: ${(props) => (props.position ? props.position : "")};
  width: ${(props) => (props.divWidth ? props.divWidth : "")};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "")};
  min-width: ${(props) => (props.minWidth ? props.minWidth : "")};
  height: ${(props) => (props.divHeight ? props.divHeight : "")};
  max-height: ${(props) => (props.maxHeight ? props.maxHeight : "")};
  min-height: ${(props) => (props.minHeight ? props.minHeight : "")};
  background-color: ${(props) => (props.bgColor ? props.bgColor : "")};
  color: ${(props) => (props.divColor ? props.divColor : "")};
  display: ${(props) => (props.display ? props.display : "")};
  cursor: ${(props) => (props.cursor ? props.cursor : "")};
  flex-wrap: ${(props) => (props.flexWrap ? props.flexWrap : "")};
  flex-flow: ${(props) => (props.flow ? props.flow : "")};
  flex: ${(props) => (props.flex ? props.flex : "")};
  flex-grow: ${(props) => (props.grow ? props.grow : "")};
  flex-shrink: ${(props) => (props.shrink ? props.shrink : "")};
  flex-direction: ${(props) => (props.direction ? props.direction : "")};
  border: ${(props) => (props.border ? props.border : "")};
  border-top: ${(props) => (props.borderTop ? props.borderTop : "")};
  border-bottom: ${(props) => (props.borderBottom ? props.borderBottom : "")};
  border-right: ${(props) => (props.borderRight ? props.borderRight : "")};
  border-left: ${(props) => (props.borderLeft ? props.borderLeft : "")};
  border-top: ${(props) => (props.borderTop ? props.borderTop : "")};
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : "")};

  box-shadow: ${(props) => (props.boxShadow ? props.boxShadow : "")};
  overflow-x: ${(props) => (props.overflowX ? props.overflowX : "")};
  overflow-y: ${(props) => (props.overflowY ? props.overflowY : "")};
  overflow: ${(props) => (props.overflow ? props.overflow : "")};
  top: ${(props) => (props.top ? props.top : "")};
  bottom: ${(props) => (props.bottom ? props.bottom : "")};
  left: ${(props) => (props.left ? props.left : "")};
  right: ${(props) => (props.right ? props.right : "")};
  z-index: ${(props) => (props.zIndex ? props.zIndex : "")};
  color: ${(props) => (props.textColor ? props.textColor : "")};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "")};
  align-items: ${(props) => (props.alignItems ? props.alignItems : "")};
  align-self: ${(props) => props.selfAlign || ""};
  padding-bottom: ${(props) => (props.pb ? props.pb : "")};
  padding-top: ${(props) => (props.pt ? props.pt : "")};
  padding-left: ${(props) => (props.pl ? props.pl : "")};
  padding-right: ${(props) => (props.pr ? props.pr : "")};
  box-sizing: ${(props) => (props.boxSizing ? props.boxSizing : "border-box")};
  font-family: ${(props) => (props.fontFamily ? props.fontFamily : "")};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "")};
  letter-spacing: ${(props) =>
    props.letterSpacing ? props.letterSpacing : ""};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : "")};
  flex-direction: ${(props) =>
    props.flexDirection ? props.flexDirection : ""};
  flex-wrap: ${(props) => (props.flexWrap ? props.flexWrap : "")};
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : ""};
  text-align: ${(props) => (props.textalign ? props.textalign : "")};
  opacity: ${(props) => (props.opacity ? props.opacity : "")};
  text-overflow: ${(props) => (props.textOverflow ? props.textOverflow : "")};
  transition: ${(props) => (props.transition ? props.transition : "")};
  animation: ${(props) => (props.animation ? props.animation : "")};
  visibility: ${(props) => (props.visibility ? props.visibility : "")};
  word-wrap: ${(props) => (props.wordWrap ? props.wordWrap : "")};
  transform: ${(props) => (props.transform ? props.transform : "")};

  &:hover {
    background-color: ${(props) =>
      props.hoverBgColor ? props.hoverBgColor : ""};
    color: ${(props) =>
      props.hoverColor ? props.hoverColor : props.textColor};
    border: ${(props) => (props.borderOnHover ? props.borderOnHover : "")};
  }
  &:focus {
    border: ${(props) => (props.borderOnFocus ? props.borderOnFocus : "")};
    box-shadow: ${(props) =>
      props.boxShadowOnFocus ? props.boxShadowOnFocus : ""};
    background-color: ${(props) =>
      props.focusBgColor ? props.focusBgColor : ""};
  }
  &:focus-within {
    border: ${(props) => props.borderOnFocusWithin || ""};
    outline: ${(props) => props.outlineOnFocusWithin || "none"};
    border-radius: ${(props) => props.borderRadiusOnFocusWithin || ""};
  }
`;

export default Div;

export const Centered = styled.div`
  position: ${(props) => props.position || "absolute"};
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  width: ${(props) => props.centeredDivWidth || "auto"};
`;
