import React from "react";
import {
  termsOfUseContent,
  additionalInformation,
  policy,
} from "./TermsOfUseContent";

import { wrapperDivStyle } from "./style";
import TermsOfUseModal from "../../Popup";
import Div from "../../Div";
import P from "../../P";
import Span from "../../Span";
import UL from "../../UL";
import Li from "../../Li";
import { useTranslation } from "react-i18next";

const TermsOfUse = (props) => {
  const { t } = useTranslation();
  let content = (
    <Div {...wrapperDivStyle}>
      <P
        margin="10px 0 20px 0"
        fontSize="15px"
        color="#000000"
        fontWeight="600"
      >
        {t("common.headingLineOfTerms")}
      </P>
      <Div display="flex" divWidth="100%">
        <P margin="0" fontSize="13px" color="#000000" paraWidth="28%">
          {t("common.importantAgreementHeader")}
        </P>
        <Span width="70%" lineHeight="25px" margin="0 0 0 2%">
          {t("common.importantAgreementContent")}
        </Span>
      </Div>

      <UL margin="20px 0 0 0" padding="0">
        {termsOfUseContent.map((item, i) => {
          return (
            <Li
              key={i}
              listStyleType="square"
              display="flex"
              margin="0 0 20px 0"
            >
              <P fontSize="15px" paraWidth="28%" color="#000000">
                {t(item.header)}
              </P>
              <P
                fontFamily="graphik-light"
                margin="0 0 0 2%"
                paraWidth="70%"
                fontSize="15px"
                lineHeight="20px"
              >
                {t(item.content.body)}
              </P>
            </Li>
          );
        })}
      </UL>

      <UL padding="0">
        {additionalInformation.map((item, i) => {
          return (
            <Li
              key={i}
              margin="0 0 25px 0"
              listStyleType="square"
              color="#000000"
              fontSize="15px"
              fontWeight="600"
            >
              <P fontFamily="graphik-light" margin="0" lineHeight="25px">
                {t(item.body)}
              </P>
            </Li>
          );
        })}
      </UL>
      {policy.map((item, i) => {
        return (
          <Div key={i}>
            <P margin="0" fontSize="15px" color="#000000" fontWeight="600">
              {t(item.header)}
            </P>
            <P color="#000000" fontFamily="graphik-light" lineHeight="20px">
              {t(item.body)}
            </P>
          </Div>
        );
      })}
    </Div>
  );
  return (
    <TermsOfUseModal
      modalWidth="80%"
      containerHeight="450px"
      header={t("common.titleTermOfUse")}
      body={content}
      show={props.show}
      close={props.close}
    />
  );
};

export default TermsOfUse;
