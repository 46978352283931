import React, { useContext } from "react";
import Div from "../Div";
import LinkableCrumb from "./LinkableCrumb";
import CrumbText from "./CrumbText";
import { PageTitle } from "../Div/divStyles";

import Button from "../Button/Button";
import SearchList from "../../components/CheckList/SearchList";

import Loader from "../Loader";

import Filter from "../Filter";
import { flexCol, flexGrow, flexRow, flexShrink } from "../Layout/style";
import Span from "../Span";
import Back from "../Back";
import { AppContext } from "../../context";
import { useTranslation } from "react-i18next";
import DateRangeFilter from "../DateRangeFilter";
import ButtonDropdown from "../Button/ButtonDropdown";

const Breadcrumb = ({ crumbs }) => {
  const { t } = useTranslation();
  const context = useContext(AppContext);
  const backButton = context.url;
  const buttons = context.buttons;
  const hasFilter = context.hasFilter;
  const filterConfig = context.filterConfig;
  const enableLogicalSearch = context.enableLogicalSearch;
  const hasSearch = context.hasSearch;
  const searchConfig = context.searchConfig;

  const dateRangeConfig = context.dateRangeConfig;

  if (crumbs.length <= 1) {
    return null;
  }
  return (
    <Div
      id="breadcrumb"
      position="relative"
      display="flex"
      bgColor="#FFF"
      padding="5px"
      boxShadow=" 0px 2px 15px 0px rgba(0, 0, 0, 0.1);"
      {...flexShrink}
      minHeight="56px"
    >
      <Div {...flexRow} {...flexGrow} position="relative" alignitems="center">
        {backButton ? (
          <Div {...flexRow} {...flexShrink} margin="auto">
            <Back to={backButton} />
          </Div>
        ) : null}

        <Div {...flexCol} {...flexShrink} ml={backButton ? "6px" : "22px"}>
          <Div {...flexRow} {...flexShrink} mb="3px">
            {crumbs.map(({ name, path, title }, key) =>
              key + 1 === crumbs.length ? (
                <Span key={key}>
                  <CrumbText>{t(name)}</CrumbText>
                </Span>
              ) : (
                <Span key={key}>
                  <LinkableCrumb to={path}>{t(name)}</LinkableCrumb>
                  <CrumbText>&nbsp;&#92;&nbsp;</CrumbText>
                </Span>
              )
            )}
          </Div>
          <Div {...flexRow} {...flexGrow}>
            <PageTitle>{t(crumbs[crumbs.length - 1].title)}</PageTitle>
          </Div>
        </Div>
        <Div {...flexRow} {...flexGrow} margin="auto" justifyContent="center">
          {hasSearch && (
            <SearchList
              onType={searchConfig.onTypeHandler || false}
              value={searchConfig.value}
              placeholder={
                t(searchConfig.placeholder) || t("common.searchBtn") + "..."
              }
            />
          )}
        </Div>
        <Div
          {...flexRow}
          {...flexShrink}
          margin="auto"
          justifyContent="flex-end"
        >
          {dateRangeConfig.hasDateRange ? (
            <DateRangeFilter
              applyDateRangeFilter={dateRangeConfig.applyFuncRef}
            />
          ) : null}

          {buttons &&
            buttons.length > 0 &&
            buttons.map(
              (button, i) =>
                button &&
                (button.loading && !button.hide ? (
                  <Button key={i} margin="2px" {...button.style} border="none">
                    <Loader key={i} />
                  </Button>
                ) : !button.hide ? (
                  button.type === "dropdown" ? (
                    <ButtonDropdown
                      text={
                        button.isConverted
                          ? button.text
                          : t(button.text, " ... ")
                      }
                      options={button.options}
                      onClickHandler={button.onClickHandler}
                    />
                  ) : (
                    <Button
                      key={i}
                      margin="2px"
                      {...button.style}
                      onClick={button.onClickHandler}
                      disabled={button.disabled}
                    >
                      <Span {...flexRow} float="left">
                        {button.icon}
                      </Span>
                      <Span fontFamily="graphik-medium" margin="0px 5px">
                        {button.isConverted
                          ? button.text
                          : t(button.text, " ... ")}
                      </Span>
                    </Button>
                  )
                ) : null)
            )}

          {hasFilter && (
            <Div
              {...flexRow}
              {...flexShrink}
              cursor="pointer"
              alignitems="center"
              title="filter"
            >
              <Filter
                show={filterConfig.show}
                customEvent={filterConfig && filterConfig.customEvent}
                sections={
                  filterConfig.sections && filterConfig.sections.length
                    ? filterConfig.sections
                    : []
                }
                onFilter={filterConfig && filterConfig.onFilter}
                externalValues={
                  filterConfig && filterConfig.externalFilterValues
                }
                clearExtenalFilterValues={
                  filterConfig && filterConfig.clearExtenalFilterValues
                }
                filterPanelOpen={filterConfig && filterConfig.filterPanelOpen}
                hasLogical={enableLogicalSearch}
              />
            </Div>
          )}
        </Div>
      </Div>
    </Div>
  );
};

export default Breadcrumb;
