import React from "react";

import styled from "styled-components";
import Loader from "../Loader";

import selctIcon from "../../assets/images/select.png";
import Options from "./Options";

const Select = styled.select.attrs((props) => ({
  disabled: props.disabled ? props.disabled : "",
}))`
  appearance: none;
  background-image: url(${selctIcon});
  background-position: 98% center;
  background-repeat: no-repeat;
  flex: ${(props) => props.flex || ""};
  display: ${(props) => props.display || ""};
  flex-direction: ${(props) => props.direction || ""};
  flex-grow: ${(props) => props.grow || ""};
  flex-shrink: ${(props) => props.shrink || ""};
  width: ${(props) => (props.inputWidth ? props.inputWidth : "")};
  height: ${(props) => (props.inputHeight ? props.inputHeight : "16px")};
  color: ${(props) =>
    props.textColor ? props.textColor : "rgb(99, 115, 129)"};
  font-size: ${(props) => (props.textSize ? props.textSize : "12px")};
  font-family: ${(props) => (props.family ? props.family : "graphik")};
  font-weight: normal;
  align-self: ${(props) => props.selfAlign || ""};
  letter-spacing: ${(props) => (props.ls ? props.ls : "-0.29px")};
  margin: ${(props) => (props.margin ? props.margin : "")};
  padding: ${(props) => (props.padding ? props.padding : "10px")};
  margin-top: ${(props) => (props.mt ? props.mt : "")};
  margin-right: ${(props) => (props.mr ? props.mr : "")};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : "12px")};
  margin-bottom: 0;
  border: ${(props) => (props.border ? props.border : "none")};
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : "0")};
  outline: none;
  cursor: ${(props) => (props.cursor ? props.cursor : "pointer")};
  text-align-last: ${(props) =>
    props.textAlignLast ? props.textAlignLast : ""};
  &:focus {
    outline: none;
    border: ${(props) => (props.borderOnFocus ? props.borderOnFocus : "")};
    box-shadow: ${(props) =>
      props.boxShadowOnFocus ? props.boxShadowOnFocus : ""};
  }
  &:disabled {
    cursor: not-allowed;
  }
`;

const SelectDropdown = (props) => {
  return props.loading ? (
    <Loader />
  ) : (
    <Select {...props}>
      <Options options={props.options} placeholder={props.placeholder} />
    </Select>
  );
};
SelectDropdown.propType = {
  placeholder: String,
  options: Array,
};

export default SelectDropdown;
