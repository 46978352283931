import { put, takeEvery, call, all } from "redux-saga/effects";
import { buildNotification } from "../../../config/notification";
import { store } from "react-notifications-component";
import axios from "../../../config/axios";

import {
  emailInviteSent,
  fetchingList,
  fetchList,
  fetchEditList,
  fetchingEditList,
  fetchOwnershipStatus,
  fetchingContactDetails,
  fetchContactDetails,
  fetchingAttributesDetails,
  fetchLocationDetails,
  fetchingLocationDetails,
  initEditList,
  fetchingAttributesDetailsSuccess,
  fetchAttributesDetails,
  fetchingEditListSuccess,
  setWorkFlowStepData,
  setDiffData,
  fetchNewCompanyCreation,
  setOwnershipMessage,
  takeProxyStatus,
  setWorkflowState,
  setContactDiffData,
  setLocationDiffData,
  initAttributesDetails,
  inviteRefreshComplete,
  saveLogoComplete,
  setAuditTrailList,
  setAuditDataById,
  setLogo,
  clearAuditTrailSuccess,
  initAuditTrailList,
  initGetLogo,
  setFlexFields,
  rejectRemoveCompanySuccess,
  rejectCompanySuccess,
} from "./actions";

import {
  INIT_EMAIL_INVITE,
  INIT_LIST_FETCH,
  INIT_EDIT_LIST_FETCH,
  INIT_TAKE_OWNERSHIP,
  INIT_CONTACT_DETAILS,
  INIT_ATTRIBUTES_DETAILS,
  SAVE_CONTACT,
  SAVE_DYNAMIC_TABS_DATA,
  INIT_LOCATION_DETAILS,
  SAVE_LOCATION,
  REMOVE_CONTACT,
  REMOVE_LOCATION,
  REMOVE_ATTACHMENT,
  GET_WORKFLOW_STEP_DATA,
  SAVE_BULK_LOCATIONS,
  GET_DIFF_DATA,
  SAVE_WORKFLOW_STEP_DATA,
  INIT_NEW_COMPANY_CREATION,
  DOWNLOAD_VMD_ATTACHMENT,
  INIT_TAKE_PROXY,
  INIT_REJECT_REMOVE,
  INIT_REJECT,
  INIT_WOKFLOW_STATE,
  GET_CONTACT_DIFF_DATA,
  GET_LOCATION_DIFF_DATA,
  INIT_INVITE_REFRESH,
  INIT_SAVE_LOGO,
  INIT_AUDIT_TRAIL_LIST,
  INIT_AUDIT_TRAIL_DATA,
  INIT_GET_LOGO,
  INIT_CLEAR_AUDIT_TRAIL,
  SAVE_ATTACHMENT,
  INIT_FETCH_FLEX_FIELDS,
  INIT_COMBINED_SAGA_CALL,
  FETCH_EDIT_DATA_BY_NAME,
} from "./actionTypes";

const putRequestDetails = {
  method: "PUT",
  headers: {
    "Content-Type": "application/json-patch+json",
  },
};
const postRequestDetails = {
  method: "POST",
  cache: "no-cache",
  headers: {
    "Content-Type": "application/json",
  },
};

export default function* watchCustomListFetch() {
  yield all([
    takeEvery(INIT_EMAIL_INVITE, sendEmailInviteSaga),
    takeEvery(INIT_LIST_FETCH, getListDataSaga),
    takeEvery(INIT_EDIT_LIST_FETCH, getEditListDataSaga),
    takeEvery(INIT_TAKE_OWNERSHIP, initTakeOwnerShipSaga),
    takeEvery(INIT_CONTACT_DETAILS, getContactDetailsSaga),
    takeEvery(INIT_ATTRIBUTES_DETAILS, getAttributesDetailsSaga),
    takeEvery(SAVE_CONTACT, saveContactSaga),
    takeEvery(REMOVE_CONTACT, removeContactSaga),
    takeEvery(SAVE_DYNAMIC_TABS_DATA, saveDynamicTabsDataSaga),
    takeEvery(INIT_LOCATION_DETAILS, getLocationDetailsSaga),
    takeEvery(SAVE_LOCATION, saveLocationSaga),
    takeEvery(REMOVE_LOCATION, removeLocationSaga),
    takeEvery(SAVE_BULK_LOCATIONS, saveBulkLocationsSaga),
    takeEvery(REMOVE_ATTACHMENT, removeAttachmentSaga),
    takeEvery(GET_WORKFLOW_STEP_DATA, getWorkFlowStepDataSaga),
    takeEvery(SAVE_WORKFLOW_STEP_DATA, saveWorkflowStepDataSaga),
    takeEvery(GET_DIFF_DATA, getDiffSaga),
    takeEvery(INIT_NEW_COMPANY_CREATION, initNewCompanyCreationSaga),
    takeEvery(DOWNLOAD_VMD_ATTACHMENT, downloadAttachmentSaga),
    takeEvery(INIT_TAKE_PROXY, takeProxySaga),
    takeEvery(INIT_REJECT_REMOVE, rejectRemoveCompanySaga),
    takeEvery(INIT_REJECT, rejectCompanySaga),
    takeEvery(INIT_WOKFLOW_STATE, getWorkflowStateSaga),
    takeEvery(GET_CONTACT_DIFF_DATA, getContactDiffDataSaga),
    takeEvery(GET_LOCATION_DIFF_DATA, getLocationDiffDataSaga),
    takeEvery(INIT_INVITE_REFRESH, initInviteRefreshSaga),
    takeEvery(INIT_SAVE_LOGO, saveLogoSaga),
    takeEvery(INIT_AUDIT_TRAIL_LIST, getAuditTrailSaga),
    takeEvery(INIT_AUDIT_TRAIL_DATA, getAuditTrailDetailsSaga),
    takeEvery(INIT_GET_LOGO, getLogoSaga),
    takeEvery(INIT_CLEAR_AUDIT_TRAIL, clearAuditTrailSaga),
    takeEvery(SAVE_ATTACHMENT, saveAttachmentSaga),
    takeEvery(INIT_FETCH_FLEX_FIELDS, getFlexFieldsSaga),
    takeEvery(INIT_COMBINED_SAGA_CALL, combinedSaga),
    takeEvery(FETCH_EDIT_DATA_BY_NAME, getEditDataByNameSaga),
  ]);
}

function* sendEmailInviteSaga(action) {
  const url =
    "/Supplier/inviteSupplier?emailID=" +
    action.data 
  try {
    const response = yield call(axios, url, postRequestDetails);
    if (response && response.status === 200) {
      const notification = buildNotification({
        message: "notification.manageSupplier.emailInvite",
        type: "success",
      });

      store.addNotification({
        ...notification,
      });
      yield put(emailInviteSent());
    }
  } catch (error) {
    action.callback && action.callback();
  }
}

function* getListDataSaga(action) {
  yield put(fetchingList());
  let sessionDetails = JSON.parse(sessionStorage.getItem("sessionDetails"));
  let languageID =
    sessionDetails &&
    sessionDetails.languageId &&
    sessionDetails.languageId !== ""
      ? sessionDetails.languageId
      : "enGB";
  let url = "/Supplier?langID=" + languageID + "&";
  let keys = action.data && Object.keys(action.data);
  keys &&
    keys.forEach((data) => {
      if (action.data[data]) {
        url = url + data + "=" + action.data[data] + "&";
      }
    });
  try {
    const response = yield call(axios, url, { method: "GET" });
    if (response && (response.status === 200 || response.status === 202)) {
      yield put(fetchList(response.data));
    }
  } catch (error) {
    action.callback && action.callback();
  }
}

function* getEditListDataSaga(action) {
  yield put(fetchingEditList());
  let sessionDetails = JSON.parse(sessionStorage.getItem("sessionDetails"));
  let languageID =
    sessionDetails &&
    sessionDetails.languageId &&
    sessionDetails.languageId !== ""
      ? sessionDetails.languageId
      : "enGB";
  const url = "/Supplier/supplierByID?supplierID=" + action.id + "&languageID=" + languageID;
  let response = {};
  try {
    response = yield call(axios, url, { method: "GET" });
    if (response) {
      if (action.flag) {
        yield put(fetchEditList(response.data));
      }
      yield put(fetchingEditListSuccess());
      if (
        action.callback &&
        (response.status === 200 || response.status === 202)
      ) {
        action.callback(response.data);
      }
      if (response.status === 204) {
        const notification = buildNotification({
          message: "notification.noDataDisplayLabel",
          type: "danger",
        });
        store.addNotification({
          ...notification,
        });
      }
    }
  } catch (error) {
    action.callback && action.callback();
  }
}

function* getEditDataByNameSaga(action) {
  yield put(fetchingEditList());
  let sessionDetails = JSON.parse(sessionStorage.getItem("sessionDetails"));
  let languageID =
    sessionDetails &&
    sessionDetails.languageId &&
    sessionDetails.languageId !== ""
      ? sessionDetails.languageId
      : "enGB";
  const url =
    "/Supplier/getCompanyDetailsBySupplierName?supplierName=" +
    action.name +
    "&languageID=" +
    languageID;
  let response = {};
  try {
    response = yield call(axios, url, { method: "GET" });
    if (response) {
      if (
        action.callback &&
        (response.status === 200 || response.status === 202)
      ) {
        action.callback(response.data);
      }
      if (response.status === 204) {
        const notification = buildNotification({
          message: "notification.noDataDisplayLabel",
          type: "danger",
        });
        store.addNotification({
          ...notification,
        });
      }
    }
  } catch (error) {
    action.callback && action.callback();
  }
}

function* initTakeOwnerShipSaga(action) {
  const url = "/Supplier/updateOwnership?userOnly=true";
  putRequestDetails.data = { listOfIds: action.id };
  try {
    const response = yield call(axios, url, putRequestDetails);
    let data = [];
    if (response && response.status === 200) {
      yield put(fetchOwnershipStatus());
      const keys = Object.keys(response.data);
      const idArr = action.id.split(",");
      idArr.forEach((arr) => {
        keys.find((item) => {
          return (
            parseInt(item) === parseInt(arr) &&
            data.push({
              id: parseInt(item),
              statusCode: response.data[item].statusCode,
              messageCode:
                response.data[item].value &&
                response.data[item].value.messageCode
                  ? response.data[item].value.messageCode
                  : "",
            })
          );
        });
      });
      if (action.callback) {
        action.callback(data);
      }
      yield put(setOwnershipMessage(data));
    }
  } catch (error) {
    action.callback && action.callback();
  }
}

function* getAttributesDetailsSaga(action) {
  yield put(fetchingAttributesDetails());
  let sessionDetails = JSON.parse(sessionStorage.getItem("sessionDetails"));
  let languageID =
    sessionDetails &&
    sessionDetails.languageId &&
    sessionDetails.languageId !== ""
      ? sessionDetails.languageId
      : "enGB";
  const url = "/Supplier/attributes?languageID=" + languageID;
  try {
    const response = yield call(axios, url, { method: "GET" });
    if (response) {
      yield put(fetchAttributesDetails(response.data));
      yield put(fetchingAttributesDetailsSuccess());
    }
  } catch (error) {
    action.callback && action.callback();
  }
}

function* getContactDetailsSaga(action) {
  yield put(fetchingContactDetails());
  const url =
    "/Supplier/supplierContactID?supplierID=" +
    action.supId +
    "&supplierContactID=" +
    action.id;
  try {
    const response = yield call(axios, url, { method: "GET" });
    if (response) {
      yield put(fetchContactDetails(response.data));
      if (action.callback) {
        action.callback(response.data, action.tabName);
      }
    }
  } catch (error) {
    action.callback && action.callback();
  }
}

function* saveContactSaga(action) {
  const url = "/Supplier/saveContacts";
  putRequestDetails.data = action.data;
  try {
    const response = yield call(axios, url, putRequestDetails);
    if (response.status === 200 || response.status === 202) {
      yield put(initAttributesDetails());
      yield put(
        initEditList(
          action.supId,
          () => {
            return;
          },
          true
        )
      );
      const notification = buildNotification({
        message: "notification.manageSupplier.contactSaved",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
    }
  } catch (error) {
    action.callback && action.callback();
  }
}

function* removeContactSaga(action) {
  const url = "/Supplier/deleteContacts";
  postRequestDetails.data = action.data;
  try {
    const response = yield call(axios, url, postRequestDetails);
    if (response.status === 200) {
      yield put(initAttributesDetails());
      yield put(
        initEditList(
          action.supId,
          () => {
            return;
          },
          true
        )
      );
      const notification = buildNotification({
        message: "notification.manageSupplier.contactDeleted",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
    }
  } catch (error) {
    action.callback && action.callback();
  }
}

function* saveDynamicTabsDataSaga(action) {
  const url = "/Supplier";
  postRequestDetails.data = action.data;
  try {
    const response = yield call(axios, url, postRequestDetails);
    if (response.status === 200 || response.status === 202) {
      yield put(initAttributesDetails());
      yield put(
        initEditList(
          action.supId,
          () => {
            return;
          },
          true
        )
      );
      const notification = buildNotification({
        message: "notification.manageSupplier.savedDetails",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
    }
  } catch (error) {
    action.callback && action.callback();
  }
}

function* getLocationDetailsSaga(action) {
  yield put(fetchingLocationDetails());
  const url =
    "/Supplier/supplierLocationID?supplierID=" +
    action.supId +
    "&supplierLocationID=" +
    action.id;
  try {
    const response = yield call(axios, url, { method: "GET" });
    if (response) {
      yield put(fetchLocationDetails(response.data));
      if (action.callback) {
        action.callback(response.data, action.tabName);
      }
    }
  } catch (error) {
    action.callback && action.callback();
  }
}

function* saveLocationSaga(action) {
  const url = "/Supplier/saveLocations";
  putRequestDetails.data = action.data;
  try {
    const response = yield call(axios, url, putRequestDetails);
    if (response.status === 200 || response.status === 202) {
      yield put(initAttributesDetails());
      yield put(
        initEditList(
          action.supId,
          () => {
            return;
          },
          true
        )
      );
      const notification = buildNotification({
        message: "notification.manageSupplier.locationSaved",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
    }
  } catch (error) {
    action.callback && action.callback();
  }
}

function* saveBulkLocationsSaga(action) {
  const url = "/Supplier/updateLocationStatus";
  postRequestDetails.data = action.data;
  try {
    const response = yield call(axios, url, postRequestDetails);
    if (response.status === 200 || response.status === 202) {
      yield put(initAttributesDetails());
      yield put(
        initEditList(
          action.supId,
          () => {
            return;
          },
          true
        )
      );
      const notification = buildNotification({
        message: action.data.enabled
          ? "notification.manageSupplier.enableLocation"
          : "notification.manageSupplier.disableLocation",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
    }
  } catch (error) {
    action.callback && action.callback();
  }
}

function* removeLocationSaga(action) {
  const url = "/Supplier/deleteLocations";
  postRequestDetails.data = action.data;
  try {
    const response = yield call(axios, url, postRequestDetails);
    if (response.status === 200) {
      yield put(initAttributesDetails());
      yield put(
        initEditList(
          action.supId,
          () => {
            return;
          },
          true
        )
      );
      const notification = buildNotification({
        message: "notification.manageSupplier.locationDeleted",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
    }
  } catch (error) {
    action.callback && action.callback();
  }
}

function* removeAttachmentSaga(action) {
  const url = "/Supplier/deleteAttachments";
  postRequestDetails.data = action.data;
  try {
    const response = yield call(axios, url, postRequestDetails);
    if (response.status === 200) {
      yield put(initAttributesDetails());
      yield put(
        initEditList(
          action.supId,
          () => {
            return;
          },
          true
        )
      );
      const notification = buildNotification({
        message: "notification.manageSupplier.attachmentDeleted",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
    }
  } catch (error) {
    action.callback && action.callback();
  }
}

function* saveAttachmentSaga(action) {
  const url = "/Supplier/saveAttachments";
  putRequestDetails.data = action.data;
  try {
    const response = yield call(axios, url, putRequestDetails);
    if (response.status === 200 || response.status === 202) {
      yield put(initAttributesDetails());
      yield put(
        initEditList(
          action.supId,
          () => {
            return;
          },
          true
        )
      );
      const notification = buildNotification({
        message: "notification.manageSupplier.attachmentSaved",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
    }
  } catch (error) {
    action.callback && action.callback();
  }
}

function* getWorkFlowStepDataSaga(action) {
  const url = "/Supplier/workflowStepForCompany?supplierID=" + action.supId;
  try {
    const response = yield call(axios, url, { method: "GET" });
    if (response) {
      yield put(setWorkFlowStepData(response.data));
      if (action.callback) {
        action.callback(response.data);
      }
    }
  } catch (error) {
    action.callback && action.callback();
  }
}

function* saveWorkflowStepDataSaga(action) {
  const url =
    "/Supplier/updateWorkflowState?supplierID=" +
    action.supId +
    "&stepNo=" +
    action.stepNo +
    "&stepinitialState=";
  try {
    const response = yield call(axios, url, putRequestDetails);
    if (response) {
      if (action.callback) {
        action.callback();
      }
    }
  } catch (err) {
    action.callback && action.callback();
  }
}

function* getDiffSaga(action) {
  let sessionDetails = JSON.parse(sessionStorage.getItem("sessionDetails"));
  let languageID =
    sessionDetails &&
    sessionDetails.languageId &&
    sessionDetails.languageId !== ""
      ? sessionDetails.languageId
      : "enGB";
  const url =
    "/Supplier/companyDetailsToCompare?supplierID=" +
    action.id +
    "&languageID=" +
    languageID;
  try {
    const response = yield call(axios, url, { method: "GET" });
    if (response) {
      yield put(setDiffData(response.data));
    }
  } catch (error) {
    action.callback && action.callback();
  }
}

function* initNewCompanyCreationSaga(action) {
  const url = "/Supplier/newCompanyRequest";
  postRequestDetails.data = action.data;
  try {
    const response = yield call(axios, url, postRequestDetails);
    if (response.status === 202) {
      yield put(fetchNewCompanyCreation());
      const notification = buildNotification({
        message: "notification.manageSupplier.companyCreated",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
    }
  } catch (err) {
    action.callback && action.callback();
  }
}

function* downloadAttachmentSaga(action) {
  const url =
    "/Supplier/downloadSupplierAttachmentByID?supplierID=" +
    action.supId +
    "&supplierAttachmentID=" +
    action.attachId;
  try {
    const response = yield call(axios, url, { responseType: "blob" });
    if (response) {
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: response.headers["content-type"],
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", action.fileName);
      document.body.appendChild(link);
      link.click();
      const notification = buildNotification({
        message: "notification.requestMgmt.attachmentDownloaded",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
    }
  } catch (err) {
    action.callback && action.callback();
  }
}

function* takeProxySaga(action) {
  const url = "/Supplier/takeProxy?supplierID=" + action.id;
  try {
    const response = yield call(axios, url, putRequestDetails);
    if (response && (response.status === 200 || response.status === 202)) {
      yield put(takeProxyStatus(response.data));
      const notification = buildNotification({
        message: "notification.manageSupplier.proxyTaken",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
      yield put(initAttributesDetails());
      yield put(
        initEditList(
          action.id,
          () => {
            return;
          },
          true
        )
      );
      if (action.callback) {
        action.callback(response.data);
      }
    }
  } catch (err) {
    action.callback && action.callback();
  }
}

function* rejectCompanySaga(action) {
  let url = "";
  if (action.comment !== "") {
    url =
      "/Supplier/reject?supplierID=" +
      action.id +
      "&rejectionComment=" +
      action.comment;
  } else url = "/Supplier/reject?supplierID=" + action.id;

  try {
    const response = yield call(axios, url, putRequestDetails);
    if (response && (response.status === 202 || response.status === 200)) {
      const notification = buildNotification({
        message: "notification.manageSupplier.companyRejected",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
      yield put(rejectCompanySuccess());
    }
  } catch (err) {
    action.callback && action.callback();
  }
}

function* rejectRemoveCompanySaga(action) {
  let url = "";
  if (action.comment !== "") {
    url =
      "/Supplier/rejectAndRemove?supplierID=" +
      action.id +
      "&rejectionComment=" +
      action.comment;
  } else url = "/Supplier/rejectAndRemove?supplierID=" + action.id;
  try {
    const response = yield call(axios, url, putRequestDetails);
    if (response && (response.status === 202 || response.status === 200)) {
      const notification = buildNotification({
        message: "notification.manageSupplier.companyRemoved",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
      yield put(rejectRemoveCompanySuccess());
    }
  } catch (err) {
    action.callback && action.callback();
  }
}

function* getWorkflowStateSaga(action) {
  const url = "/CustomListItem/listkeys?typeName=__workflow_states";
  try {
    const response = yield call(axios, url);
    if (response) {
      yield put(setWorkflowState(response.data));
    }
  } catch (err) {
    action.callback && action.callback();
  }
}

function* getContactDiffDataSaga(action) {
  const url =
    "/Supplier/supplierContactIDToCompare?supplierID=" +
    action.supId +
    "&supplierContactID=" +
    action.contactId;
  try {
    const response = yield call(axios, url, { method: "GET" });
    if (response) {
      yield put(setContactDiffData(response.data));
    }
  } catch (error) {
    action.callback && action.callback();
  }
}

function* getLocationDiffDataSaga(action) {
  const url =
    "/Supplier/supplierLocationIDToCompare?supplierID=" +
    action.supId +
    "&supplierLocationID=" +
    action.locationId;
  try {
    const response = yield call(axios, url, { method: "GET" });
    if (response) {
      yield put(setLocationDiffData(response.data));
    }
  } catch (error) {
    action.callback && action.callback();
  }
}

function* initInviteRefreshSaga(action) {
  const url = "/Supplier/inviteRefresh";
  putRequestDetails.data = { ...action.data,};
  try {
    const response = yield call(axios, url, putRequestDetails);
    let data = [];
    if (response && response.status === 200) {
      const keys = Object.keys(response.data);
      const idArr = action.data.listOfIds.split(",");
      idArr.forEach((arr) => {
        keys.find((item) => {
          return (
            parseInt(item) === parseInt(arr) &&
            data.push({
              id: parseInt(item),
              statusCode: response.data[item].statusCode,
              messageCode:
                response.data[item].value &&
                response.data[item].value.messageCode
                  ? response.data[item].value.messageCode
                  : "",
            })
          );
        });
      });
      if (action.callback) {
        action.callback(data);
      }
      yield put(inviteRefreshComplete());
    }
  } catch (error) {
    action.callback && action.callback();
  }
}

function* saveLogoSaga(action) {
  const url = "/Supplier/saveLogo";
  putRequestDetails.data = action.data;
  try {
    const response = yield call(axios, url, putRequestDetails);
    if (response) {
      yield put(initAttributesDetails());
      yield put(
        initEditList(
          action.supId,
          () => {
            return;
          },
          true
        )
      );
      yield put(initGetLogo(action.supId));
      yield put(saveLogoComplete());
      const notification = buildNotification({
        message: "notification.manageSupplier.logoSaved",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
    }
  } catch (error) {
    action.callback && action.callback();
  }
}

function* getAuditTrailSaga(action) {
  let url = "/Supplier/AuditTrailList?";
  let keys = action.data && Object.keys(action.data);
  keys &&
    keys.forEach((data) => {
      if (action.data[data]) {
        url = url + data + "=" + action.data[data] + "&";
      }
    });
  let updatedData = [];
  try {
    const response = yield call(axios, url, { method: "GET" });
    if (response) {
      updatedData = response.data || [];
      yield put(setAuditTrailList(updatedData));
    }
  } catch (error) {
    action.callback && action.callback();
  }
}

function* getAuditTrailDetailsSaga(action) {
  let url = "/Supplier/SupplierAuditTrailByID?auditTrailID=" + action.id;
  try {
    const response = yield call(axios, url, { method: "GET" });
    if (response) {
      yield put(setAuditDataById(response.data));
    }
  } catch (error) {
    action.callback && action.callback();
  }
}

function* clearAuditTrailSaga(action) {
  const url = "/Supplier/ClearAuditTrail";
  try {
    const response = yield call(axios, url, postRequestDetails);
    if (response) {
      yield put(clearAuditTrailSuccess());
      yield put(initAuditTrailList());
      const notification = buildNotification({
        message: "notification.manageSupplier.auditTrailClear",
        type: "success",
      });
      yield store.addNotification({
        ...notification,
      });
    }
  } catch (err) {
    action.callback && action.callback();
  }
}

function* getLogoSaga(action) {
  let url = "/Supplier/getLogo?supplierID=" + action.id;
  try {
    const response = yield call(axios, url, { method: "GET" });
    if (response) {
      yield put(setLogo(response.data));
    }
  } catch (err) {
    action.callback && action.callback();
  }
}

function* getFlexFieldsSaga(action) {
  let sessionDetails = JSON.parse(sessionStorage.getItem("sessionDetails"));
  let languageID =
    sessionDetails &&
    sessionDetails.languageId &&
    sessionDetails.languageId !== ""
      ? sessionDetails.languageId
      : "enGB";
  let url = "/Login/flexFields?languageID=" + languageID;
  try {
    const response = yield call(axios, url, { method: "GET" });
    if (response) {
      const sortedRes = response.data.sort((a, b) =>
        a.sortOrder > b.sortOrder ? 1 : -1
      );
      yield put(setFlexFields(sortedRes));
    }
  } catch (err) {
    action.callback && action.callback();
  }
}

function* combinedSaga(action) {
  yield* getAttributesDetailsSaga();
  yield* getEditListDataSaga({
    id: action.id,
    fetchSuccess: () => {
      return;
    },
    flag: true,
  });
}
