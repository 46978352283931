import React from "react";
import { useTranslation } from "react-i18next";
import Input from "../SearchBox";

const Search = (props) => {
  const { t } = useTranslation();
  return (
    <Input
      type="text"
      name="search"
      placeholder={t(props.placeholder)}
      autoComplete="off"
      onChange={props.onType}
      value={props.value}
      disabled={props.disabled}
    />
  );
};

export default Search;
