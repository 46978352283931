import React from "react";
import { useTranslation } from "react-i18next";
import Div from "../../components/Div";
import Label from "../../components/Label";
import Input from "../../components/Input";
import Button from "../../components/Button/Button";
import IMG from "../../components/IMG";
import Loader from "../../components/Loader";
import { inputStyles, showPasswordEyeStyle } from "./config/pageConfig";
import showPassowordDisabled from "../../assets/images/showpassword-disabled.png";
import showPasswordEnabled from "../../assets/images/showpassword-enabled.png";
import Form from "../../components/Form/Layout";

export default (props) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Form onSubmit={props.onFormSubmit}>
        <Div margin="10px 0px">
          <Div float="left" divWidth="30%" padding="10px">
            <Label cursor="default">{t("changePassword.oldPassword")}</Label>
          </Div>
          <Div>
            <Input
              {...inputStyles}
              type={
                props.state.enablePasswordFor.indexOf("oldPassword") !== -1
                  ? "text"
                  : "password"
              }
              id="oldPassword"
              name="oldPassword"
              placeholder={t("changePassword.oldPasswordPlaceholder")}
              onChange={props.handleChange}
              onBlur={props.onBlur}
              value={props.state.newFormData.oldPassword}
              borderOnFocus="1px solid #008eff"
            />
            <IMG
              {...showPasswordEyeStyle}
              src={
                props.state.enablePasswordFor.indexOf("oldPassword") !== -1
                  ? showPasswordEnabled
                  : showPassowordDisabled
              }
              onClick={() => {
                props.toggleShowPassword("oldPassword");
              }}
            />
          </Div>
          <br />
          <Div float="left" divWidth="30%" padding="10px">
            <Label cursor="default">{t("changePassword.newPassword")} </Label>
          </Div>
          <Div>
            <Input
              {...inputStyles}
              type={
                props.state.enablePasswordFor.indexOf("newPassword") !== -1
                  ? "text"
                  : "password"
              }
              id="newPassword"
              name="newPassword"
              placeholder={t("changePassword.newPasswordPlaceholder")}
              onChange={props.handleChange}
              value={props.state.newFormData.newPassword}
              onBlur={props.onBlur}
              borderOnFocus="1px solid #008eff"
            />
            <IMG
              {...showPasswordEyeStyle}
              src={
                props.state.enablePasswordFor.indexOf("newPassword") !== -1
                  ? showPasswordEnabled
                  : showPassowordDisabled
              }
              onClick={() => {
                props.toggleShowPassword("newPassword");
              }}
            />
          </Div>
          <br />
          <Div float="left" divWidth="30%" padding="10px">
            <Label cursor="default">
              {t("changePassword.confirmPassword")}{" "}
            </Label>
          </Div>
          <Div>
            <Input
              {...inputStyles}
              type={
                props.state.enablePasswordFor.indexOf("confirmPassword") !== -1
                  ? "text"
                  : "password"
              }
              id="confirmPassword"
              name="confirmPassword"
              placeholder={t("changePassword.confirmPasswordPlaceholder")}
              onChange={props.handleChange}
              value={props.state.newFormData.confirmPassword}
              onBlur={props.onBlur}
              borderOnFocus="1px solid #008eff"
            />
            <IMG
              {...showPasswordEyeStyle}
              src={
                props.state.enablePasswordFor.indexOf("confirmPassword") !== -1
                  ? showPasswordEnabled
                  : showPassowordDisabled
              }
              onClick={() => {
                props.toggleShowPassword("confirmPassword");
              }}
            />
          </Div>
          <br />
          <Div padding="10px 0 0 0" textalign="center">
            <Button
              type="submit"
              disabled={props.disabled}
              bgColor="#018eff"
              textColor="#FFF"
              margin="auto"
            >
              {props.isResetPassword ? (
                <Loader />
              ) : (
                t("changePassword.changePasswordLabel")
              )}
            </Button>
          </Div>
        </Div>
      </Form>
    </React.Fragment>
  );
};
