import { put, takeEvery, call, all } from "redux-saga/effects";
import { buildNotification } from "../../../config/notification";
import { store } from "react-notifications-component";
import {
  formSubmissionStatusAction,
  fetchingReportDetailsList,
  fetchReportDetailsList,
  fetchReportDetailsListCompleted,
  fetchingFrqeuencyDropdownList,
  fetchFrqeuencyDropdownList,
  frequencyFetchingComplete,
  fetchingCurrencyDropdownList,
  fetchCurrencyDropdownList,
  currencyFetchingComplete,
  fetchingReportDetailsbyID,
  fetchReportDetailsbyID,
  fetchReportDetailsbyIDCompleted,
  saveReportDetailsData,
  deleteSuccess,
  deleteFailed,
  saveUpdatedReportStatus,
  updateReportDetailsList,
  fetchingUserList,
  fetchUserList,
  fetchUserListComplete,
} from "../store/actions";

import {
  INIT_FREQUENCY_LIST_FETCH,
  INIT_CURRENCY_LIST_FETCH,
  INIT_REPORT_DETAILS_LIST,
  INIT_REPORT_DETAILS_BY_ID,
  INIT_REPORT_DETAILS_SAVE,
  INIT_DELETE_REPORT_CONFIG,
  INIT_UPDATE_REPORT_STATUS,
  INIT_USER_LIST,
} from "./actionTypes";
import axios from "../../../config/axios";

const getRequestDetails = {
  method: "GET",
  cache: "no-cache",
};
const postRequestDetails = {
  method: "POST",
  cache: "no-cache",
  headers: {
    "Content-Type": "application/json",
  },
};
let sessionDetails = JSON.parse(sessionStorage.getItem("sessionDetails")) || {};
let languageId = sessionDetails && sessionDetails.languageId;
export default function* watchDocHistoryListFetch() {
  yield all([
    takeEvery(INIT_CURRENCY_LIST_FETCH, getCurrencyDropdownListSaga),
    takeEvery(INIT_FREQUENCY_LIST_FETCH, getFrqeuencyDropdownListSaga),
    takeEvery(INIT_REPORT_DETAILS_SAVE, saveReportDetailsSaga),
    takeEvery(INIT_REPORT_DETAILS_LIST, getReportDetailsListSaga),
    takeEvery(INIT_REPORT_DETAILS_BY_ID, getReportDetailsByIdSaga),
    takeEvery(INIT_DELETE_REPORT_CONFIG, initReportConfigDeleteSaga),

    takeEvery(INIT_UPDATE_REPORT_STATUS, updateReportStatusSaga),
    takeEvery(INIT_USER_LIST, getUserListSaga),
  ]);
}

function* getFrqeuencyDropdownListSaga() {
  yield put(fetchingFrqeuencyDropdownList());
  let sessionDetails = JSON.parse(sessionStorage.getItem("sessionDetails"));
  let languageID =
    sessionDetails &&
    sessionDetails.languageId &&
    sessionDetails.languageId !== ""
      ? sessionDetails.languageId
      : "enGB";
  const url = "/Common/listfrequency?languageID=" + languageID;
  try {
    const response = yield call(axios, url, getRequestDetails);
    const data = yield response.data.map((item) => ({
      value: item.label,
      id: item.id,
    }));
    yield put(fetchFrqeuencyDropdownList(data));
    yield put(frequencyFetchingComplete());
  } catch (error) {
    yield put(frequencyFetchingComplete());
  }
}

function* getCurrencyDropdownListSaga() {
  yield put(fetchingCurrencyDropdownList());
  const url = "/Common/listcurrency";
  try {
    const response = yield call(axios, url, getRequestDetails);
    const data = yield response.data.map((item) => ({
      value: item.label,
      id: item.key,
    }));
    yield put(fetchCurrencyDropdownList(data));
    yield put(frequencyFetchingComplete());
  } catch (error) {
    yield put(currencyFetchingComplete());
  }
}

function* saveReportDetailsSaga(action) {
  yield (postRequestDetails.data = {
    ...action.data,
  });
  try {
    const response = yield call(
      axios,
      "/Report/saveReport",
      postRequestDetails
    );
    if (response && response.status) {
      yield put(saveReportDetailsData());
      yield put(formSubmissionStatusAction(response.statusText));
      if (action.saveSuccess) {
        action.saveSuccess(response.status);
      }
      if (action.isEditMode) {
        const notification = buildNotification({
          message: "notification.reportDetails.updated",
          type: "success",
        });
        store.addNotification({
          ...notification,
          dismiss: {
            duration: 1000,
          },
        });
      } else {
        const notification = buildNotification({
          message: "notification.reportDetails.reportCreated",
          type: "success",
        });
        store.addNotification({
          ...notification,
          dismiss: {
            duration: 1000,
          },
        });
      }
    }
  } catch (error) {
    action.saveSuccess && action.SaveSuccess();
  }
}
function* updateReportStatusSaga(action) {
  yield (postRequestDetails.data = {
    ...action.formData,
  });
  try {
    const response = yield call(
      axios,
      "/Report/changereportstatus",
      postRequestDetails
    );
    if (response) {
      yield put(saveUpdatedReportStatus());
      if (action.updateSuccess) {
        action.updateSuccess(response);
      }
      const notification = buildNotification({
        message: "notification.reportDetails.statusUpdated",
        type: "success",
      });
      store.addNotification({
        ...notification,
        dismiss: {
          duration: 1000,
        },
      });
    }
  } catch (error) {
    action.updateSuccess && action.updateSuccess();
  }
}

// Report list
function* getReportDetailsListSaga(action) {
  let sessionDetails = JSON.parse(sessionStorage.getItem("sessionDetails"));
  let languageID =
    sessionDetails &&
    sessionDetails.languageId &&
    sessionDetails.languageId !== ""
      ? sessionDetails.languageId
      : "enGB";
  let url;
  url = `/Report/listreports?langKey=${languageID || "enGB"}`;

  let keys = action.data && Object.keys(action.data);
  keys &&
    keys.forEach((data) => {
      if (action.data[data]) {
        url = url + "&" + data + "=" + action.data[data];
      }
    });

  yield put(fetchingReportDetailsList());
  try {
    const response = yield call(axios, url, getRequestDetails);
    if (response && response.status === 200) {
      yield put(fetchReportDetailsList(response.data));
      yield put(updateReportDetailsList(response.data));
    } else if (response && response.status === 204) {
      yield put(fetchReportDetailsList([]));
      yield put(updateReportDetailsList([]));
    }

    yield put(fetchReportDetailsListCompleted());
  } catch (error) {
    yield put(fetchReportDetailsListCompleted());
  }
}

function* getReportDetailsByIdSaga(action) {
  yield put(fetchingReportDetailsbyID());
  const url =
    parseInt(action.baseRoleId) !== 3
      ? `/Report/reportById?reportId=${action.invoiceReportID}&reportUserID=${action.reportUserID}`
      : `/Report/reportById?reportId=${action.invoiceReportID}`;
  try {
    const response = yield call(axios, url, getRequestDetails);
    if (response && response.status) {
      yield put(fetchReportDetailsbyID(response.data));
      if (action.callback) {
        action.callback(response.status);
      }
      yield put(fetchReportDetailsbyIDCompleted());
    }
  } catch (error) {
    yield put(fetchReportDetailsbyIDCompleted());
  }
}

function* initReportConfigDeleteSaga(action) {
  const url = `/Report/DeleteReport`;
  postRequestDetails.data = {
    userId: action.userId,
    listOfIds: action.id,
  };
  try {
    const response = yield call(axios, url, postRequestDetails);
    const notification = buildNotification({
      message: "notification.reportDetails.reportDeleted",
      type: "success",
    });
    if (response && response.status === 200) {
      const id = action.id.split(",").map((id) => parseInt(id));
      yield put(deleteSuccess(id));
      if (action.deleteSuccess) {
        action.deleteSuccess(response.status);
      }

      store.addNotification({
        ...notification,
        dismiss: {
          duration: 1000,
        },
      });
    }
  } catch (error) {
    yield put(deleteFailed());
  }
}

function* getUserListSaga(action) {
  yield put(fetchingUserList());

  let url = "/Users?approved=true&baseRoleID=3";
  let keys = action.data && Object.keys(action.data);
  keys &&
    keys.forEach((data) => {
      if (action.data[data]) {
        url = url + "&" + data + "=" + action.data[data];
      }
    });
  try {
    const response = yield call(axios, url, getRequestDetails);
    if (response && response.status) {
      yield put(fetchUserList(response.data));
      yield put(fetchUserListComplete());
    }
  } catch (err) {
    yield put(fetchUserListComplete());
  }
}
