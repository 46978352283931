import React, { useState } from "react";
import Div from "../Div";
import IMG from "../IMG";

import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { generateFilteredRoutes } from "../../routes/generateFilteredRoutes";

import { sessionDetails } from "../../config";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  selectClientDetails,
  selectIsAuthenticated,
  selectSessionDetails,
} from "../../container/LoginPage/store/selectors";
import { createStructuredSelector } from "reselect";
import Arrow from "../Arrow";
import { useTranslation } from "react-i18next";

export let subMenu1Style = {
  divHeight: "35px",
  padding: "10px",
  divColor: "rgb(99, 115, 129)",
  fontSize: "12px",
  letterSpacing: "-0.25px",
  lineHeight: "14px",
  hoverBgColor: "rgb(244, 246, 248)",
  hoverColor: "#008eff",
  cursor: "pointer",
};

const navStyle = {
  textDecoration: "none",
};

export const subMenu2Style = {
  cursor: "pointer",
  bgColor: "rgb(244, 246, 248)",
  borderRadius: "0px",
  divHeight: "35px",
  letterSpacing: "-0.25px",
  lineHeight: "14px",
  fontSize: "12px",
  divColor: "rgb(33, 43, 54)",
  padding: "10px 10px 10px 50px",
  fontWeight: "normal",
  mb: "1px",
};

const SideBarMenu = (props) => {
  const { t } = useTranslation();
  const session =
    JSON.parse(sessionStorage.getItem("sessionDetails")) || sessionDetails;

  const { menus: mainMenus } = props.sessionDetails.menus
    ? props.sessionDetails
    : session;

  let menuItems = generateFilteredRoutes(mainMenus, props.clientDetails);

  const location = useLocation();
  const currentPath = location.pathname;

  const sortMenus = menuItems.sort((curr, prev) => {
    return curr.order - prev.order;
  });

  const nest = (items, parent = 0) => {
    const nested = [];

    Object.values(items).forEach((item) => {
      if (item.accessLevelName !== "No Access" && item.isMenu) {
        const treeItem = {
          pageElementDisplayName: t(item.name),
          pageElementFileName: item.path,
          key: item.pageElementId,
          state: item.state,
        };
        if (item.parentPageElementId === parent) {
          const children = nest(items, item.pageElementId);

          if (children.length) {
            treeItem.children = children;
          }

          nested.push(treeItem);
        }
      }
    });

    return nested;
  };

  const menus = nest(sortMenus);

  const hasChildren = (item) => {
    const { children } = item;
    if (children === undefined) {
      return false;
    }
    if (children.constructor !== Array) {
      return false;
    }
    if (children.length === 0) {
      return false;
    }
    return true;
  };

  const SideMenuItem = ({ item }) => {
    return hasChildren(item) ? MultiLevel({ item }) : SingleLevel({ item });
  };

  const extractChildren = (menus) => {
    let children = [];
    if (menus && menus.length > 0) {
      menus.forEach((item) => {
        if (
          currentPath.toLowerCase().indexOf(item.pageElementFileName) !== -1 &&
          currentPath !== item.pageElementFileName &&
          item.children &&
          item.children.length > 0
        ) {
          children = [...children];
          children.push(item);
        }
      });
    }
    return children;
  };
  const currentSubmenu = extractChildren(menus);

  const SingleLevel = ({ item, index }) => {
    const [hover, setHover] = useState(false);
    const MouseOver = () => {
      setHover(true);
    };
    const MouseOut = () => {
      setHover(false);
    };

    return (
      <NavLink
        to={{
          pathname: item.pageElementFileName,
          state: item.state,
        }}
        style={navStyle}
      >
        <Div
          key={index}
          {...subMenu1Style}
          divColor={
            currentPath.indexOf(item.pageElementFileName)
              ? "rgb(99, 115, 129)"
              : "#008eff"
          }
          onMouseOver={MouseOver}
          onMouseOut={MouseOut}
        >
          {item.pageElementDisplayName.replace(/ /g, "").length > 30
            ? item.pageElementDisplayName.substring(0, 30) + "..."
            : item.pageElementDisplayName}
        </Div>
      </NavLink>
    );
  };

  let isHandleClick = false;
  const MultiLevel = ({ item }) => {
    const { children } = item;

    const [hover, setHover] = useState(false);
    const MouseOver = () => {
      setHover(true);
    };
    const MouseOut = () => {
      setHover(false);
    };

    let [open, setOpen] = useState(false);
    const handleClick = (e) => {
      setOpen((prev) => !prev);
      isHandleClick = true;
    };

    const currentActivePath = item.children.find(
      (items) => currentPath === items.pageElementFileName
    );

    if (!isHandleClick) {
      open =
        currentActivePath &&
        currentActivePath.pageElementFileName === currentPath
          ? true
          : open;
    }

    return (
      <React.Fragment>
        <Div>
          <Div
            display="flex"
            key={item.pageElementDisplayName}
            {...subMenu1Style}
            divColor={
              open &&
              currentActivePath &&
              currentPath === currentActivePath.pageElementFileName
                ? "#008eff"
                : "rgb(99, 115, 129)"
            }
            onClick={(e) => handleClick(e)}
            onMouseOver={MouseOver}
            onMouseOut={MouseOut}
          >
            {item.pageElementDisplayName.replace(/ /g, "").length > 25
              ? item.pageElementDisplayName.substring(0, 25) + "..."
              : item.pageElementDisplayName}
            <Div display="flex" ml="auto" alignItems="center">
              {open ? <Arrow facing="down" /> : <Arrow />}
            </Div>
          </Div>
          <Div display={open ? "block" : "none"}>
            {children.map((subMenuItem, index) => (
              <NavLink
                to={{
                  pathname: subMenuItem.pageElementFileName,
                  state: subMenuItem.state,
                }}
                style={navStyle}
                key={index}
              >
                <Div
                  {...subMenu2Style}
                  fontWeight={
                    currentPath.indexOf(subMenuItem.pageElementFileName)
                      ? "normal"
                      : "500"
                  }
                  key={index}
                >
                  {subMenuItem.pageElementDisplayName}
                </Div>
              </NavLink>
            ))}
          </Div>
        </Div>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      {/* {currentSubmenu && currentSubmenu.length > 0  ? 
        (
          <SideMenu>
            {currentSubmenu[0].children.map((item, key) => (
              <SideMenuItem
                key={key}
                item={item}
              />
            ))}
          </SideMenu>
        ) :
        null
      } */}

      {currentSubmenu && currentSubmenu.length > 0
        ? currentSubmenu[0].children.map((item, key) => (
            <SideMenuItem key={key} item={item} />
          ))
        : null}
    </React.Fragment>
  );
};

const mapStateToProps = createStructuredSelector({
  isAuthenticated: selectIsAuthenticated(),
  clientDetails: selectClientDetails(),
  sessionDetails: selectSessionDetails(),
});
const withConnect = connect(mapStateToProps, null);

export default compose(withConnect)(SideBarMenu);
