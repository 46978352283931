import React, { Component } from "react";
import { store } from "react-notifications-component";
import { statusCode } from "../config/messageConfig";
import { buildNotification } from "../config/notification";
const axiosHandler = (WrappedComponent, axios) => {
  return class extends Component {
    state = {
      errors: null,
    };
    removeErrors = () => {
      this.setState({ errors: null });
    };

    UNSAFE_componentWillMount() {
      this.axiosRequest = axios.interceptors.request.use((request) => {
        this.setState({ errors: null });
        return request;
      });
      this.axiosResponse = axios.interceptors.response.use(
        (res) => res,
        (error) => {
          const { data, status } = error.response || {};
          if (status === 401) {
            const clientDetails =
              JSON.parse(sessionStorage.getItem("clientDetails")) || {};
            const clientName = clientDetails ? clientDetails.name : "";
            sessionStorage.removeItem("sessionDetails");
            sessionStorage.removeItem("languageId");
            sessionStorage.removeItem("i18nextLng");
            window.location.href = "/?" + clientName;
          }
          if (data) {
            const msCode = data.messageCode;
            const moduleCode = msCode.split("-")[1];
            const errorCode = msCode.split("-")[2];

            if (statusCode.hasOwnProperty(moduleCode)) {
              const item = statusCode[moduleCode].find((x) => {
                return parseInt(x.code) === parseInt(errorCode);
              });

              const notification = buildNotification({
                message: item ? item.message : "notification.axiosError", //error.message,
                type: "danger",
              });
              store.addNotification({
                ...notification,
              });
            }
            return true;
          }
          const notification = buildNotification({
            message: "notification.axiosError", //error.message,
            type: "danger",
          });

          store.addNotification({
            ...notification,
          });
          return error;
        }
      );
    }
    componentWillUnmount() {
      axios.interceptors.request.eject(this.axiosRequest);
      axios.interceptors.request.eject(this.axiosResponse);
    }
    render() {
      return (
        <React.Fragment>
          <WrappedComponent {...this.props} />
        </React.Fragment>
      );
    }
  };
};

export default axiosHandler;
