export const INIT_FILE_TYPE = "INIT_FILE_TYPE";
export const FETCHING_FILE_TYPE = "FETCHING_FILE_TYPE";
export const FETCH_FILE_TYPE = "FETCH_FILE_TYPE";
export const FILE_TYPE_FETCH_COMPLETE = "FILE_TYPE_FETCH_COMPLETE";
export const INIT_BULK_UPLOAD_SAVE = "INIT_BULK_UPLOAD_SAVE";
export const BULK_UPLOAD_SAVED = "BULKL_UPLOAD_SAVED";
export const BULK_UPLOAD_SAVE_FAILED = "BULK_UPLOAD_SAVE_FAILED";
export const INIT_GET_RECENT_UPLOADED_FILES = "INIT_GET_RECENT_UPLOADED_FILES";
export const FETCHING_RECENT_UPLOADED_FILES = "FETCHING_RECENT_UPLOADED_FILES";
export const FETCH_RECENT_UPLOADED_FILES_COMPLETE =
  "FETCH_RECENT_UPLOADED_FILES_COMPLETE";
export const FETCHED_RECENT_UPLOADED_FILES = "FETCHED_RECENT_UPLOADED_FILES";
export const INIT_USER_UPLOAD_SAVE = "INIT_USER_UPLOAD_SAVE";
export const USER_UPLOAD_SAVED = "USER_UPLOAD_SAVED";
export const USER_UPLOAD_SAVE_FAILED = "USER_UPLOAD_SAVE_FAILED";
export const DOWNLOAD_BULK_SUPPLIERS = "DOWNLOAD_BULK_SUPPLIERS";
export const DOWNLOAD_MASS_SUPPLIERS = "DOWNLOAD_MASS_SUPPLIERS";
export const INIT_USER_UPLOAD_FETCH = "INIT_USER_UPLOAD_FETCH";
export const FETCHING_USER_UPLOAD = "FETCHING_USER_UPLOAD";
export const FETCH_USER_UPLOAD = "FETCH_USER_UPLOAD";
export const USER_UPLOAD_FETCH_COMPLETE = "USER_UPLOAD_FETCH_COMPLETE";
export const DOWNLOAD_USER_UPLOAD = "DOWNLOAD_USER_UPLOAD";
export const SEND_EMAIL = "SEND_EMAIL";
export const INIT_DOWNLOAD_RECENT_UPLOAD_FILE =
  "INIT_DOWNLOAD_RECENT_UPLOAD_FILE";
export const DOWNLOADING_RECENT_UPLOAD_FILE = "DOWNLOADING_RECENT_UPLOAD_FILE";
export const DOWNLOADED_RECENT_UPLOAD_FILE_COMPLETE =
  "DOWNLOADED_RECENT_UPLOAD_FILE_COMPLETE";
export const DOWNLOAD_RECENT_UPLOAD_FILE_FAILED =
  "DOWNLOAD_RECENT_UPLOAD_FILE_FAILED";
export const RESET_FORM_SAVED_STATE = "RESET_FORM_SAVED_STATE";
export const INIT_GET_BULK_UPLOAD_TYPES = "INIT_GET_BULK_UPLOAD_TYPES";
export const GET_BULK_UPLOAD_TYPES_COMPLETED = "GET_BULK_UPLOAD_TYPES_COMPLETED";
export const GET_BULK_UPLOAD_TYPES_FAILED = "GET_BULK_UPLOAD_TYPES_FAILED";
export const INIT_SUPPLIER_UPLOAD_FETCH = "INIT_SUPPLIER_UPLOAD_FETCH";
export const FETCHING_SUPPLIER_UPLOAD = "FETCHING_SUPPLIER_UPLOAD";
export const FETCH_SUPPLIER_UPLOAD = "FETCH_SUPPLIER_UPLOAD";
export const SUPPLIER_UPLOAD_FETCH_COMPLETE = "SUPPLIER_UPLOAD_FETCH_COMPLETE";
export const INIT_UPLOAD_FETCH = "INIT_UPLOAD_FETCH";
export const FETCHING_UPLOAD = "FETCHING_UPLOAD";
export const FETCH_UPLOAD = "FETCH_UPLOAD";
export const UPLOAD_FETCH_COMPLETE = "UPLOAD_FETCH_COMPLETE";