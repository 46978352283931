import { fromJS } from "immutable";

import {
  FETCHING_USER_GROUP_LIST,
  USER_GROUP_LIST,
  USER_GROUP_LIST_FETCHING_COMPLETED,
  FETCHING_GET_BASE_ROLE,
  FETCH_BASE_ROLE,
  GET_BASE_ROLE_COMPLETED,
  INIT_USER_GROUP_SAVE,
  SAVE_USER_GROUP,
  FORM_SUBMISSION_STATUS,
  RESET_FORM_SUBMISSION_STATUS,
  INIT_USER_GROUP_DELETE,
  DELETE_SUCCESS,
  DELETE_FAILED,
  FETCHING_SELECT_USERS_LIST,
  SELECT_USERS_LIST,
  SELECT_USERS_LIST_FETCHING_COMPLETED,
  SET_USER_GROUP_EDIT_DATA,
  INIT_USER_GROUP_EDIT,
  INIT_UPDATE_GROUP_STATUS,
  SAVE_UPDATED_GROUP_STATUS,
  SAVE_USER_GROUP_FAILED,
  SET_USER_GROUP_EDIT_DATA_FAILED,
} from "./actionTypes";

export const initialState = fromJS({
  isUserGroupFetching: false,
  userGroupList: [],
  fetchingBaseRoleList: false,
  baseRoleList: [],
  formSubmissionStatus: "",
  savingUserGroup: false,
  deleting: false,
  deleted: [],
  isSelectUsersFetching: false,
  selectUsersList: [],
  editing: false,
  userGroupDataById: null,
  updatingUserGroupStatus: false,
  fetchForEditComplete: false,
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_USER_GROUP_LIST:
      return state.set("isUserGroupFetching", true);
    case USER_GROUP_LIST:
      return state.set("userGroupList", action.data);
    case USER_GROUP_LIST_FETCHING_COMPLETED:
      return state.set("isUserGroupFetching", false);
    case FETCHING_GET_BASE_ROLE: {
      return state.set("fetchingBaseRoleList", true);
    }
    case FETCH_BASE_ROLE: {
      return state.set("baseRoleList", action.data);
    }
    case GET_BASE_ROLE_COMPLETED: {
      return state.set("fetchingBaseRoleList", false);
    }
    case INIT_USER_GROUP_SAVE: {
      return state.set("savingUserGroup", true);
    }
    case SAVE_USER_GROUP: {
      return state.set("savingUserGroup", false);
    }
    case SAVE_USER_GROUP_FAILED: {
      return state.set("savingUserGroup", false);
    }
    case FORM_SUBMISSION_STATUS: {
      return state.set("formSubmissionStatus", action.data);
    }
    case RESET_FORM_SUBMISSION_STATUS: {
      return state.set("formSubmissionStatus", false);
    }
    case INIT_USER_GROUP_DELETE: {
      return state.set("deleting", true);
    }
    case DELETE_SUCCESS: {
      return state
        .set("deleting", false)
        .set("deleted", [...state.get("deleted"), ...action.id]);
    }
    case DELETE_FAILED: {
      return state.set("deleting", false);
    }
    case FETCHING_SELECT_USERS_LIST:
      return state.set("isSelectUsersFetching", true);
    case SELECT_USERS_LIST:
      return state.set("selectUsersList", action.data);
    case SELECT_USERS_LIST_FETCHING_COMPLETED:
      return state.set("isSelectUsersFetching", false);
    case INIT_USER_GROUP_EDIT: {
      return state
      .set("fetchForEditComplete", true)
      .set("editing", true);
    }
    case SET_USER_GROUP_EDIT_DATA: {
      return state
        .set("userGroupDataById", action.data)
        .set("fetchForEditComplete", false)
        .set("editing", false);
    }
    case SET_USER_GROUP_EDIT_DATA_FAILED: {
      return state
        .set("fetchForEditComplete", false)
        .set("editing", false);
    }
    case INIT_UPDATE_GROUP_STATUS: {
      return state.set("updatingUserGroupStatus", true);
    }
    case SAVE_UPDATED_GROUP_STATUS: {
      return state.set("updatingUserGroupStatus", false);
    }
    default:
      return state;
  }
};
export default reducer;
