import {
  put,
  takeEvery,
  call,
  all,
  takeLatest,
  take,
  fork,
} from "redux-saga/effects";
import { buildNotification } from "../../../config/notification";
import { store } from "react-notifications-component";
import {
  docFetching,
  fetchDocumentSubmissionHistory,
  docFetchComplete,
  fetchingCountry,
  fetchCountryDropdownList,
  countryFetchingComplete,
  fetchingProfiles,
  fetchProfileDropdownList,
  profileFetchingComplete,
  fetchingSuppliers,
  fetchSupplierDropdownList,
  supplierFetchingComplete,
  fetchAdditionalFields,
  fetchingDocSubmissionExistingData,
  fetchDocumentSubmissionExistingData,
  fetchDocumentSubmissionExistingDataComplete,
  fetchAdditionalingFields,
  additionalingFieldsFetchComplete,
  clearAdditionalFields,
  fetchingPONumbers,
  fetchPONumbers,
  poNumbersFetchComplete,
  poDetailsFetchComplete,
  fetchOcrDetails,
  setUploadProgress,
  uploaded,
  uploadFailed,
  uploading,
  uploadReset,
  ocrDetailsFetching,
  ocrDetailsFetchComplete,
  ocrDetailsFetchFailed,
  pdfGenerating,
  pdfGenerated,
  fetchDocExtractedData,
} from "../store/actions";

import {
  INIT_DOCUMENT_HISTORY_FETCH,
  INIT_COUNTRY_LIST_FETCH,
  INIT_PROFILE_LIST_FETCH,
  INIT_SUPPLIER_LIST_FETCH,
  INIT_FETCH_ADDITIONAL_FIELDS,
  INIT_GET_DOCUMENT_SUBMISSION,
  INIT_PO_NUMBER_FETCH,
  INIT_DOCUMENT_SUBMISSION_SAVE,
  INIT_PO_DETAILS_FETCH,
  INIT_OCR_DETAILS_FETCH,
  INIT_SAVE_EINVOICE_WITH_DETAILS,
  INIT_UPLOAD_SUPPORTING_ATTACHMENTS,
  INIT_SAVE_MAPPING,
  INIT_DOWNLOAD_SAMPLE_DOC,
} from "./actionTypes";
import { apiRef } from "../config";
import axios from "../../../config/axios";
import { END, eventChannel } from "redux-saga";

const getRequestDetails = {
  method: "GET",
  cache: "no-cache",
};
const postRequestDetails = {
  method: "POST",
  cache: "no-cache",
  headers: {
    "Content-Type": "application/json",
  },
};

export default function* watchDocHistorySubmissions() {
  yield all([
    takeEvery(INIT_DOCUMENT_HISTORY_FETCH, initDocSubmissionHistoryListSaga),
    takeEvery(INIT_COUNTRY_LIST_FETCH, getCountryDropdownListSaga),
    takeEvery(INIT_SUPPLIER_LIST_FETCH, getSupplierSaga),
    takeEvery(INIT_PROFILE_LIST_FETCH, getProfileDropdownListSaga),
    takeEvery(INIT_PO_NUMBER_FETCH, initFecthPoNumberSaga),
    takeEvery(INIT_FETCH_ADDITIONAL_FIELDS, getAdditionalFieldsSaga),
    takeLatest(INIT_DOCUMENT_SUBMISSION_SAVE, saveDocumentSubmissionSaga),
    takeLatest(INIT_GET_DOCUMENT_SUBMISSION, initGetDocumentSubmissionDataSaga),
    takeEvery(INIT_PO_DETAILS_FETCH, initPODetailsFetch),
    takeEvery(INIT_OCR_DETAILS_FETCH, initOCRDetailsFetchSaga),
    takeEvery(INIT_SAVE_EINVOICE_WITH_DETAILS, initSaveEinvoiceWithDetailsSaga),
    takeEvery(
      INIT_UPLOAD_SUPPORTING_ATTACHMENTS,
      initUploadSupportingAttachmentsSaga
    ),
    takeEvery(INIT_DOWNLOAD_SAMPLE_DOC, initDownloadSampleDocSaga),
    takeEvery(INIT_SAVE_MAPPING, initSaveMappingSaga),
  ]);
}

function* initDocSubmissionHistoryListSaga(action) {
  let documentList = [];
  let additionalFields = [];
  let rowCount = [];

  const filterParam = {
    ...action.payload,
  };
  yield put(docFetching());
  try {
    postRequestDetails.data = JSON.stringify({
      languageID: "enGB",
      pageIndex: 1,
      ...filterParam,
    });

    const response = yield call(
      axios,
      "/Submission/history",
      postRequestDetails
    );

    if (response && response.status === 200) {
      documentList = response.data.data;
      rowCount = [...response.data.rowCount];
      const cellBoldStyle = {
        fontWeight: "500",
        fontFamily: "graphik-medium",
      };
      additionalFields =
        response.data.columns &&
        response.data.columns.map((field, key) => {
          return {
            name: field.displayName ? field.displayName : field.attributeName,
            selector: field.attributeName,
            sortable: true,
            filterable: true,
            filterType:
              field.attributeName.toLowerCase().indexOf("date") > -1
                ? "date"
                : "text",
            conditionalCellStyles: [
              {
                when: (row) => row[field.attributeName] !== "",
                style: cellBoldStyle,
              },
            ],
          };
        });
    }

    yield put(
      fetchDocumentSubmissionHistory({
        addtionalColumns: additionalFields,
        documentList,
        rowCount,
      })
    );
  } catch (error) {
    action.callback && action.callback();
  }

  yield put(docFetchComplete());
}

function* getCountryDropdownListSaga() {
  yield put(fetchingCountry());
  const url = `/Submission/country?langKey=enGB`;

  try {
    const response = yield call(axios, url, getRequestDetails);
    const data = yield response.data &&
      response.data.map((item) => ({
        id: item.countryId,
        value: item.label,
      }));
    yield put(fetchCountryDropdownList(data));
    yield put(countryFetchingComplete());
  } catch (error) {
    yield put(countryFetchingComplete());
  }
}

function* getSupplierSaga(action) {
  yield put(fetchingSuppliers());
  const url = `/Submission/suppliers?&countryID=${action.payload}`;
  try {
    const response = yield call(axios, url, getRequestDetails);

    const data = yield response.data &&
      response.data.map((item) => ({
        value: item.supplierName,
        id: item.supplierID,
      }));

    yield put(fetchSupplierDropdownList(data));
    yield put(supplierFetchingComplete());
  } catch (error) {
    yield put(supplierFetchingComplete());
  }
}
function* getProfileDropdownListSaga(action) {
  yield put(fetchingProfiles());
  let sessionDetails =
    JSON.parse(sessionStorage.getItem("sessionDetails")) || {};
  let langKey =
    sessionDetails && sessionDetails.languageId
      ? sessionDetails.languageId
      : "";
  const { countryID } = action.payload;
  if (!countryID) {
    yield put(fetchProfileDropdownList([]));
    yield put(clearAdditionalFields());
    yield put(profileFetchingComplete());
    return false;
  }
  const url = `/Submission/profiles?countryID=${countryID}&langKey=${langKey}`;
  try {
    const response = yield call(axios, url, getRequestDetails);
    const data = yield response.data.length > 0
      ? response.data
          .map((item) => ({
            value: item.name ? item.name : item.description,
            id: item.id,
            isAttachment: item.isAttachmentRequired,
            active: item.active,
            isPOInvoice: item.isPOInvoice,
          }))
          .filter((item) => item.active)
      : [];

    yield put(fetchProfileDropdownList(data));
    yield put(profileFetchingComplete());
  } catch (error) {
    yield put(profileFetchingComplete());
  }
}
function* initFecthPoNumberSaga(action) {
  yield put(fetchingPONumbers());

  const url = `/PurchaseOrder/list?supplierId=${action.payload}`;
  try {
    const response = yield call(axios, url, getRequestDetails);

    const data = yield response.data
      ? response.data.map((item) => ({
          id: item.poNumber,
          value: item.poNumber,
          companyCode: item.companyCode,
          vendorNumber: item.vendorNumber,
        }))
      : [];

    yield put(fetchPONumbers(data));
    yield put(poNumbersFetchComplete());
  } catch (error) {
    yield put(poNumbersFetchComplete());
  }
}

function* getAdditionalFieldsSaga(action) {
  const param = action.param;
  let fields = [];
  if (param.profileId) {
    yield put(fetchAdditionalingFields());
    const url = `/Submission/attributes?languageID=enGB&ProfileID=${param.profileId}`;
    try {
      const response = yield call(axios, url, getRequestDetails);
      if (response && response.data) {
        fields = response.data;
      }
    } catch (error) {
      action.callback && action.callback();
    }
  }
  yield put(fetchAdditionalFields(fields));
  yield put(additionalingFieldsFetchComplete());
}

function* saveDocumentSubmissionSaga(action) {
  const apiURL = apiRef[action.payload.invoiceType];
  const { flowType } = action.payload.formData;

  if (flowType === 3) yield put(pdfGenerating());

  try {
    yield (postRequestDetails.data = {
      ...action.payload.formData,
    });
    const response = yield call(axios, apiURL, postRequestDetails);

    action.callback && action.callback(response);
    if (response && response.status === 200) {
      if (flowType === 3) yield put(pdfGenerated(response.data));
      const notification = buildNotification({
        message:
          action.payload.invoiceType === "invoice-generation"
            ? "notification.docSubmission.invoiceGenerated"
            : "notification.docSubmission.docsSaveSuccess",
        type: "success",
      });
      if (flowType !== 4)
        store.addNotification({
          ...notification,
        });
    }
  } catch (error) {
    action.callback({ error: true });
  }
}

function* initGetDocumentSubmissionDataSaga(action) {
  yield put(fetchingDocSubmissionExistingData());
  const { id, refNum } = action.payload || {};
  let param = "";
  if (id) param = `submissionId=${id}`;
  if (refNum) param = `ReferenceNumber=${refNum}`;

  const url = `/Submission/submissionById?${param}`;
  try {
    const response = yield call(axios, url, getRequestDetails);
    if (action.callback) yield action.callback(response);
    if (response && response.status === 200) {
      yield put(fetchDocumentSubmissionExistingData(response.data));
      yield put(fetchDocumentSubmissionExistingDataComplete());
    } else {
      yield put(fetchDocumentSubmissionExistingDataComplete());
      const notification = buildNotification({
        message: response ? response.message : "notification.serverError",
        type: "danger",
      });
      store.addNotification({
        ...notification,
        dismiss: {
          duration: 5000,
        },
      });
    }
  } catch (error) {
    action.callback && action.callback();
  }
}

function* initPODetailsFetch(action) {
  const url = `/PurchaseOrder/getDetailsByOrderNumber?orderNumber=${action.poNumber}`;
  try {
    const response = yield call(axios, url, getRequestDetails);
    yield put(poDetailsFetchComplete(response.data));
  } catch (error) {
    yield put(poDetailsFetchComplete());
  }
}

function* initOCRDetailsFetchSaga(action) {
  const url = `EInvoice/GetFullOCRDetails?SubmissionId=${action.invoiceID}`;
  const docExtrationsUrl = `/Einvoice/GetDocExtractedData?SubmissionId=${action.invoiceID}`;
  yield put(ocrDetailsFetching());
  try {
    const response = yield call(axios, url, getRequestDetails);
    const docRes = yield call(axios, docExtrationsUrl, getRequestDetails);

    yield put(fetchDocExtractedData(docRes.data));
    yield put(fetchOcrDetails(response.data));
    yield put(ocrDetailsFetchComplete());
  } catch (error) {
    yield put(ocrDetailsFetchFailed());
  }
}

function* initSaveEinvoiceWithDetailsSaga(action) {
  const url = yield action.payload.flag === "draft" ||
  action.payload.flag === "generation"
    ? "Einvoice/DraftSubmission"
    : "Einvoice/ApproveSubmission";

  const { flag } = action.payload;
  yield delete action.payload.flag;
  yield (postRequestDetails.data = action.payload);
  try {
    const response = yield call(axios, url, postRequestDetails);

    if (action.callback) yield action.callback({ ...response, flag });
    if (response && response.status === 200) {
      if (action.payload.flowType === 3 || action.payload.flowType === 4) {
        yield put(pdfGenerating());
        yield put(pdfGenerated(response.data));
      }
      const notification = buildNotification({
        message:
          flag === "draft"
            ? "notification.docSubmission.draftSaved"
            : flag === "generation"
            ? "notification.docSubmission.pdfGenerated"
            : "notification.docSubmission.docsSaveSuccess",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
    }
  } catch (error) {
    action.callback({ error: true });
  }
}

function upload(payload, onProgress) {
  const url = `/Submission/AddSupportingDoc`;

  const config = {
    onUploadProgress: onProgress,
  };

  return axios.post(url, payload, config);
}
function createUploader(payload) {
  let emit;
  const chan = eventChannel((emitter) => {
    emit = emitter;
    return () => {}; // it's necessarily. event channel should
    // return unsubscribe function. In our case
    // it's empty function
  });

  const uploadPromise = upload(payload, (event) => {
    if (event.loaded === 1) {
      emit(END);
    }

    emit(event);
  });

  return [uploadPromise, chan];
}
function* watchOnProgress(chan) {
  while (true) {
    const data = yield take(chan);
    const { loaded, total } = data;
    yield put(setUploadProgress((loaded * 100) / total));
  }
}
function* initUploadSupportingAttachmentsSaga(action) {
  const [uploadPromise, chan] = createUploader(action.payload);
  yield put(uploading());
  yield fork(watchOnProgress, chan);

  try {
    yield call(() => uploadPromise);

    yield put(uploaded());
    if (action.callback) action.callback();

    yield put(uploadReset());
    const notification = yield buildNotification({
      message: "notification.docSubmission.attachmentUploaded",
      type: "success",
    });
    yield store.addNotification({
      ...notification,
    });
  } catch (err) {
    yield put(uploadFailed());
  }
}

function* initSaveMappingSaga(action) {
  const url = "/Submission/SaveMappings";
  yield (postRequestDetails.data = action.payload);
  try {
    const response = yield call(axios, url, postRequestDetails);

    yield action.callback(response);
    if (response && response.status === 200) {
      const notification = buildNotification({
        message: response.data,
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
    }
  } catch (error) {
    action.callback({ error: true });
  }
}

function* initDownloadSampleDocSaga(action) {
  let url = "";
  let name = "";
  const { flowType, fileType } = action.payload;
  const extension = fileType || "xlsx";
  if (flowType === 4) {
    url = "/Submission/FileMetadataDownload";
    name = `Sample file metadata.${extension}`;
  } else if (flowType === 2) {
    url = "/Submission/CustomTemplate";
    name = `custom template.${extension}`;
  }
  url += `?extension=${extension}`;
  getRequestDetails.responseType = "blob";

  try {
    if (url) {
      const response = yield call(axios, url, getRequestDetails);
      if (response && response.status !== 204) {
        const data = response.data;
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", name);
        document.body.appendChild(link);
        link.click();
      }
    }
  } catch (error) {
    action.callback && action.callback();
  }
}
