import React, { useState } from "react";
import Button from "./Button";
import Div from "../Div";

import { flexCol, flexRow } from "../Layout/style";
const dropdownContent = {
  position: "absolute",
  bgColor: "#FFF",
  minWidth: "160px",
  boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
  zIndex: 1,
};
const ButtonDropdown = (props) => {
  const [hover, setHover] = useState(false);
  const options =
    props.options && props.options.length > 0 ? props.options : [];
  return (
    <Div
      position="relative"
      margin="2px"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      {...flexCol}
    >
      <Div {...flexRow}>
        <Button> {props.text} </Button>
      </Div>
      <Div {...flexRow}>
        {hover ? (
          <Div {...dropdownContent} {...flexCol}>
            {options.map((option) => {
              return (
                <Div
                  {...flexRow}
                  cursor="pointer"
                  hoverBgColor="#018eff"
                  hoverColor="#fff"
                  padding="12px 16px"
                  onClick={() => props.onClickHandler(option.value)}
                >
                  {option.text}
                </Div>
              );
            })}
          </Div>
        ) : null}
      </Div>
    </Div>
  );
};

export default ButtonDropdown;
