import { put, takeEvery, call, all } from "redux-saga/effects";
import { buildNotification } from "../../../config/notification";
import { store } from "react-notifications-component";
import {
  submissionProfilesFetching,
  submissionProfilesFetch,
  submissionProfileFetchComplete,
  submisisonProfileSaved,
  submissionProfileSaveFailed,
  deleteSuccess,
  deletFailed,
  lineHeaderAttrsFetching,
  lineHeaderAttrsFetch,
  lineHeaderAttrsFetchComplete,
  lineAttrsFetching,
  lineAttrsFetch,
  lineAttrsFetchComplete,
  deleting,
} from "../store/actions";

import {
  INIT_SUBMISSION_PROFILE_FETCH,
  INIT_LIST_HEADER_ATTRS_FETCH,
  INIT_LIST_LINE_ATTRS_FETCH,
  INIT_SUBMISSION_PROFILE_SAVE,
  INIT_SUBMISSION_PROFILES_DELETE,
  INIT_SUBMISSION_EDIT,
} from "./actionTypes";
import axios from "../../../config/axios";

const getRequestDetails = {
  method: "GET",
  cache: "no-cache",
};
const postRequestDetails = {
  method: "POST",
  cache: "no-cache",
  headers: {
    "Content-Type": "application/json",
  },
};

const putRequestDetails = {
  method: "PUT",
  cache: "no-cache",
  headers: {
    "Content-Type": "application/json",
  },
};

export default function* watchSubmissionProfiles() {
  yield all([
    takeEvery(INIT_SUBMISSION_PROFILE_FETCH, initSubmissionProfileFecthSaga),
    takeEvery(INIT_LIST_HEADER_ATTRS_FETCH, initListHeadersAttrsFecthSaga),
    takeEvery(INIT_LIST_LINE_ATTRS_FETCH, initListsAttrsFecthSaga),

    takeEvery(INIT_SUBMISSION_PROFILE_SAVE, initSubmissionProfileSaveSaga),
    takeEvery(INIT_SUBMISSION_PROFILES_DELETE, initSubmissionProfileDelete),
    takeEvery(INIT_SUBMISSION_EDIT, initEditSaga),
  ]);
}

function* initSubmissionProfileFecthSaga(action) {
  yield put(submissionProfilesFetching());

  const url = "/SubmissionProfile/list";
  try {
    const response = yield call(axios, url, getRequestDetails);
    yield put(submissionProfilesFetch(response.data));
    yield put(submissionProfileFetchComplete());
  } catch (error) {
    yield put(submissionProfileFetchComplete());
  }
}
function* initListHeadersAttrsFecthSaga(action) {
  yield put(lineHeaderAttrsFetching());

  const url = "/SubmissionProfile/listHeaderAttributes";
  try {
    const response = yield call(axios, url, getRequestDetails);
    yield put(lineHeaderAttrsFetch(response.data));
    yield put(lineHeaderAttrsFetchComplete());
  } catch (error) {
    yield put(lineHeaderAttrsFetchComplete());
  }
}

function* initListsAttrsFecthSaga(action) {
  yield put(lineAttrsFetching());
  const url = "/SubmissionProfile/listLineAttributes";
  try {
    const response = yield call(axios, url, getRequestDetails);
    yield put(lineAttrsFetch(response.data));
    yield put(lineAttrsFetchComplete());
  } catch (error) {
    yield put(lineAttrsFetchComplete());
  }
}

function* initSubmissionProfileSaveSaga(action) {
  const url = "/SubmissionProfile/save";
  try {
    postRequestDetails.data = { ...action.payload };
    const response = yield call(axios, url, postRequestDetails);
    if (response && response.status === 202) {
      yield put(submisisonProfileSaved());
      const notification = yield buildNotification({
        message: "notification.submissionProf.saved",
        type: "success",
      });
      yield store.addNotification({
        ...notification,
        dismiss: {
          duration: 5000,
        },
      });
      if (action.callback) {
        yield action.callback();
      }
    }
  } catch (error) {
    yield put(submissionProfileSaveFailed());
    yield action.callback({ error: true });
  }
}
function* initSubmissionProfileDelete(action) {
  const url = "/SubmissionProfile/delete";
  putRequestDetails.data = {
    listOfIds: action.id,
  };
  yield put(deleting());
  try {
    const response = yield call(axios, url, putRequestDetails);
    if (response && response.status === 201) {
      const id = action.id.split(",").map((id) => parseInt(id));

      yield put(deleteSuccess(id));
      const notification = buildNotification({
        message: "notification.submissionProf.deleted",
        type: "success",
      });
      store.addNotification({
        ...notification,
        dismiss: {
          duration: 5000,
        },
      });
    }
  } catch (error) {
    yield put(deletFailed());
  }
}

function* initEditSaga(action) {
  const url = `/SubmissionProfile/profileById?profileId=${action.id}`;
  try {
    const response = yield call(axios, url, getRequestDetails);
    if (action.callback) action.callback(response.data);
  } catch (error) {
    yield put(submissionProfileSaveFailed());
  }
}
