import React from "react";

import { Route, Switch } from "react-router-dom";

import { unAuthRoutes, commonAuthRoutes } from "./routes";

const PageNotFound = React.lazy(() => import("../container/PageNotFound"));

const AuthRoutes = (isAuth, routes) => {
  const updatedRoutes = isAuth
    ? [
        ...routes,
        ...commonAuthRoutes,

        {
          path: "404",
        },
      ]
    : unAuthRoutes;

  return (
    <Switch>
      {updatedRoutes.map(({ path, Component }, key) => {
        const route =
          path === "404" ? (
            <Route key="404NotFound" component={PageNotFound} />
          ) : (
            <Route exact path={path} key={key} component={Component} />
          );
        return route;
      })}
    </Switch>
  );
};

export default AuthRoutes;
