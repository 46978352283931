export const RECONCILIATION_LIST_REQUEST = "RECONCILIATION_LIST_REQUEST";
export const RECONCILIATION_LIST_FETCH_COMPLETE = "RECONCILIATION_LIST_FETCH_COMPLETE";
export const UPDATE_RECONCIALITIN_LIST = "UPDATE_RECONCIALITIN_LIST";

export const INIT_UPDATE_RECONCILED_DATA = "INIT_UPDATE_RECONCILED_DATA";

export const SUPPLIER_LIST_REQUEST = "SUPPLIER_LIST_REQUEST";
export const SUPPLIER_LIST_FETCH_COMPLETE = "SUPPLIER_LIST_FETCH_COMPLETE";



export const DOWNLOAD_INPUT_FILE_ACTION = "DOWNLOAD_INPUT_FILE_ACTION";
export const DOWNLOAD_DETAILS_FILE_ACTION = "DOWNLOAD_DETAILS_FILE_ACTION";

export const SAVE_RECONCILIATION_REQUEST = "SAVE_RECONCILIATION_REQUEST";
export const SAVE_RECONCILIATION_COMPLETE = "SAVE_RECONCILIATION_COMPLETE";
export const RESET_SAVE_RECONCILIATION_STATUS = "RESET_SAVE_RECONCILIATION_STATUS";

export const GET_RECONCILED_REPORTS_REQUEST = "GET_RECONCILED_REPORTS_REQUEST";
export const RECONCILED_REPORTS_FETCH_COMPLETE = "RECONCILED_REPORTS_FETCH_COMPLETE";
export const GET_SUGGESTED_INVOICES_REQUEST = "GET_SUGGESTED_INVOICES_REQUEST";
export const SUGGESTED_INVOICES_FETCH_COMPLETE = "SUGGESTED_INVOICES_FETCH_COMPLETE";

export const GET_RECONCILIATION_COMMENTS_REQUEST = "GET_RECONCILIATION_COMMENTS_REQUEST";
export const RECONCILIATION_COMMENTS_FETCH_COMPLETE = "RECONCILIATION_COMMENTS_FETCH_COMPLETE";

export const SAVE_RECONCILIATION_COMMENTS_REQUEST = "SAVE_RECONCILIATION_COMMENTS_REQUEST";
export const SAVE_RECONCILIATION_COMMENTS_COMPLETE = "SAVE_RECONCILIATION_COMMENTS_COMPLETE";

export const START_RECONCILIATION_REQUEST = "START_RECONCILIATION_REQUEST";
export const START_RECONCILIATION_COMPLETE = "START_RECONCILIATION_COMPLETE";

export const INIT_DOWNLOAD_TEMPLATE = "INIT_DOWNLOAD_TEMPLATE";