import React from "react";
import Input from ".";
import Select from "../Select";
import Datepicker from "../Datepicker";

import Checklist from "../CheckList";
import Label from "../Label";
import H from "../H";
import Span from "../Span";

import SupplierLookup from "../Supplier";
import Textarea from "../Textarea";
import P from "../P";
import { Link } from "react-router-dom";
import Checkbox from "../Checkbox";
import Lookup from "../Lookup";

import FileUpload from "../Fileupload";
import MultiSelect from "@khanacademy/react-multi-select";
import SelectLookup from "../SelectLookup";
import { useTranslation } from "react-i18next";

const FormInput = (props) => {
  const { t } = useTranslation();
  let inputElement = "";
  switch (props.elementType) {
    case "h2": {
      inputElement = (
        <H {...props.elementProps}>
          <Span style={{ background: "#fff" }}>
            {t(props.elementProps.text)}
          </Span>
        </H>
      );

      break;
    }
    case "label": {
      inputElement = (
        <Label {...props.elementProps}>{t(props.elementProps.text)}</Label>
      );

      break;
    }
    case "input": {
      const updatedElemProps = {
        ...props.elementProps,
        placeholder: t(props.elementProps.placeholder),
        title: t(props.elementProps.title),
      };
      inputElement = (
        <Input
          {...updatedElemProps}
          value={props.value}
          onChange={props.onChangeHandler}
          onBlur={props.onBlurHandler}
          onKeyPress={props.onKeyPressHandler}
          onKeyDown={props.onKeyDown}
          onClick={props.onClickHandler}
          onFocus={props.onFocusHandler}
          disabled={
            (props.elementProps && props.elementProps.isDisabled) || false
          }
        />
      );
      break;
    }
    case "select":
      inputElement = (
        <Select
          {...props.elementProps}
          value={props.value}
          onBlur={props.onBlurHandler}
          onChange={props.onChangeHandler}
          isLoading={props.loading}
          disabled={
            (props.elementProps && props.elementProps.isDisabled) || false
          }
        />
      );
      break;
    case "selectLookup":
      const {
        options,
        id,
        name,
        placeholder,
        content,
        multiple,
        isDisabled,
        inTable,
      } = props.elementProps || {};

      inputElement = (
        <SelectLookup
          id={id}
          name={name}
          isDisabled={isDisabled}
          isLoading={props.loading}
          value={props.value}
          placeholder={t(placeholder)}
          options={options}
          components={content}
          onChangeHandler={props.onChangeHandler}
          onBlurHandler={props.onBlurHandler}
          onSelectInputType={props.onSelectTypeHandler}
          multiselect={multiple}
          inTable={inTable}
        />
      );
      break;
    case "datepicker":
      inputElement = (
        <Datepicker
          {...props.elementProps}
          dateValue={props.value}
          setDate={props.onChangeHandler}
        />
      );
      break;
    case "checklist":
      inputElement = (
        <Checklist
          {...props.elementProps}
          onSelect={props.onChangeHandler}
          onBlur={props.onBlurHandler}
        />
      );
      break;

    case "checkbox":
      inputElement = (
        <Checkbox
          {...props.elementProps}
          handleToggleCheckbox={props.onChangeHandler}
          checked={props.checked}
        />
      );
      break;

    case "textarea": {
      const updatedElemProps = {
        ...props.elementProps,
        placeholder: t(props.elementProps.placeholder),
        title: t(props.elementProps.title),
      };

      inputElement = (
        <Textarea
          {...updatedElemProps}
          value={props.value}
          onChange={props.onChangeHandler}
          onBlur={props.onBlurHandler}
          onClick={props.onClickHandler}
          onFocus={props.onFocusHandler}
          disabled={
            (props.elementProps && props.elementProps.isDisabled) || false
          }
        />
      );
      break;
    }
    case "supplierLookup":
      inputElement = (
        <SupplierLookup
          {...props.elementProps}
          onClickHandler={props.onClickHandler}
        />
      );
      break;
    case "paragraph":
      inputElement = (
        <P {...props.elementProps}>
          {typeof props.elementProps.text === "string"
            ? t(props.elementProps.text)
            : props.elementProps.text}
        </P>
      );
      break;
    case "linkTo":
      inputElement = (
        <Link
          to={props.elementProps.link || (() => false)}
          onClick={props.onClickHandler}
        >
          {t(props.elementProps.text)}
        </Link>
      );
      break;
    case "lookup":
      inputElement = (
        <Lookup
          id={props.elementProps.id}
          options={props.elementProps.options}
          renderSelected={props.valueRenderer}
          handleDropdownChanges={props.onChangeHandler}
          selected={props.value}
          disabled={props.disabled}
          disableSearch={props.disableSearch}
          isLoading={props.isLoading}
        />
      );
      break;
    case "multiSelectDropdown":
      inputElement = (
        <MultiSelect
          valueRenderer={props.valueRenderer}
          options={props.elementProps.options}
          disabled={props.elementProps.disabled}
          disableSearch={props.elementProps.disableSearch}
          isLoading={props.loading}
          selected={props.value}
          onBlur={props.onBlurHandler}
          onSelectedChanged={(selected) =>
            props.onChangeHandler({
              target: {
                value: selected,
                id: props.elementProps.id,
                name: props.elementProps.name,
                required: props.elementProps.required,
              },
            })
          }
          selectAllLabel={t("common.selectAllLabel")}
          overrideStrings={{
            search: t("common.searchBtn"),
          }}
        />
      );
      break;
    case "fileUploader":
      inputElement = (
        <FileUpload
          {...props.elementProps}
          id={props.elementProps.id}
          required={props.elementProps.required}
          attachmentLabel={t(props.elementProps.label)}
          addAttachmentSizeLabel={props.elementProps.sizeLabel}
          fileData={props.value}
          deleteSingleFile={props.onFileRemoveHandler}
          handleFileUpload={props.onChangeHandler}
          icon={props.elementProps.icon}
          multiple={props.elementProps.multiple}
          maxFileSize={
            props.elementProps.maxFileSize
              ? props.elementProps.maxFileSize
              : 10485760
          }
          minFileSize={
            props.elementProps.minFileSize
              ? props.elementProps.minFileSize
              : 999
          }
        />
      );
      break;
    default: {
      const updatedElemProps = {
        ...props.elementProps,
        placeholder: t(props.elementProps.placeholder),
        title: t(props.elementProps.title),
      };
      inputElement = (
        <Input
          {...updatedElemProps}
          value={props.value}
          onChange={props.onChangeHandler}
          onBlur={props.onBlurHandler}
          onClick={props.onClickHandler}
          onFocus={props.onFocusHandler}
          disabled={
            (props.elementProps && props.elementProps.isDisabled) || false
          }
        />
      );
      break;
    }
  }

  return inputElement;
};

export default FormInput;
