import { fromJS } from "immutable";

import {
  FETCHING_PAGE_ELEMENTS,
  FETCH_PAGE_ELEMENTS,
  GET_PAGE_ELEMENTS_COMPLETED,
  FETCHING_ACCESS_LEVEL,
  FETCH_ACCESS_LEVEL,
  GET_ACCESS_LEVEL_COMPLETED,
  FORM_SUBMISSION_STATUS,
  RESET_FORM_SUBMISSION_STATUS,
  INIT_PROFILE_ROLE_SAVE,
  SAVE_PROFILE_ROLE,
  INIT_PROFILE_PERMISSION_EDIT,
  SET_PROFILE_PERMISSION_EDIT_DATA,
  INIT_USER_ROLES_DELETE,
  DELETE_SUCCESS,
  DELETE_FAILED,
  SAVE_PROFILE_ROLE_FAILED,
  SET_PROFILE_PERMISSION_EDIT_DATA_FAILED,
  FETCHING_LOGIN_GDPR_OPTIONS,
  FETCH_LOGIN_GDPR_OPTIONS,
  GET_LOGIN_GDPR_OPTIONS_COMPLETED,
  FETCHING_APPLICATION_GDPR_OPTIONS,
  GET_APPLICATION_GDPR_OPTIONS_COMPLETED,
  FETCH_APPLICATION_GDPR_OPTIONS,
} from "./actionTypes";

export const initialState = fromJS({
  fetchingBaseRoleList: false,
  baseRoleList: [],
  fetchingPageElements: false,
  pageElements: [],
  fetchingProfileList: false,
  profileList: [],
  updatedProfileList: [],
  fetchingAccessLevel: false,
  accessLevelList: [],
  formSubmissionStatus: "",
  savingProfileRole: false,
  editing: false,
  profileRole: null,
  fetchForEditComplete: false,
  deleting: false,
  deleted: [],
  fetchingLoginGDPROptions: false,
  loginGDPROptions: [],
  fetchingApplicationGDPROptions: false,
  applicationGDpROptions: [],
});

const profilePermissionreducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_PAGE_ELEMENTS: {
      return state.set("fetchingPageElements", true);
    }
    case FETCH_PAGE_ELEMENTS: {
      return state.set("pageElements", action.data);
    }
    case GET_PAGE_ELEMENTS_COMPLETED: {
      return state.set("fetchingPageElements", false);
    }
    case FETCHING_ACCESS_LEVEL: {
      return state.set("fetchingAccessLevel", true);
    }
    case FETCH_ACCESS_LEVEL: {
      return state.set("accessLevelList", action.data);
    }
    case GET_ACCESS_LEVEL_COMPLETED: {
      return state.set("fetchingAccessLevel", false);
    }
    case INIT_PROFILE_ROLE_SAVE: {
      return state.set("savingProfileRole", true);
    }
    case SAVE_PROFILE_ROLE: {
      return state.set("savingProfileRole", false);
    }
    case SAVE_PROFILE_ROLE_FAILED: {
      return state.set("savingProfileRole", false);
    }
    case FORM_SUBMISSION_STATUS: {
      return state.set("formSubmissionStatus", action.data);
    }
    case RESET_FORM_SUBMISSION_STATUS: {
      return state.set("formSubmissionStatus", false);
    }
    case INIT_PROFILE_PERMISSION_EDIT: {
      return state.set("editing", true).set("fetchForEditComplete", true);
    }
    case SET_PROFILE_PERMISSION_EDIT_DATA: {
      return state
        .set("profileRole", action.data)
        .set("fetchForEditComplete", false)
        .set("editing", false);
    }
    case SET_PROFILE_PERMISSION_EDIT_DATA_FAILED: {
      return state.set("fetchForEditComplete", false).set("editing", false);
    }
    case INIT_USER_ROLES_DELETE: {
      return state.set("deleting", true);
    }
    case DELETE_SUCCESS: {
      return state
        .set("deleting", false)
        .set("deleted", [...state.get("deleted"), ...action.id]);
    }
    case DELETE_FAILED: {
      return state.set("deleting", false);
    }
    case FETCHING_APPLICATION_GDPR_OPTIONS: {
      return state.set("fetchingApplicationGDPROptions", true);
    }
    case FETCH_APPLICATION_GDPR_OPTIONS: {
      return state.set("applicationGDpROptions", action.data);
    }
    case GET_APPLICATION_GDPR_OPTIONS_COMPLETED: {
      return state.set("fetchingApplicationGDPROptions", false);
    }

    case FETCHING_LOGIN_GDPR_OPTIONS: {
      return state.set("fetchingLoginGDPROptions", true);
    }
    case FETCH_LOGIN_GDPR_OPTIONS: {
      return state.set("loginGDPROptions", action.loginGDPROptions);
    }
    case GET_LOGIN_GDPR_OPTIONS_COMPLETED: {
      return state.set("fetchingLoginGDPROptions", false);
    }
    default:
      return state;
  }
};
export default profilePermissionreducer;
