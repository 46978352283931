import {
  INIT_REJECTED_LIST,
  FETCHING_REJECTED_INVOICES,
  INIT_RESOLVE_INVOICES,
  RESOLVE_INVOICES_COMPLETED,
  SET_REJECTED_LIST,
} from "./actionTypes";

export const initResolveInvoices = (data, param) => {
  return {
    type: INIT_RESOLVE_INVOICES,
    data,
    param,
  };
};

export const resolveInvoicesComplete = () => {
  return {
    type: RESOLVE_INVOICES_COMPLETED,
  };
};

export const initRejectedList = (data) => {
  return {
    type: INIT_REJECTED_LIST,
    data,
  };
};

export const fetchingRejectedInvoices = () => {
  return {
    type: FETCHING_REJECTED_INVOICES,
  };
};

export const setRejectedList = (data) => {
  return {
    type: SET_REJECTED_LIST,
    data,
  };
};
