import _ from "lodash";
import {
  flexCol,
  flexGrow,
  flexShrink,
} from "../../../components/Layout/style";

export const signUpDivWrapper = {
  ...flexCol,
  ...flexGrow,
  backgroundColor: "rgb(255, 255, 255)",
  borderRadius: "10px",
  boxShadow: "0px 2px 15px 0px rgba(0, 0, 0, 0.1)",
  padding: "16px 16px 8px 16px",
  margin: "40px",
  overflow: "auto",
};

export const signUpAcknowledgementDivWrapper = {
  ...flexShrink,
  left: "50%",
  top: "50%",
  transform: "translate(-50%, -50%)",
  padding: "20px",
  position: "absolute",
  textalign: "center",
  alignItems: "center",
  backgroundColor: "rgb(255, 255, 255)",
  borderRadius: "10px",
  boxShadow: "0px 2px 15px 0px rgba(0, 0, 0, 0.1)",
  margin: "40px",
  overflow: "auto",
};

export const submitFormComponent = {
  ...flexCol,
  ...flexGrow,
  minHeight: "400px",
  mb: "16px",
  overflow: "auto",
};
export const signUpAcknowledgementContent = {
  ...flexCol,
  ...flexGrow,
  minHeight: "0",
  mb: "16px",
  overflow: "auto",
};

export const inputFieldWrapper = {
  display: "inline-block",
  bgColor: "#FFF",
  border: "1px solid rgb(220,226,231)",
  divHeight: "10%",
  divWidth: "30%",
  color: "rgb(51,52,54)",
  borderRadius: " 5px",
  margin: "2% 1% 2% 0",
  padding: "5px",
};

export const buttonWrapper = {
  textalign: "right",
  height: "60px",
};

export const cancelBtnStyle = {
  bgColor: "#ffffff",
  border: "2px solid #008eff",
  textColor: "#008eff",
};

export const style = {
  outlineStyle: "0",
  inputWidth: "90%",
  inputHeight: "30px",
  border: "none",
  inputColor: "#212b36",
  family: "graphik-medium",
  textSize: "14px",
  lh: "16px",
  ls: "-0.31px",
};

export const wrapperStyle = {
  divWidth: "29%",
};

export const defaultForm = {
  password: "",
  confirmPassword: "",
  userId: 0,
  primaryEmail: "",
  userName: "",
  lastName: "",
  firstName: "",
  phoneCountryCode: "",
  phone: "",
  notes: "",
  languageId: "",
  countryId: 0,
  isNotificationStatusEnable: false,

  userLoginID: 0,
  passwordQuestion: "",
  passwordAnswer: "",
  passwordReset: false,
  userID: 0,

  companyName: "",
  cityName: "",
  state: "",
  companyCountryID: 0,
  addressLine: "",
  fileContent: "",
  fileName: "",

  companyAttributes: [],
  flexAttributes: [],

  createdBy: 1,
};
export const addAdditionalInformation = (
  flexFields,
  dynamicFields,
  currentForm
) => {
  //since using unshift thus changed the chronology of the fields (waterfall execution)
  dynamicFields &&
    dynamicFields
      .sort((hRank, lRank) => (hRank.rank > lRank.rank ? -1 : 1))
      .forEach((elem) => {
        currentForm.unshift({
          controlType: "input",
          attribute: {
            id: elem.attributeID,
            "data-type": "companyFields",
            name: elem.displayName,
            label: elem.displayName,
            type: elem.attributeTypeName.toLowerCase(),
            placeholder: elem.fieldHint
              ? elem.fieldHint
              : `Please Enter ${elem.displayName}`,
            maxLength: elem.maxLength,
          },
          isTranslated: true,
          rules: {
            required: parseInt(elem.validationTypeID) === 1,
            regEx: elem.regEx, //regExData && regExData.description ? regExData.description : "",
          },
          style,
          wrapperStyle: {
            divWidth: "31.9%",
            grow: "",
            shrink: 0,
          },
        });
      });

  if (dynamicFields && dynamicFields.length > 0) {
    currentForm.unshift({
      controlType: "h2",
      attribute: {
        id: "dynamicAttribute",
        text: "signUp.signupDynamicSectionLabel",
      },
      wrapperStyle: {
        margin: "0px",
        bgColor: "none",
        border: "none",
        minHeight: "65px",
        minWidth: "100%",
        hoverBgColor: "none",
        color: "none",
        borderRadius: "0px",
        borderOnFocusWithin: "none ",
      },
      style: {
        cursor: "default",
        margin: "10px 12px",
        fontSize: "20px",
        borderBottom: "1px solid #bfc2c5",
        lh: "normal",
        width: "98%",
        padding: "0 0 18px 0",
      },
    });
  }
  flexFields &&
    flexFields
      .sort((hRank, lRank) => (hRank.rank > lRank.rank ? -1 : 1))
      .forEach((elem) => {
        const index = currentForm.findIndex((item) => {
          return item.id === elem.attributeName;
        });

        if (index === -1) {
          currentForm.unshift({
            controlType: elem.attributeName.toLowerCase().includes("country")
              ? "select"
              : "input",
            attribute: {
              id: _.camelCase(elem.attributeName),
              name: elem.displayName,
              label: elem.displayName,
              type: elem.attributeTypeName.toLowerCase(),
              placeholder: elem.fieldHint
                ? elem.fieldHint
                : `Please Enter ${elem.attributeDescription}`,
              maxLength: elem.maxLength,
              isTranslated: true,
            },
            rules: {
              required: parseInt(elem.validationTypeID) === 1,
              regEx: elem.regEx,
            },
            style,
            wrapperStyle: {
              divWidth: "31.9%",
              grow: "",
              shrink: 0,
            },
          });
        }
      });
  if (flexFields && flexFields.length > 0) {
    currentForm.unshift({
      controlType: "h2",
      attribute: {
        id: "flexAttribute",
        text: "signUp.signupFlexSectionLabel",
      },
      wrapperStyle: {
        margin: "0px",
        bgColor: "none",
        border: "none",
        minHeight: "65px",
        minWidth: "100%",
        hoverBgColor: "none",
        color: "none",
        borderRadius: "0px",
        borderOnFocusWithin: "none ",
      },
      style: {
        cursor: "default",
        margin: "10px 12px",
        fontSize: "20px",
        borderBottom: "1px solid #bfc2c5",
        lh: "normal",
        width: "98%",
        padding: "0 0 18px 0",
      },
    });
  }
  return [...currentForm];
};

export const emailRegEx =
  '^(([^<>()\\[\\]\\.,;:\\s@"\\`~!#$%\\^&*+=\\|{}\']+(\\.[^<>()\\[\\]\\.,;:\\s@"\\`~!#$%\\^&*+=\\|{}\']+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$';
export const passwordRegEx =
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[.!@#$%^&(){}:;<>,.?~_+=|\\\\-])[A-Za-z\\d.!@#$%^&(){}:;<>,.?~_+=|\\\\-]{8,50}$";
export const telephoneRegEx =
  "^(\\([0-9]{3}\\)|[0-9]{3}[-.\\s]?)[0-9]{3}[-.\\s]?[0-9]{4}$";
export const phoneRegEx  =
  "^[0-9 -.]+$";
export const phoneCodeRegEx = "^(\\+?\\d{1,3}|\\d{1,4})$";
export const nameRegEx =
  "^[A-Za-záÁàÀâÂäÄãÃåÅçÇéÉèÈêÊëËíÍìÌîÎïÏñÑóÓòÒôÔöÖõÕøØúÚùÙûÛüÜ]+$";
