import styled from "styled-components";

const Arrow = styled.div`
  position: relative;
  display: inline-block;
  width: 0;
  cursor: ${(props) => (props.cursor ? props.cursor : "pointer")};
  height: 0;
  border-top: ${(props) => (props.arrowSize ? props.arrowSize + "px" : "4px")}
    solid transparent;
  border-bottom: ${(props) =>
      props.arrowSize ? props.arrowSize + "px" : "4px"}
    solid transparent;
  border-left: ${(props) =>
      props.arrowSize ? 2 * props.arrowSize + "px" : "8px"}
    solid ${(props) => (props.arrowColor ? props.arrowColor : "#008eff")};
  transform-origin: 50% 40%;
  transform: ${(props) => (props.facing ? rotate(props.facing) : "")};
`;
const rotate = (position) => {
  switch (position) {
    case "left":
      return "rotate(180deg)";
    case "up":
      return "rotate(-90deg)";
    case "down":
      return "rotate(90deg)";
    default:
      break;
  }
};
export default Arrow;
