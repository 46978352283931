
import { fromJS } from 'immutable';
import {
  RESPONSE_TOKEN,
  ESOLOGIN_SUCCESS,
  ESOLOGIN_ERROR,
  RESPONSE_STATE,
} from './actionType';

export const initialState = fromJS({
  token: '',
  sessionDetails: '',
  loading: false,
  accessGranted: false,
  errorMessage: '',
  client: '',
});

function ssoLoginReducer(state = initialState, action) {
  switch (action.type) {
    case RESPONSE_TOKEN:
      return state.set('token', action.token);
    case RESPONSE_STATE:
      return state
        .set('client', action.client)
        .set('isClientSSO', action.isClientSSO);

    case ESOLOGIN_SUCCESS:
      return (
        state
          .set('loading', false)
          .set('accessGranted', true)
          .set('sessionDetails', action.sessionDetails)
          .set('errorMessage', '')
      );

    case ESOLOGIN_ERROR:
      return state
        .set('loading', false)
        .set('accessGranted', false)
        .set('errorMessage', action.message);

    default:
      return state;
  }
}

export default ssoLoginReducer;
