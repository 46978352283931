import React from "react";
import { useTranslation } from "react-i18next";

import ModalComponent from "../../components/Modal";
import P from "../../components/P";
import Button from "../Button/Button";
import Div from "../Div";

const GDPRMessage = (props) => {
  const { t } = useTranslation();
  return props.show ? (
    <ModalComponent
      width="533px"
      height="258px"
      borderRadius="16px"
      title={t("common.confirmGDPRTitle")}
      customStyle={{ headerBgColor: "#008eff", headerTextColor: "#fff" }}
      noClose
      show
      preventOutterClickClose
    >
      <Div>
        <Div
          padding="30px 0 0 0"
          display="flex"
          justifyContent="center"
          direction="column"
          alignItems="center"
        >
          <Div display="flex">
            <P
              color="#212b36"
              fontSize="15px"
              fontFamily="graphik-medium"
              fontWeight="500"
              letterSpacing="-0.47px"
              lineHeight="20px"
            >
              {props.gdprMessage}
            </P>
          </Div>
        </Div>
        <Div
          bgColor="#f9fafb"
          border="1px solid #dfe3e8"
          divWidth="100%"
          display="flex"
          justifyContent="space-evenly"
          alignItems="center"
          bottom="0"
          position="absolute"
          padding="10px 0"
        >
          <Button
            onClick={() => {
              props.onClose();
              props.confirmGDPR();
            }}
            bgColor="#018eff"
            textColor="#ffffff"
          >
            {t("common.confirmButton")}
          </Button>
          {props.appGDPR ? (
            <Button
              onClick={() => {
                props.onClose();
              }}
              bgColor="#FFF"
              border="1px solid #018eff"
            >
              {t("common.declineButton")}
            </Button>
          ) : null}
        </Div>
      </Div>
    </ModalComponent>
  ) : null;
};
export default GDPRMessage;
