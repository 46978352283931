export const SET_FROM_DATE = "SET_FROM_DATE";
export const SET_TO_DATE = "SET_TO_DATE";
export const FETCH_CHART_DATA = "FETCH_CHART_DATA";
export const FETCH_CHART_DATA_COMPLETE = "FETCH_CHART_DATA_COMPLETE";
export const SET_CHART_DATA = "SET_CHART_DATA";

export const FETCH_DRILLDOWN_DATA = "FETCH_DRILLDOWN_DATA";
export const DRILLDOWN_DATA_RECIEVED = "DRILLDOWN_DATA_RECIEVED";
export const FETCH_DRILLDOWN_DATA_COMPLETE = "FETCH_DRILLDOWN_DATA_COMPLETE";

export const SET_WIDGET_KEYS = "SET_WIDGET_KEYS";

export const NEW_USER_DATA_RECIEVED = "NEW_USER_DATA_RECIEVED";
export const EXISTING_USER_DATA_RECIEVED = "EXISTING_USER_DATA_RECIEVED";
export const INVOICE_STATUS_DATA_RECIEVED = "INVOICE_STATUS_DATA_RECIEVED";
export const INVOICE_COUNTRY_DATA_RECIEVED = "INVOICE_COUNTRY_DATA_RECIEVED";
export const INVOICE_REJECTION_DATA_RECIEVED =
  "INVOICE_REJECTION_DATA_RECIEVED";
export const DOCUMENT_SUBMISSION_DATA_RECIEVED =
  "DOCUMENT_SUBMISSION_DATA_RECIEVED";
export const SUPPLIER_COMPANY_DATA_RECIEVED = "SUPPLIER_COMPANY_DATA_RECIEVED";
export const TICKET_COUNT_DATA_RECIEVED = "TICKET_COUNT_DATA_RECIEVED";

export const CLEAR_ALL_CHARTS_DATA = "CLEAR_ALL_CHARTS_DATA";
