import { put, takeEvery, call, all } from "redux-saga/effects";
import moment from "moment";

import {
  newUserDataRecieved,
  existingUserDataRecieved,
  invoiceStatusDataRecieved,
  invoiceCountryDataRecieved,
  invoiceRejectionDataRecieved,
  documentSubmissionDataRecieved,
  supplierCompanyDataRecieved,
  ticketCountDataRecieved,
  drilldownDataRecieved,
  fetchChartDataComplete,
  fetchdrillDownDataComplete,
  setChartData,
  clearAllChartsData,
} from "./actions";

import { FETCH_CHART_DATA, FETCH_DRILLDOWN_DATA } from "./actionTypes";

import axios from "../../../config/axios";

const getRequestDetails = {
  method: "GET",
  cache: "no-cache",
};

export default function* watchReportStatusFetch() {
  yield all([
    takeEvery(FETCH_CHART_DATA, getChartDataSaga),
    takeEvery(FETCH_DRILLDOWN_DATA, getDrilldownDataSaga),
  ]);
}

function* getChartDataSaga(action) {
  yield put(clearAllChartsData());
  let sessionDetails = JSON.parse(sessionStorage.getItem("sessionDetails"));
  let languageID =
    sessionDetails &&
    sessionDetails.languageId &&
    sessionDetails.languageId !== ""
      ? sessionDetails.languageId
      : "enGB";
  const toDate = moment(action.toDate).toString();
  const fromDate = moment(action.fromDate).toString();
  const url =
    "/Dashboard/summary?startDate=" +
    moment(fromDate).format("YYYY-MM-DD") +
    "&endDate=" +
    moment(toDate).format("YYYY-MM-DD") +
    "&userId=" +
    action.userId +
    "&languageID=" +
    languageID;
  try {
    const response = yield call(axios, url, getRequestDetails);
    if (response) {
      const { dashboard, colorDetails } = response.data || {};
      yield put(setChartData(dashboard));
      let i = 0;
      while (dashboard && i < dashboard.length) {
        switch (dashboard[i].widgetKey) {
          case "DS":
            yield put(documentSubmissionDataRecieved(dashboard[i]));
            break;
          case "IS":
            yield put(
              invoiceStatusDataRecieved({
                labelData: dashboard[i],
                colors: colorDetails,
              })
            );
            break;
          case "TS":
            yield put(ticketCountDataRecieved(dashboard[i]));
            break;
          case "NU":
            yield put(newUserDataRecieved(dashboard[i]));
            break;
          case "EU":
            yield put(existingUserDataRecieved(dashboard[i]));
            break;
          case "IC":
            yield put(invoiceCountryDataRecieved(dashboard[i]));
            break;
          case "IR":
            yield put(invoiceRejectionDataRecieved(dashboard[i]));
            break;
          case "SC":
            yield put(supplierCompanyDataRecieved(dashboard[i]));
            break;
          default:
            break;
        }
        i++;
      }
    }
    yield put(fetchChartDataComplete());
  } catch (err) {
    yield put(fetchChartDataComplete());
    action.callback && action.callback();
  }
}

function* getDrilldownDataSaga(action) {
  let sessionDetails = JSON.parse(sessionStorage.getItem("sessionDetails"));
  let languageID =
    sessionDetails &&
    sessionDetails.languageId &&
    sessionDetails.languageId !== ""
      ? sessionDetails.languageId
      : "enGB";
  const toDate = moment(action.toDate).toString();
  const fromDate = moment(action.fromDate).toString();
  let url = "";
  if (action.widgetKey === "IR") {
    url =
      "/Dashboard/widgetById?widgetKey=" +
      action.widgetKey +
      "&statusKey=" +
      action.statusKey +
      "&startDate=" +
      moment(fromDate).format("YYYY-MM-DD") +
      "&endDate=" +
      moment(toDate).format("YYYY-MM-DD") +
      "&userId=" +
      action.userId +
      "&languageID=" +
      languageID +
      "&rejectedReason=" +
      action.label;
  } else {
    url =
      "/Dashboard/widgetById?widgetKey=" +
      action.widgetKey +
      "&statusKey=" +
      action.statusKey +
      "&startDate=" +
      moment(fromDate).format("YYYY-MM-DD") +
      "&endDate=" +
      moment(toDate).format("YYYY-MM-DD") +
      "&userId=" +
      action.userId +
      "&languageID=" +
      languageID;
  }
  try {
    const response = yield call(axios, url, getRequestDetails);
    if (response) {
      yield put(
        drilldownDataRecieved(response.data, action.widgetKey, action.label)
      );
    }
    yield put(fetchdrillDownDataComplete());
  } catch (err) {
    yield put(fetchdrillDownDataComplete());
    action.callback && action.callback();
  }
}
