export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS";
export const USER_DETAILS_UPDATE_DATA = "USER_DETAILS_UPDATE_DATA";
export const USER_DETAILS_LIST = "USER_DETAILS_LIST";
export const USER_FIELD_SUCCESS = "USER_FIELD_SUCCESS";
export const GET_USER_COLUMNS_ACTION = "GET_USER_COLUMNS_ACTION";
export const GET_USER_FIELD_DEFINITION_ACTION =
  "GET_USER_FIELD_DEFINITION_ACTION";

export const SUBMIT_USER_DATA = "SUBMIT_USER_DATA";
export const SUBMIT_USER_DATA_STATUS = "SUBMIT_USER_DATA_STATUS";
export const UPDATE_SHOW_USER_FILTER_ACTION = "UPDATE_SHOW_USER_FILTER_ACTION";
export const GET_USER_STATUS_ACTION = "FETCH_USER_STATUS_ACTION";
export const FETCH_USER_STATUS = "FETCH_USER_STATUS";

export const UPDATE_SINGLE_USER_DATA_ACTION = "UPDATE_SINGLE_USER_DATA_ACTION";
export const USER_DATA_TO_DELETE = "USER_DATA_TO_DELETE";
export const RESET_FORM_SUBMISSION_STATUS = "RESET_FORM_SUBMISSION_STATUS";
export const FORM_SUBMISSION_STATUS = "FORM_SUBMISSION_STATUS";
export const GET_USER_BASE_ROLE_ACTION = "GET_USER_BASE_ROLE_ACTION";

export const GET_SINGLE_USER_DATA_ACTION = "GET_SINGLE_USER_DATA_ACTION";
export const SET_SINGLE_USER_DATA_ACTION = "SET_SINGLE_USER_DATA_ACTION";

export const INIT_USER_ROLE_LIST = "INIT_USER_ROLE_LIST";
export const FETCH_USER_ROLE_LIST = "FETCH_USER_ROLE_LIST";

export const INIT_REGIONAL_SETTINGS = "INIT_REGIONAL_SETTINGS";
export const FETCH_REGIONAL_SETTINGS = "FETCH_REGIONAL_SETTINGS";

export const INIT_CHECK_FOR_EXISTING_USER = "INIT_CHECK_FOR_EXISTING_USER";
export const USER_EXIST = "USER_EXIST";

export const INIT_UPDATE_SECURITY_QUESTION = "INIT_UPDATE_SECURITY_QUESTION";
