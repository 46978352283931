import { fromJS } from "immutable";
import {
  GET_ANNOUNCEMENT_DETAILS,
  UPDATE_ANNOUNCEMENT_DETAILS,
  INIT_NEWS_AND_EVENTS_EDIT,
  SET_NEWS_AND_EVENTS_EDIT_DATA,
  EDIT_NEWS_AND_EVENTS_DATA_FETCHED,
  INIT_NEWSANDEVENT_DELETE,
  DELETE_SUCCESS,
  DELETE_FAILED,
  RESET_FORM_SUBMISSION_STATUS,
  FORM_SUBMISSION_STATUS,
  FETCHING_CATEGORY_LIST,
  FETCH_CATEGORY_LIST,
  CATEGORY_LIST_FETCH_COMPLETE,
  FETCHING_IMPORTANCE_LIST,
  FETCH_IMPORTANCE_LIST,
  IMPORTANCE_LIST_FETCH_COMPLETE,
  INIT_NEWS_AND_EVENTS_EDIT_FETCHING,
  SAVE_ANNOUNCEMENT_DATA,
  SAVE_ANNOUNCEMENT_DATA_COMPLETED
} from "./actionTypes";

export const initialState = fromJS({
  isAnnouncementLoading: false,
  announcementList: [],
  editing: false,
  newsandevent: null,
  fetchForEditComplete: true,
  deleting: false,
  formSubmissionStatus: "",
  deleted: [],
  lang: [],
  saveComplete: false,
  isSelectUsersFetching: false,
  selectUsersList: [],
  fetchingCategoryList: false,
  categoryListData: [],
  fetchingImportanceList: false,
  importanceListData: [],
  isEditedDataFetching : false,
  savingNewsAndEvent: false
});
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ANNOUNCEMENT_DETAILS:
      return state.set("isAnnouncementLoading", true);
    case UPDATE_ANNOUNCEMENT_DETAILS:
      return state
        .set("isAnnouncementLoading", false)
        .set("announcementList", action.announcementList);

    case INIT_NEWS_AND_EVENTS_EDIT: {
      return state.set("editing", true).set("fetchForEditComplete", true);
    }
    case INIT_NEWS_AND_EVENTS_EDIT_FETCHING: {
      return state.set("isEditedDataFetching", true);
    }
    case SET_NEWS_AND_EVENTS_EDIT_DATA: {
      return state
        .set("newsandevent", action.data)
        .set("fetchForEditComplete", false);
    }
    case EDIT_NEWS_AND_EVENTS_DATA_FETCHED: {
      return state.set("fetchForEditComplete", false)
      .set('isEditedDataFetching',false) ;
    }
    case INIT_NEWSANDEVENT_DELETE: {
      return state.set("deleting", true);
    }
    case DELETE_SUCCESS: {
      return state
        .set("deleting", false)
        .set("deleted", [...state.get("deleted"), ...action.id]);
    }
    case DELETE_FAILED: {
      return state.set("deleting", false);
    }

    case RESET_FORM_SUBMISSION_STATUS: {
      return state.set("formSubmissionStatus", false);
    }
    case FORM_SUBMISSION_STATUS: {
      return state.set("formSubmissionStatus", action.data);
    }
    case FETCHING_CATEGORY_LIST: {
      return state.set("fetchingCategoryList", true);
    }
    case FETCH_CATEGORY_LIST: {
      return state.set("categoryListData", action.data);
    }
    case CATEGORY_LIST_FETCH_COMPLETE: {
      return state.set("fetchingCategoryList", false);
    }
    case FETCHING_IMPORTANCE_LIST: {
      return state.set("fetchingImportanceList", true);
    }
    case FETCH_IMPORTANCE_LIST: {
      return state.set("importanceListData", action.data);
    }
    case IMPORTANCE_LIST_FETCH_COMPLETE: {
      return state.set("fetchingImportanceList", false);
    }
    case SAVE_ANNOUNCEMENT_DATA: {
      return state.set("savingNewsAndEvent", true); 
    }
    case SAVE_ANNOUNCEMENT_DATA_COMPLETED: {
      return state.set("savingNewsAndEvent", false);
    }
    default:
      return state;
  }
};
export default reducer;
