import {
  GET_FIELD_DEFINITIONS_ACTION,
  GET_PAYMENT_COLUMNS_ACTION,
  UPDATE_PAYMENTS_DETAILS,
  PAYMENTS_DETAILS_REQUEST,
  GET_INVOICE_COLUMNS_ACTION,
  PAY_INVOICE_DETAILS_REQUEST,
  UPDATE_INVOICE_DETAILS_ACTION,
  UPDATE_PAYMENT_DATA_ACTION,
  UPDATE_PAYMENT_FILTER_ACTION,
  INIT_GET_REMITTANCE_BY_DOC_NO,
  REMITTANCE_DATA_BY_DOC_NO,
  INIT_GET_REMITTANCE_BY_ID,
  REMITTANCE_DATA_BY_ID,
} from "./constants";

export function getFieldDefinitionsAction(paymentParam, callBackFunction) {
  return {
    type: GET_FIELD_DEFINITIONS_ACTION,
    paymentParam,
    callBackFunction,
  };
}

export function getPaymentColumnsAction(paymentColumns) {
  return {
    type: GET_PAYMENT_COLUMNS_ACTION,
    paymentColumns,
  };
}

export function getPaymentDetailsAction(param) {
  return {
    type: PAYMENTS_DETAILS_REQUEST,
    isPaymentLoading: true,
    body: param,
  };
}

export function updatePaymentsDetailsAction(paymentsList) {
  return {
    type: UPDATE_PAYMENTS_DETAILS,
    paymentsList,
  };
}

export function getPayInvoiceColumnsAction(invoiceColumns) {
  return {
    type: GET_INVOICE_COLUMNS_ACTION,
    invoiceColumns,
  };
}

export function getInvoiceDetailsAction(param, callback) {
  return {
    type: PAY_INVOICE_DETAILS_REQUEST,
    isInvoiceLoading: true,
    param: param,
    callback,
  };
}

export function updateInvoiceDetailsAction(invoiceList) {
  return {
    type: UPDATE_INVOICE_DETAILS_ACTION,
    invoiceList,
  };
}

export function updatePaymentDataAction(paymentData) {
  return {
    type: UPDATE_PAYMENT_DATA_ACTION,
    paymentData,
  };
}

export function updatePaymentFilterAction(showPaymentFilter) {
  return {
    type: UPDATE_PAYMENT_FILTER_ACTION,
    showPaymentFilter,
  };
}

export const initGetRemittanceDataByDocNo = (docNo, callback) => {
  return {
    type: INIT_GET_REMITTANCE_BY_DOC_NO,
    docNo,
    callback
  }
}

export const setRemittanceDataByDocNo = (data) => {
  return {
    type: REMITTANCE_DATA_BY_DOC_NO,
    data
  }
}

export const initGetRemittanceDataById = (id, callback) => {
  return {
    type: INIT_GET_REMITTANCE_BY_ID,
    id,
    callback
  }
}

export const setRemittanceDataById = (data) => {
  return {
    type: REMITTANCE_DATA_BY_ID,
    data
  }
}
