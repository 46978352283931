export const fullPage = {
  display: "flex",
  grow: 1,
  overflow: "hidden",
};
export const flexCol = {
  display: "flex",
  direction: "column",
};
export const flexRow = {
  display: "flex",
  direction: "row",
};
export const topHeader = {
  position: "relative",
};
export const flexGrow = {
  grow: "1",
};
export const flexShrink = {
  shrink: "0",
};

export const headerSection = {
  bgColor: "rgb(255, 255, 255)",
  border: "1px solid rgb(223, 227, 232)",
  boxShadow: "0px 1px 0px 0px rgb(223, 227, 232)",
  minHeight: "48px",
  overflowX: "hidden",
  zIndex: 10,
  padding: "0 20px 0 5px",
};
