export const USER_CREATION_REQUEST_FETCH = "USER_CREATION_REQUEST_FETCH";
export const USER_CREATION_REQUEST_COMPLETE = "USER_CREATION_REQUEST_COMPLETE";
export const UPDATE_LIST = "UPDATE_LIST";

export const APPROVE_USER_REQUEST = "APPROVE_USER_REQUEST";
export const REJECT_USER_REQUEST = "REJECT_USER_REQUEST";
export const SAVE_USER_CREATION = "SAVE_USER_CREATION";

export const UPDATE_SUBMIT_STATUS = "UPDATE_SUBMIT_STATUS";

export const CONFIRM_ACCOUNT = "CONFIRM_ACCOUNT";

export const UPDATE_CONFIRM_STATUS = "UPDATE_CONFIRM_STATUS";

export const UPDATE_REQUEST_STATUS = "UPDATE_REQUEST_STATUS";

export const GET_USER_BY_ID = "GET_USER_BY_ID";
export const FETCH_GET_USER_BY_ID = "FETCH_GET_USER_BY_ID";

export const INIT_DOWNLOAD_REQ_ATTACHMENT = "INIT_DOWNLOAD_REQ_ATTACHMENT";