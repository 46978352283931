import React from "react";

import { useLocation } from "react-router-dom";

import { NavItem } from "./style";

const MenuItems = (props) => {
  const location = useLocation();
  const currentPath = location.pathname;

  const currentMenu =
    props.allMenus && props.allMenus.find((menu) => menu.path === currentPath);

  const isActive =
    (currentMenu && currentMenu.parentPageElementId === props.id) ||
    props.pathname === currentPath;

  const menu = (
    <>
      <NavItem to={props.pathname} exact={true} active={isActive.toString()}>
        {props.text}
      </NavItem>
    </>
  );

  return menu;
};

export default MenuItems;
