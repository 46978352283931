import React from "react";
import Button from "../../../Button/Button";
import Div from "../../../Div";

import { flexCol, flexShrink, flexGrow, flexRow } from "../../../Layout/style";
import { pure } from "recompose";
import { useTranslation } from "react-i18next";
const Bottombar = (props) => {
  const { t } = useTranslation();
  return (
    <Div
      {...flexCol}
      {...flexShrink}
      padding="8px"
      borderTop="1px solid #008eff"
    >
      <Div {...flexRow} {...flexGrow} justifyContent="space-between">
        {props.hasLogical ? (
          <Div {...flexRow} {...flexGrow}>
            <Button
              role="button"
              aria-label="Toggle Advance Search Mode"
              aria-pressed={false}
              onClick={props.toggleSearchMode}
            >
              {!props.isLogical
                ? "Switch to Logical Search"
                : "Switch to default filter"}
            </Button>
          </Div>
        ) : null}
        <Div {...flexRow} {...flexShrink} ml="auto">
          <Button
            aria-label={t("common.filterApplyButton")}
            type="button"
            onClick={props.applyFilter}
            disabled={props.disable}
            bgColor="#018eff"
            textColor="#FFF"
            padding="4px 30px 4px 30px"
          >
            {t("common.filterApplyButton")}
          </Button>
        </Div>
      </Div>
    </Div>
  );
};

export default pure(Bottombar);
