import commonReducer from "./../store/reducers";
import commonSaga from "./../store/sagas";

import signUpReducer from "../container/SignUp/store/reducer";
import signUpSaga from "../container/SignUp/store/saga";
import loginReducer from "../container/LoginPage/store/reducer";
import loginSaga from "../container/LoginPage/store/saga";
import adminLogsReducer from "../container/Administration/Logs/store/reducers";
import adminLogsSaga from "../container/Administration/Logs/store/saga";
import changePasswordReducer from "../container/ChangePassword/store/reducers";
import changePasswordSaga from "../container/ChangePassword/store/saga";
import clientsListReducer from "../container/ClientManagement/store/reducers";
import clientsListSaga from "../container/ClientManagement/store/saga";
import customListReducer from "../container/CustomList/store/reducers";
import customListSaga from "../container/CustomList/store/sagas";
import dashboardReducer from "../container/Dashboard/store/reducers";
import dashboardSaga from "../container/Dashboard/store/sagas";
import documentSubmissionReducer from "../container/DocumentSubmission/store/reducers";
import documentSubmissionSaga from "../container/DocumentSubmission/store/sagas";
import emailTemplatesReducer from "../container/EmailTemplate/store/reducers";
import emailTemplatesSaga from "../container/EmailTemplate/store/saga";
import helpdeskReducer from "../container/HelpDesk/store/reducer";
import helpdeskSaga from "../container/HelpDesk/store/saga";
import helpReducer from "../container/Administration/Help/store/reducer";
import helpSaga from "../container/Administration/Help/store/saga";
import homePageReducer from "../container/HomePage/store/reducer";
import homePageSaga from "../container/HomePage/store/saga";
import invoiceAndCreditsReducer from "../container/InvoiceAndCredits/store/reducer";
import invoiceAndCreditsSaga from "../container/InvoiceAndCredits/store/sagas";
import newsAndEventsReducer from "../container/Administration/News_Events/store/reducer";
import newsAndEventsSaga from "../container/Administration/News_Events/store/saga";
import paymentsReducer from "../container/Payments/store/reducer";
import paymentsSaga from "../container/Payments/store/saga";
import profilePermissionReducer from "../container/ProfilePermissions/store/reducers";
import profilePermissionSaga from "../container/ProfilePermissions/store/saga";
import reconciliationReducer from "../container/Reconciliation/store/reducers";
import reconciliationSaga from "../container/Reconciliation/store/sagas";
import reportDetailsReducer from "../container/ReportDetails/store/reducers";
import reportDetailsSaga from "../container/ReportDetails/store/saga";
import reportStatusReducer from "../container/ReportStatus/store/reducers";
import reportStatusSaga from "../container/ReportStatus/store/sagas";
import requestsManagementReducer from "../container/RequestsManagement/store/reducers";
import requestsManagementSaga from "../container/RequestsManagement/store/sagas";
import resetPasswordReducer from "../container/ResetPassword/store/reducers";
import resetPasswordSaga from "../container/ResetPassword/store/sagas";
import statisticsReducer from "../container/Statistics/store/reducers";
import statisticsSaga from "../container/Statistics/store/sagas";
import submissionProfilesReducer from "../container/SubmissionProfiles/store/reducers";
import submissionProfilesSaga from "../container/SubmissionProfiles/store/sagas";
import supplierReducer from "../container/Supplier/store/reducer";
import supplierSaga from "../container/Supplier/store/saga";
import supplierFieldReducer from "../container/SupplierField/store/reducers";
import supplierFieldSaga from "../container/SupplierField/store/sagas";
import unauthLoaderReducer from "../container/BlankPage/store/reducers";
import unauthLoaderSaga from "../container/BlankPage/store/saga";
import userGroupReducer from "../container/UserGroup/store/reducers";
import userGroupSaga from "../container/UserGroup/store/sagas";
import userManagementReducer from "../container/UserManagement/store/reducer";
import userManagementSaga from "../container/UserManagement/store/saga";
import userUploadReducer from "../container/ManageUpload/store/reducers";
import userUploadSaga from "../container/ManageUpload/store/saga";
import vmdReducer from "../container/VMD/store/reducer";
import vmdSaga from "../container/VMD/store/sagas";
import workflowReducer from "../container/Administration/Workflow/store/reducers";
import workflowSaga from "../container/Administration/Workflow/store/sagas";
import ssoLoginReducer from "../container/SSOLoginPage/store/reducers";
import resolveInvoiceReducer from "../container/ResolveInvoices/store/reducer";
import resolveInvoicesSaga from "../container/ResolveInvoices/store/sagas";

import UMRReducer from "../container/UserManagementReports/store/reducer";
import UMRSaga from "../container/UserManagementReports/store/sagas";

export const reducerDesc = [
  {
    key: "common",
    reducer: commonReducer,
  },
  {
    key: "signUpPage",
    reducer: signUpReducer,
  },
  {
    key: "loginPage",
    reducer: loginReducer,
  },
  {
    key: "adminLogs",
    reducer: adminLogsReducer,
  },
  {
    key: "changePassword",
    reducer: changePasswordReducer,
  },
  {
    key: "clientsList",
    reducer: clientsListReducer,
  },
  {
    key: "customListItems",
    reducer: customListReducer,
  },
  {
    key: "dashboard",
    reducer: dashboardReducer,
  },
  {
    key: "documentSubmission",
    reducer: documentSubmissionReducer,
  },
  {
    key: "emailTemplates",
    reducer: emailTemplatesReducer,
  },
  {
    key: "helpdesk",
    reducer: helpdeskReducer,
  },
  {
    key: "helpMaterials",
    reducer: helpReducer,
  },
  {
    key: "homePage",
    reducer: homePageReducer,
  },
  {
    key: "invoiceAndCredits",
    reducer: invoiceAndCreditsReducer,
  },
  {
    key: "newsAndEvents",
    reducer: newsAndEventsReducer,
  },
  {
    key: "payments",
    reducer: paymentsReducer,
  },
  {
    key: "profilePermission",
    reducer: profilePermissionReducer,
  },
  {
    key: "reconciliation",
    reducer: reconciliationReducer,
  },
  {
    key: "reportDetails",
    reducer: reportDetailsReducer,
  },
  {
    key: "reportStatus",
    reducer: reportStatusReducer,
  },
  {
    key: "requestsManagement",
    reducer: requestsManagementReducer,
  },
  {
    key: "resetPassword",
    reducer: resetPasswordReducer,
  },
  {
    key: "statistics",
    reducer: statisticsReducer,
  },
  {
    key: "submissionProfiles",
    reducer: submissionProfilesReducer,
  },
  {
    key: "supplier",
    reducer: supplierReducer,
  },
  {
    key: "supplierFieldsItems",
    reducer: supplierFieldReducer,
  },
  { key: "unauthLoader", reducer: unauthLoaderReducer },
  {
    key: "userGroup",
    reducer: userGroupReducer,
  },
  {
    key: "userManagement",
    reducer: userManagementReducer,
  },
  { key: "userupload", reducer: userUploadReducer },
  { key: "VMDModule", reducer: vmdReducer },
  {
    key: "workflow",
    reducer: workflowReducer,
  },
  {
    key: "ssoLogin",
    reducer: ssoLoginReducer,
  },
  {
    key: "resolveInvoice",
    reducer: resolveInvoiceReducer,
  },
  {
    key: "UMR",
    reducer: UMRReducer,
  },
];

export const sagaDesc = [
  { key: "common", saga: commonSaga },
  { key: "signUpPage", saga: signUpSaga },
  { key: "loginPage", saga: loginSaga },
  { key: "adminLogs", saga: adminLogsSaga },
  {
    key: "changePassword",
    saga: changePasswordSaga,
  },
  {
    key: "clientsList",
    saga: clientsListSaga,
  },
  {
    key: "customListItems",
    saga: customListSaga,
  },
  { key: "dashboard", saga: dashboardSaga },
  {
    key: "documentSubmission",
    saga: documentSubmissionSaga,
  },
  {
    key: "emailTemplates",
    saga: emailTemplatesSaga,
  },
  { key: "helpdesk", saga: helpdeskSaga },
  { key: "helpMaterials", saga: helpSaga },
  { key: "homePage", saga: homePageSaga },
  {
    key: "invoiceAndCredits",
    saga: invoiceAndCreditsSaga,
  },
  {
    key: "newsAndEvents",
    saga: newsAndEventsSaga,
  },
  { key: "payments", saga: paymentsSaga },
  {
    key: "profilePermission",
    saga: profilePermissionSaga,
  },
  {
    key: "reconciliation",
    saga: reconciliationSaga,
  },
  {
    key: "reportDetails",
    saga: reportDetailsSaga,
  },
  {
    key: "reportStatus",
    saga: reportStatusSaga,
  },
  {
    key: "requestsManagement",
    saga: requestsManagementSaga,
  },
  {
    key: "resetPassword",
    saga: resetPasswordSaga,
  },
  {
    key: "statistics",
    saga: statisticsSaga,
  },
  {
    key: "submissionProfiles",
    saga: submissionProfilesSaga,
  },
  { key: "supplier", saga: supplierSaga },
  {
    key: "supplierFieldsItems",
    saga: supplierFieldSaga,
  },
  { key: "unauthLoader", saga: unauthLoaderSaga },
  {
    key: "userGroup",
    saga: userGroupSaga,
  },
  {
    key: "userManagement",
    saga: userManagementSaga,
  },
  { key: "userupload", saga: userUploadSaga },
  { key: "VMDModule", saga: vmdSaga },
  { key: "workflow", saga: workflowSaga },
  {
    key: "resolveInvoice",
    saga: resolveInvoicesSaga,
  },
  {
    key: "UMR",
    saga: UMRSaga,
  },
];
