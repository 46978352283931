import styled from "styled-components";
import A from "../A";

const HomeCrumbStyle = styled(A)`
  height: 12px;
  color: #018eff;
  font-size: 12px;
  font-family: graphik-medium;
  font-weight: 500;
  letter-spacing: -0.18px;
  line-height: 12px;
`;

export default HomeCrumbStyle;
