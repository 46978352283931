import {
  INIT_MODULE_LIST_FETCH,
  FETCHING_MODULE_LIST,
  FETCH_MODULE_LIST,
  MODULE_LIST_FETCH_COMPLETE,
  ATTRIBUTE_TYPE_LIST_FETCH_COMPLETE,
  FETCH_ATTRIBUTE_TYPE_LIST,
  FETCHING_ATTRIBUTE_TYPE_LIST,
  INIT_ATTRIBUTE_TYPE_LIST_FETCH,
  FETCHING_ATTRIBUTES_LIST,
  FETCH_ATTRIBUTES_LIST,
  ATTRIBUTES_LIST_FETCH_COMPLETE,
  INIT_ATTRIBUTE_HIERARCHY_LIST_FETCH,
  FETCHING_ATTRIBUTE_HIERARCHY_LIST,
  FETCH_ATTRIBUTE_HIERARCHY_LIST,
  ATTRIBUTE_HIERARCHY_LIST_FETCH_COMPLETE,
  INIT_ATTRIBUTE_HIERARCHY_LEVEL_FETCH,
  FETCHING_ATTRIBUTE_HIERARCHY_LEVEL,
  FETCH_ATTRIBUTE_HIERARCHY_LEVEL,
  ATTRIBUTE_HIERARCHY_LEVEL_FETCH_COMPLETE,
  INIT_ATTRIBUTES_LIST_FETCH,
  INIT_GET_CUSTOM_LIST,
  CUSTOM_LIST_FETCH_COMPLETE,
  FETCH_CUSTOM_LIST,
  FETCHING_CUSTOM_LIST,
  INIT_GET_VMD_TAB_DATA,
  FETCHING_VMD_TAB_DATA,
  FETCH_VMD_TAB_DATA,
  VMD_TAB_DATA_FETCH_COMPLETE,
  INIT_GET_VMD_SECTION_DATA,
  FETCHING_VMD_SECTION_DATA,
  FETCH_VMD_SECTION_DATA,
  VMD_SECTION_DATA_FETCH_COMPLETE,
  INIT_GET_SUBMISSION_FIELD_PLACEMENT,
  FETCHING_SUBMISSION_FIELD_PLACEMENT,
  FETCH_SUBMISSION_FIELD_PLACEMENT,
  SUBMISSION_FIELD_PLACEMENT_FETCH_COMPLETE,
  INIT_GET_SEARCH_FIELD_PLACEMENT,
  SEARCH_FIELD_PLACEMENT_FETCH_COMPLETE,
  FETCH_SEARCH_FIELD_PLACEMENT,
  FETCHING_SEARCH_FIELD_PLACEMENT,
  INIT_ATTRIBUTES_DELETE,
  ATTRIBUTES_DELETE_SUCCESS,
  ATTRIBUTES_DELETE_FAILED,
  INIT_ATTRIBUTE_EDIT,
  SET_EDIT_ATTRIBUTE_DATA,
  RESET_ATTRIBUTE_SAVE_STATUS,
  ATTRIBUTE_SAVE_FAILED,
  ATTRIBUTE_SAVED,
  INIT_ATTRIBUTE_SAVE,
  GET_EMAIL_TEMPLATES_LIST_FETCH_COMPLETE,
  FETCH_GET_EMAIL_TEMPLATES_LIST,
  FETCHING_GET_EMAIL_TEMPLATES_LIST,
  INIT_GET_EMAIL_TEMPLATES_LIST,
  INIT_HELPDESK_CONFIGARATION_SAVE,
  HELPDESK_CONFIGARATION_SAVED,
  HELPDESK_CONFIGARATION_FAILED,
  RESET_HELPDESK_CONFIGARATION_STATUS,
  INIT_GET_SEARCH_FIELDS_LIST,
  FETCHING_SEARCH_FIELDS_LIST,
  FETCH_GET_SEARCH_FIELDS_LIST,
  GET_SEARCH_FIELDS_LIST_FETCH_COMPLETE,
  INIT_GET_HELPDESK_QUERY_LIST,
  FETCH_HELPDESK_QUERY_LIST,
  HELPDESK_QUERY_LIST_FETCH_COMPLETE,
  FETCHING_HELPDESK_QUERY_LIST,
  INIT_GET_APPROVAL_TYPE_LIST,
  FETCHING_APPROVAL_TYPE_LIST,
  FETCH_APPROVAL_TYPE_LIST,
  INIT_GET_REGEX_LIST,
  FETCHING_REGEX_LIST,
  FETCH_REGEX_LIST,
  REGEX_LIST_FETCH_COMPLETE,
  APPROVAL_TYPE_LIST_FETCH_COMPLETE,
  HELPDESK_FORM_SUBMISSION_STATUS,
  ATTRIBUTE_FORM_SUBMISSION_STATUS,
  INIT_GET_VALIDATION_TYPE,
  FETCHING_VALIDATION_TYPE,
  FETCH_VALIDATION_TYPE,
  VALIDATION_TYPE_FETCH_COMPLETE,
  SEARCH_CONFIGARATION_FAILED,
  RESET_SEARCH_CONFIGARATION_STATUS,
  SEARCH_CONFIGARATION_SAVED,
  SEARCH_FORM_SUBMISSION_STATUS,
  INIT_SEARCH_CONFIGARATION_SAVE,
  SET_EDIT_ATTRIBUTE_DATA_COMPLETED,
  INIT_GET_HELPDESK_QUERY_BY_ID,
  FETCHING_HELPDESK_QUERY_BY_ID,
  FETCH_HELPDESK_QUERY_BY_ID,
  HELPDESK_QUERY_BY_ID_FETCH_COMPLETE,
  INIT_GET_HELPDESK_QUERY_ATTRIBUTE_LIST,
  FETCHING_HELPDESK_QUERY_ATTRIBUTE_LIST,
  FETCH_HELPDESK_QUERY_ATTRIBUTE_LIST,
  HELPDESK_QUERY_ATTRIBUTE_LIST_FETCH_COMPLETE,
  UPDATED_ATTRIBUTES_LIST,
  UPDATED_SEARCH_FIELDS_LIST,
  CLEAR_HELPDESK_QUERY_BY_ID,
  UPDATE_HELPDESK_QUERY_ATTRIBUTE_LIST,
} from "./actionTypes";

export const initFetchModuleList = () => {
  return {
    type: INIT_MODULE_LIST_FETCH,
  };
};

export const fetchingModuleList = () => {
  return {
    type: FETCHING_MODULE_LIST,
  };
};

export const fetchModuleList = (data) => {
  return {
    type: FETCH_MODULE_LIST,
    data: data,
  };
};

export const moduleListFetchingComplete = () => {
  return {
    type: MODULE_LIST_FETCH_COMPLETE,
  };
};

export const initFetchAttributeTypeList = () => {
  return {
    type: INIT_ATTRIBUTE_TYPE_LIST_FETCH,
  };
};

export const fetchingAttributeTypeList = () => {
  return {
    type: FETCHING_ATTRIBUTE_TYPE_LIST,
  };
};

export const fetchAttributeTypeList = (data) => {
  return {
    type: FETCH_ATTRIBUTE_TYPE_LIST,
    data: data,
  };
};

export const attributeTypeListFetchingComplete = () => {
  return {
    type: ATTRIBUTE_TYPE_LIST_FETCH_COMPLETE,
  };
};

export const initFetchAttributesList = () => {
  return {
    type: INIT_ATTRIBUTES_LIST_FETCH,
  };
};

export const fetchingAttributesList = () => {
  return {
    type: FETCHING_ATTRIBUTES_LIST,
  };
};

export const fetchAttributesList = (data) => {
  return {
    type: FETCH_ATTRIBUTES_LIST,
    data: data,
  };
};

export const attributesListFetchingComplete = () => {
  return {
    type: ATTRIBUTES_LIST_FETCH_COMPLETE,
  };
};
export const updatedAttributesList = (data) => {
  return {
    type: UPDATED_ATTRIBUTES_LIST,
    data,
  };
};
export const initFetchAttributeHierarchyList = () => {
  return {
    type: INIT_ATTRIBUTE_HIERARCHY_LIST_FETCH,
  };
};

export const fetchingAttributeHierarchyList = () => {
  return {
    type: FETCHING_ATTRIBUTE_HIERARCHY_LIST,
  };
};

export const fetchAttributeHierarchyList = (data) => {
  return {
    type: FETCH_ATTRIBUTE_HIERARCHY_LIST,
    data: data,
  };
};

export const attributeHierarchyListFetchingComplete = () => {
  return {
    type: ATTRIBUTE_HIERARCHY_LIST_FETCH_COMPLETE,
  };
};

export const initFetchAttributeHierarchyLevel = () => {
  return {
    type: INIT_ATTRIBUTE_HIERARCHY_LEVEL_FETCH,
  };
};

export const fetchingAttributeHierarchyLevel = () => {
  return {
    type: FETCHING_ATTRIBUTE_HIERARCHY_LEVEL,
  };
};

export const fetchAttributeHierarchyLevel = (data) => {
  return {
    type: FETCH_ATTRIBUTE_HIERARCHY_LEVEL,
    data: data,
  };
};

export const attributeHierarchyLevelFetchingComplete = () => {
  return {
    type: ATTRIBUTE_HIERARCHY_LEVEL_FETCH_COMPLETE,
  };
};

export const initGetCustomList = () => {
  return {
    type: INIT_GET_CUSTOM_LIST,
  };
};

export const fetchingCustomList = () => {
  return {
    type: FETCHING_CUSTOM_LIST,
  };
};

export const fetchCustomList = (data) => {
  return {
    type: FETCH_CUSTOM_LIST,
    data: data,
  };
};

export const customListFetchingComplete = () => {
  return {
    type: CUSTOM_LIST_FETCH_COMPLETE,
  };
};

export const initGetVMDTabData = () => {
  return {
    type: INIT_GET_VMD_TAB_DATA,
  };
};

export const fetchingVMDTabData = () => {
  return {
    type: FETCHING_VMD_TAB_DATA,
  };
};

export const fetchVMDTabData = (data) => {
  return {
    type: FETCH_VMD_TAB_DATA,
    data: data,
  };
};

export const VMDTabDataFetchingComplete = () => {
  return {
    type: VMD_TAB_DATA_FETCH_COMPLETE,
  };
};

export const initGetVMDSectionData = () => {
  return {
    type: INIT_GET_VMD_SECTION_DATA,
  };
};

export const fetchingVMDSectionData = () => {
  return {
    type: FETCHING_VMD_SECTION_DATA,
  };
};

export const fetchVMDSectionData = (data) => {
  return {
    type: FETCH_VMD_SECTION_DATA,
    data: data,
  };
};

export const VMDSectionDataFetchingComplete = () => {
  return {
    type: VMD_SECTION_DATA_FETCH_COMPLETE,
  };
};

export const initGetSubmissionFieldPlacement = () => {
  return {
    type: INIT_GET_SUBMISSION_FIELD_PLACEMENT,
  };
};

export const fetchingSubmissionFieldPlacement = () => {
  return {
    type: FETCHING_SUBMISSION_FIELD_PLACEMENT,
  };
};

export const fetchSubmissionFieldPlacement = (data) => {
  return {
    type: FETCH_SUBMISSION_FIELD_PLACEMENT,
    data: data,
  };
};

export const SubmissionFieldPlacementFetchingComplete = () => {
  return {
    type: SUBMISSION_FIELD_PLACEMENT_FETCH_COMPLETE,
  };
};

export const initGetSearchFieldPlacement = () => {
  return {
    type: INIT_GET_SEARCH_FIELD_PLACEMENT,
  };
};

export const fetchingSearchFieldPlacement = () => {
  return {
    type: FETCHING_SEARCH_FIELD_PLACEMENT,
  };
};

export const fetchSearchFieldPlacement = (data) => {
  return {
    type: FETCH_SEARCH_FIELD_PLACEMENT,
    data: data,
  };
};

export const SearchFieldPlacementFetchingComplete = () => {
  return {
    type: SEARCH_FIELD_PLACEMENT_FETCH_COMPLETE,
  };
};

export const initGetHelpdeskQueryByID = (
  queryID,
  fetchSuccessHelpdeskQueryByID
) => {
  return {
    type: INIT_GET_HELPDESK_QUERY_BY_ID,
    queryID,
    fetchSuccessHelpdeskQueryByID,
  };
};

export const fetchingHelpdeskQueryByID = () => {
  return {
    type: FETCHING_HELPDESK_QUERY_BY_ID,
  };
};

export const fetchHelpdeskQueryByID = (data) => {
  return {
    type: FETCH_HELPDESK_QUERY_BY_ID,
    data: data,
  };
};

export const HelpdeskQueryByIDFetchingComplete = () => {
  return {
    type: HELPDESK_QUERY_BY_ID_FETCH_COMPLETE,
  };
};
export const clearHelpdeskQueryById = () => {
  return {
    type: CLEAR_HELPDESK_QUERY_BY_ID,
  };
};
export const initAttributesDelete = (id, deleteSuccess) => {
  return {
    type: INIT_ATTRIBUTES_DELETE,
    id,
    deleteSuccess,
  };
};
export const attributesDeleteSuccess = (id) => {
  return {
    type: ATTRIBUTES_DELETE_SUCCESS,
    id,
  };
};
export const attributesDeleteFailed = () => {
  return {
    type: ATTRIBUTES_DELETE_FAILED,
  };
};

export const getDataForAttributeEdit = (id, fetchSuccess) => {
  return {
    type: INIT_ATTRIBUTE_EDIT,
    id,
    callback: fetchSuccess,
  };
};
export const setDataForAttributeEdit = (data) => {
  return {
    type: SET_EDIT_ATTRIBUTE_DATA,
    data,
  };
};
export const setDataForAttributeEditCompleted = (data) => {
  return {
    type: SET_EDIT_ATTRIBUTE_DATA_COMPLETED,
    data,
  };
};

export const initAttributeSave = (
  formdata,
  isEditMode,
  attributeSaveSuccess
) => {
  return {
    type: INIT_ATTRIBUTE_SAVE,
    formdata,
    isEditMode,
    callback: attributeSaveSuccess,
  };
};

export const attributeSaved = () => {
  return {
    type: ATTRIBUTE_SAVED,
  };
};
export const attributeSaveFailed = () => {
  return {
    type: ATTRIBUTE_SAVE_FAILED,
  };
};
export const attributeSaveFormStatus = (data) => {
  return {
    type: ATTRIBUTE_FORM_SUBMISSION_STATUS,
    data,
  };
};
export const resetAttributeFormSave = () => {
  return {
    type: RESET_ATTRIBUTE_SAVE_STATUS,
  };
};

export const initGetRegExList = () => {
  return {
    type: INIT_GET_REGEX_LIST,
  };
};

export const fetchingRegExList = () => {
  return {
    type: FETCHING_REGEX_LIST,
  };
};

export const fetchRegExList = (data) => {
  return {
    type: FETCH_REGEX_LIST,
    data: data,
  };
};

export const regExListFetchingComplete = () => {
  return {
    type: REGEX_LIST_FETCH_COMPLETE,
  };
};

export const initGetApprovalTypeList = () => {
  return {
    type: INIT_GET_APPROVAL_TYPE_LIST,
  };
};

export const fetchingApprovalTypeList = () => {
  return {
    type: FETCHING_APPROVAL_TYPE_LIST,
  };
};

export const fetchApprovalTypeList = (data) => {
  return {
    type: FETCH_APPROVAL_TYPE_LIST,
    data: data,
  };
};

export const approvalTypeListFetchingComplete = () => {
  return {
    type: APPROVAL_TYPE_LIST_FETCH_COMPLETE,
  };
};

export const initGetValidationType = () => {
  return {
    type: INIT_GET_VALIDATION_TYPE,
  };
};

export const fetchingValidationType = () => {
  return {
    type: FETCHING_VALIDATION_TYPE,
  };
};

export const fetchValidationType = (data) => {
  return {
    type: FETCH_VALIDATION_TYPE,
    data: data,
  };
};

export const validationTypeFetchingComplete = () => {
  return {
    type: VALIDATION_TYPE_FETCH_COMPLETE,
  };
};
// Module Config

export const initGetSearchFieldsList = () => {
  return {
    type: INIT_GET_SEARCH_FIELDS_LIST,
  };
};

export const fetchingGetSearchFieldsList = () => {
  return {
    type: FETCHING_SEARCH_FIELDS_LIST,
  };
};

export const fetchGetSearchFieldsList = (data) => {
  return {
    type: FETCH_GET_SEARCH_FIELDS_LIST,
    data: data,
  };
};

export const updatedSearchFields = (data) => {
  return {
    type: UPDATED_SEARCH_FIELDS_LIST,
    data,
  };
};

export const getSearchFieldsListFetchingComplete = () => {
  return {
    type: GET_SEARCH_FIELDS_LIST_FETCH_COMPLETE,
  };
};

export const initGetEmailTemplatesList = () => {
  return {
    type: INIT_GET_EMAIL_TEMPLATES_LIST,
  };
};

export const fetchingEmailTemplatesList = () => {
  return {
    type: FETCHING_GET_EMAIL_TEMPLATES_LIST,
  };
};

export const fetchEmailTemplatesList = (data) => {
  return {
    type: FETCH_GET_EMAIL_TEMPLATES_LIST,
    data: data,
  };
};

export const emailTemplatesListFetchingComplete = () => {
  return {
    type: GET_EMAIL_TEMPLATES_LIST_FETCH_COMPLETE,
  };
};

export const initHelpdeskConfigarationSave = (
  formdata,
  helpdeskConfigSaveSuccess
) => {
  return {
    type: INIT_HELPDESK_CONFIGARATION_SAVE,
    formdata,
    helpdeskConfigSaveSuccess,
  };
};

export const helpdeskConfigarationSaved = () => {
  return {
    type: HELPDESK_CONFIGARATION_SAVED,
  };
};

export const helpdeskConfigarationSaveFormStatus = (data) => {
  return {
    type: HELPDESK_FORM_SUBMISSION_STATUS,
    data,
  };
};

export const resetHelpdeskConfigarationSave = () => {
  return {
    type: RESET_HELPDESK_CONFIGARATION_STATUS,
  };
};
export const helpdeskConfigarationSaveFailed = () => {
  return {
    type: HELPDESK_CONFIGARATION_FAILED,
  };
};

export const initSearchConfigarationSave = (
  formdata,
  isDuplicate,
  searchConfigSaveSuccess
) => {
  return {
    type: INIT_SEARCH_CONFIGARATION_SAVE,
    formdata,
    isDuplicate,
    searchConfigSaveSuccess,
  };
};

export const searchConfigarationSaved = () => {
  return {
    type: SEARCH_CONFIGARATION_SAVED,
  };
};

export const searchConfigarationSaveFormStatus = (data) => {
  return {
    type: SEARCH_FORM_SUBMISSION_STATUS,
    data,
  };
};

export const resetSearchConfigarationSave = () => {
  return {
    type: RESET_SEARCH_CONFIGARATION_STATUS,
  };
};
export const searchConfigarationSaveFailed = () => {
  return {
    type: SEARCH_CONFIGARATION_FAILED,
  };
};

export const initGetHelpdeskQueryList = () => {
  return {
    type: INIT_GET_HELPDESK_QUERY_LIST,
  };
};

export const fetchingHelpdeskQueryList = () => {
  return {
    type: FETCHING_HELPDESK_QUERY_LIST,
  };
};

export const fetchHelpdeskQueryList = (data) => {
  return {
    type: FETCH_HELPDESK_QUERY_LIST,
    data: data,
  };
};

export const helpdeskQueryListFetchingComplete = () => {
  return {
    type: HELPDESK_QUERY_LIST_FETCH_COMPLETE,
  };
};

export const initGetHelpdeskAttributeList = () => {
  return {
    type: INIT_GET_HELPDESK_QUERY_ATTRIBUTE_LIST,
  };
};

export const fetchingHelpdeskAttributeList = () => {
  return {
    type: FETCHING_HELPDESK_QUERY_ATTRIBUTE_LIST,
  };
};

export const fetchHelpdeskAttributeList = (data) => {
  return {
    type: FETCH_HELPDESK_QUERY_ATTRIBUTE_LIST,
    data: data,
  };
};
export const updateHelpdeskAttributeList = (data, selectAttribute) => {
  return {
    type: UPDATE_HELPDESK_QUERY_ATTRIBUTE_LIST,
    data: data,
    selectAttribute,
  };
};

export const HelpdeskAttributeListFetchingComplete = () => {
  return {
    type: HELPDESK_QUERY_ATTRIBUTE_LIST_FETCH_COMPLETE,
  };
};
