import React, { useEffect } from "react";
import { Overlay, ModalContainer } from "./style";
import ModalHeader from "./ModalHeader";
import Div, { Centered } from "../Div";

const ModalComponent = (props) => {
  const closeModal = (e) => props.onClose(e);

  const useOverlayClickAlerter = () => {
    useEffect(() => {
      if (!props.preventOutterClickClose) {
        const handleClickOutside = (event) => {
          if (event.target.id === "modalOverlay") closeModal(event);
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }
    }, []);
  };

  const {
    headerBgColor,
    headerTextColor,
    closeIconColor,
    closeIconSize,
    overlayColor,
    headerBorder,
    headerHeight,
    headerTextalign,
    headerPadding,
    border,
    overlayZIndex,
    centeredDivWidth,
    headerFontFamily,
    headerTextSize,
    headerMargin,
  } = props.customStyle || {};
  const headerObj = {
    headerBgColor,
    headerTextColor,
    title: props.title,
    closeIconSize,
    closeIconColor,
    noClose: props.noClose,
    headerBorder,
    headerHeight,
    headerTextalign,
    headerPadding,
    headerFontFamily,
    headerTextSize,
    headerMargin,
  };

  useOverlayClickAlerter();
  return props.show ? (
    <Overlay
      bgColor={overlayColor}
      overlayZIndex={overlayZIndex}
      id="modalOverlay"
    >
      <Centered centeredDivWidth={centeredDivWidth}>
        <ModalContainer
          height={props.height}
          width={props.width}
          borderRadius={props.borderRadius}
          border={border}
          overflow={props.overflow}
          padding={props.padding}
        >
          {props.noHeader ? null : (
            <ModalHeader {...headerObj} closeModal={closeModal} />
          )}

          <Div
            minHeight="100px"
            divHeight={props.noHeader ? "100%" : "calc(100% - 60px)"}
            margin={props.containerMargin}
            padding={props.containerPadding}
          >
            {props.children}
          </Div>
        </ModalContainer>
      </Centered>
    </Overlay>
  ) : null;
};

export default ModalComponent;
