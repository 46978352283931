import { put, takeEvery, call, all } from "redux-saga/effects";
import { store } from "react-notifications-component";
import { buildNotification } from "../../../../config/notification";
import axios from "../../../../config/axios";
import {
  getRequestDetails,
  postRequestDetails,
  putRequestDetails,
} from "../../../../config/requestHeaders";

import {
  fetchingCategory,
  fetchCategory,
  initFetchFaqMaterial,
  fetchingFaqMaterials,
  fetchFaqMaterials,
  faqMaterialsFetchingComplete,
  fetchingFaqMaterialById,
  fetchFaqMaterialById,
  faqMaterialByIdFetchComplete,
  faqMaterialSaved,
  faqMaterialSaveFailed,
  deleteFaqMaterialSuccess,
  deleteFaqMaterialFailed,
  initFetchTrainingMaterial,
  fetchingTrainingMaterials,
  fetchTrainingMaterials,
  trainingMaterialsFetchingComplete,
  fetchingTrainingMaterialById,
  fetchTrainingMaterialById,
  trainingMaterialByIdFetchComplete,
  trainingMaterialSaved,
  trainingMaterialSaveFailed,
  deleteTrainingMaterialSuccess,
  deleteTrainingMaterialFailed,
  initFetchTrainingVideo,
  fetchingTrainingVideos,
  fetchTrainingVideos,
  trainingVideosFetchingComplete,
  fetchingTrainingVideoById,
  fetchTrainingVideoById,
  trainingVideoByIdFetchComplete,
  trainingVideoSaved,
  trainingVideoSaveFailed,
  deleteTrainingVideoSuccess,
  deleteTrainingVideoFailed,
} from "./action";
import {
  INIT_FETCH_CATEGORY,
  INIT_FETCH_FAQ_MATERIALS,
  INIT_FETCH_FAQ_MATERIAL_BY_ID,
  INIT_SAVE_FAQ_MATERIAL,
  INIT_DELETE_FAQ_MATERIAL,
  INIT_FETCH_TRAINING_MATERIALS,
  INIT_FETCH_TRAINING_MATERIAL_BY_ID,
  INIT_SAVE_TRAINING_MATERIAL,
  INIT_DELETE_TRAINING_MATERIAL,
  INIT_FETCH_TRAINING_VIDEOS,
  INIT_FETCH_TRAINING_VIDEO_BY_ID,
  INIT_SAVE_TRAINING_VIDEO,
  INIT_DELETE_TRAINING_VIDEO,
} from "./actionTypes";

export default function* watchHelpSaga() {
  yield all([
    takeEvery(INIT_FETCH_CATEGORY, getCategorySaga),
    takeEvery(INIT_FETCH_FAQ_MATERIALS, getFaqMaterialsSaga),
    takeEvery(INIT_FETCH_FAQ_MATERIAL_BY_ID, getFaqMaterialByIdSaga),
    takeEvery(INIT_SAVE_FAQ_MATERIAL, initSaveFaqMaterial),
    takeEvery(INIT_DELETE_FAQ_MATERIAL, deleteFaqMaterial),
    takeEvery(INIT_FETCH_TRAINING_MATERIALS, getTrainingMaterialsSaga),
    takeEvery(INIT_FETCH_TRAINING_MATERIAL_BY_ID, getTrainingMaterialByIdSaga),
    takeEvery(INIT_SAVE_TRAINING_MATERIAL, initSaveTrainingMaterial),
    takeEvery(INIT_DELETE_TRAINING_MATERIAL, deleteTrainingMaterial),
    takeEvery(INIT_FETCH_TRAINING_VIDEOS, getTrainingVideosSaga),
    takeEvery(INIT_FETCH_TRAINING_VIDEO_BY_ID, getTrainingVideoByIdSaga),
    takeEvery(INIT_SAVE_TRAINING_VIDEO, initSaveTrainingVideo),
    takeEvery(INIT_DELETE_TRAINING_VIDEO, deleteTrainingVideo),
  ]);
}
function* getCategorySaga(action) {
  yield put(fetchingCategory());
  const url = "/CustomListItem/listkeys?customListId=2";
  try {
    const response = yield call(axios, url, getRequestDetails);
    if (response) {
      yield put(fetchCategory(response.data));
    }
  } catch (error) {
    const notification = buildNotification({
      message: "notification.help.categoryError",
      type: "error",
    });
    store.addNotification({
      ...notification,
    });
  }
}

function* getFaqMaterialsSaga(action) {
  let sessionDetails =
    JSON.parse(sessionStorage.getItem("sessionDetails")) || {};
  let langKey =
    sessionDetails && sessionDetails.languageId
      ? sessionDetails.languageId
      : "";
  let url = `/Help/ListFAQdetails?langKey=${langKey}`;
  if (action.data) {
    const { categoryID } = action.data;
    if (categoryID) {
      url = `/Help/ListFAQdetails?langKey=${langKey}&categoryID=${categoryID}`;
    }
  }
  yield put(fetchingFaqMaterials());
  try {
    const response = yield call(axios, url, getRequestDetails);
    yield put(fetchFaqMaterials(response.data));

    yield put(faqMaterialsFetchingComplete());
  } catch (error) {
    const notification = buildNotification({
      message: "notification.help.faqListError",
      type: "error",
    });
    store.addNotification({
      ...notification,
    });
  }
}

function* getFaqMaterialByIdSaga(action) {
  yield put(fetchingFaqMaterialById());
  const url = `/Help/FAQdetailsById?HelpItemID=${action.id}`;
  try {
    const response = yield call(axios, url, getRequestDetails);
    if (response) {
      yield put(fetchFaqMaterialById(response.data));
    }
    yield put(faqMaterialByIdFetchComplete());
  } catch (error) {
    action.callback && action.callback();
  }
}

function* initSaveFaqMaterial(action) {
  const url = "/Help/SaveFAQ";
  postRequestDetails.data = action.payload;
  const response = yield call(axios, url, postRequestDetails);
  try {
    if (response && response.status === 202) {
      yield put(faqMaterialSaved());
      const notification = buildNotification({
        message: "notification.help.faqSaveSuccess",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
      if (action.saveSuccess) {
        action.saveSuccess();
      }
    } else {
      yield put(faqMaterialSaveFailed());
    }
  } catch (error) {
    yield put(faqMaterialSaveFailed());
  }
}

function* deleteFaqMaterial(action) {
  const url = "/Help/deleteFAQ";
  putRequestDetails.data = {
    listOfIds: action.id,
  };
  try {
    const response = yield call(axios, url, putRequestDetails);
    if (response && response.status) {
      const id = action.id.split(",").map((id) => parseInt(id));
      yield put(deleteFaqMaterialSuccess(id));
      const notification = buildNotification({
        message: "notification.help.faqDeleteSuccess",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });

      yield put(initFetchFaqMaterial());
    } else {
      yield put(deleteFaqMaterialFailed());
    }
  } catch (error) {
    yield put(deleteFaqMaterialFailed());
  }
}

function* getTrainingMaterialsSaga(action) {
  let sessionDetails =
    JSON.parse(sessionStorage.getItem("sessionDetails")) || {};
  let langKey =
    sessionDetails && sessionDetails.languageId
      ? sessionDetails.languageId
      : "";
  let url = `/Help/ListTrainingdetails?langKey=${langKey}`;
  if (action.data) {
    const { categoryID } = action.data;
    if (categoryID) {
      url = `/Help/ListTrainingdetails?langKey=${langKey}&categoryID=${categoryID}`;
    }
  }
  yield put(fetchingTrainingMaterials());
  try {
    const response = yield call(axios, url, getRequestDetails);
    yield put(fetchTrainingMaterials(response.data));

    yield put(trainingMaterialsFetchingComplete());
  } catch (error) {
    const notification = buildNotification({
      message: "notification.help.tmListError",
      type: "error",
    });
    store.addNotification({
      ...notification,
    });
  }
}

function* getTrainingMaterialByIdSaga(action) {
  yield put(fetchingTrainingMaterialById());
  const url = `/Help/TrainingDetailsById?trainingItemID=${action.id}`;
  try {
    const response = yield call(axios, url, getRequestDetails);
    if (response) {
      yield put(fetchTrainingMaterialById(response.data));
    }
    yield put(trainingMaterialByIdFetchComplete());
  } catch (error) {
    action.callback && action.callback();
  }
}

function* initSaveTrainingMaterial(action) {
  const url = "/Help/SaveTraining";
  postRequestDetails.data = action.payload;
  const response = yield call(axios, url, postRequestDetails);
  try {
    if (response && response.status === 202) {
      yield put(trainingMaterialSaved());
      const notification = buildNotification({
        message: "notification.help.tmSaveSuccess",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
      if (action.saveSuccess) {
        action.saveSuccess();
      }
    } else {
      yield put(trainingMaterialSaveFailed());
    }
  } catch (error) {
    yield put(trainingMaterialSaveFailed());
  }
}

function* deleteTrainingMaterial(action) {
  const url = "/Help/deleteTrainingDetails";
  putRequestDetails.data = {
    listOfIds: action.id,
  };
  try {
    const response = yield call(axios, url, putRequestDetails);
    if (response && response.status) {
      const id = action.id.split(",").map((id) => parseInt(id));
      yield put(deleteTrainingMaterialSuccess(id));
      const notification = buildNotification({
        message: "notification.help.tmDeleteSuccess",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
      yield put(initFetchTrainingMaterial());
    } else {
      yield put(deleteTrainingMaterialFailed());
    }
  } catch (error) {
    yield put(deleteTrainingMaterialFailed());
  }
}

function* getTrainingVideosSaga(action) {
  let url = `/Help/ListTrainingVideos`;
  if (action.data) {
    const { categoryID } = action.data;
    if (categoryID) {
      url = `/Help/ListTrainingVideos?categoryID=${categoryID}`;
    }
  }
  yield put(fetchingTrainingVideos());
  try {
    const response = yield call(axios, url, getRequestDetails);
    yield put(fetchTrainingVideos(response.data));

    yield put(trainingVideosFetchingComplete());
  } catch (error) {
    const notification = buildNotification({
      message: "notification.help.tvListError",
      type: "error",
    });
    store.addNotification({
      ...notification,
    });
  }
}

function* getTrainingVideoByIdSaga(action) {
  yield put(fetchingTrainingVideoById());
  const url = `/Help/TrainingVideoById?trainingVideoID=${action.id}`;
  try {
    const response = yield call(axios, url, getRequestDetails);
    if (response) {
      yield put(fetchTrainingVideoById(response.data));
    }
    yield put(trainingVideoByIdFetchComplete());
  } catch (error) {
    action.callback && action.callback();
  }
}

function* initSaveTrainingVideo(action) {
  const url = "/Help/SaveTrainingVideo";
  postRequestDetails.data = action.payload;
  const response = yield call(axios, url, postRequestDetails);
  try {
    if (response && response.status === 202) {
      yield put(trainingVideoSaved());
      const notification = buildNotification({
        message: "notification.help.tvSaveSuccess",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
      if (action.saveSuccess) {
        action.saveSuccess();
      }
    } else {
      yield put(trainingVideoSaveFailed());
    }
  } catch (error) {
    yield put(trainingVideoSaveFailed());
  }
}

function* deleteTrainingVideo(action) {
  const url = "/Help/deleteTrainingVideos";
  putRequestDetails.data = {
    listOfIds: action.id,
  };
  try {
    const response = yield call(axios, url, putRequestDetails);
    if (response && response.status) {
      const id = action.id.split(",").map((id) => parseInt(id));
      yield put(deleteTrainingVideoSuccess(id));
      const notification = buildNotification({
        message: "notification.help.tvDeleteSuccess",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
      yield put(initFetchTrainingVideo());
    } else {
      yield put(deleteTrainingVideoFailed());
    }
  } catch (error) {
    yield put(deleteTrainingVideoFailed());
  }
}
