export const INIT_WORKFLOW_FETCH = "INIT_WORKFLOW_FETCH";
export const FETCHING_WORKFLOWS = "FETCHING_WORKFLOWS";
export const FETCH_WORKFLOWS = "FETCH_WORKFLOWS";
export const WORKFLOWS_COMPLETE = "WORKFLOWS_COMPLETE";

export const INIT_WORKFLOW_SAVE = "INIT_WORKFLOW_SAVE";
export const WORKFLOW_SAVED = "WORKFLOW_SAVED";
export const WORKFLOW_SAVE_FAILED = "WORKFLOW_SAVE_FAILED";
export const RESET_SAVE_STATUS = "RESET_SAVE_STATUS";

export const INIT_WORKFLOWS_DELETE = "INIT_WORKFLOWS_DELETE";
export const DELETE_WORKFLOW_SUCCESS = "DELETE_SUCCESS";
export const DELETE_WORKFLOW_FAILED = "DELETE_FAILED";

export const INIT_WORKFLOW_EDIT = "INIT_WORKFLOW_EDIT";
export const SET_WORKFLOW_EDIT_DATA = "SET_WORKFLOW_EDIT_DATA";
export const EDIT_WORKFLOW_DATA_FETCHED = "EDIT_WORKFLOW_DATA_FETCHED";

export const INIT_CONDITION_LIST_FETCH = "INIT_CONDITION_LIST_FETCH";
export const FETCHING_CONDITIONS = "FETCHING_CONDITIONS";
export const FETCH_CONDITIONS = "FETCH_CONDITIONS";
export const CONDITIONS_FETCH_COMPLETE = "CONDITIONS_FETCH_COMPLETE";
