import { fromJS } from "immutable";

import {
  INIT_FETCH_UMR_LIST,
  FETCHING_UMR_LIST,
  FETCH_UMR_LIST,
  FETCH_UMR_LIST_COMPLETED,
} from "./actionTypes";

export const initialState = fromJS({
  UMRList: null,
  fetchingUMRList: false,
});

function userManagementReducer(state = initialState, action) {
  switch (action.type) {
    case INIT_FETCH_UMR_LIST:
      return state.set("UMRList", null);
    case FETCHING_UMR_LIST:
      return state.set("fetchingUMRList", true);
    case FETCH_UMR_LIST:
      return state.set("UMRList", action.payload);
    case FETCH_UMR_LIST_COMPLETED:
      return state.set("fetchingUMRList", false);

    default:
      return state;
  }
}

export default userManagementReducer;
