import styled from "styled-components";
import { Link } from "react-router-dom";
const A = styled(Link)`
  touch-action: manipulation;
  background-color: transparent;
  text-decoration: none;
  display: ${(props) => props.display || ""};
  flex-direction: ${(props) => props.direction || ""};
  flex-shrink: ${(props) => props.shrink || ""};
  margin: ${(props) => (props.margin ? props.margin : "")};
  float: ${(props) => (props.float ? props.float : "")};
  color: ${(props) => (props.color ? props.color : "#007bff")};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "")};
  padding: ${(props) => (props.padding ? props.padding : "")};
  text-align: ${(props) => (props.textalign ? props.textalign : "")};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "")};
  text-overflow: ${(props) => (props.textoverflow ? props.textoverflow : "")};
  overflow: ${(props) => (props.overflow ? props.overflow : "")};
  white-space: ${(props) => (props.whitespace ? props.whitespace : "")};
  align-items: ${(props) => props.alignitems || ""};
  position: ${(props) => props.position || ""};
`;

export default A;
