import React from "react";

const Statistics = React.lazy(() => import("../container/Statistics"));

const PaymentDetailsTable = React.lazy(() =>
  import("../container/Payments/PaymentDetailsTable")
);

const ModuleConfig = React.lazy(() =>
  import("../container/SupplierField/ModuleConfig")
);
const AttributeManagement = React.lazy(() =>
  import("../container/SupplierField/AttributeManagement")
);

const AddProfileRole = React.lazy(() =>
  import("../container/ProfilePermissions/addProfileRole")
);
const newsandevents = React.lazy(() =>
  import("../container/Administration/News_Events")
);
const Addnewsandevents = React.lazy(() =>
  import("../container/Administration/News_Events/AddNewsEvents")
);

const HelpDesk = React.lazy(() => import("../container/HelpDesk"));
const HelpdeskDetails = React.lazy(() =>
  import("../container/HelpDesk/HelpdeskDetails")
);
const serviceNowDetails = React.lazy(() =>
  import("../container/HelpDesk/serviceNowDetails")
);
const NewRequests = React.lazy(() =>
  import("../container/HelpDesk/NewRequests")
);
const HelpDeskTLog = React.lazy(() => import("../container/HelpDesk/TLog"));

const DocumentSubmit = React.lazy(() =>
  import("../container/DocumentSubmission/DocumentSubmit")
);

const InvoiceSubmissionForm = React.lazy(() =>
  import("../container/DocumentSubmission/InvoiceSubmissionForm")
);
const InvoiceAndCredits = React.lazy(() =>
  import("../container/InvoiceAndCredits")
);

const ReportStatus = React.lazy(() => import("../container/ReportStatus"));

const ReportDetails = React.lazy(() => import("../container/ReportDetails"));
const ReportList = React.lazy(() =>
  import("../container/ReportDetails/reportList")
);
const UserManagement = React.lazy(() => import("../container/UserManagement"));
const AddUser = React.lazy(() => import("../container/UserManagement/AddUser"));
const Payments = React.lazy(() => import("../container/Payments"));

const AddSubmissionProfiles = React.lazy(() =>
  import("../container/SubmissionProfiles/AddSubmissionProfiles")
);

const Dashboard = React.lazy(() => import("../container/Dashboard"));

const CustomListMgmt = React.lazy(() => import("../container/CustomList"));

const CustomList = React.lazy(() =>
  import("../container/CustomList/ListItems")
);
const AddCustomListItems = React.lazy(() =>
  import("../container/CustomList/AddItems")
);

const EmailTemplateList = React.lazy(() =>
  import("../container/EmailTemplate/list")
);
const AddEmailTemplates = React.lazy(() =>
  import("../container/EmailTemplate/AddEmailTemplate")
);

const Workflow = React.lazy(() =>
  import("../container/Administration/Workflow/Workflow")
);
const AddWorkflow = React.lazy(() =>
  import("../container/Administration/Workflow/AddWorkflow")
);
const ClientsList = React.lazy(() =>
  import("../container/ClientManagement/ClientsList")
);
const ClientSubmit = React.lazy(() => import("../container/ClientManagement"));
const ContactListAndSubmit = React.lazy(() =>
  import("../container/ClientManagement/ContactListAndSubmit")
);

const HelpdeskCountryContactList = React.lazy(() =>
  import("../container/ClientManagement/HelpdeskCountryContactList")
);

const RequestsManagement = React.lazy(() =>
  import("../container/RequestsManagement")
);
const RequestsDetails = React.lazy(() =>
  import("../container/RequestsManagement/Details")
);

const UserGroupList = React.lazy(() => import("../container/UserGroup/List"));
const AddUserGroup = React.lazy(() =>
  import("../container/UserGroup/AddUserGroup")
);
const ProfilePermissions = React.lazy(() =>
  import("../container/ProfilePermissions")
);
const AdminLogs = React.lazy(() => import("../container/Administration/Logs"));

const EmailLogsEmail = React.lazy(() =>
  import("../container/Administration/Logs/EmailLogDetails")
);
const Help = React.lazy(() => import("../container/Administration/Help"));
const AddEditFaqMaterial = React.lazy(() =>
  import("../container/Administration/Help/FaqQuestions")
);
const AddEditTrainingMaterial = React.lazy(() =>
  import("../container/Administration/Help/TrainingMaterial")
);
const AddEditTrainingVideo = React.lazy(() =>
  import("../container/Administration/Help/TrainingVideo")
);
const ViewHelp = React.lazy(() =>
  import("../container/Administration/Help/HelpView")
);
const ManageUpload = React.lazy(() => import("../container/ManageUpload"));
const UsersUpload = React.lazy(() =>
  import("../container/ManageUpload/UsersUpload")
);
const SuppliersUpload = React.lazy(() =>
  import("../container/ManageUpload/SuppliersUpload")
);
const VMD = React.lazy(() => import("../container/VMD"));
const CompanyDetails = React.lazy(() =>
  import("../container/VMD/CompanyDetails")
);
const CreateCompany = React.lazy(() =>
  import("../container/VMD/CreateCompany")
);
const AuditTrail = React.lazy(() => import("../container/VMD/AuditTrail"));
const AuditTrailDetails = React.lazy(() =>
  import("../container/VMD/AuditTrailDetails")
);
const Login = React.lazy(() => import("../container/LoginPage"));
const SSOLoginPage = React.lazy(() =>
  import("../container/SSOLoginPage/index")
);
const P2PLandingPage = React.lazy(() =>
  import("../container/P2PLandingPage/index")
);
const SignUp = React.lazy(() => import("../container/SignUp"));
const ResetPassword = React.lazy(() => import("../container/ResetPassword"));

const Reconciliation = React.lazy(() => import("../container/Reconciliation"));
const LandingPage = React.lazy(() => import("../components/LandingPage"));
const CreateReconciliation = React.lazy(() =>
  import("../container/Reconciliation/CreateReconciliation")
);
const ReconciliationDetails = React.lazy(() =>
  import("../container/Reconciliation/InvoiceDetails")
);
const ReconciliationComments = React.lazy(() =>
  import("../container/Reconciliation/Comments")
);

const ResolveInvoices = React.lazy(() =>
  import("../container/ResolveInvoices")
);

const UserManagementReport = React.lazy(() =>
  import("../container/UserManagementReports")
);

let sessionDetails = JSON.parse(sessionStorage.getItem("sessionDetails"));

if (!sessionDetails) {
  sessionDetails = {
    isAuthenticated: false,
    userName: "",
    roleId: "",
    baseRoleId: "",
    userId: "",
    loginId: "",
    menus: [],
    token: "",
    primaryEmail: "",
    lastPwdChangedOn: "",
    passwordExpiryDuration: 0,
    defaultMinDate: "",
  };
}

export const authRoutes = [
  {
    path: "/dashboard",
    name:
      sessionDetails.baseRoleId === 3
        ? "Supplier Dashboard"
        : "Helpdesk Dashboard",
    key: "dashboard",
    Component: Dashboard,
    title:
      sessionDetails.baseRoleId === 3
        ? "Supplier Dashboard"
        : "Helpdesk Dashboard",
  },
  {
    path: "/invoice-and-credits",
    name: "Invoice and Credits",
    key: "invoiceAndCredits",
    Component: InvoiceAndCredits,
    title: "Invoice and Credits",
  },
  {
    path: "/payments",
    name: "Payments",
    key: "payments",
    Component: Payments,
    title: "Payments",
  },
  {
    path: "/helpdesk",
    name: "Helpdesk",
    key: "helpdesk",
    Component: LandingPage,
  },
  {
    path: "/document-submission",
    name: "Document Submission",
    title: "Document Submission",
    key: "documents",
    Component: DocumentSubmit,
  },
  {
    path: "/administration",
    name: "Administration",
    key: "administration",
    Component: LandingPage,
  },
  {
    path: "/module-attribute-configuration",
    name: "Module & Attribute Configuration ",
    key: "ModuleAttributeConfiguration",
  },

  {
    path: "/reports",
    name: "Reports",
    key: "reports",
    Component: LandingPage,
  },
  {
    path: "/reports/statistics",
    name: "Statistics",
    key: "statistics",
    Component: Statistics,
  },
  {
    path: "/manage-supplier",
    name: "Manage Supplier",
    key: "manageSupplier",
    Component: LandingPage,
  },
  {
    path: "/helpdesk/list",
    name: "List",
    key: "helpDeskList",
    Component: HelpDesk,
    title: "List",
  },

  {
    path: "/helpdesk/new",
    name: "New",
    key: "helpDeskNew",
    Component: NewRequests,
    title: "New",
  },
  {
    path: "/helpdesk/open/details",
    name: "Details",
    key: "helpDeskOpenDetail",
    Component: HelpdeskDetails,
    title: "Details",
  },
  {
    path: "/helpdesk/service-now-list",
    name: "List",
    key: "serviceNowList",
    Component: HelpDesk,
    title: "List",
  },
  {
    path: "/helpdesk/service-now-details",
    name: "Details",
    key: "serviceNowDetails",
    Component: serviceNowDetails,
    title: "Details",
  },
  {
    path: "/helpdesk/add-service-now-ticket",
    name: "Add",
    key: "addServiceNowTicket",
    Component: NewRequests,
    title: "List",
  },
  {
    path: "/helpdesk/t-log/:requestID",
    name: "HelpDeskTLog",
    Component: HelpDeskTLog,
    title: "HelpDeskTLog",
    key: "helpDeskTLog",
  },

  {
    path: "/administration/module-config",
    name: "Module Config",
    Component: ModuleConfig,
    title: "Module Config",
    key: "moduleConfig",
  },

  {
    path: "/administration/module-config/submission-profiles-add",
    name: "Add",
    Component: AddSubmissionProfiles,
    title: "Add Submission Profiles",
    key: "addSubmissionProfile",
  },
  {
    path: "/administration/module-config/submission-profiles-edit",
    name: "Edit",
    Component: AddSubmissionProfiles,
    title: "Edit Submission Profiles",
    key: "editSubmissionProfile",
  },
  {
    path: "/administration/users",
    name: "User Management",
    Component: UserManagement,
    title: "User Management",
    key: "userManagement",
  },
  {
    path: "/administration/users-list",
    name: "User Management",
    Component: UserManagement,
    title: "User Management",
    key: "userDetails",
  },
  {
    path: "/administration/users/add",
    name: "Add User Details",
    Component: AddUser,
    title: "User Management",
    key: "addUserDetails",
  },
  {
    path: "/administration/users/edit",
    name: "Edit User Details",
    Component: AddUser,
    title: "User Management",
    key: "editUserDetails",
  },
  {
    path: "/administration/user-group",
    name: "User Group",
    Component: UserGroupList,
    title: "User Group",
    key: "userDetailsGroup",
  },
  {
    path: "/administration/user-group-list",
    name: "User Group",
    Component: UserGroupList,
    title: "User Group",
    key: "userDetailsGroupList",
  },
  {
    path: "/administration/user-group/add",
    name: "Add",
    Component: AddUserGroup,
    title: "Add",
    key: "addUserDetailsGroup",
  },
  {
    path: "/administration/user-group/edit",
    name: "Edit",
    Component: AddUserGroup,
    title: "Edit",
    key: "editUserDetailsGroup",
  },
  {
    path: "/administration/user-profiles",
    name: "Profile Permissions",
    Component: ProfilePermissions,
    title: "Profile Permissions",
    key: "userDetailsProfiles",
  },
  {
    path: "/administration/user-profiles-list",
    name: "Profile Permissions",
    Component: ProfilePermissions,
    title: "Profile Permissions",
    key: "userDetailsProfilesList",
  },
  {
    path: "/administration/user-profiles/add",
    name: "Add",
    Component: AddProfileRole,
    title: "Add",
    key: "addUserDetailsProfile",
  },
  {
    path: "/administration/user-profiles/edit",
    name: "Edit",
    Component: AddProfileRole,
    title: "Edit",
    key: "editUserDetailsProfile",
  },

  {
    path: "/administration/custom-list-management",
    name: "Custom List Management",
    Component: CustomListMgmt,
    title: "Custom List Management",
    key: "customListManagement",
  },
  {
    path: "/administration/custom-list-management/custom-list-items",
    name: "Custom List Items",
    Component: CustomList,
    title: "Custom List Items",
    key: "customListItems",
  },
  {
    path: "/administration/custom-list-management/custom-list-items/add-list-items",
    name: "Add List Items",
    Component: AddCustomListItems,
    title: "Add List Items",
    key: "addCustomListItems",
  },
  {
    path: "/administration/custom-list-management/custom-list-items/edit-list-items",
    name: "Edit List Items",
    Component: AddCustomListItems,
    title: "Edit List Items",
    key: "editCustomListItems",
  },
  {
    path: "/administration/email-templates",
    name: "Email Templates",
    Component: EmailTemplateList,
    title: "Email Templates",
    key: "emailTemplates",
  },
  {
    path: "/administration/email-templates/list",
    name: "Email Templates",
    Component: EmailTemplateList,
    title: "Email Templates",
    key: "emailTemplatesList",
  },
  {
    path: "/administration/email-templates/add",
    name: "Add Email Template",
    Component: AddEmailTemplates,
    title: "Add Email Template",
    key: "addEmailTemplate",
  },
  {
    path: "/administration/email-templates/edit",
    name: " Edit Email Template",
    Component: AddEmailTemplates,
    title: "Edit Email Template",
    key: "editEmailTemplate",
  },
  {
    path: "/administration/workflow",
    name: "Workflow",
    Component: Workflow,
    title: "Workflow",
    key: "workflow",
  },
  {
    path: "/administration/workflow/list",
    name: "Workflow",
    Component: Workflow,
    title: "Workflow",
    key: "workflowList",
  },
  {
    path: "/administration/workflow/add",
    name: "Add Workflow",
    Component: AddWorkflow,
    title: "Add Workflow",
    key: "addWorkflow",
  },
  {
    path: "/administration/workflow/edit",
    name: "Edit Workflow",
    Component: AddWorkflow,
    title: "Edit Workflow",
    key: "workflowEdit",
  },
  {
    path: "/administration/clients",
    name: "Client List",
    Component: ClientsList,
    title: "Client List",
    key: "clients",
  },
  {
    path: "/administration/clients/list",
    name: "Client List",
    Component: ClientsList,
    title: "Client List",
    key: "clientList",
  },
  {
    path: "/administration/clients/add",
    name: "Add Client",
    Component: ClientSubmit,
    title: "Add Client",
    key: "addClients",
  },
  {
    path: "/administration/clients/edit",
    name: "Edit Client",
    Component: ClientSubmit,
    title: "Edit Client",
    key: "clientsEdit",
  },
  {
    path: "/administration/clients/contacts",
    name: "Contact List",
    Component: ContactListAndSubmit,
    title: "Contact List",
    key: "clientsContacts",
  },
  {
    path: "/administration/clients/helpdesk",
    name: "Country Contact List",
    Component: HelpdeskCountryContactList,
    title: "Country Contact List",
    key: "clientHelpDesk",
  },
  {
    path: "/administration/attribute",
    name: "Attribute Management",
    Component: AttributeManagement,
    title: "Attribute Management",
    key: "attributesManagement",
  },
  {
    path: "/administration/attribute-list",
    name: "Attribute Management",
    Component: AttributeManagement,
    title: "Attribute Management",
    key: "attributesManagementList",
  },

  {
    path: "/administration/logs",
    name: "Admin Logs",
    Component: AdminLogs,
    title: "Admin Logs",
    key: "adminLogs",
  },

  {
    path: "/manage-supplier-list",
    name: "Manage Supplier Profile",
    Component: VMD,
    title: "Manage Supplier Profile",
    key: "manageSupplierList",
  },
  {
    path: "/manage-supplier/company-details",
    name: "Company Details",
    Component: CompanyDetails,
    title: "Company Details",
    key: "companyDetails",
  },
  {
    path: "/manage-supplier/create-new-company",
    name: "Create New Company",
    Component: CreateCompany,
    title: "Create New Company",
    key: "createNewCompany",
  },
  {
    path: "/manage-supplier/audit-trail",
    name: "Audit Trail",
    Component: AuditTrail,
    title: "Audit Trail",
    key: "auditTrail",
  },
  {
    path: "/manage-supplier/audit-trail/audit-trail-details",
    name: "Audit Trail Details",
    Component: AuditTrailDetails,
    title: "Audit Trail Details",
    key: "auditTrailDetails",
  },
  {
    path: "/administration/logs/email-log-details",
    name: "Email Logs Description",
    Component: EmailLogsEmail,
    title: "Email Logs Description",
    key: "emailLogsDetails",
  },
  {
    path: "/administration/news-and-events",
    name: "News & Events",
    Component: newsandevents,
    title: "News & Events",
    key: "newsAndEvents",
  },
  {
    path: "/administration/news-and-events-list",
    name: "News & Events",
    Component: newsandevents,
    title: "News & Events",
    key: "newsAndEventsList",
  },
  {
    path: "/administration/news-and-events/add",
    name: "Add News & Events",
    Component: Addnewsandevents,
    title: "Add News & Events",
    key: "addNewsAndEvents",
  },
  {
    path: "/administration/news-and-events/edit",
    name: "Edit News & Events",
    Component: Addnewsandevents,
    title: "Edit News & Events",
    key: "editNewsAndEvents",
  },
  {
    path: "/administration/help",
    name: "Help",
    Component: Help,
    title: "Help",
    key: "help",
  },
  {
    path: "/reports/report-list",
    name: "Invoice List",
    Component: ReportList,
    title: "Reports",
    key: "reportsList",
  },
  {
    path: "/reports/invoice-status-change-notification",
    name: "Invoice Status Change Notification",
    Component: ReportStatus,
    title: "Invoice Status Change Notification",
    key: "reportStatus",
  },

  {
    path: "/reports/add",
    name: "Report Add",
    Component: ReportDetails,
    title: "Reports",
    key: "addReportDetails",
  },
  {
    path: "/reports/edit",
    name: "Report Edit",
    Component: ReportDetails,
    title: "Reports",
    key: "editReportDetails",
  },
  {
    path: "/reports/reports-details/:invoiceReportID",
    Component: ReportDetails,
    name: "Report Details",
    title: "Reports",
    key: "reportDetails",
  },

  {
    path: "/helpdesk/requests-management",
    name: "Requests",
    Component: RequestsManagement,
    title: "Requests",
    key: "requestManagement",
  },
  {
    path: "/helpdesk/requests-management/details",
    name: "Request Details",
    Component: RequestsDetails,
    title: "Request Details",
    key: "requestManagementDetails",
  },
  {
    path: "/administration/manage-upload",
    name: "Manage Upload",
    Component: ManageUpload,
    title: "Manage Upload",
    key: "manageUpload",
  },
  {
    path: "/administration/manage-upload/user-upload",
    name: "Users Upload",
    Component: UsersUpload,
    title: "Users Upload",
    key: "usersUpload",
  },
  {
    path: "/administration/manage-upload/supplier-upload",
    name: "Supplier Upload",
    Component: SuppliersUpload,
    title: "Suppliers Upload",
    key: "suppliersUpload",
  },
  {
    path: "/reconciliation",
    name: "Reconciliation",
    Component: Reconciliation,
    title: "Reconciliation",
    key: "reconciliation",
  },
  {
    path: "/reconciliation/create-reconciliation",
    name: "Create Reconciliation",
    Component: CreateReconciliation,
    title: "Create Reconciliation",
    key: "createReconciliation",
  },
  {
    path: "/resolve-rejection",
    name: "Resolve Rejection",
    Component: ResolveInvoices,
    title: "Resolve Rejection",
    key: "markAsResolved",
  },
  {
    path: "/reports/user-management-reports",
    name: "User Management Reports",
    Component: UserManagementReport,
    title: "User Management Reports",
    key: "UserManagementreport",
  },
];
export const commonAuthRoutes = [
  {
    path: "/view-help",
    name: "route.viewHelp",
    Component: ViewHelp,
    title: "route.viewHelp",
    key: "viewHelp",
  },
  {
    path: "/administration/help/edit/faq",
    name: "route.help.editFaq",
    Component: AddEditFaqMaterial,
    title: "route.help.editFaq",
    key: "editFaqMaterial",
  },
  {
    path: "/administration/help/add/faq",
    name: "route.help.addFaq",
    Component: AddEditFaqMaterial,
    title: "route.help.addFaq",
    key: "addFaqMaterial",
  },
  {
    path: "/administration/help/edit/training-materials",
    name: "route.help.editTm",
    Component: AddEditTrainingMaterial,
    title: "route.help.editTm",
    key: "editTrainingMaterial",
  },
  {
    path: "/administration/help/add/training-materials",
    name: "route.help.addTm",
    Component: AddEditTrainingMaterial,
    title: "route.help.addTm",
    key: "addTrainingMaterial",
  },
  {
    path: "/administration/help/edit/training-video",
    name: "route.help.editTv",
    Component: AddEditTrainingVideo,
    title: "route.help.editTv",
    key: "editTrainingVideo",
  },
  {
    path: "/administration/help/add/training-video",
    name: "route.help.addTv",
    Component: AddEditTrainingVideo,
    title: "route.help.addTv",
    key: "addTrainingVideo",
  },
  {
    path: "/reconciliation/reconciliation-details",
    name: "route.reconciliation.details",
    Component: ReconciliationDetails,
    title: "route.reconciliation.details",
    key: "reconciliationDetails",
  },
  {
    path: "/reconciliation/reconciliation-details/comments",
    name: "route.reconciliation.comments",
    Component: ReconciliationComments,
    title: "route.reconciliation.comments",
    key: "reconciliationComments",
  },
  {
    path: "/my-account/edit-profile",
    name: "route.editProfile",
    Component: AddUser,
    title: "route.editProfile",
    key: "editProfile",
  },
  {
    path: "/payments/payments-details",
    name: "Payment Details",
    Component: PaymentDetailsTable,
    title: "Payment Details",
    key: "paymentDetails",
  },
];
export const unAuthRoutes = [
  {
    path: "/login",
    name: "Login",
    Component: Login,
    title: "Login",
  },
  {
    path: "/sign-up",
    name: "Sign Up",
    Component: SignUp,
    title: "Sign Up",
  },
  {
    path: "/reset-password",
    name: "Reset Password",
    Component: ResetPassword,
    title: "Reset Password",
  },
  {
    path: "/app/callback",
    name: "SSO Login",
    Component: SSOLoginPage,
    title: "SSO Login",
  },
  {
    path: "/p2p-landing-page",
    name: "P2P Landing Page",
    Component: P2PLandingPage,
    title: "P2P Landing Page",
  },
];

export const documentSubmissionClientConfigRoutes = [
  {
    path: "/document-submission/single-invoice",
    name: "documentSubmission.titleSingleinvoiceUpload", //"Single invoice Upload",
    Component: InvoiceSubmissionForm,
    title: "documentSubmission.titleSingleinvoiceUpload",
    key: "singleInvoiceSubmission",
    configID: 1,
  },
  {
    path: "/document-submission/bulk-invoice",
    name: "documentSubmission.titleBulkInvoiceUpload", //"Bulk invoice Upload",
    Component: InvoiceSubmissionForm,
    title: "documentSubmission.titleBulkInvoiceUpload",
    key: "bulkInvoiceSubmission",
    configID: 2,
  },
  {
    path: "/document-submission/e-form",
    name: "documentSubmission.titleEFormInvoiceGeneration", //"Invoice Generation",
    Component: InvoiceSubmissionForm,
    title: "documentSubmission.titleEFormInvoiceGeneration",
    key: "invoiceGeneration",
    configID: 3,
    type: "eform",
  },

  {
    path: "/document-submission/po-invoice-generation",
    name: "documentSubmission.titlePOInvoiceGeneration", //"PO Invoice generation",
    parent: "invoiceGeneration",
    Component: InvoiceSubmissionForm,
    title: "documentSubmission.titlePOInvoiceGeneration",
    key: "POflip",
    configID: 3,
    type: "PO",
  },

  {
    path: "/document-submission/e-form",
    name: "documentSubmission.titleEFormInvoiceGeneration", //"E-Form",
    parent: "invoiceGeneration",
    Component: InvoiceSubmissionForm,
    title: "documentSubmission.titleEFormInvoiceGeneration",
    key: "Eform",
    configID: 3,
    type: "eform",
  },
  {
    path: "/document-submission/invoice-data",
    name: "documentSubmission.titleInvoiceDataUploadwithCustomTemplate", //"Invoice upload",
    Component: InvoiceSubmissionForm,
    title: "documentSubmission.titleInvoiceDataUploadwithCustomTemplate",
    key: "customTemplateInvoiceUpload",
    configID: 4,
  },
];
