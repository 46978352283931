import React from "react";

import MenuItems from "./MenuItems";
import { MenuBar, MenuWrapper } from "./style";

import { compose } from "redux";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  selectClientDetails,
  selectSessionDetails,
} from "../../container/LoginPage/store/selectors";
import { generateFilteredRoutes } from "../../routes/generateFilteredRoutes";
import Div from "../Div";
import { sessionDetails } from "../../config";

const Menu = (props) => {
  const session =
    JSON.parse(sessionStorage.getItem("sessionDetails")) || sessionDetails;

  const { menus } = props.sessionDetails.menus ? props.sessionDetails : session;

  let menuItems = generateFilteredRoutes(menus, props.clientDetails);

  const sortMenus = menuItems.sort((curr, prev) => {
    return curr.order - prev.order;
  });

  const menuArray = sortMenus
    .filter((menu) => menu.parentPageElementId === 0 && menu.isMenu)
    .map((menu) => ({
      pathname: menu.path,
      text: menu.name,
      id: menu.pageElementId,
    }));

  return (
    <MenuWrapper id="menu">
      <MenuBar>
        {menuArray && menuArray.length > 0 ? (
          menuArray.map((menu) => {
            return <MenuItems key={menu.id} allMenus={sortMenus} {...menu} />;
          })
        ) : (
          <Div display="flex" margin="auto" justifyContent="center">
            You do not have access to any modules
          </Div>
        )}
      </MenuBar>
    </MenuWrapper>
  );
};

const mapStateToProps = createStructuredSelector({
  sessionDetails: selectSessionDetails(),
  clientDetails: selectClientDetails(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(Menu);
