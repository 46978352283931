import React from "react";
import { useTranslation } from "react-i18next";
import Button from "../../Button/Button";
import IMG from "../../IMG";

import filterIcon from "../../../assets/images/filter.png";
import Span from "../../Span";
import { flexRow } from "../../Layout/style";

const AdvanceSearchButton = (props) => {
  const { t } = useTranslation();
  return (
    <Button
      tabIndex="0"
      textColor="#018eff"
      role="Button"
      aria-label={t("common.advanceSearch", " ... ")}
      aria-pressed="false"
      onClick={props.toggleAdvanceSearch}
      margin="2px"
    >
      <IMG
        imgWidth="12.8px"
        imgHeight="12.8px"
        src={filterIcon}
        alt="Filter-Icon"
        margin="0 4px"
      />

      {t("common.advanceSearch", " ... ")}
      {props.appliedFilters ? (
        <Span
          {...flexRow}
          justifyContent="center"
          height="24px"
          width="24px"
          margin="0 8px"
          textAlign="center"
          alignItems="center"
          borderRadius="50%"
          color="#FFF"
          bgColor="#018eff"
        >
          {props.appliedFilters}
        </Span>
      ) : null}
    </Button>
  );
};

export default AdvanceSearchButton;
