export const spanStyle = {
  divWidth: "92px",
  divHeight: "15px",
  color: "#008eff",
  fontSize: "11px",
  fontWeight: "bold",
  letterSpacing: "0px",
  fontFamily: "graphik-bold",
  textTransform: "uppercase",
};

export const form = [
  {
    type: "selectLookup",
    attr: {
      id: "countryID",
      name: "BillingCountry",
      label: "documentSubmission.billingCountryLabel",
      placeholder: "documentSubmission.billingCountryPlaceholder",
      options: [],
      required: true,
    },

    page: ["single-invoice", "bulk-invoice", "po-invoice-generation", "e-form"],
    selector: "country",
    loading: "countryListFetchStatus",
    dependantInput: "submissionProfileID",
    onChangeDispatchAction: [
      {
        name: "getProfileDropdownList",
        params: ["countryID"],
      },
      {
        name: "getSupplierDropdownList",
        params: ["countryID"],
      },
    ],
  },

  {
    type: "selectLookup",
    attr: {
      id: "submissionProfileID",
      name: "submissionProfileID",
      label: "documentSubmission.documentTypeLabel",
      placeholder: "documentSubmission.documentTypePlaceholder",
      options: [],
      required: true,
    },

    page: ["single-invoice", "bulk-invoice", "po-invoice-generation", "e-form"],
    selector: "documentType",
    loading: "profileListFetchStatus",
  },
  {
    type: "selectLookup",
    attr: {
      id: "supplierID",
      name: "supplierID",
      label: "documentSubmission.companyLabel",
      placeholder: "documentSubmission.companyPlaceholder",
      options: [],
      required: true,
    },

    page: ["single-invoice", "po-invoice-generation", "e-form"],
    selector: "companyName",
    loading: "supplierListFetchStatus",
    dependantInput: "poNumber",
    onChangeDispatchAction: [
      {
        name: "getPONumberList",
        params: ["supplierID"],
      },
    ],
  },

  {
    type: "selectLookup",
    attr: {
      id: "poNumber",
      name: "poNumber",
      label: "documentSubmission.poNumberLabel",
      placeholder: "documentSubmission.poNumberPlaceholder",
      options: [],
      required: true,
    },

    page: ["single-invoice", "po-invoice-generation"],
    //excludeCondition: ["eform"],
    selector: "poNumber",
    loading: "poNumberFetching",
  },
  {
    type: "fileUploader",
    isSupporting: false,
    attr: {
      id: "submissionAttachments",
      name: "submissionAttachments",
      label: "documentSubmission.submissionAttachmentsLabel",
      maxSizeHint: "common.maxSizeHint",
      hideLabel: true,
      multiple: false,
      required: true,
      minFileSize: 99,
    },
    wrapperStyle: {
      divWidth: "100%",
      grow: "",
      shrink: 0,
      hoverBgColor: "none",
    },
    style: {
      margin: 0,
    },
    labelWrapperStyle: {
      display: "none",
    },
    page: ["single-invoice"],
  },
  {
    type: "fileUploader",
    isSupporting: true,
    attr: {
      id: "submissionAttachments",
      name: "submissionAttachments",
      label: "documentSubmission.metaDataLabel",
      hideLabel: true,
      multiple: false,
      maxSizeHint: "common.maxSizeHint",
      required: true,
      minFileSize: 99,
    },
    wrapperStyle: {
      divWidth: "100%",
      grow: "",
      shrink: 0,
      hoverBgColor: "none",
    },
    page: ["bulk-invoice"],
  },
  {
    type: "fileUploader",
    isSupporting: false,
    attr: {
      id: "invoiceAttachments",
      name: "invoiceAttachments",
      label: "documentSubmission.invoiceAttachmentLabel",
      hideLabel: true,
      multiple: true,
      required: true,
      minFileSize: 99,
    },
    wrapperStyle: {
      divWidth: "100%",
      grow: "",
      shrink: 0,
      hoverBgColor: "none",
    },
    page: ["bulk-invoice"],
  },
  {
    type: "textarea",
    attr: {
      id: "notes",
      name: "notes",
      label: "documentSubmission.submissionCommentsLabel",
      placeholder: "documentSubmission.submissionCommentsPlaceholder",
      required: false,
      maxLength: 255,
    },
    wrapperStyle: {
      divWidth: "100%",
    },

    page: ["single-invoice", "bulk-invoice", "po-invoice-generation", "e-form"],
  },
  {
    type: "fileUploader",
    isSupporting: true,
    attr: {
      id: "submissionAttachments",
      name: "submissionAttachments",
      label: "documentSubmission.invoiceDataLabel",
      hideLabel: true,
      multiple: false,
      maxSizeHint: "common.maxSizeHint",
      required: true,
      minFileSize: 99,
    },
    wrapperStyle: {
      divWidth: "100%",
      grow: "",
      shrink: 0,
      hoverBgColor: "none",
    },
    page: ["invoice-data"],
  },
  {
    type: "fileUploader",
    isSupporting: false,
    attr: {
      id: "invoiceAttachments",
      name: "invoiceAttachments",
      label: "documentSubmission.invoiceAttachmentsLabel",
      hideLabel: true,
      multiple: true,
      required: false,
      minFileSize: 99,
    },
    wrapperStyle: {
      divWidth: "100%",
      grow: "",
      shrink: 0,
      hoverBgColor: "none",
    },
    page: ["invoice-data"],
  },
];
const { getRoutePathByKey } = require("../../../helper");

export const documentSubmissionURL = getRoutePathByKey("documents");
export const documentSubmissonListURL = getRoutePathByKey("documentList");
export const singleInvoiceUploadURL = getRoutePathByKey(
  "singleInvoiceSubmission"
);
export const bulkInvoiceUploadURL = getRoutePathByKey("bulkInvoiceSubmission");
export const invoiceGenerationURL = getRoutePathByKey("invoiceGeneration");
export const customTemplateInvoiceUploadURL = getRoutePathByKey(
  "customTemplateInvoiceUpload"
);

export const eformUrl = getRoutePathByKey("Eform");
export const poUrl = getRoutePathByKey("POflip");
export const invoiceDetailsURL = getRoutePathByKey("invoiceDetails");

export const apiRef = {
  "single-invoice": "/Submission/InvoiceUpload",
  "bulk-invoice": "/Submission/BulkInvoiceUpload",
  "invoice-generation": "/Einvoice/DraftSubmission",
  "invoice-data": "/Submission/InvoiceDataUpload",
};

export const getProperType = (type) => {
  switch (type.toLowerCase()) {
    case "text":
      return "text";
    case "date":
      return "datepicker";
    case "numeric":
    case "amount":
      return "number";
    case "customlist":
      return "selectLookup";
    case "select":
      return "select";
    default:
      return "text";
  }
};

export const defaultMaxLengthMessage = "common.maxLengthExceededError";
export const defaultRequiredFieldMessage = "common.requiredFieldError";
export const defaultRegExMessage = "common.incorrectFormatError";
