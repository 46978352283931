import { fromJS } from "immutable";

import {
  FETCHING_WORKFLOWS,
  FETCH_WORKFLOWS,
  WORKFLOWS_COMPLETE,
  INIT_WORKFLOW_SAVE,
  WORKFLOW_SAVED,
  WORKFLOW_SAVE_FAILED,
  RESET_SAVE_STATUS,
  INIT_WORKFLOWS_DELETE,
  DELETE_WORKFLOW_SUCCESS,
  DELETE_WORKFLOW_FAILED,
  INIT_WORKFLOW_EDIT,
  SET_WORKFLOW_EDIT_DATA,
  EDIT_WORKFLOW_DATA_FETCHED,
  FETCHING_CONDITIONS,
  FETCH_CONDITIONS,
  CONDITIONS_FETCH_COMPLETE,
} from "./actionTypes";

export const initialState = fromJS({
  workFlowFething: true,
  workflows: [],
  workflowSaving: false,
  workflowSaved: false,
  workflowSaveFail: false,
  deleting: false,
  deleted: [],
  editing: false,
  fetchingDataForEdit: false,
  workflow: null,
  fetchForEditComplete: false,
  fetchingEmails: false,
  emailTemplates: [],
  fetchingConditions: false,
  conditions: [],
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_WORKFLOWS:
      return state.set("workFlowFething", true);
    case FETCH_WORKFLOWS:
      return state.set("workflows", action.data);
    case WORKFLOWS_COMPLETE: {
      return state.set("workFlowFething", false);
    }
    case INIT_WORKFLOW_SAVE: {
      return state.set("workflowSaving", true);
    }
    case WORKFLOW_SAVED: {
      return state.set("workflowSaved", true).set("workflowSaving", false).set("editing", false);
    }
    case WORKFLOW_SAVE_FAILED: {
      return state.set("workflowSaveFail", true).set("workflowSaving", false).set("editing", false);
    }
    case RESET_SAVE_STATUS: {
      return state.set("workflowSaved", false);
    }
    case INIT_WORKFLOWS_DELETE: {
      return state.set("deleting", true);
    }

    case DELETE_WORKFLOW_SUCCESS: {
      return state
        .set("deleting", false)
        .set("deleted", [...state.get("deleted"), ...action.id]);
    }
    case DELETE_WORKFLOW_FAILED: {
      return state.set("deleting", false);
    }

    case INIT_WORKFLOW_EDIT: {
      return state.set("editing", true).set("fetchingDataForEdit", true);
    }
    case SET_WORKFLOW_EDIT_DATA: {
      return state
        .set("workflow", action.data)
        .set("fetchingDataForEdit", false)
        .set("fetchForEditComplete", true);
    }
    case EDIT_WORKFLOW_DATA_FETCHED: {
      return state.set("fetchForEditComplete", false);
    }

    case FETCHING_CONDITIONS: {
      return state.set("fetchingConditions", true);
    }
    case FETCH_CONDITIONS: {
      return state.set("conditions", action.data);
    }
    case CONDITIONS_FETCH_COMPLETE: {
      return state.set("fetchingConditions", false);
    }

    default:
      return state;
  }
};
export default reducer;
