import React, { useState, useEffect, useRef } from "react";

import FilterPanel from "./component/FilterPanel";
import AdvanceSearchButton from "./component/AdvanceSearchButton";

import { useTranslation } from "react-i18next";

const Filter = (props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [filterValues, setFilterValues] = useState({});
  const [appliedFilters, setAppliedFilters] = useState(0);
  const [conditionValue, setConditionValue] = useState("");
  const [logicalInputs, setLogicalInputs] = useState([]);
  const [logicalValues, setLogicalValues] = useState([]);

  const logicalFilterParam = {
    control: "",
    order: 0,
    condition: "AND",
    paramFrom: "",
    paramTo: "",
    paramAdditional: "",
  };

  //check for open state of advance search panel, to call APIs on open state
  useEffect(() => {
    if (props.filterPanelOpen) {
      props.filterPanelOpen(open);
    }
  }, [open, props]);

  const handleControlChange = (e) => {
    const values = { ...filterValues };
    const { id, value, checked, type } = e.target;

    values[id] = type === "checkbox" ? checked : value;
    setFilterValues(values);
  };
  const apply = (isLogical) => {
    const fv = isLogical
      ? { ...filterValues, logical: logicalValues }
      : filterValues;
    props.onFilter && props.onFilter(fv);
  };
  const clearFilter = () => {
    let emptyfilterValues = {};
    Object.keys(filterValues).forEach((key) => {
      emptyfilterValues = {
        ...emptyfilterValues,
        [key]: undefined,
      };
    });

    setFilterValues({});
    setAppliedFilters(0);
    props.clearExtenalFilterValues && props.clearExtenalFilterValues();
    props.onFilter && props.onFilter(emptyfilterValues);
  };

  const handleConditionChange = (e) => {
    const { value } = e.target;
    setConditionValue(value);
  };

  const handleAddCondition = () => {
    const tempLogicalInputs = [...logicalInputs];
    const tmpLogicalValues = [...logicalValues];

    const control =
      props.sections &&
      props.sections.find(
        (section) => section.controlID === Number(conditionValue)
      );
    tmpLogicalValues.push({
      ...logicalFilterParam,
      order: logicalValues.length,
    });

    tempLogicalInputs.push({
      title: control.title,
      inputs: control.inputs,
      controlID: control.controlID,
    });
    setLogicalValues(tmpLogicalValues);
    setLogicalInputs(tempLogicalInputs);
  };
  const handleRemoveCondition = (conditionIndex) => {
    const tempLogicalInputs = logicalInputs.filter(
      (_, index) => index !== conditionIndex
    );
    const tempLogicalValues = logicalValues
      .filter((_, index) => index !== conditionIndex)
      .map((param, order) => ({
        ...param,
        order,
      }));
    setLogicalInputs(tempLogicalInputs);
    setLogicalValues(tempLogicalValues);
  };
  const handleLogicalControlChange = (e, ctrlAttr) => {
    const { id, value } = e.target;
    const { controlID, order } = ctrlAttr;

    const inputVal = logicalValues.map((param, index) => {
      return index === order
        ? {
            ...param,
            [id]: value,
            order: order,
            control: controlID,
          }
        : param;
    });
    setLogicalValues(inputVal);
  };
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (event.target.id === "modalContainer") setOpen(false);
      };
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  const wrapperRef = useRef(null);

  useOutsideAlerter(wrapperRef);

  const logicalFilterOptions =
    props.sections &&
    props.sections
      .filter((section) => section.includeInLogical)
      .map((section) => ({ id: section.controlID, value: t(section.title) }));

  return (
    <>
      <AdvanceSearchButton toggleAdvanceSearch={() => setOpen(!open)} />

      <FilterPanel
        show={open}
        customEvent={props.customEvent}
        sections={props.sections}
        handleControlChange={handleControlChange}
        onClose={() => setOpen(false)}
        logicalOptions={logicalFilterOptions}
        hasLogical={props.hasLogical}
        values={filterValues}
        conditionValue={conditionValue}
        onConditionChange={handleConditionChange}
        addCondition={handleAddCondition}
        removeCondition={handleRemoveCondition}
        logicalInputs={logicalInputs}
        logicalValues={logicalValues}
        onLogicalControlChange={handleLogicalControlChange}
        clearFilter={clearFilter}
        onApplyFilter={apply}
      />
    </>
  );
};

export default Filter;
