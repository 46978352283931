import { put, takeEvery, call, all } from "redux-saga/effects";
import { buildNotification } from "../../../config/notification";
import { store } from "react-notifications-component";
import {
  fetchingPageElements,
  fetchPageElements,
  PageElementsFetchingComplete,
  fetchingAccessLevel,
  fetchAccessLevel,
  accessLevelFetchingComplete,
  profileRoleSave,
  formSubmissionStatusAction,
  setDataForEdit,
  deleteSuccess,
  deleteFailed,
  saveProfileRoleFailed,
  fetchingLoginGDPROptions,
  fetchLoginGDPROptions,
  LoginGDPROptionsFetchingComplete,
  fetchingApplicationGDPROptions,
  fetchApplicationGDPROptions,
  ApplicationGDPROptionsFetchingComplete,
} from "../store/actions";

import {
  INIT_GET_PAGE_ELEMENTS,
  INIT_GET_ACCESS_LEVEL,
  INIT_PROFILE_ROLE_SAVE,
  INIT_PROFILE_PERMISSION_EDIT,
  INIT_USER_ROLES_DELETE,
  INIT_GET_APPLICATION_GDPR_OPTIONS,
  INIT_GET_LOGIN_GDPR_OPTIONS,
} from "./actionTypes";
import axios from "../../../config/axios";
import {
  getRequestDetails,
  postRequestDetails,
  putRequestDetails,
} from "../../../config/requestHeaders";

export default function* watchProfilePermissions() {
  yield all([
    takeEvery(INIT_GET_PAGE_ELEMENTS, getPageElementsSaga),
    takeEvery(INIT_GET_ACCESS_LEVEL, getAccessLevelListSaga),
    takeEvery(INIT_PROFILE_ROLE_SAVE, saveProfileRoleSaga),
    takeEvery(INIT_PROFILE_PERMISSION_EDIT, initEditSaga),
    takeEvery(INIT_USER_ROLES_DELETE, initUserRoleDeleteSaga),
    takeEvery(
      INIT_GET_APPLICATION_GDPR_OPTIONS,
      initApplicationGDPROptionsSaga
    ),
    takeEvery(INIT_GET_LOGIN_GDPR_OPTIONS, initLoginGDPROptionsSaga),
  ]);
}

function* getPageElementsSaga(action) {
  yield put(fetchingPageElements());
  const url = "/Users/PageElements";
  try {
    const response = yield call(axios, url, getRequestDetails);
    const nest = (items, parent = 0) => {
      const nested = [];
      Object.values(items).forEach((item) => {
        const treeItem = {
          name: item.pageElementDisplayName,
          key: item.pageElementId,
        };
        if (item.parentPageElementId === parent) {
          const children = nest(items, item.pageElementId);

          if (children.length) {
            treeItem.children = children;
          }

          nested.push(treeItem);
        }
      });

      return nested;
    };
    const pageElement =
      response.data && response.data.length > 0 ? nest(response.data) : [];
    yield put(fetchPageElements(pageElement));
    yield put(PageElementsFetchingComplete());
  } catch (error) {
    const notification = buildNotification({
      message: "notification.errorOccurred",
      type: "danger",
    });
    store.addNotification({
      ...notification,
      dismiss: {
        duration: 1000,
      },
    });
    yield put(PageElementsFetchingComplete());
  }
}

function* getAccessLevelListSaga() {
  yield put(fetchingAccessLevel());
  const url = "/Users/listAccessLevel";
  try {
    const response = yield call(axios, url, getRequestDetails);
    yield put(fetchAccessLevel(response.data));
    yield put(accessLevelFetchingComplete());
  } catch (error) {
    const notification = buildNotification({
      message: "notification.errorOccurred",
      type: "danger",
    });
    store.addNotification({
      ...notification,
      dismiss: {
        duration: 1000,
      },
    });
    yield put(accessLevelFetchingComplete());
  }
}

function* saveProfileRoleSaga(action) {
  yield (postRequestDetails.data = {
    userId: action.userId,
    ...action.formdata,
  });
  try {
    const response = yield call(axios, "/Users/saverole", postRequestDetails);
    if (response) {
      if (response.status) {
        yield put(profileRoleSave());
        yield put(formSubmissionStatusAction(response.statusText));
        if (action.saveSuccess) {
          action.saveSuccess(response.status);
        }
        if (action.isEditMode) {
          const notification = buildNotification({
            message: "notification.profilePerm.roleUpdated",
            type: "success",
          });
          store.addNotification({
            ...notification,
            dismiss: {
              duration: 1000,
            },
          });
        } else {
          const notification = buildNotification({
            message: "notification.profilePerm.creationSuccess",
            type: "success",
          });
          store.addNotification({
            ...notification,
            dismiss: {
              duration: 1000,
            },
          });
        }
      } else {
        if (action.saveFailure) {
          action.saveFailure(true);
        }
      }
    }
  } catch (error) {
    yield put(saveProfileRoleFailed());
  }
}

function* initEditSaga(action) {
  const url = `/Users/roleById?roleId=${action.id}`;
  try {
    const response = yield call(axios, url, getRequestDetails);
    if (response && response.status) {
      yield put(setDataForEdit(response.data));
      if (action.callback) {
        action.callback(response.status);
      }
    }
  } catch (error) {
    action.callback && action.callback();
  }
}
function* initUserRoleDeleteSaga(action) {
  const url = `Users/DeleteProfile`;
  putRequestDetails.data = {
    userId: action.userId,
    listOfIds: action.id,
  };
  try {
    const response = yield call(axios, url, putRequestDetails);
    if (response && response.status) {
      const id = action.id.split(",").map((id) => parseInt(id));
      yield put(deleteSuccess(id));
      if (action.callback) {
        action.callback(response.status);
      }
      const notification = buildNotification({
        message: "notification.profilePerm.roleDeleted",
        type: "success",
      });
      store.addNotification({
        ...notification,
        dismiss: {
          duration: 1000,
        },
      });
    }
  } catch (error) {
    const notification = buildNotification({
      message: "notification.errorOccurred",
      type: "danger",
    });
    store.addNotification({
      ...notification,
      dismiss: {
        duration: 1000,
      },
    });
    yield put(deleteFailed());
  }
}

function* initLoginGDPROptionsSaga() {
  yield put(fetchingLoginGDPROptions());
  const url = "/CustomListItem/listkeys?typeName=__Login_GDPR_Messages";
  try {
    const response = yield call(axios, url, getRequestDetails);
    yield put(fetchLoginGDPROptions(response.data));
    yield put(LoginGDPROptionsFetchingComplete());
  } catch (error) {
    const notification = buildNotification({
      message: "notification.errorOccurred",
      type: "danger",
    });
    store.addNotification({
      ...notification,
      dismiss: {
        duration: 1000,
      },
    });
    yield put(LoginGDPROptionsFetchingComplete());
  }
}

function* initApplicationGDPROptionsSaga() {
  yield put(fetchingApplicationGDPROptions());
  const url = "/CustomListItem/listkeys?typeName=__Application_GDPR_Messages";
  try {
    const response = yield call(axios, url, getRequestDetails);
    yield put(fetchApplicationGDPROptions(response.data));
    yield put(ApplicationGDPROptionsFetchingComplete());
  } catch (error) {
    const notification = buildNotification({
      message: "notification.errorOccurred",
      type: "danger",
    });
    store.addNotification({
      ...notification,
      dismiss: {
        duration: 1000,
      },
    });
    yield put(ApplicationGDPROptionsFetchingComplete());
  }
}
