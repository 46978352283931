import React from "react";
import styled from "styled-components";

import IMG from "../IMG";
const Button = styled.button.attrs((props) => ({
  disabled: props.disabled ? props.disabled : false,
}))`
  background: ${(props) => (props.bgColor ? props.bgColor : "#FFF")};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "20px"};
  border: ${(props) => (props.border ? props.border : "1.5px solid #018eff")};
  opacity: ${(props) => (props.disabled ? "0.65" : "1")};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  height: ${(props) => (props.buttonHeight ? props.buttonHeight : "auto")};
  width: ${(props) => (props.buttonWidth ? props.buttonWidth : "auto")};
  color: ${(props) => (props.textColor ? props.textColor : "#018eff")};
  display: ${(props) => (props.display ? props.display : "flex")};
  font-family: ${(props) =>
    props.fontfamily ? props.fontfamily : "graphik-medium"};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "12px")};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "500")};
  letter-spacing: ${(props) =>
    props.letterSpacing ? props.letterSpacing : "-0.53px"};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : "20px")};
  padding: ${(props) => (props.padding ? props.padding : "4px 30px 4px 30px")};
  margin: ${(props) => (props.margin ? props.margin : "")};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "")};
  margin-right: ${(props) => (props.marginRight ? props.marginRight : "")};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : "")};
  outline: ${(props) => (props.outline ? props.outline : "")};
  text-align: center;
  &:focus {
    outline: 0;
    border: ${(props) => props.borderOnFocus || ""};
    color: ${(props) => props.focusColor || ""};
    background-color: ${(props) => props.focusBgColor || ""};
  }
  &:last-child {
    margin-left: ${(props) => (props.ml ? props.ml : "")};
  }
  &:first-child {
    margin: ${(props) =>
      props.firstChildMargin ? props.firstChildMargin : ""};
  }
  float: ${(props) => (props.float ? props.float : "")};
  position: ${(props) => (props.position ? props.position : "")};
  top: ${(props) => (props.top ? props.top : "")};
  bottom: ${(props) => (props.bottom ? props.bottom : "")};
  left: ${(props) => (props.left ? props.left : "")};
  right: ${(props) => (props.right ? props.right : "")};
  display: ${(props) => (props.display ? props.display : "")};
  flex: ${(props) => (props.flex ? props.flex : "")};
  flex-direction: row;
  align-items: ${(props) => (props.alignItems ? props.alignItems : "center")};
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "center"};
  padding-right: ${(props) => (props.pr ? props.pr : "")};
`;

export default Button;

export const ImageButton = (props) => {
  return (
    <React.Fragment>
      <IMG src={props.image} position="absolute" margin={props.imageMargin} />
      <Button
        bgColor={props.bgColor}
        buttonWidth={props.buttonWidth}
        buttonHeight={props.buttonHeight}
        marginRight={props.marginRight}
      >
        {props.children}
      </Button>
    </React.Fragment>
  );
};
