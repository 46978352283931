import { put, takeEvery, call, all, delay } from "redux-saga/effects";
import { store } from "react-notifications-component";
import { buildNotification } from "../../../config/notification";

import axios from "../../../config/axios";
import { postRequestDetails } from "../../../config/requestHeaders";

import {
  changingPassword,
  changePasswordSuccess,
  changePasswordFailed,
  updatePasswordOnLoginSuccess,
  updatePasswordOnLoginFailed,
} from "./action";
import {
  INIT_CHANGE_PASSWORD,
  INIT_UPDATE_PASSWORD_ON_LOGIN,
} from "./actionTypes";

const { userId, userName } =
  JSON.parse(sessionStorage.getItem("sessionDetails")) || "";

export default function* watchChangePasswordSaga() {
  yield all([
    takeEvery(INIT_CHANGE_PASSWORD, initChangePassword),
    takeEvery(INIT_UPDATE_PASSWORD_ON_LOGIN, initUpdatePasswordOnLogin),
  ]);
}

function* initChangePassword(action) {
  yield put(changingPassword());
  const url = "/Login/ResetOldPassword";
  try {
    postRequestDetails.data = { ...action.payload, userId, userName };
    const response = yield call(axios, url, postRequestDetails);
    if (!response) yield put(changePasswordFailed());

    if (response && response.status === 202) {
      const notification = buildNotification({
        message: "notification.changePassword.success",
        type: "success",
      });

      store.addNotification({
        ...notification,
      });
      yield delay(5000);
      yield put(changePasswordSuccess());
    } else if (response && response.status !== 202) {
      yield put(changePasswordFailed());
      if (action.callback) {
        action.callback(true);
      }
    }
  } catch (error) {
    action.callback && action.callback();
  }
}

function* initUpdatePasswordOnLogin(action) {
  const url = "/Login/UpdatePasswordOnLogin";
  try {
    postRequestDetails.data = JSON.stringify(action.payload);
    const response = yield call(axios, url, postRequestDetails);
    if (response && response.status === 200) {
      yield put(updatePasswordOnLoginSuccess());
      const notification = buildNotification({
        message: "notification.updatePasswordSuccess",
        type: "success",
      });

      store.addNotification({
        ...notification,
      });
      action.callBackFunction(response);
    } else {
      yield put(updatePasswordOnLoginFailed());
      action.callBackFunction("error");
    }
  } catch (error) {
    yield put(updatePasswordOnLoginFailed());
    const notification = buildNotification({
      message: "notification.errorOccurred",
      type: "danger",
    });

    store.addNotification({
      ...notification,
    });
    action.callBackFunction("error");
  }
}
