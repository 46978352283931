export const collapseChatbotCustomStyle = {
  modalMargin: "5% 3% 5% 58%",
  modalBgColor: "transparent",
  modalWidth: "80%",
  modalHeight: "80%",
  modalContentBorder: "0",
  modalContentBorderRadius: "8px",
  modalContentBoxShadow: "0px 8px 20px 0px rgba(0, 0, 0, 0.15)",
  padding: "0",
  modalHeaderBoxShadow: " 0px 2px 15px 0px rgba(0, 0, 0, 0.15)",
  modalHeaderBackgroundImage:
    "linear-gradient(-45deg, rgb(1, 187, 255) 0%, #008eff 100%)",
  bodyPadding: "0px",
  modalCloseDisplay: "none",
  backgroundColor: "#128c7e",
  headerPosition: "relative",
  closeButtonPosition: "absolute",
  closeButtonTop: "10px",
  closeButtonRight: "10px",
  headerHeight: "40px",
  closeButtonFloat: "initial",
};

export const chatbotHeaderText = {
  color: "rgb(255, 255, 255)",
  fontSize: "18px",
  fontFamily: "Graphik-Medium",
  fontWeight: "500",
  letterSpacing: "-0.25px",
  lineHeight: "18px",
};

export const chatbotLeftWrapper = {
  background: " rgb(255, 255, 255)",
  boxShadow: "1px 0px 0px 0px rgb(227, 234, 237)",
  borderRadius: "0px 0px 0px 8px",
  flex: "0.25",
};
export const chatbotRightWrapper = {
  background: "#f9f9f9",
  height: "340px",
};

export const messageWrapper = {
  background: " rgb(255, 255, 255)",
  boxShadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.05)",
  borderRadius: "20px 20px 20px 8px",
  padding: "15px",
  margin: "0 0 15px 0",
};
export const messageText = {
  color: "rgb(53, 65, 82)",
  fontSize: "15px",
  fontFamily: "AppleColorEmoji",
  letterSpacing: "-0.25px",
  margin: "0",
  lineHeight: "17px",
};
