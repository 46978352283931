import { ApiConfig } from "../../../config/apiConfig";

const BASE_API_URL = ApiConfig();

export const GET_INVOICE_FIELDS_URL =
  BASE_API_URL + "Search/listFieldLabels?findInInvoice=";
export const GET_INVOICE_URL = BASE_API_URL + "Search/listInvoice";

export const GET_FIELD_DEFINITION_ACTION = "GET_FIELD_DEFINITION_ACTION";
export const GET_INVOICE_COLUMNS_ACTION = "GET_INVOICE_COLUMNS_ACTION";
export const UPDATE_INVOICE_FIELDS_ACTION = "UPDATE_INVOICE_FIELDS_ACTION";
export const INVOICE_DETAILS_REQUEST = "INVOICE_DETAILS_REQUEST";
export const INVOICE_DETAILS_SUCCESS = "INVOICE_DETAILS_SUCCESS";

export const REMITTANCE_DETAILS_REQUEST = "REMITTANCE_DETAILS_REQUEST";
export const GET_REMITTANCE_URL = BASE_API_URL + "Search/listRemittanceByID";
export const REMITTANCE_DETAILS_SUCCESS = "REMITTANCE_DETAILS_SUCCESS";
export const UPDATE_REMITTANCE_COLUMNS_ACTION =
  "UPDATE_REMITTANCE_COLUMNS_ACTION";
export const UPDATE_INVOICE_DATA_ACTION = "UPDATE_INVOICE_DATA_ACTION";

export const UPDATE_SHOW_FILTER_ACTION = "UPDATE_SHOW_FILTER_ACTION";

export const GET_CURRENCY_LIST_ACTION = "GET_CURRENCY_LIST_ACTION";
export const GET_CURRENCY_LIST_URL =
  BASE_API_URL + "Common/listkeys?typeName=__Currencies&language=enGB";
export const UPDATE_CURRENCY_LIST = "UPDATE_CURRENCY_LIST";

export const INIT_FETCH_INVOICE_DETAILS = "INIT_FETCH_INVOICE_DETAILS";
export const FETHCING_INVOICE_DETAILS = "FETHCING_INVOICE_DETAILS";
export const FETCH_INVOICE_DETAILS = "FETCH_INVOICE_DETAILS";
export const INVOICE_DETAILS_FETCH_COMPLETE = "INVOICE_DETAILS_FETCH_COMPLETE";

export const INIT_SUPPLIER_LIST = "INIT_SUPPLIER_LIST";
export const FETCH_SUPPLIER_LIST = "FETCH_SUPPLIER_LIST";
export const FETCH_SUPPLIER_LIST_COMPLETED = "FETCH_SUPPLIER_LIST_COMPLETED";
export const FETCHING_SUPPLIER_LIST = "FETCHING_SUPPLIER_LIST";

export const INIT_DOWNLOAD_INVOICE_DFM = "INIT_DOWNLOAD_INVOICE_DFM";
export const SET_INVOICE_DFM = "SET_INVOICE_DFM";

export const INIT_GET_SELECTED_SUPPLIER_LIST =
  "INIT_GET_SELECTED_SUPPLIER_LIST";
