import React from "react";

import FormInput from "../../../Input/FormInput";

import Span from "../../../Span";

const FilterControls = (props) => {
  const onChangeHandler = (e, customEvent) => {
    props.customEvent && props.customEvent(customEvent, e.target.value);
    props.onFilterChange(e);
  };
  const renderControl = (controls, index) => {
    return controls.map((control, key) => {
      const value = props.logical
        ? props.values[control.logicalID]
        : props.values[control.id] || "";

      return (
        <FormInput
          key={control.id}
          elementType={control.type}
          value={value}
          checked={control.type === "checkbox" && value}
          elementProps={{
            ...control.config,
            id: props.logical ? control.logicalID : control.id,
          }}
          onChangeHandler={(e) =>
            onChangeHandler(e, control.config.customOnChange)
          }
        />
      );
    });
  };

  return (
    <>
      {props.controls ? (
        renderControl(props.controls, props.index || 0)
      ) : (
        <Span>No Controls Specified</Span>
      )}
    </>
  );
};

export default FilterControls;
