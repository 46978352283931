import { fromJS } from "immutable";

import {
  FETCHING_CATEGORY,
  FETCH_CATEGORY,
  FETCHING_FAQ_MATERIALS,
  FETCH_FAQ_MATERIALS,
  FAQ_MATERIALS_FETCH_COMPLETE,
  FETCHING_FAQ_MATERIAL_BY_ID,
  FETCH_FAQ_MATERIAL_BY_ID,
  FAQ_MATERIAL_BY_ID_FETCH_COMPLETE,
  INIT_SAVE_FAQ_MATERIAL,
  FAQ_MATERIAL_SAVED,
  FAQ_MATERIAL_SAVE_FAILED,
  INIT_DELETE_FAQ_MATERIAL,
  DELETE_FAQ_MATERIAL_SUCCESS,
  DELETE_FAQ_MATERIAL_FAILED,
  FETCHING_TRAINING_MATERIALS,
  FETCH_TRAINING_MATERIALS,
  TRAINING_MATERIALS_FETCH_COMPLETE,
  FETCHING_TRAINING_MATERIAL_BY_ID,
  FETCH_TRAINING_MATERIAL_BY_ID,
  TRAINING_MATERIAL_BY_ID_FETCH_COMPLETE,
  INIT_SAVE_TRAINING_MATERIAL,
  TRAINING_MATERIAL_SAVED,
  TRAINING_MATERIAL_SAVE_FAILED,
  INIT_DELETE_TRAINING_MATERIAL,
  DELETE_TRAINING_MATERIAL_SUCCESS,
  DELETE_TRAINING_MATERIAL_FAILED,
  FETCHING_TRAINING_VIDEOS,
  FETCH_TRAINING_VIDEOS,
  TRAINING_VIDEOS_FETCH_COMPLETE,
  FETCHING_TRAINING_VIDEO_BY_ID,
  FETCH_TRAINING_VIDEO_BY_ID,
  TRAINING_VIDEO_BY_ID_FETCH_COMPLETE,
  INIT_SAVE_TRAINING_VIDEO,
  TRAINING_VIDEO_SAVED,
  TRAINING_VIDEO_SAVE_FAILED,
  INIT_DELETE_TRAINING_VIDEO,
  DELETE_TRAINING_VIDEO_SUCCESS,
  DELETE_TRAINING_VIDEO_FAILED,
} from "./actionTypes";

export const initialState = fromJS({
  fetchingCategory: false,
  category: [],
  fetchingFaqList: false,
  faqList: [],
  fetchingFaqById: false,
  faqById: [],
  faqSavingStatus: false,
  deletingFaqMaterial: false,
  faqMaterialDeleted: [],
  fetchingTmList: false,
  tmList: [],
  fetchingTmById: false,
  tmById: [],
  tmSavingStatus: false,
  deletingTrainingMaterial: false,
  trainingMaterialDeleted: [],
  fetchingTvList: false,
  tvList: [],
  fetchingTvById: false,
  tvById: [],
  tvSavingStatus: false,
  deletingTrainingVideo: false,
  trainingVideoDeleted: [],
});
const reducer = (state = initialState, action) => {
  switch (action.type) {
    //category
    case FETCHING_CATEGORY: {
      return state.set("fetchingCategory", true);
    }
    case FETCH_CATEGORY: {
      return state.set("category", action.data).set("fetchingCategory", false);
    }
    //FAQ List
    case FETCHING_FAQ_MATERIALS: {
      return state.set("fetchingFaqList", true);
    }
    case FETCH_FAQ_MATERIALS: {
      return state.set("faqList", action.data);
    }
    case FAQ_MATERIALS_FETCH_COMPLETE: {
      return state.set("fetchingFaqList", false);
    }
    //FAQ by id
    case FETCHING_FAQ_MATERIAL_BY_ID: {
      return state.set("fetchingFaqById", true);
    }
    case FETCH_FAQ_MATERIAL_BY_ID: {
      return state.set("faqById", action.data);
    }
    case FAQ_MATERIAL_BY_ID_FETCH_COMPLETE: {
      return state.set("fetchingFaqById", false);
    }
    //FAQ save
    case INIT_SAVE_FAQ_MATERIAL: {
      return state.set("faqSavingStatus", true);
    }
    case FAQ_MATERIAL_SAVED: {
      return state.set("faqSavingStatus", false);
    }
    case FAQ_MATERIAL_SAVE_FAILED: {
      return state.set("faqSavingStatus", false);
    }
    //remove FAQ
    case INIT_DELETE_FAQ_MATERIAL: {
      return state.set("deletingFaqMaterial", true);
    }
    case DELETE_FAQ_MATERIAL_SUCCESS: {
      return state
        .set("deletingFaqMaterial", false)
        .set("faqMaterialDeleted", [
          ...state.get("faqMaterialDeleted"),
          ...action.id,
        ]);
    }
    case DELETE_FAQ_MATERIAL_FAILED: {
      return state.set("deletingFaqMaterial", false);
    }

    //TM List
    case FETCHING_TRAINING_MATERIALS: {
      return state.set("fetchingTmList", true);
    }
    case FETCH_TRAINING_MATERIALS: {
      return state.set("tmList", action.data);
    }
    case TRAINING_MATERIALS_FETCH_COMPLETE: {
      return state.set("fetchingTmList", false);
    }
    //TM by id
    case FETCHING_TRAINING_MATERIAL_BY_ID: {
      return state.set("fetchingTmById", true);
    }
    case FETCH_TRAINING_MATERIAL_BY_ID: {
      return state.set("tmById", action.data);
    }
    case TRAINING_MATERIAL_BY_ID_FETCH_COMPLETE: {
      return state.set("fetchingTmById", false);
    }
    //TM save
    case INIT_SAVE_TRAINING_MATERIAL: {
      return state.set("tmSavingStatus", true);
    }
    case TRAINING_MATERIAL_SAVED: {
      return state.set("tmSavingStatus", false);
    }
    case TRAINING_MATERIAL_SAVE_FAILED: {
      return state.set("tmSavingStatus", false);
    }
    //remove TM
    case INIT_DELETE_TRAINING_MATERIAL: {
      return state.set("deletingTrainingMaterial", true);
    }
    case DELETE_TRAINING_MATERIAL_SUCCESS: {
      return state
        .set("deletingTrainingMaterial", false)
        .set("trainingMaterialDeleted", [
          ...state.get("trainingMaterialDeleted"),
          ...action.id,
        ]);
    }
    case DELETE_TRAINING_MATERIAL_FAILED: {
      return state.set("deletingTrainingMaterial", false);
    }

    //TV List
    case FETCHING_TRAINING_VIDEOS: {
      return state.set("fetchingTvList", true);
    }
    case FETCH_TRAINING_VIDEOS: {
      return state.set("tvList", action.data);
    }
    case TRAINING_VIDEOS_FETCH_COMPLETE: {
      return state.set("fetchingTvList", false);
    }
    //TV by id
    case FETCHING_TRAINING_VIDEO_BY_ID: {
      return state.set("fetchingTvById", true);
    }
    case FETCH_TRAINING_VIDEO_BY_ID: {
      return state.set("tvById", action.data);
    }
    case TRAINING_VIDEO_BY_ID_FETCH_COMPLETE: {
      return state.set("fetchingTvById", false);
    }
    //TV save
    case INIT_SAVE_TRAINING_VIDEO: {
      return state.set("tvSavingStatus", true);
    }
    case TRAINING_VIDEO_SAVED: {
      return state.set("tvSavingStatus", false);
    }
    case TRAINING_VIDEO_SAVE_FAILED: {
      return state.set("tvSavingStatus", false);
    }
    //remove TV
    case INIT_DELETE_TRAINING_VIDEO: {
      return state.set("deletingTrainingMaterial", true);
    }
    case DELETE_TRAINING_VIDEO_SUCCESS: {
      return state
        .set("deletingTrainingMaterial", false)
        .set("trainingMaterialDeleted", [
          ...state.get("trainingMaterialDeleted"),
          ...action.id,
        ]);
    }
    case DELETE_TRAINING_VIDEO_FAILED: {
      return state.set("deletingTrainingMaterial", false);
    }

    default:
      return state;
  }
};
export default reducer;
