export const SUPPLIER_LIST_URL = "/SupplierField/list";
export const MODULE_LIST_URL = "/Attribute/listModule";
export const ATTRIBUTE_TYPE_LIST_URL = "/Attribute/listAttributeType";
export const ATTRIBUTES_LIST_URL = "/Attribute";
export const ATTRIBUTE_HIERARCHY_LIST_URL =
  "/Attribute/listAttributesHierarchy";
export const ATTRIBUTE_HIERARCHY_LEVEL_URL =
  "/Attribute/listAttributesHierarchyLevel";
export const CUSTOM_LIST_URL = "/CustomList/list";
export const VMD_TAB_DATA_URL = "/Common/listVMDTabData";
export const VMD_SECTION_DATA_URL = "/Common/listVMDSectionData";
export const SUBMISSION_FIELD_PLACEMENT_URL =
  "/Common/listSubmissionFieldPlacement";
export const SEARCH_FIELD_PLACEMENT_URL = "/Common/listSearchFieldPlacement";
export const DELETE_ATTRIBUTE_URL = "/Attribute";

export const INIT_MODULE_LIST_FETCH = "INIT_MODULE_LIST_FETCH";
export const FETCHING_MODULE_LIST = "FETCHING_MODULE_LIST";
export const FETCH_MODULE_LIST = "FETCH_MODULE_LIST";
export const MODULE_LIST_FETCH_COMPLETE = "MODULE_LIST_FETCH_COMPLETE";

export const INIT_ATTRIBUTE_TYPE_LIST_FETCH = "INIT_ATTRIBUTE_TYPE_LIST_FETCH";
export const FETCHING_ATTRIBUTE_TYPE_LIST = "FETCHING_ATTRIBUTE_TYPE_LIST";
export const FETCH_ATTRIBUTE_TYPE_LIST = "FETCH_ATTRIBUTE_TYPE_LIST";
export const ATTRIBUTE_TYPE_LIST_FETCH_COMPLETE =
  "ATTRIBUTE_TYPE_LIST_FETCH_COMPLETE";

export const INIT_ATTRIBUTES_LIST_FETCH = "INIT_ATTRIBUTES_LIST_FETCH";
export const FETCHING_ATTRIBUTES_LIST = "FETCHING_ATTRIBUTES_LIST";
export const FETCH_ATTRIBUTES_LIST = "FETCH_ATTRIBUTES_LIST";
export const ATTRIBUTES_LIST_FETCH_COMPLETE = "ATTRIBUTES_LIST_FETCH_COMPLETE";
export const UPDATED_ATTRIBUTES_LIST = "UPDATED_ATTRIBUTES_LIST";

export const INIT_ATTRIBUTE_HIERARCHY_LIST_FETCH =
  "INIT_ATTRIBUTE_HIERARCHY_LIST_FETCH";
export const FETCHING_ATTRIBUTE_HIERARCHY_LIST =
  "FETCHING_ATTRIBUTE_HIERARCHY_LIST";
export const FETCH_ATTRIBUTE_HIERARCHY_LIST = "FETCH_ATTRIBUTE_HIERARCHY_LIST";
export const ATTRIBUTE_HIERARCHY_LIST_FETCH_COMPLETE =
  "ATTRIBUTE_HIERARCHY_LIST_FETCH_COMPLETE";

export const INIT_ATTRIBUTE_HIERARCHY_LEVEL_FETCH =
  "INIT_ATTRIBUTE_HIERARCHY_LEVEL_FETCH";
export const FETCHING_ATTRIBUTE_HIERARCHY_LEVEL =
  "FETCHING_ATTRIBUTE_HIERARCHY_LEVEL";
export const FETCH_ATTRIBUTE_HIERARCHY_LEVEL =
  "FETCH_ATTRIBUTE_HIERARCHY_LEVEL";
export const ATTRIBUTE_HIERARCHY_LEVEL_FETCH_COMPLETE =
  "ATTRIBUTE_HIERARCHY_LEVEL_FETCH_COMPLETE";

export const INIT_GET_CUSTOM_LIST = "INIT_GET_CUSTOM_LIST";
export const FETCHING_CUSTOM_LIST = "FETCHING_CUSTOM_LIST";
export const FETCH_CUSTOM_LIST = "FETCH_CUSTOM_LIST";
export const CUSTOM_LIST_FETCH_COMPLETE = "CUSTOM_LIST_FETCH_COMPLETE";

export const INIT_GET_VMD_TAB_DATA = "INIT_GET_VMD_TAB_DATA";
export const FETCHING_VMD_TAB_DATA = "FETCHING_VMD_TAB_DATA";
export const FETCH_VMD_TAB_DATA = "FETCH_VMD_TAB_DATA";
export const VMD_TAB_DATA_FETCH_COMPLETE = "VMD_TAB_DATA_FETCH_COMPLETE";

export const INIT_GET_VMD_SECTION_DATA = "INIT_GET_VMD_SECTION_DATA";
export const FETCHING_VMD_SECTION_DATA = "FETCHING_VMD_SECTION_DATA";
export const FETCH_VMD_SECTION_DATA = "FETCH_VMD_SECTION_DATA";
export const VMD_SECTION_DATA_FETCH_COMPLETE =
  "VMD_SECTION_DATA_FETCH_COMPLETE";

export const INIT_GET_SUBMISSION_FIELD_PLACEMENT =
  "INIT_GET_SUBMISSION_FIELD_PLACEMENT";
export const FETCHING_SUBMISSION_FIELD_PLACEMENT =
  "FETCHING_SUBMISSION_FIELD_PLACEMENT";
export const FETCH_SUBMISSION_FIELD_PLACEMENT =
  "FETCH_SUBMISSION_FIELD_PLACEMENT";
export const SUBMISSION_FIELD_PLACEMENT_FETCH_COMPLETE =
  "SUBMISSION_FIELD_PLACEMENT_FETCH_COMPLETE";

export const INIT_GET_SEARCH_FIELD_PLACEMENT =
  "INIT_GET_SEARCH_FIELD_PLACEMENT";
export const FETCHING_SEARCH_FIELD_PLACEMENT =
  "FETCHING_SEARCH_FIELD_PLACEMENT";
export const FETCH_SEARCH_FIELD_PLACEMENT = "FETCH_SEARCH_FIELD_PLACEMENT";
export const SEARCH_FIELD_PLACEMENT_FETCH_COMPLETE =
  "SEARCH_FIELD_PLACEMENT_FETCH_COMPLETE";

export const INIT_GET_REGEX_LIST = "INIT_GET_REGEX_LIST";
export const FETCHING_REGEX_LIST = "FETCHING_REGEX_LIST";
export const FETCH_REGEX_LIST = "FETCH_REGEX_LIST";
export const REGEX_LIST_FETCH_COMPLETE = "REGEX_LIST_FETCH_COMPLETE";

export const INIT_GET_APPROVAL_TYPE_LIST = "INIT_GET_APPROVAL_TYPE_LIST";
export const FETCHING_APPROVAL_TYPE_LIST = "FETCHING_APPROVAL_TYPE_LIST";
export const FETCH_APPROVAL_TYPE_LIST = "FETCH_APPROVAL_TYPE_LIST";
export const APPROVAL_TYPE_LIST_FETCH_COMPLETE =
  "APPROVAL_TYPE_LIST_FETCH_COMPLETE";

export const INIT_GET_VALIDATION_TYPE = "INIT_GET_VALIDATION_TYPE";
export const FETCHING_VALIDATION_TYPE = "FETCHING_VALIDATION_TYPE";
export const FETCH_VALIDATION_TYPE = "FETCH_VALIDATION_TYPE";
export const VALIDATION_TYPE_FETCH_COMPLETE = "VALIDATION_TYPE_FETCH_COMPLETE";

export const INIT_ATTRIBUTES_DELETE = "INIT_ATTRIBUTES_DELETE";
export const ATTRIBUTES_DELETE_SUCCESS = "ATTRIBUTES_DELETE_SUCCESS";
export const ATTRIBUTES_DELETE_FAILED = "ATTRIBUTES_DELETE_FAILED";

export const INIT_ATTRIBUTE_EDIT = "INIT_ATTRIBUTE_EDIT";
export const SET_EDIT_ATTRIBUTE_DATA = "SET_EDIT_ATTRIBUTE_DATA";
export const SET_EDIT_ATTRIBUTE_DATA_COMPLETED =
  "SET_EDIT_ATTRIBUTE_DATA_COMPLETED";

export const INIT_ATTRIBUTE_SAVE = "INIT_ATTRIBUTE_SAVE";
export const ATTRIBUTE_SAVED = "ATTRIBUTE_SAVED";
export const ATTRIBUTE_SAVE_FAILED = "ATTRIBUTE_SAVE_FAILED";
export const ATTRIBUTE_FORM_SUBMISSION_STATUS =
  "ATTRIBUTE_FORM_SUBMISSION_STATUS";
export const RESET_ATTRIBUTE_SAVE_STATUS = "RESET_ATTRIBUTE_SAVE_STATUS";

// Module Config
export const INIT_GET_HELPDESK_QUERY_LIST = "INIT_GET_HELPDESK_QUERY_LIST";
export const FETCHING_HELPDESK_QUERY_LIST = "FETCHING_HELPDESK_QUERY_LIST";
export const FETCH_HELPDESK_QUERY_LIST = "FETCH_HELPDESK_QUERY_LIST";
export const HELPDESK_QUERY_LIST_FETCH_COMPLETE =
  "HELPDESK_QUERY_LIST_FETCH_COMPLETE";

export const INIT_GET_HELPDESK_QUERY_ATTRIBUTE_LIST =
  "INIT_GET_HELPDESK_QUERY_ATTRIBUTE_LIST";
export const FETCHING_HELPDESK_QUERY_ATTRIBUTE_LIST =
  "FETCHING_HELPDESK_QUERY_ATTRIBUTE_LIST";
export const FETCH_HELPDESK_QUERY_ATTRIBUTE_LIST =
  "FETCH_HELPDESK_QUERY_ATTRIBUTE_LIST";
export const UPDATE_HELPDESK_QUERY_ATTRIBUTE_LIST =
  "UPDATE_HELPDESK_QUERY_ATTRIBUTE_LIST";
export const HELPDESK_QUERY_ATTRIBUTE_LIST_FETCH_COMPLETE =
  "HELPDESK_QUERY_ATTRIBUTE_LIST_FETCH_COMPLETE";

export const INIT_GET_HELPDESK_QUERY_BY_ID = "INIT_GET_HELPDESK_QUERY_BY_ID";
export const FETCHING_HELPDESK_QUERY_BY_ID = "FETCHING_HELPDESK_QUERY_BY_ID";
export const FETCH_HELPDESK_QUERY_BY_ID = "FETCH_HELPDESK_QUERY_BY_ID";
export const HELPDESK_QUERY_BY_ID_FETCH_COMPLETE =
  "HELPDESK_QUERY_BY_ID_FETCH_COMPLETE";
export const CLEAR_HELPDESK_QUERY_BY_ID = "CLEAR_HELPDESK_QUERY_BY_ID";

export const INIT_GET_SEARCH_FIELDS_LIST = "INIT_GET_SEARCH_FIELDS_LIST";
export const FETCHING_SEARCH_FIELDS_LIST = "FETCHING_SEARCH_FIELDS_LIST";
export const FETCH_GET_SEARCH_FIELDS_LIST = "FETCH_GET_SEARCH_FIELDS_LIST";
export const UPDATED_SEARCH_FIELDS_LIST = "UPDATED_SEARCH_FIELDS_LIST";
export const GET_SEARCH_FIELDS_LIST_FETCH_COMPLETE =
  "GET_SEARCH_FIELDS_LIST_FETCH_COMPLETE";

export const INIT_GET_EMAIL_TEMPLATES_LIST = "INIT_GET_EMAIL_TEMPLATES_LIST";
export const FETCHING_GET_EMAIL_TEMPLATES_LIST =
  "FETCHING_GET_EMAIL_TEMPLATES_LIST";
export const FETCH_GET_EMAIL_TEMPLATES_LIST = "FETCH_GET_EMAIL_TEMPLATES_LIST";
export const GET_EMAIL_TEMPLATES_LIST_FETCH_COMPLETE =
  "GET_EMAIL_TEMPLATES_LIST_FETCH_COMPLETE";

export const INIT_HELPDESK_CONFIGARATION_SAVE =
  "INIT_HELPDESK_CONFIGARATION_SAVE";
export const HELPDESK_CONFIGARATION_SAVED = "HELPDESK_CONFIGARATION_SAVED";
export const HELPDESK_CONFIGARATION_FAILED = "HELPDESK_CONFIGARATION_FAILED";
export const HELPDESK_FORM_SUBMISSION_STATUS =
  "HELPDESK_FORM_SUBMISSION_STATUS";
export const RESET_HELPDESK_CONFIGARATION_STATUS =
  "RESET_HELPDESK_CONFIGARATION_STATUS";

export const INIT_SEARCH_CONFIGARATION_SAVE = "INIT_SEARCH_CONFIGARATION_SAVE";
export const SEARCH_CONFIGARATION_SAVED = "SEARCH_CONFIGARATION_SAVED";
export const SEARCH_CONFIGARATION_FAILED = "SEARCH_CONFIGARATION_FAILED";
export const SEARCH_FORM_SUBMISSION_STATUS = "SEARCH_FORM_SUBMISSION_STATUS";
export const RESET_SEARCH_CONFIGARATION_STATUS =
  "RESET_SEARCH_CONFIGARATION_STATUS";
