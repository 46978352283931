import styled from "styled-components";

const Embed = styled.embed`  
  width: ${props => (props.width ? props.width : "")};
  height: ${props => (props.height ? props.height : "")};
  border: ${props => (props.border ? props.border : "")};
  position: ${props => (props.position ? props.position : "relative")};
  display: ${props => (props.display ? props.display : "")};
`;

export default Embed;
