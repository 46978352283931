import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";

import { Provider } from "react-redux";
import { createBrowserHistory } from "history";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

import configureStore from "./configureStore";
import { BrowserRouter } from "react-router-dom";

import "./css/Fonts.css";
import "./css/Scrollbar.css";
import "./css/font-awesome.css";

import LanguageWrapper from "./context/language-context";

if (process.env.NODE_ENV === "production") console.log = () => {};

const initialState = {};
const history = createBrowserHistory();
const store = configureStore(initialState, history);
const MOUNT_NODE = document.getElementById("root");

ReactDOM.render(
  <LanguageWrapper>
    <Provider store={store}>
      <BrowserRouter basename={process.env.REACT_APP_BASE_URL}>
        <App history={history} store={store} />
      </BrowserRouter>
    </Provider>
  </LanguageWrapper>,
  MOUNT_NODE
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
