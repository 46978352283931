import React, { useEffect } from "react";

import MainContentHead from "../MainContentHead";
import Div from "../Div";
import Footer from "./Footer";
import Menu from "../Menu";

import ChangePassword from "../../container/ChangePassword";

import QuickAccessBar from "./QuickAccessBar";
import AppContextProvider from "../../context";
import { useState } from "react";
import { fullPage, flexCol, flexGrow, flexRow } from "./style";
import Header from "./Header/index";
import Separator from "../Separator";
import { initIdleTimer } from "../../container/LoginPage/store/actions";
import { connect } from "react-redux";

const Layout = (props) => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const idleTimer = () => {
      props.initIdleTimer();
    };
    document.addEventListener("click", idleTimer);
    document.addEventListener("keypress", idleTimer);
    idleTimer();
    return () => {
      document.removeEventListener("click", idleTimer);
      document.removeEventListener("keypress", idleTimer);
    };
  }, [props]);
  return (
    <Div {...fullPage} {...flexCol}>
      <Header handleModal={() => setShowModal(true)} />
      <Menu />

      <Separator />
      <Div id="container" {...flexRow} {...flexGrow} overflow="hidden" pb="1px">
        <AppContextProvider>
          <QuickAccessBar />
          <Div
            id="mainContent"
            bgColor="#FFF"
            {...flexGrow}
            {...flexCol}
            overflow="hidden"
          >
            <MainContentHead />
            <Div
              tabIndex="0"
              id="content"
              overflow="auto"
              padding="8px 16px"
              {...flexCol}
              {...flexGrow}
            >
              {props.children}
            </Div>
          </Div>
        </AppContextProvider>
      </Div>
      <Footer />
      <ChangePassword show={showModal} onClose={() => setShowModal(false)} />
    </Div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    initIdleTimer: () => {
      dispatch(initIdleTimer());
    },
  };
};
const withConnect = connect(null, mapDispatchToProps);

export default withConnect(Layout);
