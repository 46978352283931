export const INIT_REPORT_DETAILS_SAVE = "INIT_REPORT_DETAILS_SAVE";
export const SAVE_REPORT_DETAILS = "SAVE_REPORT_DETAILS";

export const INIT_FREQUENCY_LIST_FETCH = "INIT_FREQUENCY_LIST_FETCH";
export const FETCHING_FREQUENCY_LIST = "FETCHING_FREQUENCY_LIST";
export const FETCH_FREQUENCY_LIST = "FETCH_FREQUENCY_LIST";
export const FREQUENCY_LIST_FETCH_COMPLETE = "FREQUENCY_LIST_FETCH_COMPLETE";

export const INIT_CURRENCY_LIST_FETCH = "INIT_CURRENCY_LIST_FETCH";
export const FETCHING_CURRENCY_LIST = "FETCHING_CURRENCY_LIST";
export const FETCH_CURRENCY_LIST = "FETCH_CURRENCY_LIST";
export const CURRENCY_LIST_FETCH_COMPLETE = "CURRENCY_LIST_FETCH_COMPLETE";

export const FORM_SUBMISSION_STATUS = "FORM_SUBMISSION_STATUS";
export const RESET_FORM_SUBMISSION_STATUS = "RESET_FORM_SUBMISSION_STATUS";

// Report List Action Types
export const INIT_REPORT_DETAILS_LIST = "INIT_REPORT_DETAILS_LIST";
export const FETCHING_REPORT_DETAILS_LIST = "FETCHING_REPORT_DETAILS_LIST";
export const FETCH_REPORT_DETAILS_LIST = "FETCH_REPORT_DETAILS_LIST";
export const FETCH_REPORT_DETAILS_LIST_COMPLETED =
  "FETCH_REPORT_DETAILS_LIST_COMPLETED";
export const UPDATE_REPORT_DETAILS_LIST = "UPDATE_REPORT_DETAILS_LIST";

export const INIT_REPORT_DETAILS_BY_ID = "INIT_REPORT_DETAILS_BY_ID";
export const FETCHING_REPORT_DETAILS_BY_ID = "FETCHING_REPORT_DETAILS_BY_ID";
export const FETCH_REPORT_DETAILS_BY_ID = "FETCH_REPORT_DETAILS_BY_ID";
export const FETCH_REPORT_DETAILS_BY_ID_COMPLETED =
  "FETCH_REPORT_DETAILS_BY_ID_COMPLETED";

export const INIT_DELETE_REPORT_CONFIG = "INIT_DELETE_REPORT_CONFIG";
export const DELETE_SUCCESS = "DELETE_SUCCESS";
export const DELETE_FAILED = "DELETE_FAILED";

export const INIT_USER_LIST = "INIT_USER_LIST";
export const FETCH_USER_LIST = "FETCH_USER_LIST";
export const FETCHING_USER_LIST = "FETCHING_USER_LIST";
export const FETCH_USER_LIST_COMPLETED = "FETCH_USER_LIST_COMPLETED";

export const INIT_UPDATE_REPORT_STATUS = "INIT_UPDATE_REPORT_STATUS";
export const SAVE_UPDATED_REPORT_STATUS = "SAVE_UPDATED_REPORT_STATUS";
export const ADMIN_ROLE_DETAILS = "ADMIN_ROLE_DETAILS";
