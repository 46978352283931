import React from "react";
import Div from "../Div";
import { ModalTitle } from "./style";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { flexGrow, flexRow, flexShrink } from "../Layout/style";
import { useTranslation } from "react-i18next";
const ModalHeader = (props) => {
  const { t } = useTranslation();
  return (
    <Div
      {...flexRow}
      {...flexGrow}
      divHeight={props.headerHeight ? props.headerHeight : "60px"}
      bgColor={props.headerBgColor}
      padding={props.headerPadding ? props.headerPadding : "8px 16px"}
      border={props.headerBorder ? props.headerBorder : "1px solid #dcdcdc"}
      margin={props.headerMargin ? props.headerMargin : "0"}
      textalign={props.headerTextalign ? props.headerTextalign : ""}
    >
      <ModalTitle
        color={props.headerTextColor}
        headerFontFamily={props.headerFontFamily}
        headerTextSize={props.headerTextSize}
      >
        {typeof props.title === "string" ? t(props.title) : props.title}
      </ModalTitle>
      {props.noClose ? null : (
        <Div
          {...flexRow}
          {...flexShrink}
          justifyContent="flex-end"
          cursor="pointer"
          padding="0 10px"
          onClick={props.closeModal}
          selfAlign="center"
        >
          <FontAwesomeIcon
            icon={faTimes}
            color={props.closeIconColor || "#b0b2c2"}
            size={props.closeIconSize || "lg"}
          />
        </Div>
      )}
    </Div>
  );
};

export default ModalHeader;
