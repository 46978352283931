import { fromJS } from "immutable";

import {
  INIT_RESOLVE_INVOICES,
  RESOLVE_INVOICES_COMPLETED,
  SET_REJECTED_LIST,
  INIT_REJECTED_LIST,
  FETCHING_REJECTED_INVOICES,
} from "./actionTypes";

export const initialState = fromJS({
  resolveInvoicesComplete: false,
  getRejectedListComplete: false,
  fetchingRejectedInvoices: false,
  rowCount: 0,
  rejectedData: [],
  totalCount: 0,
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_RESOLVE_INVOICES:
      return state.set("resolveInvoicesComplete", true).set("rejectedData", []);

    case RESOLVE_INVOICES_COMPLETED:
      return state.set("resolveInvoicesComplete", false);
    case INIT_REJECTED_LIST:
      return state.set("getRejectedListComplete", false);
    case FETCHING_REJECTED_INVOICES:
      return state.set("fetchingRejectedInvoices", true);
    case SET_REJECTED_LIST: {
      return state
        .set("rejectedData", action.data.invoiceList)
        .set("rowCount", action.data.rowCount)
        .set(
          "totalCount",
          action.data.rowCount && action.data.rowCount.length
            ? action.data.rowCount[0].ticketCount
            : 0
        )
        .set("fetchingRejectedInvoices", false)
        .set("getRejectedListComplete", true);
    }
    default:
      return state;
  }
};
export default reducer;
