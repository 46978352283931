import styled from "styled-components";

const IMG = styled.img`
  position: ${(props) => (props.position ? props.position : "")};
  float: ${(props) => (props.float ? props.float : "")};
  width: ${(props) => (props.imgWidth ? props.imgWidth : "")};
  height: ${(props) => (props.imgHeight ? props.imgHeight : "")};
  margin: ${(props) => (props.margin ? props.margin : "")};
  margin-top: ${(props) => (props.mt ? props.mt : "")};
  margin-right: ${(props) => (props.mr ? props.mr : "")};
  margin-bottom: ${(props) => (props.mb ? props.mb : "")};
  margin-left: ${(props) => (props.ml ? props.ml : "")};
  padding: ${(props) => (props.padding ? props.padding : "")};
  top: ${(props) => (props.top ? props.top : "")};
  bottom: ${(props) => (props.bottom ? props.bottom : "")};
  right: ${(props) => (props.right ? props.right : "")};
  left: ${(props) => (props.left ? props.left : "")};
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
  cursor: ${(props) => (props.disabled ? "not-allowed" : props.cursor)};
  object-fit: ${(props) => (props.fit ? props.fit : "")};
  background-color: ${(props) => (props.bgColor ? props.bgColor : "")};
  align-self: ${(props) => props.alignSelf || "center"};
  display: ${(props) => (props.display ? props.display : "")};
`;

export default IMG;
