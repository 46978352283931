import styled from "styled-components";

const P = styled.p`
  float: ${(props) =>
    props.float ? props.float.toString().toLowerCase() : ""};
  margin-right: ${(props) => (props.mr ? props.mr : "")};
  margin-left: ${(props) => (props.ml ? props.ml : "")};
  margin-top: ${(props) => (props.mt ? props.mt : "")};
  word-break: ${(props) => props.wordBreak || ""};
  margin-bottom: ${(props) => (props.mb ? props.mb : "")};
  margin: ${(props) => (props.margin ? props.margin : "")};
  padding: ${(props) => (props.padding ? props.padding : "")};
  position: ${(props) => (props.position ? props.position : "")};
  width: ${(props) => (props.paraWidth ? props.paraWidth : "")};
  height: ${(props) => (props.paraHeight ? props.paraHeight : "")};
  background-color: ${(props) => (props.bgColor ? props.bgColor : "")};
  display: ${(props) => (props.display ? props.display : "")};
  order: ${(props) => (props.order ? props.order : "")};
  flex: ${(props) => (props.flex ? props.flex : "")};
  flex-grow: ${(props) => (props.grow ? props.grow : "")};

  color: ${(props) => (props.color ? props.color : "")};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "")};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "")};
  letter-spacing: ${(props) =>
    props.letterSpacing ? props.letterSpacing : ""};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : "")};
  text-transform: ${(props) =>
    props.textTransform ? props.textTransform : ""};
  font-family: ${(props) => (props.fontFamily ? props.fontFamily : "")};
  cursor: ${(props) => (props.disabled ? "not-allowed" : props.cursor)};
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
  pointer-events: ${(props) => (props.disabled ? "none" : "")};
  text-align: ${(props) => (props.textalign ? props.textalign : "")};
  text-overflow: ${(props) => (props.textOverflow ? props.textOverflow : "")};
  text-decoration: ${(props) =>
    props.textDecoration ? props.textDecoration : "none"};

  overflow: ${(props) => (props.overflow ? props.overflow : "")};
  white-space: ${(props) => (props.whiteSpace ? props.whiteSpace : "")};
  &:hover {
    background-color: ${(props) =>
      props.hoverBgColor ? props.hoverBgColor : ""};
    color: ${(props) =>
      props.hoverColor ? props.hoverColor : props.textColor};
    border: ${(props) => (props.borderOnHover ? props.borderOnHover : "")};
  }
`;

export default P;
