import { put, takeEvery, call, all } from "redux-saga/effects";
import { buildNotification } from "../../../config/notification";
import { store } from "react-notifications-component";
import {
  updateEmailDetails,
  updatePasswordSuccess,
  fetchingEmailToResetPassword,
  fetchingEmailToResetPasswordSuccess,
  fetchingEmailToResetPasswordFailed,
} from "../store/actions";

import { GET_EMAIL_DETAILS, UPDATE_PASSWORD_REQUEST } from "./actionTypes";
import axios from "../../../config/axios";

const postRequestDetails = {
  method: "POST",
  cache: "no-cache",
  headers: {
    "Content-Type": "application/json",
  },
};

export default function* watchResetPassword() {
  yield all([
    takeEvery(GET_EMAIL_DETAILS, getEmailToResetPassword),
    takeEvery(UPDATE_PASSWORD_REQUEST, updatePassword),
  ]);
}

function* getEmailToResetPassword(action) {
  yield put(fetchingEmailToResetPassword());
  try {
    const url = "/Login/resetPassword?token=" + action.token;
    const response = yield call(axios, url, postRequestDetails);
    if (response && response.status === 200) {
      yield put(updateEmailDetails(response.data));
      yield put(fetchingEmailToResetPasswordSuccess());
      action.callBackFunction("success");
    } else {
      yield put(fetchingEmailToResetPasswordFailed());
    }
  } catch (error) {
    yield put(fetchingEmailToResetPasswordFailed());
    action.callBackFunction("error");
  }
}

function* updatePassword(action) {
  try {
    const url = "/Login/UpdatePasswordOTP";
    postRequestDetails.data = {
      password: btoa(action.body.password),
      confirmPassword: btoa(action.body.confirmPassword),
      userName: action.body.userName,
      token: action.body.token,
    };
    const response = yield call(axios, url, postRequestDetails);
    if (response.status === 202) {
      yield put(updatePasswordSuccess());
      const notification = buildNotification({
        message: "notification.forgotPassword.changeSuccess",
        type: "success",
      });
      store.addNotification({
        ...notification,
        dismiss: {
          duration: 2000,
        },
      });
      action.callBackFunction("success");
    } else {
      yield put(updatePasswordSuccess());
    }
  } catch (err) {
    yield put(updatePasswordSuccess());
    const notification = buildNotification({
      message: "notification.errorOccurred",
      type: "danger",
    });
    store.addNotification({
      ...notification,
    });
    action.callBackFunction("error");
  }
}
