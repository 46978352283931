export const divWrapperStyle = {
  bgColor: "#f9f9f9",
  divHeight: "100%",
  divWidth: "100%",
  position: "relative",
  padding: "10px",
};
export const formDivWrapperStyle = {
  bgColor: "rgb(255, 255, 255)",
  borderRadius: "10px",
  boxShadow: "0px 2px 15px 0px rgba(0, 0, 0, 0.1)",
  divWidth: "30%",
  divHeight: "auto",
  position: "absolute",
  margin: "auto",
  left: "0",
  top: "0",
  right: "0",
  bottom: "30%",
  padding: "1% 1% 0 2%",
};

export const inputStyles = {
  padding: "5px 5px 5px 15px",
  inputWidth: "65%",
  inputHeight: "36px",
  border: "1px solid #e3eaed",
  borderRadius: "5px",
};

export const showPasswordEyeStyle = {
  position: "absolute",
  margin: "1.5% 0% 0% -7%",
  imgWidth: "20px",
  imgHeight: "20px",
};
