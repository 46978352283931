import { fromJS } from "immutable";
import moment from "moment";

import {
  SET_FROM_DATE,
  SET_TO_DATE,
  FETCH_CHART_DATA,
  NEW_USER_DATA_RECIEVED,
  EXISTING_USER_DATA_RECIEVED,
  INVOICE_STATUS_DATA_RECIEVED,
  INVOICE_COUNTRY_DATA_RECIEVED,
  INVOICE_REJECTION_DATA_RECIEVED,
  DOCUMENT_SUBMISSION_DATA_RECIEVED,
  SUPPLIER_COMPANY_DATA_RECIEVED,
  TICKET_COUNT_DATA_RECIEVED,
  DRILLDOWN_DATA_RECIEVED,
  SET_WIDGET_KEYS,
  FETCH_CHART_DATA_COMPLETE,
  FETCH_DRILLDOWN_DATA_COMPLETE,
  FETCH_DRILLDOWN_DATA,
  SET_CHART_DATA,
  CLEAR_ALL_CHARTS_DATA,
} from "./actionTypes";

const fromDate = new Date();
fromDate.setMonth(fromDate.getMonth() - 1);

export const initialState = fromJS({
  fetchingChartData: true,
  fetchingDrillDownData: true,
  fromDate: fromDate,
  toDate: moment(new Date(), "YYYY-MM-DD").toDate(),
  widgetKeys: [],
  isFilter: false,
  newUserBarData: [],
  existingUserBarData: [],
  invoiceStatusBarData: [],
  invoiceCountryBarData: [],
  invoiceRejectionBarData: [],
  docSubBarData: [],
  supplierCompanyBarData: [],
  ticketCountBarData: [],
  downloadData: [],
  downloadColumn: [],
  newUserBarDataOptions: {},
  existingUserBarDataOptions: {},
  invoiceStatusBarDataOptions: {},
  invoiceCountryBarDataOptions: {},
  invoiceRejectionBarDataOptions: {},
  docSubBarDataOptions: {},
  supplierCompanyBarDataOptions: {},
  ticketCountBarDataOptions: {},
  newUser: [],
  existingUser: [],
  invoiceStatus: [],
  invoiceCountry: [],
  invoiceRejection: [],
  documentSubmission: [],
  supplierCompany: [],
  ticketCount: [],
  userRoleList: [],
  fetchingUserRoleList: true,
  baseRoleList: [],
  fetchingBaseRoleList: true,
  chartData: [],
});

function createChartData(data, bgColor) {
  let testdata = [];
  let labels = [];
  let statusKeys = [];
  let dataset = {};
  const parsedData =
    data && data.widgetMetricsData && JSON.parse(data.widgetMetricsData);
  parsedData &&
    parsedData.length &&
    parsedData
      .sort((a, b) => a.StatusKey - b.StatusKey)
      .forEach((element) => {
        if (element) {
          testdata.push(parseFloat(element.RecordCount));
          labels.push(element.Label);
          statusKeys.push(element.StatusKey);
        }
      });
  if (data.widgetKey === "IR") {
    dataset = {
      labels: labels,
      datasets: [
        {
          data: testdata,
          fill: false,
          lineTension: 0.1,
          backgroundColor: "rgba(75,192,192,0.4)",
          borderColor: "rgba(75,192,192,1)",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "rgba(75,192,192,1)",
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgba(75,192,192,1)",
          pointHoverBorderColor: "rgba(220,220,220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          label: "Invoice Count (Rejection Reason)",
          statusKey: statusKeys,
        },
      ],
      widgetKey: data.widgetKey,
    };
  } else if (data.widgetKey === "EU") {
    dataset = {
      labels: labels,
      datasets: [
        {
          data: testdata,
          backgroundColor: bgColor,
          label: "",
          borderWidth: 0,
          statusKey: statusKeys,
        },
      ],
      widgetKey: data.widgetKey,
    };
  } else if (data.widgetKey === "IS") {
    const bgColors =
      bgColor && bgColor.length
        ? typeof bgColor[0] === "string"
          ? bgColor
          : bgColor
              .sort((a, b) => a.statusID - b.statusID)
              .map((status) => status.color)
        : [];
    dataset = {
      labels: labels,
      datasets: [
        {
          data: testdata,
          backgroundColor: bgColors,
          label: labels,
          borderWidth: 0,
          statusKey: statusKeys,
        },
      ],
      widgetKey: data.widgetKey,
    };
  } else
    dataset = {
      labels: labels,
      datasets: [
        {
          data: testdata,
          backgroundColor: bgColor,
          label: labels,
          borderWidth: 0,
          statusKey: statusKeys,
        },
      ],
      widgetKey: data.widgetKey,
    };
  return dataset;
}

function createHorizontalChartData(data, bgColor) {
  let testdata = [];
  let labels = [];
  const sortedData =
    data &&
    data.sort((a, b) =>
      parseInt(a.recordCount) < parseInt(b.recordCount) ? 1 : -1
    );
  if (sortedData && sortedData.length > 0) {
    sortedData &&
      sortedData.forEach((element) => {
        testdata.push(parseFloat(element.recordCount));
        labels.push(element.country);
      });
    const horizontalBar = {
      labels: labels,
      datasets: [
        {
          data: testdata,
          backgroundColor: bgColor,
          borderWidth: 0,
        },
      ],
    };
    return horizontalBar;
  } else {
    return {
      labels: labels,
      datasets: [],
    };
  }
}

function createHorizontalOption(data, label, key) {
  let count = [];
  data &&
    data.forEach((element) => {
      count.push(parseFloat(element.recordCount));
    });

  const max = Math.max(...count) + 5;

  const options = {
    key: key,
    label: label,
    responsive: true,
    maintainAspectRatio: false,
    title: {
      display: false,
      text:
        "Count for " +
        label +
        (key === "IR" || key === "IC"
          ? " vs Top 10 Supplier Company"
          : " vs Top 10 Country"),
    },
    excelText: `${label}`,
    //${key === "IR" || key === "IC" ? `vs Top 10` : `vs Top 10`}
    legend: {
      display: false,
      position: "bottom",
      labels: {
        fontSize: 13,
        fontColor: "rgb(81, 92, 101)",
        fontFamily: "Roboto-Regular",
        boxWidth: 0,
        padding: 15,
      },
    },
    scales: {
      xAxes: [
        {
          stacked: true,
          ticks: {
            min: 0,
            max: max,
            stepSize: 1,
            beginAtZero: true,
          },
          gridLines: {
            display: true,
          },
          offsetGridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          stacked: false,
          gridLines: {
            display: false,
          },
        },
      ],
    },
  };
  return options;
}

const reducer = (state = initialState, action) => {
  const bgColorDrilldown = [
    "#f8be8d",
    "#f49342",
    "#7cd9de",
    "#26c1c9",
    "#018eff",
    "#1e9aa0",
    "#66bbff",
    "#008eff",
    "#0071cc",
  ];
  const bgColor = [
    "#66bbff",
    "#26c1c9",
    "#b5c0ca",
    "#0071cc",
    "#008eff",
    "#7cd9de",
  ];
  switch (action.type) {
    case FETCH_CHART_DATA: {
      return state.set("fetchingChartData", true);
    }
    case FETCH_CHART_DATA_COMPLETE: {
      return state.set("fetchingChartData", false);
    }
    case SET_CHART_DATA: {
      return state.set("chartData", action.data);
    }
    case SET_FROM_DATE: {
      return state.set("fromDate", action.data);
    }
    case SET_TO_DATE: {
      return state.set("toDate", action.data);
    }
    case SET_WIDGET_KEYS: {
      return state.set("widgetKeys", action.data);
    }
    case FETCH_DRILLDOWN_DATA: {
      return state.set("fetchingDrillDownData", true);
    }
    case DRILLDOWN_DATA_RECIEVED: {
      const dataset = createHorizontalChartData(action.data, bgColorDrilldown);
      const option = createHorizontalOption(
        action.data,
        action.label,
        action.key
      );
      switch (action.key) {
        case "DS": {
          return state
            .set("docSubBarData", dataset)
            .set("docSubBarDataOptions", option);
        }
        case "IS": {
          return state
            .set("invoiceStatusBarData", dataset)
            .set("invoiceStatusBarDataOptions", option);
        }
        case "TS": {
          return state
            .set("ticketCountBarData", dataset)
            .set("ticketCountBarDataOptions", option);
        }
        case "NU": {
          return state
            .set("newUserBarData", dataset)
            .set("newUserBarDataOptions", option);
        }
        case "EU": {
          return state
            .set("existingUserBarData", dataset)
            .set("existingUserBarDataOptions", option);
        }
        case "IC": {
          return state
            .set("invoiceCountryBarData", dataset)
            .set("invoiceCountryBarDataOptions", option);
        }
        case "IR": {
          return state
            .set("invoiceRejectionBarData", dataset)
            .set("invoiceRejectionBarDataOptions", option);
        }
        case "SC": {
          return state
            .set("supplierCompanyBarData", dataset)
            .set("supplierCompanyBarDataOptions", option);
        }
        default:
          return state;
      }
    }
    case FETCH_DRILLDOWN_DATA_COMPLETE: {
      return state.set("fetchingDrillDownData", false);
    }
    case NEW_USER_DATA_RECIEVED: {
      const dataset = createChartData(action.data, bgColor);
      return state.set("newUser", dataset);
    }
    case EXISTING_USER_DATA_RECIEVED: {
      const dataset = createChartData(action.data, bgColor);
      return state.set("existingUser", dataset);
    }
    case INVOICE_STATUS_DATA_RECIEVED: {
      const colors =
        action.data.colors && action.data.colors.length
          ? action.data.colors
          : bgColor;
      const dataset = createChartData(action.data.labelData, colors);
      return state.set("invoiceStatus", dataset);
    }
    case INVOICE_COUNTRY_DATA_RECIEVED: {
      const dataset = createChartData(action.data, bgColor);
      return state.set("invoiceCountry", dataset);
    }
    case INVOICE_REJECTION_DATA_RECIEVED: {
      const dataset = createChartData(action.data, bgColor);
      return state.set("invoiceRejection", dataset);
    }
    case DOCUMENT_SUBMISSION_DATA_RECIEVED: {
      const dataset = createChartData(action.data, bgColor);
      return state.set("documentSubmission", dataset);
    }
    case SUPPLIER_COMPANY_DATA_RECIEVED: {
      const dataset = createChartData(action.data, bgColor);
      return state.set("supplierCompany", dataset);
    }
    case TICKET_COUNT_DATA_RECIEVED: {
      const dataset = createChartData(action.data, bgColor);
      return state.set("ticketCount", dataset);
    }
    case CLEAR_ALL_CHARTS_DATA: {
      return state
        .set("newuser", [])
        .set("existingUser", [])
        .set("invoiceStatus", [])
        .set("invoiceCountry", [])
        .set("invoiceRejection", [])
        .set("documentSubmission", [])
        .set("supplierCompany", [])
        .set("ticketCount", []);
    }
    default:
      return state;
  }
};
export default reducer;
