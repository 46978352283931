import {
  FETCH_REPORT_STATUS,
  INIT_FETCH_REPORT_STATUS,
  SAVE_REPORT_STATUS,
  INIT_NOTIFICATION_LIST,
  FETCH_NOTIFICATION_LIST,
  SAVE_NOTIFICATION_LIST,
} from "./actionTypes";

export const initReportStatus = (userId, fetchSuccess) => {
  return {
    type: INIT_FETCH_REPORT_STATUS,
    userId,
    callback: fetchSuccess,
  };
};

export const fetchReportStatus = data => {
  return {
    type: FETCH_REPORT_STATUS,
    data: data
  };
};

export const saveReportStatus = formData => {
  return {
    type: SAVE_REPORT_STATUS,
    data: formData
  };
};

export const initNotificationsList = (data,listFetchSuccess) => {
  return {
    type: INIT_NOTIFICATION_LIST,
    data,
    callback: listFetchSuccess,
  }
}

export const fetchNotificationList = (data) => {
  return {
    type: FETCH_NOTIFICATION_LIST,
    data
  }
}

export const saveNotificationList = (data, saveStatus) => {
  return {
    type: SAVE_NOTIFICATION_LIST,
    data,
    callback: saveStatus,
  }
}