import React from "react";
import {
  ModalDiv,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalTitle,
  ModalClose,
  ModalFooter,
  ModalSubmit,
  ModalCancel,
  ModalError,
} from "./modalStyle";

import Div from "../Div";
import { useTranslation } from "react-i18next";

const Modal = (props) => {
  const { t } = useTranslation();
  return (
    <ModalDiv
      style={{
        transform: props.show ? "translateY(0vh)" : "translateY(-100vh)",
        opacity: props.show ? "1" : "0",
      }}
      modalZIndex={props.modalZIndex}
    >
      <ModalContent
        width={props.modalWidth}
        overflow={props.overflow}
        height={props.modalHeight}
        ModalContentMargin={props.ModalContentMargin}
      >
        <ModalHeader
          backgroundColor={props.backgroundColor}
          headerColor={props.headerColor}
          headerFontSize={props.headerFontSize}
          headerFontWeight={props.headerFontWeight}
          borderRadius={props.borderRadius}
          padding={props.padding}
          headerHeight={props.headerHeight}
        >
          {props.noClose ? null : (
            <ModalClose
              closeButtonFloat={props.closeButtonFloat}
              closeButtonColor={props.closeButtonColor}
              onClick={props.close}
              closeButtonWeight={props.closeButtonWeight}
              closeButtonSize={props.closeButtonSize}
              closeButtonMT={props.closeButtonMT}
              closeButtonPosition={props.closeButtonPosition}
              closeButtonTop={props.closeButtonTop}
              closeButtonRight={props.closeButtonRight}
            >
              ×
            </ModalClose>
          )}

          <ModalTitle
            titleSize={props.titleSize}
            titleLineHeight={props.titleLineHeight}
            titleColor={props.titleColor}
            titleHeight={props.titleHeight}
            titlePT={props.titlePT}
            titlePL={props.titlePL}
            titleFontFamily={props.titleFontFamily}
          >
            {props.header}
          </ModalTitle>
        </ModalHeader>
        <ModalError errors={props.errors}>{props.errors}</ModalError>
        <ModalBody
          bodyPadding={props.bodyPadding}
          bodyHeight={props.bodyHeight}
          bodyWidth={props.bodyWidth}
          modalBodyOverflowY={props.modalBodyOverflowY}
        >
          <Div
            padding={props.pPadding}
            margin={props.containerMargin}
            position={props.position}
            divHeight={props.containerHeight}
            divWidth={props.containerWidth}
            textalign={props.bodyTextAlign}
          >
            {props.body}
          </Div>
        </ModalBody>
        {props.showButton
          ? props.showButton && (
              <ModalFooter
                footerAlign={props.footerAlign}
                footerPadding={props.footerPadding}
              >
                {props.showCancelButton
                  ? props.showCancelButton && (
                      <ModalCancel
                        onClick={props.cancelButtonClick}
                        cancelButtonColor={props.cancelButtonColor}
                        cancelButtonBorderColor={props.cancelButtonBorderColor}
                        cancelButtonWidth={props.cancelButtonWidth}
                        cancelButtonBorderRadius={
                          props.cancelButtonBorderRadius
                        }
                        cancelButtonBorder={props.cancelButtonBorder}
                        cancelButtonTextColor={props.cancelButtonTextColor}
                      >
                        {props.cancelButtonText
                          ? props.cancelButtonText
                          : t("common.cancelButton")}
                      </ModalCancel>
                    )
                  : ""}
                {props.fromMyAccnt ? null : (
                  <ModalSubmit
                    onClick={props.saveButtonClick}
                    submitButtonColor={props.submitButtonColor}
                    submitButtonBorderColor={props.submitButtonBorderColor}
                    submitBGImg={props.submitBGImg}
                    buttonWidth={props.buttonWidth}
                    disabled={props.disabled ? props.disabled : false}
                    submitTextColor={props.submitTextColor}
                    submitBR={props.submitBR}
                    submitPadding={props.submitPadding}
                  >
                    {props.submitButtonText
                      ? props.submitButtonText
                      : t("common.saveButton")}
                  </ModalSubmit>
                )}
              </ModalFooter>
            )
          : ""}
      </ModalContent>
    </ModalDiv>
  );
};

export default Modal;
