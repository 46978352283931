import { fromJS } from "immutable";

import {
  FETCHING_CLIENTS,
  FETCH_CLIENTS_LIST,
  CLIENTS_FETCHING_COMPLETE,
  FETCHING_CONTACT,
  FETCH_CONTACT_LIST,
  CONTACT_FETCHING_COMPLETE,
  CONTACT_DATA_SAVED,
  RESET_CONTACT_SAVED_STATUS,
  UPDATE_SEARCHED_CONTACT_DATA,
  SET_EDIT_CLIENT_DATA,
  INIT_GET_CLIENT_BY_ID,
  EDIT_DATA_FETCHED,
  RESET_IS_EDIT,
  INIT_SAVE_CLIENT_DATA,
  CLIENT_SAVE_FAILED,
  CLIENT_SAVED,
  INIT_SAVE_CLIENT_HELPDESK_DATA,
  CLIENT_HELPDESK_SAVED,
  CLIENT_HELPDESK_SAVE_FAILED,
  UPDATE_SEARCHED_CLIENT_DATA,
  FETCHING_COUNTRY_CONTACT,
  FETCH_COUNTRY_CONTACT_LIST,
  COUNTRY_CONTACT_FETCHING_COMPLETE,
  INIT_DELETE_CONTACT,
  CONTACT_DELETE_SUCCESS,
  CONTACT_DELETE_FAILED,
  UPDATE_SEARCHED_COUNTRY_CONTACT_DATA,
} from "./actionTypes";

export const initialState = fromJS({
  pending: true,
  clientsList: [],
  tempClientsList: [],
  fetchingContact: false,
  contactList: [],
  tempContactList: [],
  isContactSaved: false,
  isEditing: false,
  clientDataToEdit: {},
  fetchForEditComplete: false,
  clientDataSaving: false,
  fetchingEmails: false,
  emailTemplates: [],
  clientHelpdeskSaving: false,
  fetchingCountryContact: false,
  countryContactList: [],
  deleting: false,
  tempCountryContactList: [],
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_CLIENTS: {
      return state.set("pending", true);
    }

    case FETCH_CLIENTS_LIST: {
      return state
        .set("clientsList", action.data)
        .set("tempClientsList", action.data);
    }

    case CLIENTS_FETCHING_COMPLETE: {
      return state.set("pending", false);
    }

    case FETCHING_CONTACT: {
      return state.set("fetchingContact", true);
    }

    case FETCH_CONTACT_LIST: {
      return state
        .set("contactList", action.data)
        .set("tempContactList", action.data);
    }

    case CONTACT_FETCHING_COMPLETE: {
      return state.set("fetchingContact", false);
    }

    case CONTACT_DATA_SAVED: {
      return state.set("isContactSaved", true);
    }

    case RESET_CONTACT_SAVED_STATUS: {
      return state.set("isContactSaved", false);
    }

    case UPDATE_SEARCHED_CONTACT_DATA: {
      return state.set("contactList", action.data);
    }

    case UPDATE_SEARCHED_CLIENT_DATA: {
      return state.set("clientsList", action.data);
    }

    case INIT_GET_CLIENT_BY_ID: {
      return state.set("isEditing", true);
    }

    case SET_EDIT_CLIENT_DATA: {
      return state
        .set("clientDataToEdit", action.data)
        .set("fetchForEditComplete", true);
    }

    case EDIT_DATA_FETCHED: {
      return state.set("fetchForEditComplete", false);
    }

    case RESET_IS_EDIT: {
      return state.set("isEditing", false).set("clientDataToEdit", null);
    }

    case INIT_SAVE_CLIENT_DATA: {
      return state.set("clientDataSaving", true);
    }

    case CLIENT_SAVE_FAILED: {
      return state.set("clientDataSaving", false);
    }

    case CLIENT_SAVED: {
      return state.set("clientDataSaving", false);
    }

    case INIT_SAVE_CLIENT_HELPDESK_DATA: {
      return state.set("clientHelpdeskSaving", true);
    }

    case CLIENT_HELPDESK_SAVED: {
      return state.set("clientHelpdeskSaving", false);
    }

    case CLIENT_HELPDESK_SAVE_FAILED: {
      return state.set("clientHelpdeskSaving", false);
    }

    case FETCHING_COUNTRY_CONTACT: {
      return state.set("fetchingCountryContact", true);
    }

    case FETCH_COUNTRY_CONTACT_LIST: {
      return state
        .set("countryContactList", action.data)
        .set("tempCountryContactList", action.data);
    }

    case COUNTRY_CONTACT_FETCHING_COMPLETE: {
      return state.set("fetchingCountryContact", false);
    }

    case INIT_DELETE_CONTACT: {
      return state.set("deleting", true);
    }

    case CONTACT_DELETE_SUCCESS: {
      return state.set("deleting", false);
    }

    case CONTACT_DELETE_FAILED: {
      return state.set("deleting", false);
    }

    case UPDATE_SEARCHED_COUNTRY_CONTACT_DATA: {
      return state.set("countryContactList", action.data);
    }

    default:
      return state;
  }
};
export default reducer;
