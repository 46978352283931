import React from "react";
import { useTranslation } from "react-i18next";
import { pure } from "recompose";
import styled from "styled-components";
const Option = styled.option`
  display: ${(props) => (props.display ? props.display : "")};
  padding: 2px;
`;
const Options = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <Option value="">
        {props.placeholder ? t(props.placeholder) : t("common.globalSearch")}
      </Option>
      {props.options && props.options.length > 0
        ? props.options.map((element, key) => {
            return (
              <React.Fragment key={element.id ? element.id : key}>
                <Option
                  display={element.exclude ? "none" : "block"}
                  value={
                    typeof element.id !== "undefined"
                      ? element.id
                      : element.value
                  }
                  disabled={element.disabled ? element.disabled : ""}
                >
                  {element.value
                    ? element.value
                    : typeof element === "string"
                    ? element
                    : ""}
                </Option>
              </React.Fragment>
            );
          })
        : []}
    </>
  );
};

export default pure(Options);
