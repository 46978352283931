import styled from "styled-components";

export const Overlay = styled.div`
  z-index: 100;
  position: absolute;
  overflow: hidden;
  height: ${(props) => props.height};
  width: 100%;
  top: 8px;
  left: 0;
  background-color: rgba(255, 255, 255, 0.4);
  transition: opacity 0.15s linear;
  margin: 48px 0 20px 0;
  display: flex;
  flex-direction: column;
  visibility: ${(props) => (props.show ? "visible" : "hidden")};
`;

export const FilterPanelSection = styled.div`
  max-height: 500px;
  min-width: 400px;
  border-radius: 6px;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgb(227, 234, 237);
  z-index: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  flex-grow: ${(props) => (props.vertical ? "0" : "1")};
  flex-shrink: 1;
  flex-basis: auto;
  transition: all 0.5s ease-in-out;
`;
