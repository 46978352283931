import React from "react";
import styled from "styled-components";

const StyledInput = styled.input.attrs((props) => ({
  maxlength: props.maxLength ? props.maxLength : "",
  min: props.min ? props.min : "",
}))`
  width: ${(props) => (props.inputWidth ? props.inputWidth : "")};
  height: ${(props) => (props.inputHeight ? props.inputHeight : "")};
  color: ${(props) => (props.inputColor ? props.inputColor : "")};
  background-color: ${(props) => (props.bgColor ? props.bgColor : "")};
  font-size: ${(props) => (props.textSize ? props.textSize : "12px")};
  font-family: ${(props) => (props.family ? props.family : "")};
  font-weight: ${(props) => (props.textWeight ? props.textWeight : "")};
  letter-spacing: ${(props) => (props.ls ? props.ls : "")};
  margin: ${(props) => (props.margin ? props.margin : "")};
  margin-top: ${(props) => (props.mt ? props.mt : "")};
  margin-bottom: ${(props) => (props.mb ? props.mb : "")};
  margin-left: ${(props) => (props.ml ? props.ml : "")};
  margin-right: ${(props) => (props.mr ? props.mr : "")};
  border: ${(props) => (props.border ? props.border : "")};
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : "")};
  position: ${(props) => (props.position ? props.position : "")};
  outline: ${(props) => (props.outlineStyle ? props.outlineStyle : "")};
  padding: ${(props) => (props.padding ? props.padding : "10px")};
  padding-right: ${(props) => (props.pr ? props.pr : "")};
  padding-left: ${(props) => (props.pl ? props.pl : "")};
  padding-top: ${(props) => (props.pt ? props.pt : "")};
  padding-bottom: ${(props) => (props.pb ? props.pb : "")};
  line-height: ${(props) => (props.lh ? props.lh : "")};
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "initial")};
  flex: ${(props) => props.flex || ""};
  flex-grow: ${(props) => (props.grow ? props.grow : "")};
  align-self: ${(props) => props.alignSelf || ""};

  box-sizing: border-box;
  ::placeholder {
    color: ${(props) => (props.phTextColor ? props.phTextColor : "")};
    font-size: ${(props) => (props.textSize ? props.textSize : "12px")};
    font-family: ${(props) => (props.family ? props.family : "")};
    text-transform: ${(props) => props.textTransform || "capitalize"};
  }
  &:focus {
    outline: none;
    border: ${(props) => (props.borderOnFocus ? props.borderOnFocus : "")};
    box-shadow: ${(props) =>
      props.boxShadowOnFocus ? props.boxShadowOnFocus : ""};
  }
`;

const Input = (props) => {
  const onInputChange = (e) => {
    let inputValue = e.target.value;
    const strippedHtml = inputValue.replace(/<[^>]+>/g, "");
    e.target.value = strippedHtml;

    props.onChange && props.onChange(e);
  };
  return <StyledInput {...props} onChange={onInputChange} />;
};

export default Input;
