import viewHelpIcon from "../../../assets/images/Header/Help.png";
import appDrawerIcon from "../../../assets/images/Header/DrawerIcon.png";
import { getRoutePathByKey } from "../../../helper";

export const headerIcons = [
  {
    icon: viewHelpIcon,
    alt: "Open Help",
    action: "gotoViewHelp",
  },
  {
    icon: appDrawerIcon,
    alt: "Show all Apps",
  },
];

export const myAccountURL = getRoutePathByKey("editProfile");
