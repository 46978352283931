import { put, takeEvery, call, all } from "redux-saga/effects";
import { buildNotification } from "../../../config/notification";
import { store } from "react-notifications-component";
import axios from "../../../config/axios";

import {
  fetchingClients,
  fetchClientsList,
  ClientsFetchingComplete,
  fetchingContact,
  fetchContactList,
  ContactFetchingComplete,
  ContactDataSaved,
  setDataForEditClient,
  clientSaveFailed,
  clientSaved,
  clientHelpdeskSaved,
  clientHelpdeskSaveFailed,
  fetchingCountryContact,
  fetchCountryContactList,
  countryContactFetchingComplete,
  contactDeleteSuccess,
  contactDeleteFailed,
} from "../store/actions";

import {
  INIT_CLIENTS_LIST,
  INIT_CONTACT_LIST,
  INIT_CONTACT_BY_ID,
  SUBMIT_CONTACT_DATA,
  INIT_DELETE_CONTACT,
  INIT_GET_CLIENT_BY_ID,
  INIT_SAVE_CLIENT_DATA,
  INIT_SAVE_CLIENT_HELPDESK_DATA,
  INIT_COUNTRY_CONTACT_DATA,
} from "./actionTypes";
import {
  getRequestDetails,
  postRequestDetails,
} from "../../../config/requestHeaders";

export default function* watchClientManagement() {
  yield all([
    takeEvery(INIT_CLIENTS_LIST, initClientsListSaga),
    takeEvery(INIT_CONTACT_LIST, initContactListSaga),
    takeEvery(INIT_CONTACT_BY_ID, initContactByIdSaga),
    takeEvery(SUBMIT_CONTACT_DATA, submitContactDataSaga),
    takeEvery(INIT_DELETE_CONTACT, initDeleteContactDataSaga),
    takeEvery(INIT_GET_CLIENT_BY_ID, initGetClientByIdDataSaga),
    takeEvery(INIT_SAVE_CLIENT_DATA, initSaveClientDataSaga),

    takeEvery(INIT_SAVE_CLIENT_HELPDESK_DATA, initSaveClientHelpdeskSaga),
    takeEvery(INIT_COUNTRY_CONTACT_DATA, initCountryContactListSaga),
  ]);
}

function* initClientsListSaga() {
  yield put(fetchingClients());
  const url = "/ClientManagement/ClientList";
  try {
    const response = yield call(axios, url, getRequestDetails);
    yield put(fetchClientsList(response.data));
    yield put(ClientsFetchingComplete());
  } catch (error) {
    const notification = buildNotification({
      message: "notification.errorOccurred",
      type: "",
    });
    store.addNotification({
      ...notification,
    });
    yield put(ClientsFetchingComplete());
  }
}

function* initContactListSaga() {
  yield put(fetchingContact());
  const url = "/ClientManagement/ContactList";
  try {
    const response = yield call(axios, url, getRequestDetails);
    if (response && response.status === 200) {
      yield put(fetchContactList(response.data));
      yield put(ContactFetchingComplete());
    } else {
      yield put(ContactFetchingComplete());
    }
  } catch (error) {
    const notification = buildNotification({
      message: "notification.errorOccurred",
      type: "danger",
    });
    store.addNotification({
      ...notification,
    });
    yield put(ContactFetchingComplete());
  }
}

function* initContactByIdSaga(action) {
  const url = "/ClientManagement/ContactById?contactId=" + action.contactId;
  try {
    const response = yield call(axios, url, getRequestDetails);
    if (response) {
      if (action.callback) {
        action.callback(response.data);
      }
    }
  } catch (error) {
    const notification = buildNotification({
      message: "notification.errorOccurred",
      type: "danger",
    });
    store.addNotification({
      ...notification,
    });
  }
}

function* submitContactDataSaga(action) {
  yield (postRequestDetails.data = {
    ...action.data,
  });
  const url = "/ClientManagement/AddContact";
  try {
    const response = yield call(axios, url, postRequestDetails);
    if (response && response.status === 202) {
      yield put(ContactDataSaved());
      const notification = buildNotification({
        message: "notification.clientMgmt.contactSaved",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
    }
  } catch (error) {
    const notification = buildNotification({
      message: "notification.errorOccurred",
      type: "danger",
    });
    store.addNotification({
      ...notification,
    });
  }
}

function* initDeleteContactDataSaga(action) {
  yield (postRequestDetails.data = {
    ...action.dataToDelete,
  });
  const url = "/ClientManagement/DeleteContact";
  try {
    const response = yield call(axios, url, postRequestDetails);
    if (response && response.status === 201) {
      yield put(contactDeleteSuccess());
      const notification = buildNotification({
        message: "notification.clientMgmt.contactDeleted",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
      action.callback();
    }
  } catch (error) {
    yield put(contactDeleteFailed());
    const notification = buildNotification({
      message: "notification.errorOccurred",
      type: "danger",
    });
    store.addNotification({
      ...notification,
    });
  }
}

function* initGetClientByIdDataSaga(action) {
  let sessionDetails = JSON.parse(sessionStorage.getItem("sessionDetails"));
  let languageID = sessionDetails.languageId;
  const url =
    `/ClientManagement/ClientById?clientId=${action.clientId}` +
    "&languageID=" +
    languageID;
  try {
    const response = yield call(axios, url, getRequestDetails);
    if (response && response.status === 200) {
      let tempData = response.data;
      for (var key in tempData.client) {
        if (tempData.client[key] === null) {
          tempData.client[key] = "";
        }
      }
      yield put(setDataForEditClient(tempData));
    }
  } catch (error) {
    const notification = buildNotification({
      message: "notification.errorOccurred",
      type: "danger",
    });
    store.addNotification({
      ...notification,
    });
  }
}

function* initSaveClientDataSaga(action) {
  const url = "/ClientManagement/AddClient";
  try {
    postRequestDetails.data = action.formData;
    const response = yield call(axios, url, postRequestDetails);
    if (response && response.status === 202) {
      yield put(clientSaved());
      const notification = buildNotification({
        message: "notification.clientMgmt.contactUpdated",
        type: "success",
      });
      store.addNotification({
        ...notification,
        dismiss: {
          duration: 2000,
        },
      });
    } else {
      yield put(clientSaveFailed());
    }
  } catch (error) {
    yield put(clientSaveFailed());
    const notification = buildNotification({
      message: "notification.errorOccurred",
      type: "danger",
    });
    store.addNotification({
      ...notification,
    });
  }
}

function* initSaveClientHelpdeskSaga(action) {
  const url = "/ClientManagement/SaveContactCountry";
  try {
    postRequestDetails.data = action.data;
    const response = yield call(axios, url, postRequestDetails);
    if (response && response.status === 202) {
      yield put(clientHelpdeskSaved());
      const notification = buildNotification({
        message: "notification.adminLogs.contactSaved",
        type: "success",
      });
      store.addNotification({
        ...notification,
        dismiss: {
          duration: 2000,
        },
      });
      action.callback();
    } else {
      yield put(clientHelpdeskSaveFailed());
    }
  } catch (error) {
    const notification = buildNotification({
      message: "notification.errorOccurred",
      type: "danger",
    });
    store.addNotification({
      ...notification,
    });
    yield put(clientHelpdeskSaveFailed());
  }
}

function* initCountryContactListSaga() {
  yield put(fetchingCountryContact());
  const url = "/ClientManagement/countryContacts";
  try {
    const response = yield call(axios, url, getRequestDetails);
    if (response && response.status === 200) {
      yield put(fetchCountryContactList(response.data));
      yield put(countryContactFetchingComplete());
    } else {
      yield put(ContactFetchingComplete());
    }
  } catch (error) {
    const notification = buildNotification({
      message: "notification.errorOccurred",
      type: "danger",
    });
    store.addNotification({
      ...notification,
    });
    yield put(countryContactFetchingComplete());
  }
}
