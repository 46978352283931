import React, { useState } from "react";

export const AppContext = React.createContext({
  url: null,
  updateBackURL: (url) => {},
  buttons: [],
  updateButtons: (buttonArray) => {},
  hasSearch: false,
  searchConfig: {},
  updateSearch: (boolValue, config) => {},
  hasFilter: false,
  filterConfig: {},
  updateFilter: (boolValue, config, enableLogicalSearch) => {},
  showQuickAccess: false,
  updateQuickAccessVisibility: (status) => {},
  dateRangeConfig: {},
  updateDateRange: (boolValue, funcRef) => {},
});

export default (props) => {
  const [backButton, setBackButton] = useState(null);
  const [buttons, setButtons] = useState([]);

  const [hasSearch, setHasSearch] = useState(false);
  const [search, setSearch] = useState({});

  const [hasFilter, setHasFilter] = useState(false);
  const [filter, setFilter] = useState({});
  const [enableLogicalSearch, setEnableLogicalSearch] = useState(false);

  const [showQuickAccess, setShowQuickAccess] = useState(false);

  const [dateRangeConfig, setDateRangeConfig] = useState({});

  const updateBackURL = (value) => {
    setBackButton(value);
  };
  const updateButtonList = (value) => {
    setButtons(value);
  };
  const updateSearchDisplay = (status, config) => {
    setHasSearch(status);
    setSearch(config);
  };
  const updateFilterDisplay = (status, config, enableLogicalSearch = false) => {
    setHasFilter(status);
    setFilter(config);
    setEnableLogicalSearch(enableLogicalSearch);
  };
  const updateQuickAccessVisibility = (value) => {
    setShowQuickAccess(value);
  };
  const updateDateRangeDisplay = (config) => {
    setDateRangeConfig(config);
  };

  return (
    <AppContext.Provider
      value={{
        url: backButton,
        buttons,
        searchConfig: search,
        filterConfig: filter,
        hasSearch,
        hasFilter,
        enableLogicalSearch,
        showQuickAccess,
        dateRangeConfig,
        updateButtons: updateButtonList,
        updateSearch: updateSearchDisplay,
        updateFilter: updateFilterDisplay,
        updateBackURL,
        updateQuickAccessVisibility,
        updateDateRange: updateDateRangeDisplay,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};
