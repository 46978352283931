export const INIT_CHANGE_PASSWORD = "INIT_CHANGE_PASSWORD";
export const CHANGING_PASSWORD = "CHANGING_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILED = "CHANGE_PASSWORD_FAILED";

export const INIT_UPDATE_PASSWORD_ON_LOGIN = "INIT_UPDATE_PASSWORD_ON_LOGIN";
export const UPDATE_PASSWORD_ON_LOGIN_SUCCESS =
  "UPDATE_PASSWORD_ON_LOGIN_SUCCESS";
export const UPDATE_PASSWORD_ON_LOGIN_FAILED =
  "UPDATE_PASSWORD_ON_LOGIN_FAILED";
