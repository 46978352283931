import {
  INIT_EMAIL_INVITE,
  EMAIL_INVITE_SENT,
  INIT_LIST_FETCH,
  FETCHING_LIST,
  FETCH_LIST,
  INIT_EDIT_LIST_FETCH,
  FETCHING_EDIT_LIST,
  FETCH_EDIT_LIST,
  INIT_TAKE_OWNERSHIP,
  FETCH_OWNERSHIP_STATUS,
  SET_OWNERSHIP_DATA,
  INIT_CONTACT_DETAILS,
  FETCHING_CONTACT_DETAILS,
  FETCH_CONTACT_DETAILS,
  INIT_ATTRIBUTES_DETAILS,
  FETCHING_ATTRIBUTES_DETAILS,
  FETCH_ATTRIBUTES_DETAILS,
  SAVE_CONTACT,
  REMOVE_CONTACT,
  SAVE_DYNAMIC_TABS_DATA,
  INIT_LOCATION_DETAILS,
  FETCHING_LOCATION_DETAILS,
  FETCH_LOCATION_DETAILS,
  SAVE_LOCATION,
  REMOVE_LOCATION,
  INIT_ATTACHMENT_DETAILS,
  FETCHING_ATTACHMENT_DETAILS,
  FETCH_ATTACHMENT_DETAILS,
  SAVE_ATTACHMENT,
  REMOVE_ATTACHMENT,
  FETCHING_ATTRIBUTES_DETAILS_SUCCESS,
  FETCHING_EDIT_LIST_SUCCESS,
  GET_WORKFLOW_STEP_DATA,
  SET_WORKFLOW_STEP_DATA,
  SAVE_BULK_LOCATIONS,
  GET_DIFF_DATA,
  SET_DIFF_DATA,
  SAVE_WORKFLOW_STEP_DATA,
  INIT_NEW_COMPANY_CREATION,
  FETCH_NEW_COMPANY_CREATION,
  DOWNLOAD_VMD_ATTACHMENT,
  INIT_TAKE_PROXY,
  TAKE_PROXY_STATUS,
  INIT_REJECT,
  INIT_REJECT_REMOVE,
  INIT_WOKFLOW_STATE,
  SET_WORKFLOW_STATE,
  GET_CONTACT_DIFF_DATA,
  SET_CONTACT_DIFF_DATA,
  GET_LOCATION_DIFF_DATA,
  SET_LOCATION_DIFF_DATA,
  INIT_INVITE_REFRESH,
  INVITE_REFRESH_COMPLETE,
  INIT_SAVE_LOGO,
  SAVE_LOGO_COMPLETE,
  INIT_AUDIT_TRAIL_LIST,
  SET_AUDIT_TRAIL_LIST,
  INIT_AUDIT_TRAIL_DATA,
  SET_AUDIT_TRAIL_DATA,
  INIT_GET_LOGO,
  SET_LOGO,
  INIT_CLEAR_AUDIT_TRAIL,
  CLEAR_AUDIT_TRAIL_SUCCESS,
  UPDATE_LIST_DATA,
  UPDATE_AUDIT_TRAIL_LIST,
  INIT_FETCH_FLEX_FIELDS,
  SET_FLEX_FIELDS,
  INIT_COMBINED_SAGA_CALL,
  INIT_CLEAR_STORE,
  REJECT_SUCCESS,
  REJECT_REMOVE_SUCCESS,
  FETCH_EDIT_DATA_BY_NAME,
} from "./actionTypes";

export const initEmailInvite = (data) => {
  return {
    type: INIT_EMAIL_INVITE,
    data,
  };
};

export const emailInviteSent = () => {
  return {
    type: EMAIL_INVITE_SENT,
  };
};

export const initList = (data) => {
  return {
    type: INIT_LIST_FETCH,
    data,
  };
};

export const fetchingList = () => {
  return {
    type: FETCHING_LIST,
  };
};

export const fetchList = (data) => {
  return {
    type: FETCH_LIST,
    data,
  };
};

export const updateListData = (data, statusCounts, totalCount) => {
  return {
    type: UPDATE_LIST_DATA,
    data,
    statusCounts,
    totalCount,
  };
};

export const initEditList = (id, fetchSuccess, flag) => {
  return {
    type: INIT_EDIT_LIST_FETCH,
    id,
    callback: fetchSuccess,
    flag,
  };
};

export const fetchingEditList = () => {
  return {
    type: FETCHING_EDIT_LIST,
  };
};

export const fetchEditList = (data) => {
  return {
    type: FETCH_EDIT_LIST,
    data,
  };
};

export const fetchingEditListSuccess = () => {
  return {
    type: FETCHING_EDIT_LIST_SUCCESS,
  };
};

export const fetchEditDataByName = (name, callback) => {
  return {
    type: FETCH_EDIT_DATA_BY_NAME,
    name,
    callback,
  }
}

export const initTakeOwnership = (id, ownershipStatus) => {
  return {
    type: INIT_TAKE_OWNERSHIP,
    id,
    callback: ownershipStatus,
  };
};

export const fetchOwnershipStatus = () => {
  return {
    type: FETCH_OWNERSHIP_STATUS,
  };
};

export const setOwnershipMessage = (data) => {
  return {
    type: SET_OWNERSHIP_DATA,
    data,
  };
};

export const initAttributesDetails = (fetchAttrSuccess) => {
  return {
    type: INIT_ATTRIBUTES_DETAILS,
    callback: fetchAttrSuccess,
  };
};

export const fetchingAttributesDetails = () => {
  return {
    type: FETCHING_ATTRIBUTES_DETAILS,
  };
};

export const fetchingAttributesDetailsSuccess = () => {
  return {
    type: FETCHING_ATTRIBUTES_DETAILS_SUCCESS,
  };
};

export const fetchAttributesDetails = (data) => {
  return {
    type: FETCH_ATTRIBUTES_DETAILS,
    data,
  };
};

export const initContactDetails = (id, supId, fetchDetailsSuccess) => {
  return {
    type: INIT_CONTACT_DETAILS,
    id,
    supId,
    callback: fetchDetailsSuccess,
  };
};

export const fetchingContactDetails = () => {
  return {
    type: FETCHING_CONTACT_DETAILS,
  };
};

export const fetchContactDetails = (data) => {
  return {
    type: FETCH_CONTACT_DETAILS,
    data,
  };
};

export const saveContact = (data, supId) => {
  return {
    type: SAVE_CONTACT,
    data,
    supId,
  };
};

export const removeContacts = (data, supId) => {
  return {
    type: REMOVE_CONTACT,
    data,
    supId,
  };
};

export const initLocationDetails = (id, supId, fetchDetailsSuccess) => {
  return {
    type: INIT_LOCATION_DETAILS,
    id,
    supId,
    callback: fetchDetailsSuccess,
  };
};

export const fetchingLocationDetails = () => {
  return {
    type: FETCHING_LOCATION_DETAILS,
  };
};

export const fetchLocationDetails = (data) => {
  return {
    type: FETCH_LOCATION_DETAILS,
    data,
  };
};

export const saveLocation = (data, supId) => {
  return {
    type: SAVE_LOCATION,
    data,
    supId,
  };
};

export const removeLocations = (data, supId) => {
  return {
    type: REMOVE_LOCATION,
    data,
    supId,
  };
};

export const saveBulkLocations = (data, supId) => {
  return {
    type: SAVE_BULK_LOCATIONS,
    data,
    supId,
  };
};

export const initAttachmentDetails = (id, fetchDetailsSuccess) => {
  return {
    type: INIT_ATTACHMENT_DETAILS,
    id,
    callback: fetchDetailsSuccess,
  };
};

export const fetchingAttachmentDetails = () => {
  return {
    type: FETCHING_ATTACHMENT_DETAILS,
  };
};

export const fetchAttachmentDetails = (data) => {
  return {
    type: FETCH_ATTACHMENT_DETAILS,
    data,
  };
};

export const saveAttachment = (data, supId) => {
  return {
    type: SAVE_ATTACHMENT,
    data,
    supId,
  };
};

export const removeAttachments = (data, supId) => {
  return {
    type: REMOVE_ATTACHMENT,
    data,
    supId,
  };
};

export const downloadVmdAttachment = (attachId, supId, fileName) => {
  return {
    type: DOWNLOAD_VMD_ATTACHMENT,
    attachId,
    supId,
    fileName,
  };
};

export const saveDynamicTabDetails = (data, supId) => {
  return {
    type: SAVE_DYNAMIC_TABS_DATA,
    data,
    supId,
  };
};

export const getWorkFlowStepData = (supId, callback) => {
  return {
    type: GET_WORKFLOW_STEP_DATA,
    supId,
    callback,
  };
};

export const setWorkFlowStepData = (data) => {
  return {
    type: SET_WORKFLOW_STEP_DATA,
    data,
  };
};

export const saveWorkflowStepData = (stepNo, supId, callback) => {
  return {
    type: SAVE_WORKFLOW_STEP_DATA,
    stepNo,
    //initialStep,
    supId,
    callback,
  };
};

export const getDiff = (id) => {
  return {
    type: GET_DIFF_DATA,
    id,
  };
};

export const setDiffData = (data) => {
  return {
    type: SET_DIFF_DATA,
    data,
  };
};

export const initNewCompanyCreation = (data) => {
  return {
    type: INIT_NEW_COMPANY_CREATION,
    data,
  };
};

export const fetchNewCompanyCreation = () => {
  return {
    type: FETCH_NEW_COMPANY_CREATION,
  };
};

export const initTakeProxy = (id, callback) => {
  return {
    type: INIT_TAKE_PROXY,
    id,
    callback,
  };
};

export const takeProxyStatus = (status) => {
  return {
    type: TAKE_PROXY_STATUS,
  };
};

export const initRejectCompany = (comment, id) => {
  return {
    type: INIT_REJECT,
    comment,
    id,
  };
};

export const rejectCompanySuccess = () => {
  return {
    type: REJECT_SUCCESS,
  };
};

export const initRejectRemoveCompany = (comment, id) => {
  return {
    type: INIT_REJECT_REMOVE,
    comment,
    id,
  };
};

export const rejectRemoveCompanySuccess = () => {
  return {
    type: REJECT_REMOVE_SUCCESS,
  };
};

export const initWorkflowState = () => {
  return {
    type: INIT_WOKFLOW_STATE,
  };
};

export const setWorkflowState = (data) => {
  return {
    type: SET_WORKFLOW_STATE,
    data,
  };
};

export const getContactDiffData = (supId, contactId) => {
  return {
    type: GET_CONTACT_DIFF_DATA,
    supId,
    contactId,
  };
};

export const setContactDiffData = (data) => {
  return {
    type: SET_CONTACT_DIFF_DATA,
    data,
  };
};

export const getLocationDiffData = (supId, locationId) => {
  return {
    type: GET_LOCATION_DIFF_DATA,
    supId,
    locationId,
  };
};

export const setLocationDiffData = (data) => {
  return {
    type: SET_LOCATION_DIFF_DATA,
    data,
  };
};

export const initInviteRefresh = (data, callback) => {
  return {
    type: INIT_INVITE_REFRESH,
    data,
    callback,
  };
};

export const inviteRefreshComplete = (data) => {
  return {
    type: INVITE_REFRESH_COMPLETE,
    data,
  };
};

export const initSaveLogo = (data, supId) => {
  return {
    type: INIT_SAVE_LOGO,
    data,
    supId,
  };
};

export const saveLogoComplete = () => {
  return {
    type: SAVE_LOGO_COMPLETE,
  };
};

export const initAuditTrailList = (data) => {
  return {
    type: INIT_AUDIT_TRAIL_LIST,
    data,
  };
};

export const setAuditTrailList = (data) => {
  return {
    type: SET_AUDIT_TRAIL_LIST,
    data,
  };
};

export const updateAuditTrailList = (data) => {
  return {
    type: UPDATE_AUDIT_TRAIL_LIST,
    data,
  };
};

export const getAuditDataById = (id) => {
  return {
    type: INIT_AUDIT_TRAIL_DATA,
    id,
  };
};

export const setAuditDataById = (data) => {
  return {
    type: SET_AUDIT_TRAIL_DATA,
    data,
  };
};

export const initClearAuditTrail = () => {
  return {
    type: INIT_CLEAR_AUDIT_TRAIL,
  };
};

export const clearAuditTrailSuccess = () => {
  return {
    type: CLEAR_AUDIT_TRAIL_SUCCESS,
  };
};

export const initGetLogo = (id) => {
  return {
    type: INIT_GET_LOGO,
    id,
  };
};

export const setLogo = (data) => {
  return {
    type: SET_LOGO,
    data,
  };
};

export const initFlexFields = () => {
  return {
    type: INIT_FETCH_FLEX_FIELDS,
  };
};

export const setFlexFields = (data) => {
  return {
    type: SET_FLEX_FIELDS,
    data,
  };
};

export const initCombinedSagaCall = (id, callback) => {
  return {
    type: INIT_COMBINED_SAGA_CALL,
    id,
    callback,
  };
};

export const initClearStore = () => {
  return {
    type: INIT_CLEAR_STORE,
  };
};
