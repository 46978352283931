import React from "react";

import { wrapperDivStyle } from "./style";
import ContactSupportModal from "../../Popup";
import Div from "../../Div";
import Span from "../../Span";
import { useTranslation } from "react-i18next";

const ContactSupport = (props) => {
  const { t } = useTranslation();
  let content = (
    <Div
      style={{
        ...wrapperDivStyle,
        padding: "12px 0 30px 0",
        lineHeight: "25px",
        fontFamily: "graphik-light",
      }}
    >
      {t("common.contactEmail")}
      <Span fontFamily="graphik-bold">
        {props.contactSupportEmail
          ? props.contactSupportEmail
          : " helpdesk@test.com "}
      </Span>
      {t("common.ContactNumber")}
      <Span fontFamily="graphik-bold">
        {props.contactSupportNo ? props.contactSupportNo : " 0123456789"}
      </Span>
      .
    </Div>
  );
  return (
    <ContactSupportModal
      modalWidth="50%"
      containerHeight="auto"
      header= {t("common.contactSupportTitle")}
      body={content}
      show={props.show}
      close={props.close}
    />
  );
};

export default ContactSupport;
