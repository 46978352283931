import {
  INIT_GET_PAGE_ELEMENTS,
  FETCHING_PAGE_ELEMENTS,
  FETCH_PAGE_ELEMENTS,
  GET_PAGE_ELEMENTS_COMPLETED,
  GET_ACCESS_LEVEL_COMPLETED,
  FETCH_ACCESS_LEVEL,
  FETCHING_ACCESS_LEVEL,
  INIT_GET_ACCESS_LEVEL,
  INIT_PROFILE_ROLE_SAVE,
  SAVE_PROFILE_ROLE,
  FORM_SUBMISSION_STATUS,
  RESET_FORM_SUBMISSION_STATUS,
  INIT_PROFILE_PERMISSION_EDIT,
  SET_PROFILE_PERMISSION_EDIT_DATA,
  INIT_USER_ROLES_DELETE,
  DELETE_SUCCESS,
  DELETE_FAILED,
  SAVE_PROFILE_ROLE_FAILED,
  SET_PROFILE_PERMISSION_EDIT_DATA_FAILED,
  GET_LOGIN_GDPR_OPTIONS_COMPLETED,
  FETCH_LOGIN_GDPR_OPTIONS,
  FETCHING_LOGIN_GDPR_OPTIONS,
  INIT_GET_LOGIN_GDPR_OPTIONS,
  GET_APPLICATION_GDPR_OPTIONS_COMPLETED,
  FETCHING_APPLICATION_GDPR_OPTIONS,
  FETCH_APPLICATION_GDPR_OPTIONS,
  INIT_GET_APPLICATION_GDPR_OPTIONS,
} from "./actionTypes";

export const initPageElementsList = (payload) => {
  return {
    type: INIT_GET_PAGE_ELEMENTS,
    payload,
  };
};
export const fetchingPageElements = () => {
  return {
    type: FETCHING_PAGE_ELEMENTS,
  };
};

export const fetchPageElements = (data) => {
  return {
    type: FETCH_PAGE_ELEMENTS,
    data: data,
  };
};

export const PageElementsFetchingComplete = () => {
  return {
    type: GET_PAGE_ELEMENTS_COMPLETED,
  };
};

export const initAccessLevel = () => {
  return {
    type: INIT_GET_ACCESS_LEVEL,
  };
};
export const fetchingAccessLevel = () => {
  return {
    type: FETCHING_ACCESS_LEVEL,
  };
};

export const fetchAccessLevel = (data) => {
  return {
    type: FETCH_ACCESS_LEVEL,
    data: data,
  };
};

export const accessLevelFetchingComplete = () => {
  return {
    type: GET_ACCESS_LEVEL_COMPLETED,
  };
};

export const initProfileRoleSave = (
  formdata,
  userId,
  isEditMode,
  saveSuccess,
  saveFailure
) => {
  return {
    type: INIT_PROFILE_ROLE_SAVE,
    formdata,
    isEditMode,
    userId,
    saveSuccess,
    saveFailure,
  };
};

export const profileRoleSave = () => {
  return {
    type: SAVE_PROFILE_ROLE,
  };
};
export const saveProfileRoleFailed = () => {
  return {
    type: SAVE_PROFILE_ROLE_FAILED,
  };
};
export const formSubmissionStatusAction = (data) => {
  return {
    type: FORM_SUBMISSION_STATUS,
    data: data,
  };
};
export const resetFormSubmissionStatus = () => {
  return {
    type: RESET_FORM_SUBMISSION_STATUS,
  };
};

export const getDataForEdit = (id, fetchSuccess) => {
  return {
    type: INIT_PROFILE_PERMISSION_EDIT,
    id,
    callback: fetchSuccess,
  };
};
export const setDataForEdit = (data) => {
  return {
    type: SET_PROFILE_PERMISSION_EDIT_DATA,
    data,
  };
};
export const setDataForEditFailed = () => {
  return {
    type: SET_PROFILE_PERMISSION_EDIT_DATA_FAILED,
  };
};

export const initUserRolesDelete = (id, userId, deleteSuccess) => {
  return {
    type: INIT_USER_ROLES_DELETE,
    id,
    userId,
    callback: deleteSuccess,
  };
};
export const deleteSuccess = (id) => {
  return {
    type: DELETE_SUCCESS,
    id,
  };
};
export const deleteFailed = () => {
  return {
    type: DELETE_FAILED,
  };
};

export const initFetchApplicationGDPROptions = () => {
  return {
    type: INIT_GET_APPLICATION_GDPR_OPTIONS,
  };
};
export const fetchingApplicationGDPROptions = () => {
  return {
    type: FETCHING_APPLICATION_GDPR_OPTIONS,
  };
};

export const fetchApplicationGDPROptions = (data) => {
  return {
    type: FETCH_APPLICATION_GDPR_OPTIONS,
    data: data,
  };
};
export const ApplicationGDPROptionsFetchingComplete = () => {
  return {
    type: GET_APPLICATION_GDPR_OPTIONS_COMPLETED,
  };
};

export const initFetchLoginGDPROptions = () => {
  return {
    type: INIT_GET_LOGIN_GDPR_OPTIONS,
  };
};
export const fetchingLoginGDPROptions = () => {
  return {
    type: FETCHING_LOGIN_GDPR_OPTIONS,
  };
};

export const fetchLoginGDPROptions = (loginGDPROptions) => {
  return {
    type: FETCH_LOGIN_GDPR_OPTIONS,
    loginGDPROptions,
  };
};

export const LoginGDPROptionsFetchingComplete = () => {
  return {
    type: GET_LOGIN_GDPR_OPTIONS_COMPLETED,
  };
};
