import React from "react";
import Button from "../../../Button/Button";
import Div from "../../../Div";
import IMG from "../../../IMG";
import closeIcon from "../../../../assets/images/cross-button.png";
import { flexCol, flexShrink, flexGrow, flexRow } from "../../../Layout/style";
import { pure } from "recompose";
import { useTranslation } from "react-i18next";

const Topbar = (props) => {
  const { t } = useTranslation();
  return (
    <Div {...flexCol} {...flexShrink}>
      <Div {...flexRow} {...flexGrow} justifyContent="space-between">
        <Div {...flexRow} {...flexShrink}>
          <Button
            tabIndex="0"
            role="button"
            aria-label="Clear filter"
            aria-pressed={false}
            padding="0"
            margin="0 16px"
            border="none"
            buttonHeight="40px"
            buttonWidth="40px"
            onClick={props.clearFilter}
          >
            {t("common.filterClearButton", "...")}
          </Button>
        </Div>
        <Div {...flexRow} {...flexShrink}>
          <Button
            tabIndex="0"
            role="button"
            aria-label="Close filter"
            aria-pressed={false}
            padding="0"
            border="none"
            buttonHeight="40px"
            buttonWidth="40px"
            onClick={props.onClose}
          >
            <IMG
              imgHeight="10px"
              imgWidth="10px"
              alt="Close Modal"
              src={closeIcon}
            />
          </Button>
        </Div>
      </Div>
    </Div>
  );
};

export default pure(Topbar);
