export const INIT_CLIENTS_LIST = "INIT_CLIENTS_LIST";
export const FETCHING_CLIENTS = "FETCHING_CLIENTS";
export const FETCH_CLIENTS_LIST = "FETCH_CLIENTS_LIST";
export const CLIENTS_FETCHING_COMPLETE = "CLIENTS_FETCHING_COMPLETE";

export const INIT_CONTACT_LIST = "INIT_CONTACT_LIST";
export const FETCHING_CONTACT = "FETCHING_CONTACT";
export const FETCH_CONTACT_LIST = "FETCH_CONTACT_LIST";
export const CONTACT_FETCHING_COMPLETE = "CONTACT_FETCHING_COMPLETE";

export const INIT_CONTACT_BY_ID = "INIT_CONTACT_BY_ID";

export const SUBMIT_CONTACT_DATA = "SUBMIT_CONTACT_DATA";
export const CONTACT_DATA_SAVED = "CONTACT_DATA_SAVED";
export const RESET_CONTACT_SAVED_STATUS = "RESET_CONTACT_SAVED_STATUS";

export const INIT_DELETE_CONTACT = "INIT_DELETE_CONTACT";
export const CONTACT_DELETE_SUCCESS = "CONTACT_DELETE_SUCCESS";
export const CONTACT_DELETE_FAILED = "CONTACT_DELETE_FAILED";

export const UPDATE_SEARCHED_CONTACT_DATA = "UPDATE_SEARCHED_CONTACT_DATA";
export const UPDATE_SEARCHED_CLIENT_DATA = "UPDATE_SEARCHED_CLIENT_DATA";

export const INIT_GET_CLIENT_BY_ID = "INIT_GET_CLIENT_BY_ID";
export const SET_EDIT_CLIENT_DATA = "SET_EDIT_CLIENT_DATA";
export const EDIT_DATA_FETCHED = "EDIT_DATA_FETCHED";
export const RESET_IS_EDIT = "RESET_IS_EDIT";

export const INIT_SAVE_CLIENT_DATA = "INIT_SAVE_CLIENT_DATA";
export const CLIENT_SAVE_FAILED = "CLIENT_SAVE_FAILED";
export const CLIENT_SAVED = "CLIENT_SAVED";

export const INIT_SAVE_CLIENT_HELPDESK_DATA = "INIT_SAVE_CLIENT_HELPDESK_DATA";
export const CLIENT_HELPDESK_SAVED = "CLIENT_HELPDESK_SAVED";
export const CLIENT_HELPDESK_SAVE_FAILED = "CLIENT_HELPDESK_SAVE_FAILED";
export const INIT_COUNTRY_CONTACT_DATA = "INIT_COUNTRY_CONTACT_DATA";
export const FETCHING_COUNTRY_CONTACT = "FETCHING_COUNTRY_CONTACT";
export const FETCH_COUNTRY_CONTACT_LIST = "FETCH_COUNTRY_CONTACT_LIST";
export const COUNTRY_CONTACT_FETCHING_COMPLETE =
  "COUNTRY_CONTACT_FETCHING_COMPLETE";
export const UPDATE_SEARCHED_COUNTRY_CONTACT_DATA =
  "UPDATE_SEARCHED_COUNTRY_CONTACT_DATA";
