import { put, takeEvery, call, all } from "redux-saga/effects";

import {
  INIT_MODULE_LIST_FETCH,
  MODULE_LIST_URL,
  INIT_ATTRIBUTE_TYPE_LIST_FETCH,
  ATTRIBUTE_TYPE_LIST_URL,
  ATTRIBUTES_LIST_URL,
  INIT_ATTRIBUTES_LIST_FETCH,
  INIT_ATTRIBUTE_HIERARCHY_LIST_FETCH,
  INIT_ATTRIBUTE_HIERARCHY_LEVEL_FETCH,
  ATTRIBUTE_HIERARCHY_LIST_URL,
  ATTRIBUTE_HIERARCHY_LEVEL_URL,
  CUSTOM_LIST_URL,
  INIT_GET_CUSTOM_LIST,
  INIT_GET_VMD_TAB_DATA,
  INIT_GET_VMD_SECTION_DATA,
  INIT_GET_SUBMISSION_FIELD_PLACEMENT,
  INIT_GET_SEARCH_FIELD_PLACEMENT,
  VMD_TAB_DATA_URL,
  VMD_SECTION_DATA_URL,
  SEARCH_FIELD_PLACEMENT_URL,
  DELETE_ATTRIBUTE_URL,
  INIT_ATTRIBUTES_DELETE,
  INIT_ATTRIBUTE_EDIT,
  INIT_ATTRIBUTE_SAVE,
  INIT_GET_EMAIL_TEMPLATES_LIST,
  INIT_HELPDESK_CONFIGARATION_SAVE,
  INIT_GET_SEARCH_FIELDS_LIST,
  INIT_GET_REGEX_LIST,
  INIT_GET_HELPDESK_QUERY_LIST,
  INIT_GET_VALIDATION_TYPE,
  INIT_GET_APPROVAL_TYPE_LIST,
  INIT_SEARCH_CONFIGARATION_SAVE,
  INIT_GET_HELPDESK_QUERY_BY_ID,
  INIT_GET_HELPDESK_QUERY_ATTRIBUTE_LIST,
} from "./actionTypes";

import {
  fetchingModuleList,
  fetchModuleList,
  moduleListFetchingComplete,
  fetchingAttributeTypeList,
  fetchAttributeTypeList,
  attributeTypeListFetchingComplete,
  fetchingAttributesList,
  fetchAttributesList,
  attributesListFetchingComplete,
  fetchingAttributeHierarchyList,
  fetchAttributeHierarchyList,
  attributeHierarchyListFetchingComplete,
  fetchingAttributeHierarchyLevel,
  fetchAttributeHierarchyLevel,
  attributeHierarchyLevelFetchingComplete,
  fetchingCustomList,
  fetchCustomList,
  customListFetchingComplete,
  fetchingVMDTabData,
  fetchVMDTabData,
  VMDTabDataFetchingComplete,
  fetchingVMDSectionData,
  fetchVMDSectionData,
  VMDSectionDataFetchingComplete,
  fetchingSubmissionFieldPlacement,
  fetchSubmissionFieldPlacement,
  SubmissionFieldPlacementFetchingComplete,
  fetchingSearchFieldPlacement,
  SearchFieldPlacementFetchingComplete,
  fetchSearchFieldPlacement,
  attributesDeleteSuccess,
  attributesDeleteFailed,
  setDataForAttributeEdit,
  attributeSaved,
  attributeSaveFailed,
  fetchingEmailTemplatesList,
  emailTemplatesListFetchingComplete,
  fetchEmailTemplatesList,
  helpdeskConfigarationSaved,
  helpdeskConfigarationSaveFailed,
  fetchingGetSearchFieldsList,
  fetchGetSearchFieldsList,
  getSearchFieldsListFetchingComplete,
  fetchingHelpdeskQueryList,
  fetchHelpdeskQueryList,
  helpdeskQueryListFetchingComplete,
  fetchingApprovalTypeList,
  fetchApprovalTypeList,
  approvalTypeListFetchingComplete,
  fetchingRegExList,
  fetchRegExList,
  regExListFetchingComplete,
  helpdeskConfigarationSaveFormStatus,
  attributeSaveFormStatus,
  fetchingValidationType,
  fetchValidationType,
  validationTypeFetchingComplete,
  searchConfigarationSaved,
  searchConfigarationSaveFormStatus,
  searchConfigarationSaveFailed,
  setDataForAttributeEditCompleted,
  fetchingHelpdeskQueryByID,
  fetchHelpdeskQueryByID,
  HelpdeskQueryByIDFetchingComplete,
  fetchingHelpdeskAttributeList,
  fetchHelpdeskAttributeList,
  HelpdeskAttributeListFetchingComplete,
  updatedAttributesList,
  updatedSearchFields,
  clearHelpdeskQueryById,
  updateHelpdeskAttributeList,
} from "../store/actions";

import axios from "../../../config/axios";
import { buildNotification } from "../../../config/notification";
import { store } from "react-notifications-component";

const getRequestDetails = {
  method: "GET",
  cache: "no-cache",
};

const postRequestDetails = {
  method: "POST",
  cache: "no-cache",
  headers: {
    "Content-Type": "application/json",
  },
};

const putRequestDetails = {
  method: "PUT",
  headers: {
    "Content-Type": "application/json-patch+json",
  },
};

let sessionDetails = JSON.parse(sessionStorage.getItem("sessionDetails"));

export default function* watchCustomListFetch() {
  yield all([
    takeEvery(INIT_MODULE_LIST_FETCH, getModuleListSaga),
    takeEvery(INIT_ATTRIBUTE_TYPE_LIST_FETCH, getAttributeTypeListSaga),
    takeEvery(INIT_ATTRIBUTES_LIST_FETCH, getAttributesListSaga),
    takeEvery(
      INIT_ATTRIBUTE_HIERARCHY_LIST_FETCH,
      getAttributeHierarchyListSaga
    ),
    takeEvery(
      INIT_ATTRIBUTE_HIERARCHY_LEVEL_FETCH,
      getAttributeHierarchyLevelSaga
    ),
    takeEvery(INIT_GET_CUSTOM_LIST, getCustomListSaga),
    takeEvery(INIT_GET_VMD_TAB_DATA, getVMDTabDataSaga),
    takeEvery(INIT_GET_VMD_SECTION_DATA, getVMDSectionSaga),
    takeEvery(
      INIT_GET_SUBMISSION_FIELD_PLACEMENT,
      getSubmissionFieldPlacementSaga
    ),
    takeEvery(INIT_GET_SEARCH_FIELD_PLACEMENT, getSearchFieldPlacementSaga),
    takeEvery(INIT_ATTRIBUTES_DELETE, initAttributeDeleteSaga),
    takeEvery(INIT_ATTRIBUTE_EDIT, initAttributeEditSaga),
    takeEvery(INIT_ATTRIBUTE_SAVE, initAttributeSaveSaga),
    takeEvery(INIT_GET_HELPDESK_QUERY_BY_ID, getHelpdeskQueryByIDSaga),
    takeEvery(INIT_GET_SEARCH_FIELDS_LIST, getSearchFieldsListSaga),
    takeEvery(INIT_GET_EMAIL_TEMPLATES_LIST, initGetEmailTemplatesListSaga),
    takeEvery(
      INIT_HELPDESK_CONFIGARATION_SAVE,
      initHelpdeskConfigarationSaveSaga
    ),
    takeEvery(INIT_SEARCH_CONFIGARATION_SAVE, initSearchConfigarationSaveSaga),
    takeEvery(INIT_GET_REGEX_LIST, getRegExListSaga),
    takeEvery(INIT_GET_APPROVAL_TYPE_LIST, getApprovalTypeListSaga),
    takeEvery(INIT_GET_HELPDESK_QUERY_LIST, getHelpdeskQueryListSaga),
    takeEvery(INIT_GET_VALIDATION_TYPE, getValidationTypeSaga),
    takeEvery(
      INIT_GET_HELPDESK_QUERY_ATTRIBUTE_LIST,
      getHelpdeskAttributesListSaga
    ),
  ]);
}

function* getModuleListSaga() {
  yield put(fetchingModuleList());
  const url = `${MODULE_LIST_URL}`;
  try {
    const response = yield call(axios, url, getRequestDetails);
    yield put(fetchModuleList(response.data));
    yield put(moduleListFetchingComplete());
  } catch (error) {
    yield put(moduleListFetchingComplete());
  }
}

function* getAttributeTypeListSaga() {
  yield put(fetchingAttributeTypeList());
  const url = `${ATTRIBUTE_TYPE_LIST_URL}`;
  try {
    const response = yield call(axios, url, getRequestDetails);
    let getData = [];
    if (response) {
      getData = response.data.filter(
        (item) => item.attributeTypeID !== "2" && item.attributeTypeID !== "7"
      );
      yield put(fetchAttributeTypeList(getData));
    }

    yield put(attributeTypeListFetchingComplete());
  } catch (error) {
    yield put(attributeTypeListFetchingComplete());
  }
}

function* getAttributesListSaga() {
  yield put(fetchingAttributesList());
  const url = `${ATTRIBUTES_LIST_URL}`;
  try {
    const response = yield call(axios, url, getRequestDetails);
    if (response) {
      yield put(fetchAttributesList(response.data));
      yield put(updatedAttributesList(response.data));
    }
    yield put(attributesListFetchingComplete());
  } catch (error) {
    yield put(attributesListFetchingComplete());
  }
}

function* getAttributeHierarchyListSaga() {
  yield put(fetchingAttributeHierarchyList());
  try {
    const response = yield call(
      axios,
      ATTRIBUTE_HIERARCHY_LIST_URL,
      getRequestDetails
    );
    yield put(fetchAttributeHierarchyList(response.data));
    yield put(attributeHierarchyListFetchingComplete());
  } catch (error) {
    yield put(attributeHierarchyListFetchingComplete());
  }
}

function* getAttributeHierarchyLevelSaga() {
  yield put(fetchingAttributeHierarchyLevel());
  try {
    const response = yield call(
      axios,
      ATTRIBUTE_HIERARCHY_LEVEL_URL,
      getRequestDetails
    );
    yield put(fetchAttributeHierarchyLevel(response.data));
    yield put(attributeHierarchyLevelFetchingComplete());
  } catch (error) {
    yield put(attributeHierarchyLevelFetchingComplete());
  }
}

function* getCustomListSaga() {
  yield put(fetchingCustomList());
  const url = `${CUSTOM_LIST_URL}`;
  try {
    const response = yield call(axios, url, getRequestDetails);
    yield put(fetchCustomList(response.data));
    yield put(customListFetchingComplete());
  } catch (error) {
    yield put(customListFetchingComplete());
  }
}

function* getVMDTabDataSaga() {
  yield put(fetchingVMDTabData());
  const url = `${VMD_TAB_DATA_URL}`;
  try {
    const response = yield call(axios, url, getRequestDetails);
    yield put(fetchVMDTabData(response.data));
    yield put(VMDTabDataFetchingComplete());
  } catch (error) {
    yield put(VMDTabDataFetchingComplete());
  }
}

function* getVMDSectionSaga() {
  yield put(fetchingVMDSectionData());
  const url = `${VMD_SECTION_DATA_URL}`;
  try {
    const response = yield call(axios, url, getRequestDetails);
    yield put(fetchVMDSectionData(response.data));
    yield put(VMDSectionDataFetchingComplete());
  } catch (error) {
    yield put(VMDSectionDataFetchingComplete());
  }
}

function* getSubmissionFieldPlacementSaga() {
  yield put(fetchingSubmissionFieldPlacement());
  const url = `/Common/submissionFieldPlacement`;
  try {
    const response = yield call(axios, url, getRequestDetails);
    yield put(fetchSubmissionFieldPlacement(response.data));
    yield put(SubmissionFieldPlacementFetchingComplete());
  } catch (error) {
    yield put(SubmissionFieldPlacementFetchingComplete());
  }
}

function* getSearchFieldPlacementSaga() {
  yield put(fetchingSearchFieldPlacement());
  const url = `${SEARCH_FIELD_PLACEMENT_URL}`;
  try {
    const response = yield call(axios, url, getRequestDetails);
    yield put(fetchSearchFieldPlacement(response.data));
    yield put(SearchFieldPlacementFetchingComplete());
  } catch (error) {
    yield put(SearchFieldPlacementFetchingComplete());
  }
}

function* getHelpdeskQueryByIDSaga(action) {
  yield put(fetchingHelpdeskQueryByID());
  if (!action.queryID) {
    yield put(clearHelpdeskQueryById());
    yield put(HelpdeskQueryByIDFetchingComplete());
    return false;
  }
  const url = `/Module/QueryDetailsById?Id=${action.queryID}&languageID=enGb`;
  try {
    const response = yield call(axios, url, getRequestDetails);
    yield put(fetchHelpdeskQueryByID(response.data));
    yield put(HelpdeskQueryByIDFetchingComplete());
    if (action.fetchSuccessHelpdeskQueryByID) {
      action.fetchSuccessHelpdeskQueryByID(response.status);
    }
  } catch (error) {
    yield put(HelpdeskQueryByIDFetchingComplete());
  }
}

function* initAttributeDeleteSaga(action) {
  const url = `${DELETE_ATTRIBUTE_URL}`;
  putRequestDetails.data = {
    listOfIds: action.id,
  };
  try {
    const response = yield call(axios, url, putRequestDetails);
    if (response.status === 200) {
      const id = action.id.split(",").map((id) => id);
      const notification = buildNotification({
        message: "notification.supplierField.attrDeleted",
        type: "success",
      });
      yield put(attributesDeleteSuccess(id));
      if (action.deleteSuccess) {
        action.deleteSuccess(response.status);
      }

      store.addNotification({
        ...notification,
        dismiss: {
          duration: 1000,
        },
      });
    }
  } catch (error) {
    yield put(attributesDeleteFailed());
  }
}

function* initAttributeEditSaga(action) {
  const url = `/Attribute/attributeByID?attributeID=${action.id}`;
  try {
    const response = yield call(axios, url, getRequestDetails);
    if (response) {
      yield put(setDataForAttributeEdit(response.data));
      yield put(setDataForAttributeEditCompleted(response.status));
      if (action.callback) {
        action.callback(response.status);
      }
    }
  } catch (error) {
    action.callback && action.callback();
  }
}

function* initAttributeSaveSaga(action) {
  const url = `/Attribute`;
  try {
    postRequestDetails.data = action.formdata;
    const response = yield call(axios, url, postRequestDetails);
    if (response.status === 200) {
      yield put(attributeSaved());
      yield put(attributeSaveFormStatus(response.status));
      if (action.callback) {
        action.callback(response.status);
      }
      if (action.isEditMode) {
        const notification = buildNotification({
          message: "notification.supplierField.attrUpdated",
          type: "success",
        });
        store.addNotification({
          ...notification,
          dismiss: {
            duration: 1000,
          },
        });
      } else {
        const notification = buildNotification({
          message: "notification.supplierField.attrCreated",
          type: "success",
        });
        store.addNotification({
          ...notification,
          dismiss: {
            duration: 1000,
          },
        });
      }
    } else {
      yield put(attributeSaveFailed());
    }
  } catch (error) {
    yield put(attributeSaveFailed());
  }
}

function* getRegExListSaga(action) {
  yield put(fetchingRegExList());
  const url = `/Common/listRegEx`;
  try {
    const response = yield call(axios, url, getRequestDetails);
    yield put(fetchRegExList(response.data));
    yield put(regExListFetchingComplete());
  } catch (error) {
    yield put(regExListFetchingComplete());
  }
}

function* getApprovalTypeListSaga(action) {
  yield put(fetchingApprovalTypeList());
  const url = `/Common/listApprovalType`;
  try {
    const response = yield call(axios, url, getRequestDetails);
    yield put(fetchApprovalTypeList(response.data));
    yield put(approvalTypeListFetchingComplete());
  } catch (error) {
    yield put(approvalTypeListFetchingComplete());
  }
}

function* getValidationTypeSaga(action) {
  yield put(fetchingValidationType());
  const url = `/Common/ValidationType`;
  try {
    const response = yield call(axios, url, getRequestDetails);
    yield put(fetchValidationType(response.data));
    yield put(validationTypeFetchingComplete());
  } catch (error) {
    yield put(validationTypeFetchingComplete());
  }
}
// Module Config
function* getHelpdeskQueryListSaga(action) {
  let sessionDetails =
    JSON.parse(sessionStorage.getItem("sessionDetails")) || {};
  yield put(fetchingHelpdeskQueryList());
  let languageID =
    sessionDetails &&
    sessionDetails.languageId &&
    sessionDetails.languageId !== ""
      ? sessionDetails.languageId
      : "enGB";
  const url = `/Common/listHelpdeskQuery?languageID=${languageID}`;
  try {
    const response = yield call(axios, url, getRequestDetails);
    yield put(fetchHelpdeskQueryList(response.data));
    yield put(helpdeskQueryListFetchingComplete());
  } catch (error) {
    yield put(helpdeskQueryListFetchingComplete());
  }
}

function* getSearchFieldsListSaga(action) {
  yield put(fetchingGetSearchFieldsList());
  const url = `/Module/ListSearchFields`;
  try {
    const response = yield call(axios, url, getRequestDetails);
    yield put(fetchGetSearchFieldsList(response.data));
    yield put(updatedSearchFields(response.data));
    action.payload.setFieldsToState(true);
    yield put(getSearchFieldsListFetchingComplete());
  } catch (error) {
    yield put(getSearchFieldsListFetchingComplete());
  }
}

function* initGetEmailTemplatesListSaga() {
  yield put(fetchingEmailTemplatesList());
  const url = `/EmailTemplate/ListEmailTemplate`;
  try {
    const response = yield call(axios, url, getRequestDetails);
    let getUpdatedList;
    if (response.data) {
      getUpdatedList = response.data.filter(
        (item) => item.categoryLabel === "Supplier Queries"
      );
    }
    yield put(fetchEmailTemplatesList(getUpdatedList));
    yield put(emailTemplatesListFetchingComplete());
  } catch (error) {
    yield put(emailTemplatesListFetchingComplete());
  }
}

function* initHelpdeskConfigarationSaveSaga(action) {
  const url = `/Module/HelpdeskSaveConfiguration`;
  try {
    postRequestDetails.data = action.formdata;
    const response = yield call(axios, url, postRequestDetails);
    const notification = buildNotification({
      message: "notification.supplierField.helpdeskConfigSaved",
      type: "success",
    });
    if (response && response.status) {
      yield put(helpdeskConfigarationSaved());
      yield put(helpdeskConfigarationSaveFormStatus(response.status));
      if (action.helpdeskConfigSaveSuccess) {
        action.helpdeskConfigSaveSuccess(response.status);
      }
      store.addNotification({
        ...notification,
        dismiss: {
          duration: 1000,
        },
      });
    }
  } catch (error) {
    yield put(helpdeskConfigarationSaveFailed());
  }
}

function* initSearchConfigarationSaveSaga(action) {
  const url = `/Module/SearchSaveConfiguration`;
  try {
    postRequestDetails.data = action.formdata;
    let response;
    if (!action.isDuplicate) {
      response = yield call(axios, url, postRequestDetails);
    } else {
      const notification = buildNotification({
        message: "notification.supplierField.multipleGridOrder",
        type: "danger",
      });
      store.addNotification({
        ...notification,
        dismiss: {
          duration: 3000,
        },
      });
      yield put(searchConfigarationSaveFailed());
    }
    if (response) {
      const notification = buildNotification({
        message: "notification.supplierField.searchConfigSaved",
        type: "success",
      });
      yield put(searchConfigarationSaved());
      yield put(searchConfigarationSaveFormStatus(response.status));
      if (action.searchConfigSaveSuccess) {
        action.searchConfigSaveSuccess(response.status);
      }
      store.addNotification({
        ...notification,
        dismiss: {
          duration: 1000,
        },
      });
    }
  } catch (error) {
    yield put(searchConfigarationSaveFailed());
  }
}
function* getHelpdeskAttributesListSaga() {
  yield put(fetchingHelpdeskAttributeList());
  const url = `/Module/ListHelpdeskQueryAttributes?languageID=enGb`;
  try {
    const response = yield call(axios, url, getRequestDetails);
    yield put(fetchHelpdeskAttributeList(response.data));
    yield put(updateHelpdeskAttributeList(response.data));
    yield put(HelpdeskAttributeListFetchingComplete());
  } catch (error) {
    yield put(HelpdeskAttributeListFetchingComplete());
  }
}
