import React from "react";
import styled from "styled-components";

const StyledTextarea = styled.textarea`
  box-sizing: border-box;
  background: rgb(255, 255, 255);
  border: ${(props) => (props.border ? props.border : "0")};
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : "")};
  height: ${(props) => (props.inputHeight ? props.inputHeight : "")};
  resize: none;
  width: ${(props) => (props.inputWidth ? props.inputWidth : "")};
  padding: ${(props) => (props.padding ? props.padding : "4px")};
  outline: 0;
  &::-webkit-input-placeholder {
    color: rgb(123, 142, 152);
    font-family: graphik;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: -0.5px;
    text-transform: ${(props) => props.textTransform || "capitalize"};
  }
  display: ${(props) => (props.display ? props.display : "")};
  margin-top: ${(props) => (props.mt ? props.mt : "")};
  &:focus {
    outline: none;
    box-shadow: ${(props) =>
      props.boxShadowOnFocus ? props.boxShadowOnFocus : ""};
    border: ${(props) => (props.borderOnFocus ? props.borderOnFocus : "")};
  }
  margin: ${(props) => (props.margin ? props.margin : "")};
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "initial")};
  font-family: ${(props) =>
    props.fontFamily ? props.fontFamily : "graphik-medium"};
  font-size: ${(props) =>
    props.textareaFontSize ? props.textareaFontSize : "14px"};
  color: ${(props) =>
    props.textareaFontColor ? props.textareaFontColor : "#212b36"};
`;
const Textarea = (props) => {
  const onInputChange = (e) => {
    let inputValue = e.target.value;
    const strippedHtml = inputValue.replace(/<[^>]+>/g, "");
    e.target.value = strippedHtml;

    props.onChange && props.onChange(e);
  };

  return <StyledTextarea {...props} onChange={onInputChange} />;
};

export default Textarea;
