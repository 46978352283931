import { put, takeEvery, call, all } from "redux-saga/effects";
import { buildNotification } from "../../../config/notification";
import { store } from "react-notifications-component";
import {
  fetchingEmailTemplateList,
  fetchEmailTemplateList,
  EmailTemplateListFetchingComplete,
  fetchingCategoryList,
  fetchCategoryList,
  CategoryListFetchingComplete,
  emailTemplateSaved,
  emailTemplateSaveFailed,
  deleteSuccess,
  deletFailed,
  setDataForEdit,
  getTemplateFileComplete,
  initEmailTemplateList,
} from "../store/actions";

import {
  INIT_EMAIL_TEMPLATE_LIST,
  INIT_CATEGORY_LIST,
  INIT_EMAIL_TEMPLATE_SAVE,
  INIT_EMAIL_TEMPLATE_DELETE,
  INIT_EMAIL_TEMPLATE_EDIT,
  EDIT_TEMPLATE_FILE,
} from "../store/actionTypes";

import FileSaver from "file-saver";
import axios from "../../../config/axios";

import {
  getRequestDetails,
  postRequestDetails,
} from "../../../config/requestHeaders";
export default function* watchDocHistoryListFetch() {
  yield all([
    takeEvery(INIT_EMAIL_TEMPLATE_LIST, getEmailTemplatesListSaga),
    takeEvery(INIT_CATEGORY_LIST, getCategoryListSaga),
    takeEvery(INIT_EMAIL_TEMPLATE_SAVE, initEmailTemplateSaveSaga),
    takeEvery(INIT_EMAIL_TEMPLATE_DELETE, initEmailTemplateDelete),
    takeEvery(INIT_EMAIL_TEMPLATE_EDIT, initEditSaga),
    takeEvery(EDIT_TEMPLATE_FILE, getTemplateFile),
  ]);
}

function* getEmailTemplatesListSaga() {
  yield put(fetchingEmailTemplateList());
  const url = `/EmailTemplate/ListEmailTemplate`;
  try {
    const response = yield call(axios, url, getRequestDetails);
    if (response && response.status === 200) {
      yield put(fetchEmailTemplateList(response.data));
    }

    yield put(EmailTemplateListFetchingComplete());
  } catch (error) {
    yield put(EmailTemplateListFetchingComplete());
  }
}

function* getCategoryListSaga() {
  let categoryList = [];
  yield put(fetchingCategoryList());
  let sessionDetails =
    JSON.parse(sessionStorage.getItem("sessionDetails")) || {};
  let langKey =
    sessionDetails && sessionDetails.languageId
      ? sessionDetails.languageId
      : "";
  const url = `/Common/listEmailTemplateCategory?languageID=${langKey}`;
  try {
    const response = yield call(axios, url, getRequestDetails);
    if (response && response.status === 200) {
      categoryList = yield response.data.map((item) => {
        return item;
      });
    }

    yield put(fetchCategoryList(categoryList));
    yield put(CategoryListFetchingComplete());
  } catch (error) {
    yield put(CategoryListFetchingComplete());
  }
}

function* initEmailTemplateSaveSaga(action) {
  const url = "/EmailTemplate/Save";
  postRequestDetails.data = action.payload;

  try {
    const response = yield call(axios, url, postRequestDetails);
    if (response && response.status === 200) {
      yield put(emailTemplateSaved());
      if (action.saveSuccess) {
        action.saveSuccess(response.status);
      }
      yield put(initEmailTemplateList());
      const notification = buildNotification({
        message: "notification.emailTemplate.success",
        type: "success",
      });
      store.addNotification({
        ...notification,
        dismiss: {
          duration: 1000,
        },
      });
    }
  } catch (error) {
    yield put(emailTemplateSaveFailed());
  }
}

function* initEmailTemplateDelete(action) {
  const url = `/EmailTemplate/DeleteEmailTemplate?templateId=${action.id}`;
  try {
    const response = yield call(axios, url, postRequestDetails);

    if (response.status === 200) {
      yield put(deleteSuccess(action.id));
      if (action.deleteSuccess) {
        action.deleteSuccess(response.status);
      }
      const notification = buildNotification({
        message: "notification.emailTemplate.deleted",
        type: "success",
      });
      store.addNotification({
        ...notification,
        dismiss: {
          duration: 2000,
        },
      });
      yield put(initEmailTemplateList());
    }
  } catch (error) {
    yield put(deletFailed());
  }
}

function* initEditSaga(action) {
  const url = `/EmailTemplate/ListEmailTemplateById?templateId=${action.id}`;
  try {
    const response = yield call(axios, url, getRequestDetails);
    if (response && response.status === 200) {
      yield put(setDataForEdit(response.data));
      if (action.callback) {
        action.callback(response.data);
      }
    }
  } catch (error) {
    yield put(emailTemplateSaveFailed());
  }
}

function* getTemplateFile(action) {
  const getRequest = {
    method: "GET",
    cache: "no-cache",
  };
  const url = `EmailTemplate/Download?blobId=${action.blobid}`;
  let fileContent;
  try {
    const response = yield call(axios, url, getRequest);
    const blob = new Blob([response.data], {
      type: response.headers["content-type"],
    });
    FileSaver.saveAs(blob, "File.xslt");

    fileContent = response.data;
    yield put(getTemplateFileComplete(fileContent));
  } catch (error) {
    action.callback && action.callback();
  }
}
