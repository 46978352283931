import styled from "styled-components";

import { NavLink } from "react-router-dom";

export const MenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 2px 16px 0px rgba(33, 43, 54, 0.1);
  align-items: center;
  @media screen and (max-width: 1000px) {
    // display: none;
  }
`;

export const MenuBar = styled.nav`
  flex-wrap: wrap;
  box-sizing: unset;
  overflow: hidden;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  margin-left: 32px;
`;

export const NavItem = styled(NavLink)`
  box-sizing: unset;
  height: 14px;
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: graphik-medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
  text-decoration: none;
  text-align: center;
  float: left;
  padding: 24px 16px 14px 16px;
  border-bottom: 4px solid
    ${(props) =>
      props.active !== "false" ? "rgb(0, 142, 255)" : "transparent"};
  border-radius: 4px 4px 0px 0px;
  color: ${(props) =>
    props.active !== "false" ? "rgb(21, 24, 27)" : "rgb(99, 115, 129)"};
  outline: none;
  &:focus,
  &:hover {
    background: rgb(239, 242, 245);
    border-bottom: 4px solid
      ${(props) =>
        props.active !== "false" ? "rgb(0, 142, 255)" : "rgb(239, 242, 245)"};
  }
  @media screen and (max-width: 1280px) {
    font-size: 12px;
  }
`;
