import { put, takeEvery, call, all } from "redux-saga/effects";
import { store } from "react-notifications-component";

import axios from "../../../config/axios";
import { buildNotification } from "../../../config/notification";

import { fetchReportStatus, fetchNotificationList } from "./actions";
import {
  INIT_FETCH_REPORT_STATUS,
  SAVE_REPORT_STATUS,
  INIT_NOTIFICATION_LIST,
  SAVE_NOTIFICATION_LIST,
} from "./actionTypes";

const getRequestDetails = {
  method: "GET",
  cache: "no-cache",
};

const postRequestDetails = {
  method: "POST",
  cache: "no-cache",
  headers: {
    "Content-Type": "application/json",
  },
};

export default function* watchReportStatusFetch() {
  yield all([
    takeEvery(INIT_FETCH_REPORT_STATUS, getReportStatusSaga),
    takeEvery(SAVE_REPORT_STATUS, saveReportStatusSaga),
    takeEvery(INIT_NOTIFICATION_LIST, getNotificationListSaga),
    takeEvery(SAVE_NOTIFICATION_LIST, saveNotificationListSaga),
  ]);
}

function* getReportStatusSaga(action) {
  let sessionDetails =
    JSON.parse(sessionStorage.getItem("sessionDetails")) || {};
  let languageID =
    sessionDetails &&
    sessionDetails.languageId &&
    sessionDetails.languageId !== ""
      ? sessionDetails.languageId
      : "enGB";
  const url =
    "/Report/invoiceNotificationForUser?userId=" +
    action.userId +
    "&LanguageID=" +
    languageID;
  try {
    const response = yield call(axios, url, getRequestDetails);
    if (response) {
      const data = response.data.map((item) => {
        return item;
      });
      yield put(fetchReportStatus(data[0]));
      if (action.callback) {
        action.callback(data);
      }
    }
  } catch (err) {
    action.callback && action.callback();
  }
}

function* saveReportStatusSaga(action) {
  yield (postRequestDetails.data = {
    ...action.data,
  });
  try {
    const response = yield call(
      axios,
      "/Report/savenotification",
      postRequestDetails
    );
    if (response && response.status === 202) {
      const notification = buildNotification({
        message: "notification.reportStatus.statusSave",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
    }
  } catch (error) {
    action.callback && action.callback();
  }
}

function* getNotificationListSaga(action) {
  let url = "/Report/listInvoiceNotifications";
  let keys = action.data && Object.keys(action.data);
  keys &&
    keys.forEach((data) => {
      if (action.data[data]) {
        url = url + "?" + data + "=" + action.data[data];
      }
    });
  try {
    const response = yield call(axios, url, getRequestDetails);
    if (response) {
      yield put(fetchNotificationList(response.data));
      if (action.callback) {
        action.callback(response.data);
      }
    }
  } catch (err) {
    action.callback && action.callback();
  }
}

function* saveNotificationListSaga(action) {
  yield (postRequestDetails.data = {
    ...action.data,
  });
  try {
    const response = yield call(
      axios,
      "/Report/savenotificationInBulk",
      postRequestDetails
    );
    if (response && response.status === 202) {
      const notification = buildNotification({
        message: "notification.reportStatus.bulkStatusSave",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
    }
    if (action.callback) {
      action.callback(response.status);
    }
  } catch (error) {
    action.callback && action.callback();
  }
}
