import React, { Component } from "react";

import ChatBotModal from "../../components/Popup/index";
import ChatBotModalBody from "./ChatBotBody";

import { collapseChatbotCustomStyle } from "./style";

let sessionDetails = JSON.parse(sessionStorage.getItem("sessionDetails")) || {};
let languageId = sessionDetails && sessionDetails.languageId;

let encryptedUserName = sessionDetails && sessionDetails.encryptedUserName;
let primaryEmail = sessionDetails && sessionDetails.primaryEmail;

class ChatBot extends Component {
  render() {
    let customStyle;
    customStyle = { ...collapseChatbotCustomStyle };
    return (
      <ChatBotModal
        {...customStyle}
        show={this.props.show}
        close={this.props.onClose}
        body={
          <ChatBotModalBody
            {...this.props}
            encryptedUserName={encryptedUserName}
            primaryEmail={primaryEmail}
            languageId={languageId}
          />
        }
      ></ChatBotModal>
    );
  }
}

export default ChatBot;
