import React, { useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import { selectClientDetails } from "../../container/LoginPage/store/selectors";
import Button from "../Button/Button";
import { useTranslation } from "react-i18next";

import DateRange from "./DateRange";

const DateRangeFilter = (props) => {
  const { t } = useTranslation();
  const [showDateRange, setShowDateRange] = useState(false);

  const { client } = props.clientDetails || {};
  const { noOfYears } = client || { noOfYears: 5 };
  return (
    <>
      <Button onClick={() => setShowDateRange(true)} margin="2px">
        {t("common.selectDateRange")}
      </Button>
      <DateRange
        yearRange={noOfYears}
        show={showDateRange}
        applyDateRangeFilter={props.applyDateRangeFilter}
        close={() => setShowDateRange(false)}
      />
    </>
  );
};
const mapStateToProps = createStructuredSelector({
  clientDetails: selectClientDetails(),
});
const withConnect = connect(mapStateToProps);

export default compose(withConnect)(DateRangeFilter);
