import { fromJS } from "immutable";

import {
  UPDATE_SEARCHED_LOG_LIST,
  UPDATE_DATA_TO_DISPLAY,
  FETCHING_LOG_STATUS,
  FETCHING_LOG_COMPLETE,
  REPROCESSED_SAVED_OR_FAILED,
  FETCHING_EMAIL_LIST,
  FETCH_EMAIL_LOG_LIST,
  EMAIL_LOG_FETCH_COMPLETE,
  FETCHING_EMAIL_LOG_DESCRIPTION,
  FETCH_EMAIL_LOG_DESCRIPTION,
  EMAIL_LOG_DESCRIPTION_FETCH_COMPLETE,
  CLEARING_EMAIL_LOG,
  CLEARED_EMAIL_LOG,
  CLEAR_EMAIL_LOG_FAILED,
  FETCH_LOGS_LIST,
  INIT_REPROCESS_SUBMISSION,
  RESET_LOG_LIST,
} from "./actionTypes";

export const initialState = fromJS({
  showAdminLogFilter: false,
  displayData: [],
  sourceType: "",
  fetchLogStatus: false,
  isReprocessed: false,
  fetchingEmailList: false,
  emailLogList: [],
  fetchingEmailDescription: false,
  emailLogDescription: [],
  clearingEmailLog: false,
  emailLogCleared: false,
  errorMessage: "",
  logsList:[],
  totalCount:"0",
});

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case UPDATE_SEARCHED_LOG_LIST:
      return state.set("displayData", action.data);

    case UPDATE_DATA_TO_DISPLAY:
    return state
      .set("displayData", action.data)
      .set("sourceType", action.param.logType);

    case FETCHING_LOG_STATUS:
      return state.set("fetchLogStatus", true).set("showAdminLogFilter", false);

    case FETCHING_LOG_COMPLETE:
      return state.set("fetchLogStatus", false);

    case INIT_REPROCESS_SUBMISSION:
      return state.set("isReprocessed", true);

    case REPROCESSED_SAVED_OR_FAILED:
      return state.set("isReprocessed", false);

    //email log list
    case FETCHING_EMAIL_LIST: {
      return state.set("fetchingEmailList", true);
    }
    case FETCH_EMAIL_LOG_LIST: {
      return state.set("emailLogList", action.data);
    }
    case EMAIL_LOG_FETCH_COMPLETE: {
      return state.set("fetchingEmailList", false);
    }
    //email log description
    case FETCHING_EMAIL_LOG_DESCRIPTION: {
      return state.set("fetchingEmailDescription", true);
    }
    case FETCH_EMAIL_LOG_DESCRIPTION: {
      return state.set("emailLogDescription", action.data);
    }
    case EMAIL_LOG_DESCRIPTION_FETCH_COMPLETE: {
      return state.set("fetchingEmailDescription", false);
    }
    //clear/archive email logs
    case CLEARING_EMAIL_LOG: {
      return state.set("clearingEmailLog", true);
    }
    case CLEARED_EMAIL_LOG: {
      return state.set("clearingEmailLog", false).set("emailLogCleared", true);
    }
    case CLEAR_EMAIL_LOG_FAILED: {
      return state
        .set("errorMessage", action.message)
        .set("clearingEmailLog", false)
        .set("emailLogCleared", false);
    }
    
    case FETCH_LOGS_LIST: {
      return state.set("logsList", action.data.logs).set("totalCount", action.data.totalCount);
    }
    case RESET_LOG_LIST : {
      return state.set("logsList", []).set("displayData", []);
    }
    default:
      return state;
  }
};
export default reducer;
