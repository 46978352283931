import { all, call, put, takeLatest } from "@redux-saga/core/effects";
import Axios from "axios";
import { postRequestDetails } from "../../../config/requestHeaders";
import { fetchingClientSsoToken } from "./actions";
import { INIT_CLIENT_SSO_LOGIN } from "./actionTypes";

export default function* watchUnauthLoader() {
  yield all([takeLatest(INIT_CLIENT_SSO_LOGIN, initFetchClientSsoTokenSaga)]);
}

function* initFetchClientSsoTokenSaga(action) {
  const tokenUrl = "https://ssotest.sbdinc.com/as/token.oauth2";
  const tokenRequestDetails = { ...postRequestDetails };
  yield ((tokenRequestDetails.data = action.payload),
  (tokenRequestDetails.headers = {
    "content-type": "application/x-www-form-urlencoded",
  }));
  yield put(fetchingClientSsoToken());
  try {
    const response = yield call(Axios, tokenUrl, tokenRequestDetails);
    if (response && response.status === 200) {
      if (action.callback) {
        action.callback(response);
      }
    } else {
      if (action.callback) {
        action.callback({});
      }
    }
    // const response = yield call(Axios, tokenUrl, tokenRequestDetails);
  } catch {
    if (action.callback) {
      action.callback({});
    }
  }
}
