import React, { useState } from "react";

import {
  ClientLogoWrapper,
  CopyrightWrapper,
  FooterText,
  FooterWrapper,
} from "./style";

import accLogo from "../../../assets/images/Footer/AccentureLogo-Purple.png";
import synOpsLogo from "../../../assets/images/Footer/Synops-Logo.png";
import { flexGrow, flexRow, flexShrink } from "../style";
import Div from "../../Div";
import IMG from "../../IMG";
import { useTranslation } from "react-i18next";
import TermsOfUse from "./TermsOfUse";
import PrivacyStatement from "./PrivacyStatement";
import ContactSupport from "./ContactSupport";
import { VerticalLine } from "../Header/style";
import { createStructuredSelector } from "reselect";
import {
  selectClientDetails,
  selectSessionDetails,
} from "../../../container/LoginPage/store/selectors";

import { connect } from "react-redux";
import { compose } from "redux";

const Footer = (props) => {
  const { t } = useTranslation();
  const { clientDetails } = props || {};
  const { client } = clientDetails || {};

  let [termsOfUse, showTermsOfUse] = useState(false);
  let [privacyStatement, showPrivacyStatement] = useState(false);
  let [contactSupport, showContactSupport] = useState(false);

  const sessionDetails = JSON.parse(sessionStorage.getItem("sessionDetails"));

  const { contactSupportEmailAddress, contactSupportPhoneNumber, baseRoleId } =
    sessionDetails ? sessionDetails : "";

  const termsOfUseHandler = () => {
    return showTermsOfUse(!termsOfUse);
  };
  const closeTermsOfUseModal = () => {
    return showTermsOfUse(false);
  };
  const privacyStatementHandler = () => {
    return showPrivacyStatement(!termsOfUse);
  };
  const closePrivacyStatementModal = () => {
    return showPrivacyStatement(false);
  };
  const contactSupportHandler = () => {
    return showContactSupport(!contactSupport);
  };
  const closeContactSupportModal = () => {
    return showContactSupport(false);
  };

  return (
    <FooterWrapper id="footer">
      <Div {...flexRow} {...flexGrow}>
        <ClientLogoWrapper>
          <IMG
            height={
              client && client.fileLogo && baseRoleId !== 1 && baseRoleId !== 4
                ? "57px"
                : "auto"
            }
            src={
              client && client.fileLogo && baseRoleId !== 1 && baseRoleId !== 4
                ? `data:image/png;base64,${client.fileLogo}`
                : accLogo
            }
            alt="Accenture Logo"
          />
        </ClientLogoWrapper>
        <CopyrightWrapper>
          <FooterText>&copy; {new Date().getFullYear()} {t("common.copyrightText")}</FooterText>
        </CopyrightWrapper>
        <Div {...flexRow} {...flexGrow} position="relative" alignItems="center">
          <FooterText padding="0 20px" onClick={termsOfUseHandler}>
            {t("common.termsOfUse")}
          </FooterText>
          <FooterText padding="0 20px" onClick={privacyStatementHandler}>
            {t("common.privacyStatement")}
          </FooterText>
          {/* <FooterText padding="0 20px" onClick={contactSupportHandler}>
            {t("common.contactSupport")}
          </FooterText> */}
        </Div>
        <TermsOfUse show={termsOfUse} close={closeTermsOfUseModal} />
        <PrivacyStatement
          show={privacyStatement}
          close={closePrivacyStatementModal}
        />
        <ContactSupport
          show={contactSupport}
          contactSupportNo={contactSupportPhoneNumber}
          contactSupportEmail={contactSupportEmailAddress}
          close={closeContactSupportModal}
        />
      </Div>
      <Div
        {...flexRow}
        {...flexShrink}
        position="relative"
        justifyContent="flex-end"
      >
        <IMG src={synOpsLogo} alt="Synops Logo" />
        <FooterText alwaysVisible padding="0 0 0 5px">
          {t("common.synOps")}
        </FooterText>
        <VerticalLine alwaysVisible bg="#008eff" width="2px" height="20px" />
        <FooterText alwaysVisible>
          {t("common.financeAndAccounting")}
        </FooterText>
      </Div>
    </FooterWrapper>
  );
};

const mapStateToProps = createStructuredSelector({
  clientDetails: selectClientDetails(),
  sessionDetails: selectSessionDetails(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(Footer);
