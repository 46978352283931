import { fromJS } from "immutable";

import {
  USER_CREATION_REQUEST_FETCH,
  USER_CREATION_REQUEST_COMPLETE,
  UPDATE_SUBMIT_STATUS,
  UPDATE_CONFIRM_STATUS,
  UPDATE_REQUEST_STATUS,
  GET_USER_BY_ID,
  FETCH_GET_USER_BY_ID,
  UPDATE_LIST,
} from "./actionTypes";

export const initialState = fromJS({
  requestList: [],
  isRequestListLoading: false,
  submitStatus: "",
  confirmStatus: "",
  requestStatus: "Not Approved",
  editDataStatus: true,
  editData: [],
  statusCounts: [],
  totalCount: 0,
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_CREATION_REQUEST_FETCH:
      return state.set("isRequestListLoading", true);
    case USER_CREATION_REQUEST_COMPLETE: {
      return state
        .set("requestList", action.requestList)
        .set("statusCounts", action.statusCounts)
        .set("totalCount", action.totalCount)
        .set("isRequestListLoading", false);
    }
    case UPDATE_LIST: {
      return state
        .set("requestList", action.requestList)
        .set("statusCounts", action.statusCounts)
        .set("totalCount", action.totalCount)
        .set("isRequestListLoading", false);
    }
    case UPDATE_SUBMIT_STATUS:
      return state.set("submitStatus", action.status);
    case UPDATE_CONFIRM_STATUS:
      return state.set("confirmStatus", action.status);
    case UPDATE_REQUEST_STATUS:
      return state.set("requestStatus", action.requestStatus);
    case GET_USER_BY_ID:
      return state.set("editDataStatus", true);
    case FETCH_GET_USER_BY_ID:
      return state.set("editData", action.data).set("editDataStatus", false);
    default:
      return state;
  }
};
export default reducer;
