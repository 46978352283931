import { fromJS } from "immutable";
import {
  FETCHING_SIGNUP_FORM_FIELDS,
  FETCH_SIGNUP_FORM_FIELDS,
  FETCHING_SIGNUP_FLEX_FIELDS,
  FETCH_SIGNUP_FLEX_FIELDS,
  INIT_SAVE_SIGNUP_FORM,
  SIGNUP_FORM_SAVED,
  SIGNUP_FORM_SAVE_FAILED,
  RESET_SAVE_STATUS,
  USER_EXIST,
  FETCHING_SECURITY_QUESTION,
  FETCH_SECURITY_QUESTION,
} from "./actionTypes";

export const initialState = fromJS({
  signUpFormFields: "",
  fetchSignUpFormFields: false,
  signUpFlexFields: "",
  fetchSignUpFlexFields: false,
  signUpFormSaving: false,
  formSaveStatus: false,
  isExistingUser: false,
  securityQuestions: [],
  fetchSecurityQuestion: false,
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_SIGNUP_FORM_FIELDS: {
      return state.set("fetchSignUpFormFields", true);
    }
    case FETCH_SIGNUP_FORM_FIELDS: {
      return state
        .set("signUpFormFields", action.data)
        .set("fetchSignUpFormFields", false);
    }
    case FETCHING_SIGNUP_FLEX_FIELDS: {
      return state.set("fetchSignUpFlexFields", true);
    }
    case FETCH_SIGNUP_FLEX_FIELDS: {
      return state
        .set("signUpFlexFields", action.data)
        .set("fetchSignUpFlexFields", false);
    }
    case INIT_SAVE_SIGNUP_FORM: {
      return state.set("signUpFormSaving", true);
    }
    case SIGNUP_FORM_SAVED: {
      return state.set("signUpFormSaving", false).set("formSaveStatus", true);
    }
    case SIGNUP_FORM_SAVE_FAILED: {
      return state.set("signUpFormSaving", false).set("formSaveStatus", false);
    }
    case RESET_SAVE_STATUS: {
      return state.set("formSaveStatus", false);
    }
    case USER_EXIST: {
      return state.set("isExistingUser", action.data); //data that has to be set from action <---> saga to selector
    }
    case FETCHING_SECURITY_QUESTION: {
      return state.set("fetchSecurityQuestion", true);
    }
    case FETCH_SECURITY_QUESTION: {
      return state
        .set("securityQuestions", action.data)
        .set("fetchSecurityQuestion", false);
    }
    default:
      return state;
  }
};
export default reducer;
