import { fromJS } from "immutable";

import {
  UPDATE_EMAIL_DETAILS,
  UPDATE_FIELD_VALUE,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  FETCHING_EMAIL_TO_RESET_PASSWORD,
  FETCHING_EMAIL_TO_RESET_PASSWORD_SUCCESS,
  FETCHING_EMAIL_TO_RESET_PASSWORD_FAILED,
} from "./actionTypes";

export const initialState = fromJS({
  userName: "",
  newPassword: "",
  confirmNewPassword: "",
  isResetPassword: false,
  isUserNameReceived: false,
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_EMAIL_DETAILS:
      return state.set("userName", action.userName);
    case UPDATE_FIELD_VALUE:
      return state.set(action.field.name, action.field.value);
    case UPDATE_PASSWORD_REQUEST:
      return state.set("isResetPassword", true);
    case UPDATE_PASSWORD_SUCCESS:
      return state
        .set("isResetPassword", false)
        .set("newPassword", "")
        .set("confirmNewPassword", "");
    case FETCHING_EMAIL_TO_RESET_PASSWORD:
      return state.set("isUserNameReceived", true);
    case FETCHING_EMAIL_TO_RESET_PASSWORD_SUCCESS:
      return state.set("isUserNameReceived", false);
    case FETCHING_EMAIL_TO_RESET_PASSWORD_FAILED:
      return state.set("isUserNameReceived", false);
    default:
      return state;
  }
};
export default reducer;
