import {
  call,
  put,
  all,
  takeEvery,
  select,
  takeLatest,
} from "redux-saga/effects";

import {
  GET_INVOICE_FIELDS_URL,
  GET_FIELD_DEFINITION_ACTION,
  GET_INVOICE_URL,
  INVOICE_DETAILS_REQUEST,
  GET_REMITTANCE_URL,
  REMITTANCE_DETAILS_REQUEST,
  GET_CURRENCY_LIST_ACTION,
  GET_CURRENCY_LIST_URL,
  INIT_FETCH_INVOICE_DETAILS,
  INIT_SUPPLIER_LIST,
  INIT_DOWNLOAD_INVOICE_DFM,
  INIT_GET_SELECTED_SUPPLIER_LIST,
} from "./actionTypes";

import {
  updateInvoiceDetailsAction,
  updateRemittanceDetailsAction,
  updateCurrencyListAction,
  fetchingInvoiceDetails,
  fetchInvoiceDetails,
  invoiceDetailsFetchComplete,
  fetchingSupplierList,
  fetchSupplierList,
  fetchSupplierListComplete,
  setDownloadInvoice,
} from "./actions";

import axios from "../../../config/axios";
import {
  selectAdminRoleDetails,
  selectSelectedUserId,
} from "../../ReportDetails/store/selectors";

let sessionDetails = JSON.parse(sessionStorage.getItem("sessionDetails")) || {};

export default function* rootInvoiceAndCreditsSaga() {
  yield all([
    takeEvery(GET_FIELD_DEFINITION_ACTION, getFieldDefinition),
    takeEvery(INVOICE_DETAILS_REQUEST, getInvoiceDetails),
    takeEvery(REMITTANCE_DETAILS_REQUEST, getRemittanceDetails),
    takeEvery(GET_CURRENCY_LIST_ACTION, getCurrencyList),
    takeEvery(INIT_FETCH_INVOICE_DETAILS, getInvoiceDetailsSaga),
    takeEvery(INIT_SUPPLIER_LIST, getSupplierListSaga),
    takeEvery(INIT_DOWNLOAD_INVOICE_DFM, getDownloadInvoiceFromDFMSaga),
    takeLatest(INIT_GET_SELECTED_SUPPLIER_LIST, getSelectedSupplierListSaga),
  ]);
}

const getRequestDetails = {
  method: "GET",
  cache: "no-cache",
};

const postRequestDetails = {
  method: "POST",
  cache: "no-cache",
  headers: {
    "Content-Type": "application/json",
  },
};

function* getFieldDefinition(action) {
  let sessionDetails =
    JSON.parse(sessionStorage.getItem("sessionDetails")) || {};
  let languageID =
    sessionDetails &&
    sessionDetails.languageId &&
    sessionDetails.languageId !== ""
      ? sessionDetails.languageId
      : "enGB";
  const param = action && action.invoiceParam;
  try {
    const URL =
      GET_INVOICE_FIELDS_URL + param.findInInvoices + "&lanKey=" + languageID;
    const response = yield call(axios, URL, getRequestDetails);

    if (response === [] || response === null || response === -undefined) {
      if (action.callBackFunction) {
        action.callBackFunction();
      }
    } else {
      if (action.callBackFunction) {
        action.callBackFunction(response.data);
      }
    }
  } catch (err) {
    action.callBackFunction && action.callBackFunction();
  }
}

function* getInvoiceDetails(action) {
  let invoiceDetails = [];
  try {
    postRequestDetails.data = JSON.stringify(action.body);
    const response = yield call(axios, GET_INVOICE_URL, postRequestDetails);

    invoiceDetails = response.data || [];
    yield put(
      updateInvoiceDetailsAction(invoiceDetails, invoiceDetails.rowCount)
    );
    if (
      invoiceDetails === [] ||
      invoiceDetails === null ||
      invoiceDetails === undefined
    ) {
      if (action.callBackFunction) {
        action.callBackFunction();
      }
    } else {
      if (action.callBackFunction) {
        action.callBackFunction(invoiceDetails, invoiceDetails.rowCount);
      }
    }
  } catch (err) {
    action.callBackFunction && action.callBackFunction();
  }
}

export function* getRemittanceDetails(action) {
  const { invoiceID, isArchival } = action.param || {
    invoiceID: 0,
    isArchival: false,
  };
  let sessionDetails =
    JSON.parse(sessionStorage.getItem("sessionDetails")) || {};
  let languageID =
    sessionDetails &&
    sessionDetails.languageId &&
    sessionDetails.languageId !== ""
      ? sessionDetails.languageId
      : "enGB";
  try {
    const url =
      GET_REMITTANCE_URL +
      "?invoiceID=" +
      invoiceID +
      "&LanguageID=" +
      languageID +
      "&isArchival=" +
      isArchival;
    const response = yield call(axios, url, getRequestDetails);
    yield put(updateRemittanceDetailsAction(response.data));
  } catch (err) {
    action.callback && action.callback();
  }
}

function* getCurrencyList(action) {
  var invoiceCurrency = [];
  try {
    const response = yield call(
      axios,
      GET_CURRENCY_LIST_URL,
      getRequestDetails
    );

    invoiceCurrency = response.data;
    yield put(updateCurrencyListAction(invoiceCurrency));
  } catch (err) {
    action.callback && action.callback();
  }
}

function* getInvoiceDetailsSaga(action) {
  //These two params are mandatory, so default value are used in case of not being passed
  const { invoiceId = 0, isArchival = false } = action.payload;
  let sessionDetails =
    JSON.parse(sessionStorage.getItem("sessionDetails")) || {};
  let languageID =
    sessionDetails &&
    sessionDetails.languageId &&
    sessionDetails.languageId !== ""
      ? sessionDetails.languageId
      : "enGB";
  let url = "";
  if (action.globalSearch) {
    url = `Search/listInvoiceByID?InvoiceNumber=${invoiceId}&LanguageID=${languageID}`;
  } else
    url = `Search/listInvoiceByID?invoiceID=${invoiceId}&LanguageID=${languageID}&isArchival=${isArchival}`;
  try {
    yield put(fetchingInvoiceDetails());
    const response = yield call(axios, url, getRequestDetails);
    if (response && response.status === 200) {
      yield put(fetchInvoiceDetails(response.data));
      if (action.callback) {
        action.callback();
      }
    }
    if (response.status === 204) {
      // no action
      // const notification = buildNotification({
      //   message: "notification.noDataDisplayLabel",
      //   type: "danger",
      // });
      // store.addNotification({
      //   ...notification,
      // });
    }
    yield put(invoiceDetailsFetchComplete());
  } catch (error) {
    action.callback && action.callback();
  }
}

function* getSupplierListSaga(action) {
  yield put(fetchingSupplierList());
  // For Invoice Reports if the role is admin populate supplier based on user click
  const isRoleAdmin = yield select(selectAdminRoleDetails());
  const selectedUserId = yield select(selectSelectedUserId());
  let langID =
    sessionDetails &&
    sessionDetails.languageId &&
    sessionDetails.languageId !== ""
      ? sessionDetails.languageId
      : "enGB";
  let url = isRoleAdmin
    ? `/Supplier/companiesForLookUpForInvoiceReports?langID=${langID}&userId=${selectedUserId}&`
    : `/Supplier/companiesForLookUp?langID=${langID}&`;
  if (action.data && action.data.userId)
    url = "/Supplier/companiesForLookUpEdit?langID=" + langID + "&";
  let keys = action.data && Object.keys(action.data);
  keys &&
    keys.forEach((item) => {
      if (action.data[item]) {
        url = url + item + "=" + action.data[item] + "&";
      }
    });
  try {
    const response = yield call(axios, url, getRequestDetails);

    if (action.callback) action.callback(response);
    if (response && response.status === 200) {
      const resObj = { companies: [], totalSupplierCount: 0 };
      if (isRoleAdmin && selectedUserId) {
        resObj.companies = response.data;
        resObj.totalSupplierCount = response.data && response.data.length;
      } else {
        const { companies, totalCount: totalSupplierCount } = response.data || {
          companies: [],
          totalCount: 0,
        };
        resObj.companies = companies;
        resObj.totalSupplierCount = totalSupplierCount;
      }
      yield put(fetchSupplierList(resObj.companies, resObj.totalSupplierCount));
    }
    yield put(fetchSupplierListComplete());
  } catch (err) {
    yield put(fetchSupplierListComplete());
  }
}

function* getDownloadInvoiceFromDFMSaga(action) {
  if (action.urn === null) return;
  let url = `Search/DownloadInvoicefromDFM?URN=${action.urn}`;
  try {
    const response = yield call(axios, url, getRequestDetails);
    if (response && response.status === 200) {
      yield put(setDownloadInvoice(response.data));
    }
  } catch (err) {
    action.callback && action.callback();
  }
}

function* getSelectedSupplierListSaga(action) {
  let langID =
    sessionDetails &&
    sessionDetails.languageId &&
    sessionDetails.languageId !== ""
      ? sessionDetails.languageId
      : "enGB";
  let url = yield "Supplier/SupplierList?langID=" + langID;
  let keys = action.payload && Object.keys(action.payload);
  keys &&
    keys.forEach((item) => {
      if (action.payload[item]) {
        url += `&${item}=${action.payload[item]}`;
      }
    });

  try {
    const response = yield call(axios, url, getRequestDetails);

    if (action.callback) action.callback(response);
  } catch (err) {
    action.callback && action.callback(err);
  }
}
