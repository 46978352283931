export const INIT_DOCUMENT_HISTORY_FETCH = "INIT_DOCUMENT_HISTORY_FETCH";
export const FETCHING_DOC_LIST = "FETCHING_DOC_LIST";
export const FETCH_DOCUMENT_SUBMISSION_HISTORY =
  "FETCH_DOCUMENT_SUBMISSION_HISTORY";
export const DOC_FETCH_COMPLETE = "DOC_FETCH_COMPLETE";

// Document Submit action types
export const INIT_COUNTRY_LIST_FETCH = "INIT_COUNTRY_LIST_FETCH";
export const FETCHING_COUNTRY_LIST = "FETCHING_COUNTRY_LIST";
export const FETCH_COUNTRY_LIST = "FETCH_COUNTRY_LIST";
export const COUNTRY_LIST_FETCH_COMPLETE = "COUNTRY_LIST_FETCH_COMPLETE";

export const INIT_SUPPLIER_LIST_FETCH = "INIT_SUPPLIER_LIST_FETCH";
export const FETCHING_SUPPLIER_LIST = "FETCHING_SUPPLIER_LIST";
export const FETCH_SUPPLIER_LIST = "FETCH_SUPPLIER_LIST";
export const SUPPLIER_LIST_FETCH_COMPLETE = "SUPPLIER_LIST_FETCH_COMPLETE";

export const INIT_PROFILE_LIST_FETCH = "INIT_PROFILE_LIST_FETCH";
export const FETCHING_PROFILE_LIST = "FETCHING_PROFILE_LIST";
export const FETCH_PROFILE_LIST = "FETCH_PROFILE_LIST";
export const PROFILE_LIST_FETCH_COMPLETE = "PROFILE_LIST_FETCH_COMPLETE";

export const INIT_FETCH_ADDITIONAL_FIELDS = "INIT_FETCH_ADDITIONAL_FIELDS";
export const FECTHING_ADDITIONAL_FIELDS = "FECTHING_ADDITIONAL_FIELDS";
export const FETCH_ADDITIONAL_FIELDS = "FETCH_ADDITIONAL_FIELDS";
export const ADDITIONAL_FIELDS_FETCH_COMPLETE =
  "ADDITIONAL_FIELDS_FETCH_COMPLETE";
export const CLEAR_ADDITIONAL_FIELDS = "CLEAR_ADDITIONAL_FIELDS";

export const INIT_DOCUMENT_SUBMISSION_SAVE = "INIT_DOCUMENT_SUBMISSION_SAVE";

export const INIT_GET_DOCUMENT_SUBMISSION = "INIT_GET_DOCUMENT_SUBMISSION";

export const INIT_DOCUMENT_SUBMISSION_EDIT = "INIT_DOCUMENT_SUBMISSION_EDIT";
export const FETCHING_DOCUMENT_SUBMISSION_EXISTING_DATA =
  "FETCHING_DOCUMENT_SUBMISSION_EXISTING_DATA";
export const FETCH_DOCUMENT_SUBMISSION_EXISTING_DATA =
  "FETCH_DOCUMENT_SUBMISSION_EXISTING_DATA";
export const FETCH_DOCUMENT_SUBMISSION_EXISTING_DATA_COMPLETE =
  "FETCH_DOCUMENT_SUBMISSION_EXISTING_DATA_COMPLETE";
export const SET_DOCUMENT_SUBMISSION_FOR_EDIT_COMPLETED =
  "SET_DOCUMENT_SUBMISSION_FOR_EDIT_COMPLETED";
export const NO_DATA_FOUND_FOR_EDIT = "NO_DATA_FOUND_FOR_EDIT";

export const INIT_PO_NUMBER_FETCH = "INIT_PO_NUMBER_FETCH";
export const FETCHING_PO_NUMBER = "FETCHING_PO_NUMBER";
export const FETCH_PO_NUMBER = "FETCH_PO_NUMBER";
export const PO_NUMBER_FETCH_COMPLETE = "PO_NUMBER_FETCH_COMPLETE";

export const INIT_PO_DETAILS_FETCH = "INIT_PO_DETAILS_FETCH";
export const PO_DETAILS_FETCH_COMPLETE = "PO_DETAILS_FETCH_COMPLETE";

export const INIT_OCR_DETAILS_FETCH = "INIT_OCR_DETAILS_FETCH";
export const FETCHING_OCR_DETAILS = "FETCHING_OCR_DETAILS";
export const FETCH_OCR_DETAILS = "FETCH_OCR_DETAILS";
export const OCR_DETAILS_FETCH_COMPLETE = "OCR_DETAILS_FETCH_COMPLETE";
export const OCR_DETAILS_FETCH_FAILED = "OCR_DETAILS_FETCH_FAILED";

export const FETCH_DOC_EXTRACTED_DATA = "FETCH_DOC_EXTRACTED_DATA";

export const INIT_SAVE_EINVOICE_WITH_DETAILS =
  "INIT_SAVE_EINVOICE_WITH_DETAILS";

export const INIT_UPLOAD_SUPPORTING_ATTACHMENTS =
  "INIT_UPLOAD_SUPPORTING_ATTACHMENTS";
export const SUPPORTING_ATTCAHMENTS_UPLOADING =
  "SUPPORTING_ATTCAHMENTS_UPLOADING";
export const SET_UPLOAD_PROGRESS = "SET_UPLOAD_PROGRESS";
export const SUPPORTING_ATTCAHMENTS_UPLOADED =
  "SUPPORTING_ATTCAHMENTS_UPLOADED";
export const SUPPORTING_ATTCAHMENTS_UPLOAD_FAIL =
  "SUPPORTING_ATTCAHMENTS_UPLOAD_FAIL";
export const SUPPORTING_ATTCAHMENTS_UPLOAD_RESET =
  "SUPPORTING_ATTCAHMENTS_UPLOAD_RESET";

export const INIT_PDF_GENERATION = "INIT_PDF_GENERATION";
export const PDF_GENERATING = "PDF_GENERATING";
export const PDF_GENERATED = "PDF_GENERATED";
export const PDF_GENERATE_FAILED = "PDF_GENERATE_FAILED";

export const INIT_SAVE_MAPPING = "INIT_SAVE_MAPPING";

export const INIT_DOWNLOAD_SAMPLE_DOC = "INIT_DOWNLOAD_SAMPLE_DOC";
