import { fromJS } from "immutable";

import {
  UPDATE_SUPPLIER_FILTERS_ACTION,
  UPDATE_SUPPLIER_FIELDS_ACTION,
  UPDATE_SUPPLIER_COLUMNS,
  UPDATE_SUPPLIER_LIST_ACTION,
  UPDTAE_SUPPLIER_LIST_BY_ID,
  UPDATE_SELECTED_SUPPLIERS_ACTION,
  UPDATE_SHOW_SUPPLIER_MODAL,
} from "./constants";

export const initialState = fromJS({
  supplierFilters: [],
  supplierFieldListMaster: [],
  supplierFieldListWorkFlow: [],
  supplierColumns: [],
  supplierList: [],
  supplierFilterFields: [],
  selectedSuppliers: [],
  showSupplier: false,
});

function supplierReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_SUPPLIER_FILTERS_ACTION:
      return state.set("supplierFilters", action.supplierFilters);
    case UPDATE_SUPPLIER_FIELDS_ACTION:
      if (action.fieldPlacementTypeId === 1) {
        return state.set("supplierFieldListMaster", action.supplierFields);
      }
      if (action.fieldPlacementTypeId === 5) {
        return state.set("supplierFieldListWorkFlow", action.supplierFields);
      }
      break;
    case UPDATE_SUPPLIER_COLUMNS:
      return state.set("supplierColumns", action.supplierColumns);
    case UPDATE_SUPPLIER_LIST_ACTION:
      return state.set("supplierList", action.supplierList);
    case UPDTAE_SUPPLIER_LIST_BY_ID:
      var data = action.payload;
      if (data.fieldList != null && data.fieldList.length > 0) {
        return state.set("supplierFilterFields", data.fieldList);
      } else {
        return state
          .set("supplierList", data.supplierList)
          .set("supplierFilterFields", data.fieldList);
      }
    case UPDATE_SELECTED_SUPPLIERS_ACTION:
      return state.set("selectedSuppliers", action.selectedSuppliers);
    case UPDATE_SHOW_SUPPLIER_MODAL:
      return state.set("showSupplier", action.showSupplier);
    default:
      return state;
  }
}

export default supplierReducer;
