import styled from "styled-components";
import A from "../../A";

export const FooterWrapper = styled.footer`
  display: flex;
  flex-direction: row;
  height: 64px;
  background: #fff;
  box-shadow: 0px -2px 16px 0px rgba(33, 43, 54, 0.1),
    0px -1px 0px 0px rgb(239, 242, 245);
  border-radius: 0px;
  left: 0;
  bottom: 0;
  right: 0;
  justify-content: center;
  padding: 0 24px;
  @media screen and (max-width: 991px) {
    height: 48px;
  }
`;

export const ClientLogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  padding: 4px 0;

  @media screen and (max-width: 640px) {
    display: none;
  }
`;
export const FooterText = styled.p`
  margin: 0;
  padding: ${(props) => props.padding || 0};
  align-self: center;
  position: relative;
  height: 12px;
  color: rgb(145, 158, 171);
  font-size: 12px;
  font-family: graphik;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 12px;
  cursor: pointer;
  @media screen and (max-width: 640px) {
    display: ${(props) => (props.alwaysVisible ? "flex" : "none")};
  }
`;

export const CopyrightWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  position: relative;
  padding: 26px 48px 26px 80px;
  @media screen and (max-width: 640px) {
    padding: 0;
  }
`;
export const StaticPage = styled(A)`
  position: relative;
  float: left;
  width: auto;
  color: #919eab;
  font-size: 12px;
  font-family: graphik;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 19px;
  margin-right: 15px;
  align-self: center;
`;

export const wrapperDivStyle = {
  bgColor: "#ffffff",
  position: "relative",
  borderRadius: "10px",
  overflow: "auto",
  divHeight: "100%",
};
