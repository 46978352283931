import {
  INIT_HELPDESK_OPENCASES_HISTORY_FETCH,
  INIT_FETCH_COMPLETE,
  FETCH_CASES_SUCCESS,
  FETCH_CASES_FAILURE,
  FETCH_ATTACHMENT,
  FETCH_ADDITIONAL_FIELDS,
  FETCH_HELPDESK_TLOG,
  FETCH_HELPDESK_TLOG_SUCCESS,
  FETCH_HELPDESK_TLOG_FAILURE,
  SUBMIT_REQUEST,
  SUBMIT_SUCCESS,
  SUBMIT_FAILURE,
  QUERY_TYPE_LIST,
  FETCH_QUERY_TYPE_LIST,
  QUERY_FIELDS_LIST,
  DOWNLOADED_ATTACHMENT,
  FETCHING_HELPDESK_DETAILS_BY_ID,
  FETCHING_HELPDESK_DETAILS_BY_ID_COMPLETED,
  RESET_FORM_SUBMISSION_STATUS,
  INIT_SAVE_COMMENT,
  COMMENT_SAVED,
  COMMENT_SAVE_FAILED,
  RESET_COMMENT_SAVE_STATUS,
  GET_COMMENTS,
  FETCH_COMMENT,
  FETCHING_COMMENTS_END,
  INIT_FETCH_QUERY_FIELDS,
  FETCHING_QUERY_FIELDS,
  QUERY_FIELDS_FETCH_COMPLETED,
  INIT_ADD_ATTACHMENT,
  ADDING_ATTACHMENT,
  ATTACHMENT_ADDED,
  ATTACHMENT_ADDED_COMPLETED,
  INIT_HELPDESK_OPEN_REQUEST_MARK_AS_COMPLETE,
  SAVED_HELPDESK_OPEN_REQUEST_MARK_AS_COMPLETE,
  INIT_GET_HELPDESK_QUERY_TYPE,
  FETCHING_GET_HELPDESK_QUERY_TYPE,
  FETCH_GET_HELPDESK_QUERY_TYPE,
  GET_HELPDESK_QUERY_TYPE_COMPLETED,
  UPDATE_HELPDESK_CASES,
  FETCHING_CASES,
  INIT_GET_SERVICE_QUERY_BY_ID,
  GET_SERVICE_QUERY_BY_ID_COMPLETED,
  FETCH_GET_SERVICE_QUERY_BY_ID,
  FETCHING_GET_SERVICE_QUERY_BY_ID,
  INIT_SERVICE_NOW_SAVE_COMMENT,SERVICE_NOW_COMMENT_SAVED,SERVICE_NOW_COMMENT_SAVE_FAILED

} from "./constants";

export const initOpenCasesHistoryList = (isExtHelpDeskLinkUsed, filterData,clientName) => {
  return {
    type: INIT_HELPDESK_OPENCASES_HISTORY_FETCH,
    isExtHelpDeskLinkUsed,
    filterData,
    clientName  };
};

export const fetchingCases = () => {
  return {
    type: FETCHING_CASES,
  };
};



export function FetchCasesSuccess(data, rowCount) {
  return {
    type: FETCH_CASES_SUCCESS,
    data: data,
    rowCount,
  };
}

export function FetchCasesFailure(message) {
  return {
    type: FETCH_CASES_FAILURE,
    message,
  };
}
export function updateHelpdeskCases(data, rowCount) {
  return {
    type: UPDATE_HELPDESK_CASES,
    data,
    rowCount,
  };
}
export const initFetchComplete = () => {
  return {
    type: INIT_FETCH_COMPLETE,
  };
};
export function fetchAttachment(data) {
  return {
    type: FETCH_ATTACHMENT,
    data,
  };
}

export function downloadedAttachment(data) {
  return {
    type: DOWNLOADED_ATTACHMENT,
    data,
  };
}

export const saveNewRequest = (
  formData,
  isExtHelpDeskLinkUsed,
  clientName,
  primaryEmail,
  saveSuccess
) => {
  return {
    type: SUBMIT_REQUEST,
    formData,
    isExtHelpDeskLinkUsed,
    clientName,
    primaryEmail,
    saveSuccess,
  };
};

export const submitSuccess = () => {
  return {
    type: SUBMIT_SUCCESS,
  };
};
export const resetFormSubmissionStatus = () => {
  return {
    type: RESET_FORM_SUBMISSION_STATUS,
  };
};
export function submitFailure() {
  return {
    type: SUBMIT_FAILURE,
  };
}

export function fetchqueryTypeList() {
  return {
    type: FETCH_QUERY_TYPE_LIST,
  };
}

export function queryTypeList(data) {
  return {
    type: QUERY_TYPE_LIST,
    data,
  };
}

export function initFetchqueryFields(queryField, fetchDynamicFieldSuccess) {
  return {
    type: INIT_FETCH_QUERY_FIELDS,
    queryField,
    fetchDynamicFieldSuccess,
  };
}
export function fetchingqueryFields() {
  return {
    type: FETCHING_QUERY_FIELDS,
  };
}

export function queryFieldsList(data) {
  return {
    type: QUERY_FIELDS_LIST,
    data,
  };
}
export function queryFieldsListFetchingCompleted() {
  return {
    type: QUERY_FIELDS_FETCH_COMPLETED,
  };
}
export function fetchTLogCase(reqID, fetchHelpdeskByIdSuccess) {
  return {
    type: FETCH_HELPDESK_TLOG,
    reqID,
    fetchHelpdeskByIdSuccess,
  };
}
export function fetchingHelpdeskDetailsByID() {
  return {
    type: FETCHING_HELPDESK_DETAILS_BY_ID,
  };
}
export function fetchTLogSuccess(data) {
  return {
    type: FETCH_HELPDESK_TLOG_SUCCESS,
    data,
  };
}
export function fetchingHelpdeskDetailsByIDCompleted() {
  return {
    type: FETCHING_HELPDESK_DETAILS_BY_ID_COMPLETED,
  };
}

export function fetchTLogFailure(message) {
  return {
    type: FETCH_HELPDESK_TLOG_FAILURE,
    message,
  };
}

export const fetchAdditionalFields = (data) => {
  return {
    type: FETCH_ADDITIONAL_FIELDS,
    data: data,
  };
};

// save comment internal
export const initCommentSave = (payload, ID, commentSaveSuccess) => {
  return {
    type: INIT_SAVE_COMMENT,
    payload,
    ID,
    commentSaveSuccess,
  };
};

export const commentSaved = () => {
  return {
    type: COMMENT_SAVED,
  };
};
export const commentSaveFailed = () => {
  return {
    type: COMMENT_SAVE_FAILED,
  };
};

export const resetCommentSave = () => {
  return {
    type: RESET_COMMENT_SAVE_STATUS,
  };
};
//save comment service now
export const initServicenowCommentSave = (formdata,clientName, callback) => {
  return {
    type: INIT_SERVICE_NOW_SAVE_COMMENT,
    formdata,
    clientName,
    callback
  };
};
export const servicenowCommentSaved = () => {
  return {
    type: SERVICE_NOW_COMMENT_SAVED,
  };
};
export const serviceNowCommentSaveFailed = () => {
  return {
    type: SERVICE_NOW_COMMENT_SAVE_FAILED,
  };
};
// get comment

export const getComments = (id) => {
  return {
    type: GET_COMMENTS,
    id,
  };
};

export const fetchComments = (data) => {
  return {
    type: FETCH_COMMENT,
    data,
  };
};

export const fetchCommentsEnd = () => {
  return {
    type: FETCHING_COMMENTS_END,
  };
};

export const initAddAttachment = (formData, fetchSuccess) => {
  return {
    type: INIT_ADD_ATTACHMENT,
    formData,
    callback: fetchSuccess,
  };
};

export const addingAttachment = () => {
  return {
    type: ADDING_ATTACHMENT,
  };
};

export const attachmentAdded = () => {
  return {
    type: ATTACHMENT_ADDED,
  };
};

export const attachmentAddCompleted = () => {
  return {
    type: ATTACHMENT_ADDED_COMPLETED,
  };
};

export const initHelpdeskOpenRequestMarkAsComplete = (
  helpdeskID,
  saveSuccess
) => {
  return {
    type: INIT_HELPDESK_OPEN_REQUEST_MARK_AS_COMPLETE,
    helpdeskID,
    saveSuccess,
  };
};
export const savedHelpdeskOpenRequestMarkAsComplete = () => {
  return {
    type: SAVED_HELPDESK_OPEN_REQUEST_MARK_AS_COMPLETE,
  };
};

export const initGetHelpdeskQuery = () => {
  return {
    type: INIT_GET_HELPDESK_QUERY_TYPE,
  };
};

export const FetchingGetHelpdeskQuery = () => {
  return {
    type: FETCHING_GET_HELPDESK_QUERY_TYPE,
  };
};

export const FetchGetHelpdeskQuery = (data) => {
  return {
    type: FETCH_GET_HELPDESK_QUERY_TYPE,
    data,
  };
};

export const helpdeskQueryFetchCompleted = () => {
  return {
    type: GET_HELPDESK_QUERY_TYPE_COMPLETED,
  };
};


export const initGetServiceNowQueryByID = (reqID,clientName, fetchServicenowByIdSuccess) => {
  return {
    type: INIT_GET_SERVICE_QUERY_BY_ID,
    reqID,
    clientName,
    fetchServicenowByIdSuccess
  };
};

export const FetchingGetServiceNowQueryByID = () => {
  return {
    type: FETCHING_GET_SERVICE_QUERY_BY_ID,
  };
};

export const FetchGetServiceNowQueryByID = (data) => {
  return {
    type: FETCH_GET_SERVICE_QUERY_BY_ID,
    data,
  };
};

export const ServiceNowQueryByIDFetchCompleted = () => {
  return {
    type: GET_SERVICE_QUERY_BY_ID_COMPLETED,
  };
};
