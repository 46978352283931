import { fromJS } from "immutable";
import {
  FETCHING_FILE_TYPE,
  FETCH_FILE_TYPE,
  FILE_TYPE_FETCH_COMPLETE,
  INIT_USER_UPLOAD_SAVE,
  USER_UPLOAD_SAVED,
  USER_UPLOAD_SAVE_FAILED,
  FETCHING_USER_UPLOAD,
  FETCH_USER_UPLOAD,
  USER_UPLOAD_FETCH_COMPLETE,
  FETCHING_SUPPLIER_UPLOAD,
  FETCH_SUPPLIER_UPLOAD,
  SUPPLIER_UPLOAD_FETCH_COMPLETE,
  FETCHING_RECENT_UPLOADED_FILES,
  FETCH_RECENT_UPLOADED_FILES_COMPLETE,
  FETCHED_RECENT_UPLOADED_FILES,
  DOWNLOADING_RECENT_UPLOAD_FILE,
  DOWNLOADED_RECENT_UPLOAD_FILE_COMPLETE,
  DOWNLOAD_RECENT_UPLOAD_FILE_FAILED,
  RESET_FORM_SAVED_STATE,
  INIT_GET_BULK_UPLOAD_TYPES,
  GET_BULK_UPLOAD_TYPES_COMPLETED,
  GET_BULK_UPLOAD_TYPES_FAILED,
  FETCHING_UPLOAD,
  FETCH_UPLOAD,
  UPLOAD_FETCH_COMPLETE,
} from "./actionTypes";
export const initialState = fromJS({
  fileTypeStatus: false,
  fileType: [],
  userUploadSaving: false,
  userUploadSaved: false,
  userUploadSaveFail: false,
  editing: false,
  bulkSuppliers: "",
  massSuppliers: "",
  fetchingUserUpload: false,
  userUploadData: [],
  fetchingSupplierUpload: false,
  supplierUploadData: [],
  recentUploadedFilesStatus: false,
  recentUploadedFiles: [],
  downloadingFileStatus: false,
  formSubmitted: false,
  bulkUploadTypes:[],
  bulkUploadTypeStatus:false,
});
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_FILE_TYPE: {
      return state.set("fileTypeStatus", true);
    }
    case FETCH_FILE_TYPE: {
      return state.set("fileType", action.data);
    }
    case FILE_TYPE_FETCH_COMPLETE: {
      return state.set("fileTypeStatus", false);
    }
    case INIT_USER_UPLOAD_SAVE: {
      return state.set("userUploadSaving", true);
    }
    case USER_UPLOAD_SAVED: {
      return state
        .set("userUploadSaved", true)
        .set("userUploadSaving", false)
        .set("editing", false)
        .set("formSubmitted", true);
    }
    case USER_UPLOAD_SAVE_FAILED: {
      return state
        .set("userUploadSaving", false)
        .set("userUploadSaveFail", true);
    }
    case FETCHING_USER_UPLOAD: {
      return state.set("fetchingUserUpload", true);
    }
    case FETCH_USER_UPLOAD: {
      return state.set("userUploadData", action.data);
    }
    case USER_UPLOAD_FETCH_COMPLETE: {
      return state.set("fetchingUserUpload", false);
    }
    case FETCHING_SUPPLIER_UPLOAD: {
      return state.set("fetchingSupplierUpload", true);
    }
    case FETCH_SUPPLIER_UPLOAD: {
      return state.set("supplierUploadData", action.data);
    }
    case SUPPLIER_UPLOAD_FETCH_COMPLETE: {
      return state.set("fetchingSupplierUpload", false);
    }
    case FETCHING_UPLOAD: {
      return state.set("fetchingUpload", true);
    }
    case FETCH_UPLOAD: {
      return state.set("UploadData", action.data);
    }
    case UPLOAD_FETCH_COMPLETE: {
      return state.set("fetchingUpload", false);
    }
    case FETCHING_RECENT_UPLOADED_FILES: {
      return state.set("recentUploadedFilesStatus", true);
    }
    case FETCH_RECENT_UPLOADED_FILES_COMPLETE: {
      return state.set("recentUploadedFilesStatus", false);
    }
    case FETCHED_RECENT_UPLOADED_FILES: {
      return state.set("recentUploadedFiles", action.data);
    }
    case DOWNLOADING_RECENT_UPLOAD_FILE: {
      return state.set("downloadingFileStatus", true);
    }
    case DOWNLOADED_RECENT_UPLOAD_FILE_COMPLETE: {
      return state.set("downloadingFileStatus", false);
    }
    case DOWNLOAD_RECENT_UPLOAD_FILE_FAILED: {
      return state.set("downloadingFileStatus", false);
    }
    case RESET_FORM_SAVED_STATE: {
      return state.set("formSubmitted", false);
    }
    case INIT_GET_BULK_UPLOAD_TYPES: {
      return state.set("bulkUploadTypeStatus", true);
    }
    case GET_BULK_UPLOAD_TYPES_COMPLETED: {
      return state.set("bulkUploadTypes", action.data).set("bulkUploadTypeStatus", false);
    }
    case GET_BULK_UPLOAD_TYPES_FAILED: {
      return state.set("bulkUploadTypeStatus", false);
    }
    default:
      return state;
  }
};
export default reducer;
