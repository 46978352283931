import React, { Component, Fragment } from "react";
import Div from "../../components/Div";
import Embed from "../../components/Embed";

class ChatBotModalBody extends Component {
  render() {
    const ChatbotUrl = `https://divachatbotstaging.accenture.com/DIVA_ConversationalUI/embed/MSPChatBot_UAT?s=qfl6fysl3Dc.cwA.InI.j7GDcrDIbAtSw9ZE1CUnUQdkGALY1ZErk40gcdc3Dfo&user_ID=${this.props.encryptedUserName}&userName=${this.props.primaryEmail}&language=${this.props.languageId}`;
    return (
      <Fragment>
        <Div>
          <Embed
            src={ChatbotUrl}
            width="100%"
            height="100%"
            position="absolute"
          />
        </Div>
      </Fragment>
    );
  }
}

export default ChatBotModalBody;
