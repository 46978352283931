export const INIT_FETCH_USER_GROUP_LIST = "INIT_FETCH_USER_GROUP_LIST";
export const FETCHING_USER_GROUP_LIST = "FETCHING_USER_GROUP_LIST";
export const USER_GROUP_LIST = "USER_GROUP_LIST";
export const USER_GROUP_LIST_FETCHING_COMPLETED =
  "USER_GROUP_LIST_FETCHING_COMPLETED";

export const INIT_GET_BASE_ROLE = "INIT_GET_BASE_ROLE";
export const FETCHING_GET_BASE_ROLE = "FETCHING_GET_BASE_ROLE";
export const FETCH_BASE_ROLE = "FETCH_BASE_ROLE";
export const GET_BASE_ROLE_COMPLETED = "GET_BASE_ROLE_COMPLETED";

export const INIT_USER_GROUP_SAVE = "INIT_USER_GROUP_SAVE";
export const SAVE_USER_GROUP = "SAVE_USER_GROUP";
export const SAVE_USER_GROUP_FAILED = "SAVE_USER_GROUP_FAILED";

export const FORM_SUBMISSION_STATUS = "FORM_SUBMISSION_STATUS";
export const RESET_FORM_SUBMISSION_STATUS = "RESET_FORM_SUBMISSION_STATUS";

export const INIT_USER_GROUP_DELETE = "INIT_USER_GROUP_DELETE";
export const DELETE_SUCCESS = "DELETE_SUCCESS";
export const DELETE_FAILED = "DELETE_FAILED";

export const INIT_SELECT_USERS_LIST = "INIT_SELECT_USERS_LIST";
export const FETCHING_SELECT_USERS_LIST = "FETCHING_SELECT_USERS_LIST";
export const SELECT_USERS_LIST = "SELECT_USERS_LIST";
export const SELECT_USERS_LIST_FETCHING_COMPLETED =
  "SELECT_USERS_LIST_FETCHING_COMPLETED";

export const INIT_USER_GROUP_EDIT = "INIT_USER_GROUP_EDIT";
export const SET_USER_GROUP_EDIT_DATA = "SET_USER_GROUP_EDIT_DATA";
export const SET_USER_GROUP_EDIT_DATA_FAILED = "SET_USER_GROUP_EDIT_DATA_FAILED";


export const INIT_UPDATE_GROUP_STATUS = "INIT_UPDATE_GROUP_STATUS";
export const SAVE_UPDATED_GROUP_STATUS = "SAVE_UPDATED_GROUP_STATUS";
