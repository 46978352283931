import {
  UPDATE_SEARCHED_LOG_LIST,
  UPDATE_DATA_TO_DISPLAY,
  FETCHING_LOG_STATUS,
  FETCHING_LOG_COMPLETE,
  REPROCESSED_SAVED_OR_FAILED,
  INIT_EMAIL_LOG,
  FETCHING_EMAIL_LIST,
  FETCH_EMAIL_LOG_LIST,
  EMAIL_LOG_FETCH_COMPLETE,
  INIT_FETCH_EMAIL_LOG_DESCRIPTION_BY_ID,
  FETCHING_EMAIL_LOG_DESCRIPTION,
  FETCH_EMAIL_LOG_DESCRIPTION,
  EMAIL_LOG_DESCRIPTION_FETCH_COMPLETE,
  INIT_CLEAR_EMAIL_LOG,
  CLEARING_EMAIL_LOG,
  CLEARED_EMAIL_LOG,
  CLEAR_EMAIL_LOG_FAILED,
  INIT_LOGS_LIST,
  FETCH_LOGS_LIST,
  INIT_REPROCESS_SUBMISSION,
  RESET_LOG_LIST,
} from "./actionTypes";

export function initLogsList(param, updateDataToDisplay) {
  return {
    type: INIT_LOGS_LIST,
    callback: updateDataToDisplay,
    param,
  };
}
export const fetchLogsList = (data) => {
  return {
    type: FETCH_LOGS_LIST,
    data,
  };
};
export function fetchingLogStatus() {
  return {
    type: FETCHING_LOG_STATUS,
  };
}
export function fetchingLogComplete() {
  return {
    type: FETCHING_LOG_COMPLETE,
  };
}
export const updateDataToDisplay = (param, data) => {
  return {
    type: UPDATE_DATA_TO_DISPLAY,
    data: data,
    param,
  };
};

export const updateSearchedLogData = (data) => {
  return {
    type: UPDATE_SEARCHED_LOG_LIST,
    data: data,
  };
};
//email log
export const initEmailLog = (data) => {
  return {
    type: INIT_EMAIL_LOG,
    data,
  };
};

export const fetchingEmailList = () => {
  return {
    type: FETCHING_EMAIL_LIST,
  };
};

export const fetchEmailLogList = (data) => {
  return {
    type: FETCH_EMAIL_LOG_LIST,
    data,
  };
};

export const emailLogFetchComplete = () => {
  return {
    type: EMAIL_LOG_FETCH_COMPLETE,
  };
};

//email log description
export const initFetchEmailLogDescriptionById = (id) => {
  return {
    type: INIT_FETCH_EMAIL_LOG_DESCRIPTION_BY_ID,
    id,
  };
};

export const fetchingEmailLogDescription = () => {
  return {
    type: FETCHING_EMAIL_LOG_DESCRIPTION,
  };
};

export const fetchEmailLogDescription = (data) => {
  return {
    type: FETCH_EMAIL_LOG_DESCRIPTION,
    data,
  };
};

export const emailLogDescriptionFetchComplete = () => {
  return {
    type: EMAIL_LOG_DESCRIPTION_FETCH_COMPLETE,
  };
};

export const initClearEmailLog = (payload) => {
  return {
    type: INIT_CLEAR_EMAIL_LOG,
    payload,
  };
};
export const clearingEmailLog = () => {
  return {
    type: CLEARING_EMAIL_LOG,
  };
};
export const clearedEmailLog = () => {
  return {
    type: CLEARED_EMAIL_LOG,
  };
};
export const clearEmailLogFailed = () => {
  return {
    type: CLEAR_EMAIL_LOG_FAILED,
  };
};

export const initReprocessSubmission = (submissionId,callback) => {
  return {
    type: INIT_REPROCESS_SUBMISSION,
    submissionId,
    callback,
  }
}
export const reprocessedSavedORFailed = () => {
  return {
    type: REPROCESSED_SAVED_OR_FAILED,
  };
};
export const resetLogList = () => {
  return {
    type: RESET_LOG_LIST,
  };
}

