import { put, takeEvery, call, all } from "redux-saga/effects";

import {
  statisticsFetching,
  fetchStatistics,
  statisticsFetchComplete,
} from "../store/actions";

import { INIT_STATISTICS_FETCH } from "./actionTypes";
import axios from "../../../config/axios";

export default function* watchWorkflow() {
  yield all([takeEvery(INIT_STATISTICS_FETCH, initStatisticsFetchSaga)]);
}

function* initStatisticsFetchSaga(action) {
  yield put(statisticsFetching());
  const url = `/Report/statistics`;
  try {
    const response = yield call(axios, url);

    if (response && response.status === 200) {
      yield put(fetchStatistics(response.data));
    }
    yield put(statisticsFetchComplete());
  } catch (error) {
    yield put(statisticsFetchComplete());
  }
}
