import { put, takeEvery, call, all } from "redux-saga/effects";

import { fetchNotificationData } from "./actions";

import { GET_NOTIFICATION_DATA } from "./actionTypes";
import axios from "../../../config/axios";
import { getRequestDetails } from "../../../config/requestHeaders";

export default function* watchHomePage() {
  yield all([takeEvery(GET_NOTIFICATION_DATA, getNotification)]);
}

function* getNotification(action) {
  const url = "/Users/notifications";
  try {
    const response = yield call(axios, url, getRequestDetails);
    yield put(fetchNotificationData(response.data));
  } catch (error) {
    action.callback && action.callback();
  }
}
