import { fromJS } from "immutable";

import {
  UPDATE_INVOICE_FIELDS_ACTION,
  INVOICE_DETAILS_SUCCESS,
  GET_INVOICE_COLUMNS_ACTION,
  REMITTANCE_DETAILS_SUCCESS,
  UPDATE_REMITTANCE_COLUMNS_ACTION,
  UPDATE_INVOICE_DATA_ACTION,
  UPDATE_SHOW_FILTER_ACTION,
  INVOICE_DETAILS_REQUEST,
  REMITTANCE_DETAILS_REQUEST,
  UPDATE_CURRENCY_LIST,
  FETHCING_INVOICE_DETAILS,
  FETCH_INVOICE_DETAILS,
  INVOICE_DETAILS_FETCH_COMPLETE,
  FETCHING_SUPPLIER_LIST,
  FETCH_SUPPLIER_LIST,
  FETCH_SUPPLIER_LIST_COMPLETED,
  SET_INVOICE_DFM,
} from "./actionTypes";

export const initialState = fromJS({
  invoiceAllDetails: [],
  invoiceFields: [],
  invoiceColumns: [],
  remittanceList: [],
  remittanceColumns: [],
  invoiceData: [],
  showFilter: false,
  isInvoiceLoading: false,
  isRemittanceLoading: false,
  invoiceCurrency: [],
  invCreditNumFrom: "",
  showSupplier: false,
  supplierFilters: [],
  supplierFieldListMaster: [],
  supplierFieldListWorkFlow: [],
  supplierColumns: [],
  supplierList: [],
  totalSupplierCount: 0,
  supplierFilterFields: [],
  selectedSuppliers: [],
  invoiceDetails: {},
  fetchingInvoiceDetails: true,
  fetchSupplierList: true,
  invoiceList: [],
  rowCount: [],
  totalCount: [],
  downloadedInvoice: {},
});

function invoiceAndCreditsReducer(state = initialState, action) {
  switch (action.type) {
    case INVOICE_DETAILS_REQUEST:
      return state
        .set("isInvoiceLoading", true)
        .set("invoiceList", [])
        .set("invoiceAllDetails", []);
    case REMITTANCE_DETAILS_REQUEST:
      return state.set("isRemittanceLoading", action.isRemittanceLoading);
    case UPDATE_INVOICE_FIELDS_ACTION:
      return state.set("invoiceFields", action.invoiceFields);
    case GET_INVOICE_COLUMNS_ACTION:
      return state.set("invoiceColumns", action.columnsList);
    case INVOICE_DETAILS_SUCCESS:
      return state
        .set("invoiceAllDetails", action.invoiceAllDetails.invoiceList)
        .set("invoiceList", action.invoiceAllDetails.invoiceList)
        .set("rowCount", action.rowCount)
        .set("totalCount", action.rowCount)
        .set("isInvoiceLoading", false);
    case REMITTANCE_DETAILS_SUCCESS:
      return state
        .set("remittanceList", action.remittanceList)
        .set("isRemittanceLoading", action.isRemittanceLoading);
    case UPDATE_REMITTANCE_COLUMNS_ACTION:
      return state.set("remittanceColumns", action.columnsList);
    case UPDATE_INVOICE_DATA_ACTION:
      return state
        .set("invoiceAllDetails", action.invoiceData)
        .set("rowCount", action.rowCount);
    case UPDATE_SHOW_FILTER_ACTION:
      return state.set("showFilter", action.showFilter);
    case UPDATE_CURRENCY_LIST:
      return state.set("invoiceCurrency", action.invoiceCurrency);
    case FETHCING_INVOICE_DETAILS: {
      return state.set("fetchingInvoiceDetails", true);
    }
    case FETCH_INVOICE_DETAILS: {
      return state.set("invoiceDetails", action.data);
    }
    case INVOICE_DETAILS_FETCH_COMPLETE: {
      return state.set("fetchingInvoiceDetails", false);
    }
    case FETCHING_SUPPLIER_LIST: {
      return state
        .set("fetchingSupplierList", true)
        .set("supplierList", [])
        .set("totalSupplierCount", 0);
    }
    case FETCH_SUPPLIER_LIST: {
      return state
        .set("supplierList", action.data)
        .set("totalSupplierCount", action.totalSupplierCount);
    }
    case FETCH_SUPPLIER_LIST_COMPLETED: {
      return state.set("fetchingSupplierList", false);
    }
    case SET_INVOICE_DFM: {
      return state.set("downloadedInvoice", action.data);
    }
    default:
      return state;
  }
}

export default invoiceAndCreditsReducer;
